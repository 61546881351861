import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DrawerPage } from "../../../../components/common/BottomDrawer";
import { FavouritesProvider } from "../../../../Store/favourites";
import { FolderProvider } from "../../../../Store/folder";
import FavoriteFolderDrawer from "../FavoriteFolderDrawer";
import FavoritesDrawer from "../FavoritesDrawer";
import FolderOptionsDrawer from "../FolderOptionsDrawer";
import GalleryWrapperFaceResults from "../GalleryWrapperFaceResults";
import Header from "../Header";
import { useEvent } from "../../../../Store/event";
import { useUser } from "../../../../Store/user";
import {
  faecResults,
  getFaceResultWithFolders,
} from "../../../../apis/facesearch";
import Error from "../../../Errors";
import { useUploader } from "../../../../Store/upload";
import {
  EVENT_WITH_FACE_SEARCH_FOLDER,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  PHOTOGRAPHER_WITH_FACE_SEARCH_FOLDER,
  REFRESH_LOCAL_STORAGE,
} from "../../../../constants";
import { checkIsDeviceIOS } from "../../../../helper";
import Swal from "sweetalert2";

const FaceResults = () => {
  const eventDetails = useEvent().response;
  const { user, setUser } = useUser();
  const [isFavDrawerVisible, showFavoritiesDrawer] = useState(false);
  const [isFoldersDrawerVisible, showFoldersDrawer] = useState(false);
  const [isFolderOptionsDrawerVisible, showFolderOptionsDrawer] =
    useState(false);
  const [isFavourtieImagesVisible, showFavourtieImages] = useState(false);
  const [faceSearchFolders, setFaceSearchFolders] = useState([]);
  const [currentFaceSearchFolder, setCurrentFaceSearchFolder] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const { setGuestUploadedImages } = useUploader();

  const isDeviceIOS = checkIsDeviceIOS();

  const foldersClickHandler = (e) => {
    !isFoldersDrawerVisible ? (
      showFoldersDrawer(!isFoldersDrawerVisible)
    ) : (
      <></>
    );
    e.stopPropagation();
  };

  const favClickHandler = (e) => {
    showFavoritiesDrawer(!isFavDrawerVisible);
    e.stopPropagation();
  };

  const folderOptionsClickHandler = (e) => {
    showFolderOptionsDrawer(!isFolderOptionsDrawerVisible);
    e.stopPropagation();
  };

  const changeGallery = (e) => {
    showFoldersDrawer(e);
    showFavourtieImages(e);
  };

  const onFavoriteFolderClickHandler = (e) => {
    showFoldersDrawer(!isFoldersDrawerVisible);
    showFavourtieImages(!isFavourtieImagesVisible);
    e.stopPropagation();
  };

  const addTokenToLocalStorage = () =>
    localStorage.setItem(REFRESH_LOCAL_STORAGE, true);

  useEffect(() => {
    if (
      EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id) &&
      localStorage.getItem(REFRESH_LOCAL_STORAGE)
    )
      navigate(`/facerec/${eventDetails.data.slug}`);
    if (
      EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id) &&
      isDeviceIOS &&
      !user.search_id
    )
      navigate(`/facerec/${eventDetails.data.slug}`);
  }, []);

  useEffect(() => {
    if (EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id)) {
      window.addEventListener("beforeunload", addTokenToLocalStorage);
    }
    return () => {
      window.removeEventListener("beforeunload", addTokenToLocalStorage);
    };
  }, []);

  useEffect(async () => {
    let searchId = params.searchId;
    let imgs;
    if (
      EVENT_WITH_FACE_SEARCH_FOLDER.includes(eventDetails.data.id) ||
      PHOTOGRAPHER_WITH_FACE_SEARCH_FOLDER.includes(
        eventDetails.photographer.id
      )
    ) {
      imgs = await getFaceResultWithFolders(
        searchId,
        currentFaceSearchFolder?.id
      );
      setFaceSearchFolders(imgs.response.folders);
      if (!currentFaceSearchFolder)
        setCurrentFaceSearchFolder(imgs.response.folders[0]);
    } else {
      imgs = await faecResults(searchId, eventDetails.data.id);
      if (imgs.response.allImagesPrivate) {
        Swal.fire({
          icon: "warning",
          text: "Nice smile! We are yet to upload your images!! The one we found are private or deleted.",
        }).then(() => {
          setUser({ ...user, images: [], id: 0 });
          localStorage.removeItem("userFor" + eventDetails.data.id);
          navigate(`/facerec/${eventDetails.data.slug}`)
        });
      }
    }
    setUser({
      ...user,
      search_id: searchId,
      images: imgs.response.data,
      id: imgs.response?.guest?.id,
      phone: imgs.response?.guest?.phone ?? "",
      fname: imgs.response?.guest?.name?.split(" ")[0] ?? "",
      lname: imgs.response?.guest?.name?.split(" ")[0] ?? "",
      accessToken: imgs.response?.guest?.access_token,
      email: imgs.response?.guest?.email ?? "",
      maxPage: imgs.response?.meta?.last_page,
      isFaceSearch: true,
    });
    setGuestUploadedImages(imgs.response.guestUploads ?? []);
  }, [eventDetails]);

  if (!eventDetails.data.isPublished) {
    return (
      <Error
        message={
          <div className="text-center">This event has been unpublished</div>
        }
        text="Please contact your photographer for more details."
      />
    );
  } else
    return (
      <FolderProvider>
        <FavouritesProvider>
          <div className="dark:bg-dark-gray text-dark-gray1">
            <Header></Header>
            <GalleryWrapperFaceResults
              onFavClickHandler={favClickHandler}
              onFolderClickHandler={foldersClickHandler}
              onFolderOptionsClickHandler={folderOptionsClickHandler}
              changeGallery={changeGallery}
              showFavouriteImages={isFavourtieImagesVisible}
              isFoldersDrawerVisible={isFoldersDrawerVisible}
              currentFaceSearchFolder={currentFaceSearchFolder}
              setCurrentFaceSearchFolder={setCurrentFaceSearchFolder}
              faceSearchFolders={faceSearchFolders}
              setFaceSearchFolders={setFaceSearchFolders}
            ></GalleryWrapperFaceResults>
            {isFavDrawerVisible && (
              <DrawerPage
                component={<FavoritesDrawer />}
                isOpen={isFavDrawerVisible}
                setIsOpen={favClickHandler}
              />
            )}
            {isFoldersDrawerVisible && (
              <FavoriteFolderDrawer
                onFolderClickHandler={foldersClickHandler}
                changeGallery={changeGallery}
                onFavoriteFolderClickHandler={onFavoriteFolderClickHandler}
                isFoldersDrawerVisible={isFoldersDrawerVisible}
              />
              // <DrawerPage
              //     component={<FavoriteFolderDrawer />}
              //     isOpen={isFoldersDrawerVisible}
              //     setIsOpen={foldersClickHandler}
              // />
            )}
            {isFolderOptionsDrawerVisible && (
              <DrawerPage
                component={<FolderOptionsDrawer />}
                isOpen={isFolderOptionsDrawerVisible}
                setIsOpen={folderOptionsClickHandler}
              />
            )}
          </div>
        </FavouritesProvider>
      </FolderProvider>
    );
};

export default FaceResults;
