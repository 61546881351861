import React from "react";

const ProgressBar = (props) => {
  const { percent, size = "small" } = props;

  return (
    <div className="flex items-center w-full">
      <div
        className={`w-full relative overflow-hidden bg-dark-gray dark:bg-pale-gray min-h-[1px] rounded-xl ${
          size === "small" ? "h-[3px]" : "h-[6px]"
        }`}
      >
        <div
          className={`h-full absolute left-0 bg-dark-pink`}
          style={{ width: percent + "%" }}
        ></div>
      </div>
      <span className="text-dark-gray1 dark:text-light-gray pl-1 w-[30px]">{percent}%</span>
    </div>
  );
};

export default ProgressBar;
