import React, { useState, useContext, useEffect } from "react";

import DownArrow from "../../../assets/icons/DownArrow";
import CloseIcon from "../../../assets/icons/CloseIcon";
import MaximizeIcon from "../../../assets/icons/MaximizeIcon";
import UploadModal from "../UploadModal";
import BottomModal from "../BottomModal";
import { useUploader } from "../../../Store/upload";
import ImageQueue from "../ImageQueue";
import { ThemeContext } from "../../App";

const BottomUploadModal = ({}) => {
  const { coverImageUpload } = useUploader();

  const [isMaximizedModal, showMaximizedModal] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const { closeUploader, isUploading } = useUploader();

  const openMaximizedModal = () => {
    showMaximizedModal(true);
  };

  const theme = useContext(ThemeContext);
  const iconsColor = theme === "dark" ? "#EEE" : "black";

  useEffect(() => {
    if (coverImageUpload) setIsClosed(true);
    else setIsClosed(false)
  }, [coverImageUpload]);

  return (
    <>
      {!isMaximizedModal && (
        <BottomModal parentID="bottom-popup">
          <div
            style={{
              bottom: isClosed ? "-20rem" : 0,
              boxShadow:
                "4px 4px 10px 0 rgba(0, 0, 0, 0.7), -4px -4px 10px 0 rgba(58, 58, 58, 0.3)",
            }}
            className={`fixed rounded mb-[1px] left-5 p-4 bg-white  dark:bg-secondary-800 max-w-[calc(100%-40px)] w-[23.8rem] h-[24.1rem] duration-1000 transition-all z-[99]`}
          >
            <div className="flex justify-center flex-col">
              <div className="flex items-center justify-between mt-2">
                <p className="text-base text-dark-gray1 dark:text-light-gray">
                  Upload Queue
                </p>
                <div className="flex gap-4">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setIsClosed(!isClosed);
                    }}
                  >
                    <DownArrow
                      className="cursor-pointer"
                      color={iconsColor}
                      rotate={isClosed}
                    />
                  </span>
                  <span className="cursor-pointer" onClick={openMaximizedModal}>
                    <MaximizeIcon fill={iconsColor} />
                  </span>
                  {!isUploading && (
                    <span className="cursor-pointer" onClick={closeUploader}>
                      <CloseIcon
                        h="16"
                        w="16"
                        fill={
                          isUploading
                            ? theme === "dark"
                              ? "rgba(0,0,0,0.5)"
                              : "#aaa"
                            : theme === "dark"
                            ? "#eee"
                            : "black"
                        }
                      />
                    </span>
                  )}
                </div>
              </div>
              <ImageQueue isBottom={true} />
            </div>
          </div>
        </BottomModal>
      )}
      {isMaximizedModal && <UploadModal />}
    </>
  );
};

export default BottomUploadModal;
