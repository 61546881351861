import { Dialog, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Cropper from "react-easy-crop";

import CloseIcon from "../../../assets/icons/CloseIcon";
import CropIcon from "../../../assets/icons/CropIcon";
import DownArrow from "../../../assets/icons/DownArrow";
import FrameBlack from "../../../assets/images/Frame_Black.png";
import FrameBlue from "../../../assets/images/Frame_Blue.png";
import FramePurple from "../../../assets/images/Frame_Purple.png";
import FrameRed from "../../../assets/images/Frame_Red.png";
import FrameYellow from "../../../assets/images/Frame_Yellow.png";
import { ThemeContext } from "../../../components/App";
import IconButton from "../../../components/common/IconButton";
import {
  BMS_PHOTOGRAPHER_ID,
  CART_FRAME_STORE,
  INDIAN_CURRENCY,
  PHOTOGRAPHER_WITH_ALL_DIGITAL_DOWNLOAD,
  PRICING_IN_DOLLAR,
} from "../../../constants";
import { Colors } from "../../../constants/Color";
import { useCart } from "../../../Store/cart";
import { useEvent } from "../../../Store/event";
import Button from "../Button";
import SliderComponent from "../Slider";
import Text from "../Text";
import OtherFrames from "./OtherFrames";
import "./ProductDetailsModal.css";

const DIGITAL_DOWNLOAD = "digitalDownload";

const PHYSICAL_COPY = "physicalCopy";

const FRAME = "frame";

const RADIO_LABEL_CLASS = "mr-3 ml-4 text-[15px] md:text-[17px]";

const RADIO_INPUT_CLASS = "w-4 h-4 accent-cyan-600 mt-1";

const HEADER_CLASSES = "mb-4 text-sm md:text-base font-medium";

const stylingObject = {
  tileStyle: {
    border: "1px solid #06b6d4",
    borderRadius: "20px",
  },

  cart_btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "180px",
    height: "40px",
    padding: "5px 20px",
    backgroundColor: "#000",
    color: "#fff",
    fontSize: "11px",
    textDecoration: "none",
  },

  frameStyle: {
    fontSize: "12px",
    marginLeft: "3px",
    marginBottom: "3px",
    width: "15%",
    height: "auto",
  },

  frameImageStyle: {
    width: "100%",
    height: "auto",
  },

  captionStyle: {
    fontSize: "12px",
    maxWidth: "100px",
  },

  cartImage: {
    // width: '65%',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  App: {
    position: "relative",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    width: "100%",
    height: "100%",
  },

  cropContainer: {
    position: "relative",
    top: "0",
    left: "0",
    right: "0",
    bottom: "80px",
    width: "100%",
    height: "100%",
  },

  controls: {
    position: "absolute",
    bottom: "20px",
    left: "50%",
    width: "50%",
    transform: "translateX(-50%)",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },

  slider: {
    padding: "22px 0px",
  },

  zoomRange: {
    // -webkitAppearance: 'none',
    // -mozAppearance: 'none',
    height: "2px",
    borderTop: "5px solid #fff",
    borderBottom: "5px solid #fff",
    background: "#3f51b5",
    width: "100%",
  },
};

export default function ProductDetailsModal(props) {
  const eventDetails = useEvent().response;
  const { title, open, close, description, photo, cartId } = props;
  const theme = useContext(ThemeContext);
  let { addToCart, addAllProductsToCart } = useCart();
  const isDarkMode = theme === "dark";
  const cancelButtonRef = useRef(null);
  const premium = [
    "Distressed",
    "Natural Wood",
    "Classic Wood Frames",
    "Classic thin",
    "Modern Wood Frame",
    "Metallic Brush",
    "Classic",
  ];

  const [size, setsize] = useState();
  const [frame, setFrame] = useState();
  const [frameType, setFrameType] = useState();
  const [imgSize, setImgSize] = useState();
  const [im, setIm] = useState();
  const [quantity, setQuantity] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedParameters, setCroppedParameters] = useState();
  const [second, showSecond] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [imageStatus, setImageStatus] = useState("loading");
  const [classicSize, setClassicSize] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [cropImage, setCropImage] = useState(false);
  const [cropperImage, setCropperImage] = useState({
    imageSrc: photo.printStoreSrc,
  });
  const [optionSelected, setOptionSelected] = useState(
    cartId
      ? CART_FRAME_STORE
      : eventDetails.data.isDownloadChargeable
      ? null
      : CART_FRAME_STORE
  );
  const [addToCartDisabled, setAddToCartDisabled] = useState(false);
  const MAX = 10;
  const isMobile = width <= 768;
  const dimension = "medium";
  const isCountryIndia =
    eventDetails.photographer.printStoreCurrency === INDIAN_CURRENCY;

  const onCropChange = (c) => {
    setCrop({ ...crop, x: c.x, y: c.y });
    setCropperImage({
      ...cropperImage,
      imageSrc: photo.printStoreSrc + "&cr=" + croppedParameters,
    });
  };

  const incrementQty = () => {
    setQuantity(quantity + 1);
  };

  const decrementQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    } else {
      setQuantity(1);
    }
  };

  useEffect(() => {
    setCropperImage({ ...cropperImage, imageSrc: photo.printStoreSrc });
  }, [photo]);

  useEffect(() => {
    setFrameType("Distressed");
    setsize("8x8");
    setFrame("Brown");
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    cancelCropping();
  }, [size]);

  useEffect(() => {
    imageStatus != "loaded" ? setDisableAdd(true) : setDisableAdd(false);
  }, [imageStatus]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedParameters(
      Math.round(croppedAreaPixels.x) +
        "," +
        Math.round(croppedAreaPixels.y) +
        "," +
        Math.round(croppedAreaPixels.width) +
        "," +
        Math.round(croppedAreaPixels.height)
    );
  }, []);

  const reverseString = (s) => {
    let a = s.split("x");
    return a[1] + "x" + a[0];
  };

  const setIntial = () => {
    setIm("");
  };

  useEffect(() => {
    if (frame && Object.keys(photo).length > 0 && photo.type != "video") {
      switch (frameType) {
        case "Distressed":
          switch (size) {
            case "8x8":
              setImgSize("framed_prints_style1_8x8");
              break;
            case "8x10":
              setImgSize("framed_prints_style1_8x10");
              break;
            case "12x15":
              setImgSize("framed_prints_style1_12x15");
              break;
            case "12x18":
              setImgSize("framed_prints_style1_12x18");
              break;
            case "16x20":
              setImgSize("framed_prints_style1_16x20");
              break;
          }
          if (size)
            switch (frame) {
              case "Brown":
                // console.log(photo)
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-brown.png");
                else setIm(size + "-2photo-grid-brown.png");
                break;
              case "Green":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-green.png");
                else setIm(size + "-2photo-grid-green.png");
                break;
            }
          break;
        case "Natural Wood":
          switch (size) {
            case "8x8":
              setImgSize("framed_prints_style2_8x8");
              break;
            case "8x10":
              setImgSize("framed_prints_style2_8x10");
              break;
            case "12x15":
              setImgSize("framed_prints_style2_12x15");
              break;
            case "12x18":
              setImgSize("framed_prints_style2_12x18");
              break;
            case "16x20":
              setImgSize("framed_prints_style2_16x20");
              break;
          }
          if (size)
            switch (frame) {
              case "Brown":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-brown.png");
                else setIm(size + "-2photo-grid-brown.png");
                break;
              case "White":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-white.png");
                else setIm(size + "-2photo-grid-white.png");
                break;
              case "Golden":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-golden-ombre.png");
                else setIm(size + "-2photo-grid-golden-ombre.png");
                break;
            }
          break;
        case "Classic Wood Frames":
          switch (size) {
            case "8x8":
              setImgSize("framed_prints_style3_8x8");
              break;
            case "8x10":
              setImgSize("framed_prints_style3_8x10");
              break;
            case "12x15":
              setImgSize("framed_prints_style3_12x15");
              break;
            case "12x18":
              setImgSize("framed_prints_style3_12x18");
              break;
            case "16x20":
              setImgSize("framed_prints_style3_16x20");
              break;
          }
          if (size)
            switch (frame) {
              case "Black":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-black.png");
                else setIm(size + "-2photo-grid-black.png");
                break;
              case "Tan":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-tan.png");
                else setIm(size + "-2photo-grid-tan.png");
                break;
              case "Walnut":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-walnut.png");
                else setIm(size + "-2photo-grid-walnut.png");
                break;
            }
          break;
        case "Classic thin":
          switch (size) {
            case "8x8":
              setImgSize("framed_prints_style4_8x8");
              break;
            case "8x10":
              setImgSize("framed_prints_style4_8x10");
              break;
            case "12x15":
              setImgSize("framed_prints_style4_12x15");
              break;
            case "12x18":
              setImgSize("framed_prints_style4_12x18");
              break;
            case "16x20":
              setImgSize("framed_prints_style4_16x20");
              break;
          }
          if (size)
            switch (frame) {
              case "Walnut":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-walnut.png");
                else setIm(size + "-2photo-grid-walnut.png");
                break;
              case "Black":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-black.png");
                else setIm(size + "-2photo-grid-black.png");
                break;
              case "White":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-white.png");
                else setIm(size + "-2photo-grid-white.png");
                break;
            }
          break;
        case "Modern Wood Frame":
          switch (size) {
            case "8x8":
              setImgSize("framed_prints_style5_8x8");
              break;
            case "8x10":
              setImgSize("framed_prints_style5_8x10");
              break;
            case "12x15":
              setImgSize("framed_prints_style5_12x15");
              break;
            case "12x18":
              setImgSize("framed_prints_style5_12x18");
              break;
            case "16x20":
              setImgSize("framed_prints_style5_16x20");
              break;
          }
          if (size)
            switch (frame) {
              case "Black":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-black.png");
                else setIm(size + "-2photo-grid-black.png");
                break;
              case "Honey":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-honey.png");
                else setIm(size + "-2photo-grid-honey.png");
                break;
              case "White":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-white.png");
                else setIm(size + "-2photo-grid-white.png");
                break;
            }
          break;
        case "Metallic Brush":
          switch (size) {
            case "8x8":
              setImgSize("framed_prints_style6_8x8");
              break;
            case "8x10":
              setImgSize("framed_prints_style6_8x10");
              break;
            case "12x15":
              setImgSize("framed_prints_style6_12x15");
              break;
            case "12x18":
              setImgSize("framed_prints_style6_12x18");
              break;
            case "16x20":
              setImgSize("framed_prints_style6_16x20");
              break;
          }
          if (size)
            switch (frame) {
              case "Green":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-green-speck.png");
                else setIm(size + "-2photo-grid-green-speck.png");
                break;
              case "Tortoise":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  setIm(reverseString(size) + "-2photo-grid-tortoise.png");
                else setIm(size + "-2photo-grid-tortoise.png");
                break;
            }
          break;
        case "Classic":
          switch (size) {
            case "4x4":
              setImgSize("framedprint_6_4");
              setClassicSize("6_4_");
              break;
            case "4x6":
              setImgSize("framedprint_6x8_4x6");
              setClassicSize("6x8_4x6_");
              break;
            case "5.5x5.5":
              setImgSize("framedprint_7.5_5.5");
              setClassicSize("7.5_5.5_");
              break;
            case "5x7":
              setImgSize("framedprint_7x9_5x7");
              setClassicSize("7x9_5x7_");
              break;
            // case "6x4":
            // setImgSize('framedprint_6_4');
            // break;
            // case "6x8":
            // setImgSize('framedprint_6x8_4x6');
            // break;
            // case "7x5":
            // setImgSize('framedprint_7.5_5.5');
            // break;
            // case "7x9":
            // setImgSize('framedprint_7x9_5x7');
            // break;
            case "8x10":
              setImgSize("framedprint_10x12_8x10");
              setClassicSize("10x12_4x4_");
              break;
            // case "10x12":
            // setImgSize('framedprint_10x12_8x10');
            // break;
          }
          if (size)
            switch (frame) {
              case "Beige":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  if (size == "4x4" || size == "5.5x5.5")
                    size == "4x4"
                      ? setIm(classicSize + "beige.png")
                      : setIm(classicSize + "beige.png");
                  else setIm(classicSize + "beige_l.png");
                else if (size == "4x4" || size == "5.5x5.5")
                  size == "4x4"
                    ? setIm(classicSize + "beige.png")
                    : setIm(classicSize + "beige.png");
                else setIm(classicSize + "beige_p.png");
                break;
              case "Black":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  if (size == "4x4" || size == "5.5x5.5")
                    size == "4x4"
                      ? setIm(classicSize + "black.png")
                      : setIm(classicSize + "black.png");
                  else setIm(classicSize + "black_l.png");
                else if (size == "4x4" || size == "5.5x5.5")
                  size == "4x4"
                    ? setIm(classicSize + "black.png")
                    : setIm(classicSize + "black.png");
                else setIm(classicSize + "black_p.png");
                break;
              case "Brown":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  if (size == "4x4" || size == "5.5x5.5")
                    size == "4x4"
                      ? setIm(classicSize + "brown.png")
                      : setIm(classicSize + "brown.png");
                  else setIm(classicSize + "brown_l.png");
                else if (size == "4x4" || size == "5.5x5.5")
                  size == "4x4"
                    ? setIm(classicSize + "brown.png")
                    : setIm(classicSize + "brown.png");
                else setIm(classicSize + "brown_p.png");
                break;
              case "Gold":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  if (size == "4x4" || size == "5.5x5.5")
                    size == "4x4"
                      ? setIm(classicSize + "gold.png")
                      : setIm(classicSize + "gold.png");
                  else setIm(classicSize + "gold_l.png");
                else if (size == "4x4" || size == "5.5x5.5")
                  size == "4x4"
                    ? setIm(classicSize + "gold.png")
                    : setIm(classicSize + "gold.png");
                else setIm(classicSize + "gold_p.png");
                break;
              case "White":
                if (
                  photo.originalImageObj.width > photo.originalImageObj.height
                )
                  if (size == "4x4" || size == "5.5x5.5")
                    size == "4x4"
                      ? setIm(classicSize + "white.png")
                      : setIm(classicSize + "white.png");
                  else setIm(classicSize + "white_l.png");
                else if (size == "4x4" || size == "5.5x5.5")
                  size == "4x4"
                    ? setIm(classicSize + "white.png")
                    : setIm(classicSize + "white.png");
                else setIm(classicSize + "white_p.png");
                break;
            }
          break;
      }
    }
  });

  useEffect(() => {
    let paddingTop;
    let varwidth;
    let varheight;
    let vartop;
    let varleft;
    switch (size) {
      case "4x4":
        stylingObject.outerDiv = { width: "100%", paddingTop: "100%" };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: "66.6667%",
          height: "66.6667%",
          top: "16.6667%",
          left: "16.6667%",
        };
        break;
      case "4x6":
        paddingTop = "133.333%";
        varwidth = "66.6667%";
        varheight = "75%";
        vartop = "12.5%";
        varleft = "16.6667%";
        if (photo.originalImageObj.width > photo.originalImageObj.height) {
          paddingTop = "75%";
          varwidth = "75%";
          varheight = "66.6667%";
          vartop = "16.6667%";
          varleft = "12.5%";
        }
        stylingObject.outerDiv = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
        };
        break;
      case "5.5x5.5":
        stylingObject.outerDiv = { width: "100%", paddingTop: "100%" };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: "73.3333%",
          height: "73.3333%",
          top: "13.3333%",
          left: "13.3333%",
        };
        break;
      case "5x7":
        paddingTop = "128.571%";
        varwidth = "71.4286%";
        varheight = "77.7778%";
        vartop = "11.1111%";
        varleft = "14.2857%";
        if (photo.originalImageObj.width > photo.originalImageObj.height) {
          paddingTop = "77.7778%";
          varwidth = "77.7778%";
          varheight = "71.4286%";
          vartop = "14.2857%";
          varleft = "11.1111%";
        }
        stylingObject.outerDiv = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
        };
        break;
      case "8x8":
        stylingObject.outerDiv = { width: "100%", paddingTop: "100%" };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: "61.4812%",
          height: "61.4812%",
          top: "19.2425%",
          left: "19.2425%",
        };
        break;
      case "8x10":
        if (frameType == "Classic") {
          paddingTop = "120%";
          varwidth = "80%";
          varheight = "83.3333%";
          vartop = "8.33333%";
          varleft = "10%";
          if (photo.originalImageObj.width > photo.originalImageObj.height) {
            paddingTop = "83.3333%";
            varwidth = "83.3333%";
            varheight = "80%";
            vartop = "10%";
            varleft = "8.33333%";
          }
        } else {
          paddingTop = "115.049%";
          varwidth = "61.6505%";
          varheight = "66.9831%";
          vartop = "16.5084%";
          varleft = "19.1748%";
          if (photo.originalImageObj.width > photo.originalImageObj.height) {
            paddingTop = "86.9198%";
            varwidth = "66.9831%";
            varheight = "61.6505%";
            vartop = "19.1748%";
            varleft = "16.5084%";
          }
        }
        stylingObject.outerDiv = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
        };
        break;
      case "12x15":
        paddingTop = "118.435%";
        varwidth = "69.7514%";
        varheight = "74.7298%";
        vartop = "12.6143%";
        varleft = "15.112%";
        if (photo.originalImageObj.width > photo.originalImageObj.height) {
          paddingTop = "83.435%";
          varwidth = "74.7298%";
          varheight = "69.7514%";
          vartop = "15.112%";
          varleft = "12.6143%";
        }
        stylingObject.outerDiv = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
        };
        break;
      case "12x18":
        paddingTop = "138.675%";
        varwidth = "71.4034%";
        varheight = "79.1788%";
        vartop = "10.4106%";
        varleft = "14.2857%";
        if (photo.originalImageObj.width > photo.originalImageObj.height) {
          paddingTop = "72.1112%";
          varwidth = "79.1788%";
          varheight = "71.4034%";
          vartop = "14.2857%";
          varleft = "10.4106%";
        }
        stylingObject.outerDiv = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
        };
        break;
      case "16x20":
        paddingTop = "120.55%";
        varwidth = "77.2627%";
        varheight = "81.0055%";
        vartop = "9.48893%";
        varleft = "11.3586%";
        if (photo.originalImageObj.width > photo.originalImageObj.height) {
          paddingTop = "82.9532%";
          varwidth = "81.0055%";
          varheight = "77.2627%";
          vartop = "11.3586%";
          varleft = "9.48893%";
        }
        stylingObject.outerDiv = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
        };
        break;
    }
  });

  const addthis = () => {
    setDisableAdd(true);
    // console.log(croppedParameters)
    // return
    let res = addToCart(
      photo.key,
      size,
      frame,
      quantity,
      frameType,
      "https://picsniff.s3.ap-south-1.amazonaws.com/frames/" +
        imgSize +
        "/" +
        im,
      croppedParameters,
      null,
      null
    );
    if (res) {
      setDisableAdd(false);
      setImageStatus("loading");
      setCroppedParameters();
      close();
    }
  };

  const toggleSlide = (e) => {
    showSecond(e);
  };

  const incrementZoom = () => {
    setZoom(zoom + 1);
  };

  const decrementZoom = () => {
    if (zoom > 1) {
      setZoom(zoom - 1);
    } else {
      setZoom(1);
    }
  };

  function handleImageLoaded() {
    setImageStatus("loaded");
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const handleClose = (e) => {
    if (cartId) {
      setImageStatus("loading");
      setCropImage(false);
      setCroppedParameters();
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      close(true);
    } else {
      if (optionSelected === CART_FRAME_STORE) {
        setOptionSelected(null);
      } else {
        setImageStatus("loading");
        setCropImage(false);
        setCroppedParameters();
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        close(true);
      }
    }
  };

  function updateCroppedCoverImage() {
    setCropImage(false);
    setCropperImage({
      ...cropperImage,
      imageSrc: photo.printStoreSrc + "&cr=" + croppedParameters,
    });
    window.Toast.fire({
      icon: "success",
      title: "Image cropped successfully.",
    });
  }

  function cancelCropping() {
    setCropImage(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCropperImage({ ...cropperImage, imageSrc: photo.printStoreSrc });
  }

  const closeDigitalStore = () => {
    setOptionSelected(null);
    close();
  };

  useEffect(() => {
    if (!open) {
      if (eventDetails.data.isDownloadChargeable) {
        setOptionSelected(null);
      }
    }
  }, [open]);

  function onAddToCartClicked() {
    const res = addToCart(
      photo.key,
      null,
      null,
      1,
      null,
      "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style1_8x8/8x8-2photo-grid-brown.png",
      null,
      "digital",
      null
    );
    if (res) {
      close();
    }
  }

  const onAddAllToCart = async () => {
    setAddToCartDisabled(true);
    const res = await addAllProductsToCart(photo.key);
    setAddToCartDisabled(false);
    if (res) close();
  };

  const isPortrait =
    photo?.originalImageObj?.height > photo?.originalImageObj?.width;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-[9999] inset-0 overflow-y-auto ${
          isDarkMode && "dark"
        } px-[18px]`}
        initialFocus={cancelButtonRef}
        onClose={(e) => {
          handleClose(e);
        }}
      >
        <div
          className={`flex lg:items-end justify-center min-h-screen 
            
              pt-4 md:px-4 pb-20 text-center sm:block sm:p-0`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`fixed inset-0  ${
                isDarkMode
                  ? "bg-black bg-opacity-60"
                  : "bg-[#d6d6d6] bg-opacity-75"
              }  transition-opacity`}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom  text-left overflow-hidden shadow-xl transform transition-all sm:w-full ${
                isDarkMode
                  ? "bg-theme-light text-light-gray"
                  : "bg-dark-gray text-dark-gray1"
              } ${
                !optionSelected && eventDetails.data.isDownloadChargeable
                  ? "md:!w-fit"
                  : ""
              } self-center 
                rounded-lg sm:my-8 sm:align-middle sm:max-w-7xl`}
            >
              <div
                className={`bg-theme-light dark:bg-dark-gray
                 pt-6 sm:px-6 pb-4 sm:p-6 sm:pb-6`}
              >
                {!optionSelected && eventDetails.data.isDownloadChargeable ? (
                  <div className="px-[18px] md:px-4 md:pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 sm:mt-0  sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className={`text-base flex items-center justify-between border-b border-b-gray border-b-solid pb-4 md:text-3xl leading-6 font-medium text-gray-900`}
                      >
                        Print Store
                        <div onClick={handleClose}>
                          <CloseIcon h="14" w="14" fill="black" />
                        </div>
                      </Dialog.Title>
                      <section className="mt-16 mb-16">
                        <div className=" flex md:gap-80 w-full justify-between">
                          <div className="flex relative">
                            <input
                              defaultChecked
                              id={DIGITAL_DOWNLOAD}
                              name="typeOfProduct"
                              type="radio"
                              className={`${RADIO_INPUT_CLASS} mt-1`}
                              onClick={() => {
                                !addToCartDisabled && setOptionSelected("");
                              }}
                            />
                            <label
                              htmlFor={DIGITAL_DOWNLOAD}
                              class={RADIO_LABEL_CLASS}
                            >
                              <div> High Res Digital Download</div>
                              <div className="font-normal mt-1">
                                {eventDetails.photographer.id === 73591 ? (
                                  <div className="text-xs md:text-sm leading-[19px] text-stone-400">{`Price for  1 - ${
                                    eventDetails.data?.downloadPricing
                                      ?.currency === PRICING_IN_DOLLAR
                                      ? "$"
                                      : "₹"
                                  } 20`}</div>
                                ) : (
                                  eventDetails.data.downloadPricing.price.map(
                                    (el, id) => (
                                      <div
                                        className="text-xs md:text-sm leading-[19px] text-stone-400"
                                        key={el.amount}
                                      >{`Price for ${
                                        id + 1 ===
                                        eventDetails.data.downloadPricing?.price
                                          ?.length
                                          ? "upto"
                                          : ""
                                      } ${el.quantity} - ${
                                        eventDetails.data?.downloadPricing
                                          ?.currency === PRICING_IN_DOLLAR
                                          ? "$"
                                          : "₹"
                                      } ${el.amount}`}</div>
                                    )
                                  )
                                )}
                              </div>
                              {!optionSelected ? (
                                <div className="mt-4 mb-4 md:mb-0 flex gap-4 flex-col md:flex-row">
                                  <Button
                                    text="Add to cart"
                                    className="px-2 md:px-4 text-xs md:text-base"
                                    onClick={onAddToCartClicked}
                                  ></Button>
                                  {PHOTOGRAPHER_WITH_ALL_DIGITAL_DOWNLOAD.includes(
                                    eventDetails.photographer.id
                                  ) &&
                                  window.location.pathname.includes(
                                    "facerec"
                                  ) ? (
                                    <Button
                                      text="Add all photos to cart"
                                      className={`px-2 md:px-4 text-xs md:text-base  ${
                                        isMobile ? "absolute top-full" : ""
                                      }`}
                                      onClick={onAddAllToCart}
                                      disabled={addToCartDisabled}
                                    ></Button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                            </label>
                          </div>
                          <div>
                            <img
                              className={
                                isPortrait
                                  ? " max-h-[80px] md:max-h-[120px] object-contain"
                                  : "max-w-[80px] md:max-w-[120px] object-contain"
                              }
                              src={photo.src}
                            />
                          </div>
                        </div>
                      </section>
                      <section>
                        {BMS_PHOTOGRAPHER_ID.includes(
                          eventDetails.photographer.id
                        ) ? (
                          <></>
                        ) : (
                          <div className=" flex md:gap-80 w-full justify-between">
                            <div className="flex">
                              <input
                                id={CART_FRAME_STORE}
                                name="typeOfProduct"
                                type="radio"
                                className={`${RADIO_INPUT_CLASS} mt-1`}
                                onClick={() =>
                                  !addToCartDisabled &&
                                  setOptionSelected(CART_FRAME_STORE)
                                }
                              />
                              <label
                                htmlFor={CART_FRAME_STORE}
                                class={RADIO_LABEL_CLASS}
                              >
                                <div> Buy a framed copy</div>
                                <div className="font-normal mt-1">
                                  <div className="text-xs md:text-sm leading-[19px] text-stone-400">
                                    Check out our amazing frames in different
                                    sizes and styles
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div className="relative">
                              <img
                                className={` z-[2] max-w-[80px] md:max-w-[120px] ${
                                  isPortrait ? "aspect-[3/4]" : "aspect-[4/3]"
                                }`}
                                src={
                                  eventDetails.photographer
                                    .printStoreCurrency === INDIAN_CURRENCY
                                    ? "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style1_8x8/8x8-2photo-grid-brown.png"
                                    : "https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/SoHo+Black+Frame.jpg"
                                }
                              />
                              <img
                                className={`absolute left-[20px] w-[calc(100%-40px)] object-contain
                              ${
                                isPortrait
                                  ? "max-h-[80px] md:max-h-[120px]  top-1/2 -translate-y-1/2 aspect-[3/4]"
                                  : "max-w-[80px] md:max-w-[120px] top-[17px] md:top-[15px] aspect-[4/3]"
                              }`}
                                src={photo.src}
                              />
                            </div>
                          </div>
                        )}
                      </section>
                    </div>
                  </div>
                ) : (
                  <div className="px-[18px] md:px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className={`text-base md:text-3xl leading-6 font-medium text-gray-900`}
                      >
                        {cropImage
                          ? "Crop your image"
                          : "Customize your print order"}
                        {!cropImage && (
                          <span
                            className="absolute right-6 cursor-pointer"
                            onClick={handleClose}
                          >
                            <CloseIcon
                              fill={`${isDarkMode ? "#eee" : "#222"}`}
                              h={isMobile ? "16" : "20"}
                              w={isMobile ? "16" : "20"}
                            />
                          </span>
                        )}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div
                          className={`flex flex-row grow pb-4 text-xs md:text-xl`}
                        >
                          {/* {cropImage ? ""  : "Scroll to down to select product type"} */}
                        </div>
                        {isCountryIndia ? (
                          <div className="w-full pt-3">
                            {cropImage ? (
                              <div className={`w-full`}>
                                <div className="sm:flex justify-between mb-2.5">
                                  <Text
                                    as="p"
                                    className="text-base mb-4 font-medium"
                                  >
                                    Crop the image to your liking
                                  </Text>
                                  <div className="flex justify-center">
                                    <Button
                                      disabled={
                                        imageStatus == "loaded" ? false : true
                                      }
                                      className="mr-3"
                                      onClick={updateCroppedCoverImage}
                                      text="Crop Image"
                                    />
                                    <Button
                                      className="mr-3"
                                      onClick={cancelCropping}
                                      text="Cancel"
                                    />
                                  </div>
                                </div>
                                <div className="sm:flex sm:justify-start sm:gap-12">
                                  <div
                                    className={`relative sm:w-3/4 ${(() => {
                                      switch (size) {
                                        case "8x8":
                                          return "aspect-[8/8]";
                                        case "8x10":
                                          if (
                                            photo.originalImageObj.width >
                                            photo.originalImageObj.height
                                          )
                                            return "aspect-[10/8]";
                                          else return "aspect-[8/10]";
                                        case "12x15":
                                          if (
                                            photo.originalImageObj.width >
                                            photo.originalImageObj.height
                                          )
                                            return "aspect-[15/12]";
                                          else return "aspect-[12/15]";
                                        case "12x18":
                                          if (
                                            photo.originalImageObj.width >
                                            photo.originalImageObj.height
                                          )
                                            return "aspect-[18/12]";
                                          else return "aspect-[12/18]";
                                        case "16x20":
                                          if (
                                            photo.originalImageObj.width >
                                            photo.originalImageObj.height
                                          )
                                            return "aspect-[20/16]";
                                          else return "aspect-[16/20]";
                                        case "4x4":
                                          return "aspect-[4/4]";
                                        case "4x6":
                                          if (
                                            photo.originalImageObj.width >
                                            photo.originalImageObj.height
                                          )
                                            return "aspect-[6/4]";
                                          else return "aspect-[4/6]";
                                        case "5.5x5.5":
                                          return "aspect-[5.5/5.5]";
                                        case "5x7":
                                          if (
                                            photo.originalImageObj.width >
                                            photo.originalImageObj.height
                                          )
                                            return "aspect-[7/5]";
                                          else return "aspect-[5/7]";
                                      }
                                    })()}`}
                                  >
                                    <Cropper
                                      image={photo.printStoreSrc}
                                      crop={crop}
                                      zoom={zoom}
                                      aspect={(() => {
                                        switch (size) {
                                          case "8x8":
                                            return 8 / 8;
                                          case "8x10":
                                            if (
                                              photo.originalImageObj.width >
                                              photo.originalImageObj.height
                                            )
                                              return 10 / 8;
                                            else return 8 / 10;
                                          case "12x15":
                                            if (
                                              photo.originalImageObj.width >
                                              photo.originalImageObj.height
                                            )
                                              return 15 / 12;
                                            else return 12 / 15;
                                          case "12x18":
                                            if (
                                              photo.originalImageObj.width >
                                              photo.originalImageObj.height
                                            )
                                              return 18 / 12;
                                            else return 12 / 18;
                                          case "16x20":
                                            if (
                                              photo.originalImageObj.width >
                                              photo.originalImageObj.height
                                            )
                                              return 20 / 16;
                                            else return 16 / 20;
                                          case "4x4":
                                            return 4 / 4;
                                          case "4x6":
                                            if (
                                              photo.originalImageObj.width >
                                              photo.originalImageObj.height
                                            )
                                              return 6 / 4;
                                            else return 4 / 6;
                                          case "5.5x5.5":
                                            return 5.5 / 5.5;
                                          case "5x7":
                                            if (
                                              photo.originalImageObj.width >
                                              photo.originalImageObj.height
                                            )
                                              return 7 / 5;
                                            else return 5 / 7;
                                        }
                                      })()}
                                      showGrid={true}
                                      viewMode={3}
                                      onCropChange={onCropChange}
                                      onCropComplete={onCropComplete}
                                      onZoomChange={setZoom}
                                      objectFit={"horizontal-cover"}
                                      onMediaLoaded={handleImageLoaded}
                                    />
                                  </div>
                                  <div className="controls mt-2 sm:w-1/4 text-center">
                                    <Text className="text-base mb-4 font-medium">
                                      Zoom
                                    </Text>
                                    <div className="flex justify-center items-center mx-auto py-4">
                                      <span
                                        className="text-base md:text-lg pt-2 mr-2 cursor-pointer font-bold"
                                        onClick={() => {
                                          decrementZoom();
                                        }}
                                      >
                                        -
                                      </span>
                                      <SliderComponent
                                        defaultValue={zoom}
                                        min={1}
                                        max={MAX}
                                        step={1}
                                        onChange={(e) => {
                                          setZoom(e);
                                        }}
                                      />
                                      <span
                                        className="text-base md:text -ml-3 pt-1 cursor-pointer font-bold"
                                        onClick={() => {
                                          incrementZoom();
                                        }}
                                      >
                                        +
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="lg:flex lg:gap-4">
                                <div className="lg:w-3/5 sm:w-full lg:mx-10 md:mx-10 order-2">
                                  <div className="flex justify-between items-center mb-8">
                                    <div className="flex items-center">
                                      <div className="text-base md:text-2xl font-bold">
                                        All products
                                        <span className="text-xs md:text-base text-stone-400 block lg:inline-block lg:ml-4">
                                          {!second
                                            ? "Showing 4 out of 7"
                                            : "Showing 7 out of 7"}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex items-center">
                                      <span
                                        className="rotate-90 ml-4 cursor-pointer"
                                        onClick={(e) => {
                                          toggleSlide(false);
                                        }}
                                      >
                                        <DownArrow
                                          color={
                                            second
                                              ? "rgb(168 162 158 / var(--tw-text-opacity))"
                                              : "#EEE"
                                          }
                                          height={isMobile ? "14" : "16"}
                                          width={isMobile ? "14" : "16"}
                                        />
                                      </span>
                                      {/* <ThreeDots
                                        dimension='medium'
                                        color={isDarkMode ? '#FFFFFF' : 'rgb(168 162 158 / var(--tw-text-opacity))'}
                                        className='w-2 h-2'
                                        /> */}
                                      <span
                                        className={`flex flex-row items-center cursor-pointer`}
                                      >
                                        <span
                                          className={`rounded-full ${
                                            dimension === "small"
                                              ? "w-1 h-1"
                                              : "w-2 h-2"
                                          } bg-white`}
                                          style={{
                                            backgroundColor: second
                                              ? "rgb(168 162 158 / var(--tw-text-opacity))"
                                              : "#EEE",
                                          }}
                                          onClick={(e) => {
                                            toggleSlide(false);
                                          }}
                                        ></span>
                                        <span
                                          className={`rounded-full ${
                                            dimension === "small"
                                              ? "w-1 h-1"
                                              : "w-2 h-2"
                                          } ml-1 bg-white`}
                                          style={{
                                            backgroundColor: !second
                                              ? "rgb(168 162 158 / var(--tw-text-opacity))"
                                              : "#EEE",
                                          }}
                                          onClick={(e) => {
                                            toggleSlide(true);
                                          }}
                                        ></span>
                                      </span>
                                      <span
                                        className="-rotate-90 ml-4 cursor-pointer ml-[0]"
                                        onClick={(e) => {
                                          toggleSlide(true);
                                        }}
                                      >
                                        <DownArrow
                                          color={
                                            !second
                                              ? "rgb(168 162 158 / var(--tw-text-opacity))"
                                              : "#EEE"
                                          }
                                          height={isMobile ? "14" : "16"}
                                          width={isMobile ? "14" : "16"}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  {!second ? (
                                    <>
                                      <ul className="flex md:grid grid-cols-2 gap-6 justify-between flex-wrap">
                                        {React.Children.toArray(
                                          [
                                            {
                                              id: 1,
                                              imgSrc:
                                                "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style1_8x8/cover.jpg",
                                              productName:
                                                "Premium Frame Distressed",
                                              fType: "Distressed",
                                              // productPrice: "Rs. 999",
                                              initialFrameColor: "Brown",
                                              initialFrameSize: "8x8",
                                            },
                                            {
                                              id: 2,
                                              imgSrc:
                                                "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style2_8x8/cover.jpg",
                                              productName:
                                                "Premium Frame Natural Wood",
                                              fType: "Natural Wood",
                                              // productPrice: "Rs. 1199",
                                              initialFrameColor: "Brown",
                                              initialFrameSize: "8x8",
                                            },
                                            {
                                              id: 3,
                                              imgSrc:
                                                "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style3_8x8/cover.jpg",
                                              productName:
                                                "Premium Frame Classic Wood Frames",
                                              fType: "Classic Wood Frames",
                                              // productPrice: "Rs. 2,499",
                                              initialFrameColor: "Black",
                                              initialFrameSize: "8x8",
                                            },
                                            {
                                              id: 4,
                                              imgSrc:
                                                "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style4_8x8/cover.jpg",
                                              productName:
                                                "Premium Frame Classic thin",
                                              fType: "Classic thin",
                                              // productPrice: "Rs. 2,649",
                                              initialFrameColor: "Black",
                                              initialFrameSize: "8x8",
                                            },
                                          ].map(
                                            ({
                                              id,
                                              imgSrc,
                                              productName,
                                              fType,
                                              productPrice,
                                              initialFrameColor,
                                              initialFrameSize,
                                            }) => {
                                              return (
                                                <li
                                                  className={`md:grid md:grid-flow-rows ${
                                                    frameType === fType
                                                      ? "shadow-dark-input-bottom-right dark:shadow-dark-input rounded-md md:rounded-xl"
                                                      : ""
                                                  } `}
                                                  onClick={() => {
                                                    setFrameType(fType);
                                                    setIntial();
                                                    setFrame(initialFrameColor);
                                                    setsize(initialFrameSize);
                                                  }}
                                                >
                                                  <img
                                                    className={`cursor-pointer w-[100px] md:w-auto h-[100px] md:h-auto  ${
                                                      frameType === fType
                                                        ? "p-[10px]"
                                                        : "shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                    }`}
                                                    src={imgSrc}
                                                  />
                                                  <h1 className="my-2 max-w-[100px] md:max-w-none text-xs md:text-base lg:text-xl font-medium pl-1 pr-1 md:pr-0 md:pl-4">
                                                    {productName}
                                                  </h1>
                                                  {/* <p className='text-stone-400'>{productPrice}</p> */}
                                                </li>
                                              );
                                            }
                                          )
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <>
                                      <ul className="flex md:grid grid-cols-2 gap-6 justify-between flex-wrap">
                                        {React.Children.toArray(
                                          [
                                            {
                                              id: 5,
                                              imgSrc:
                                                "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style5_8x8/cover.jpg",
                                              productName:
                                                "Premium Frame Modern Wood Frame",
                                              fType: "Modern Wood Frame",
                                              // productPrice: "Rs. 2,649",
                                              initialFrameColor: "Black",
                                              initialFrameSize: "8x8",
                                            },
                                            {
                                              id: 6,
                                              imgSrc:
                                                "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style6_8x8/cover.jpg",
                                              productName:
                                                "Premium Frame Metallic Brush",
                                              fType: "Metallic Brush",
                                              // productPrice: "Rs. 2,649",
                                              initialFrameColor: "Tortoise",
                                              initialFrameSize: "8x8",
                                            },
                                            {
                                              id: 7,
                                              imgSrc:
                                                "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/cover.jpg",
                                              productName: "Classic",
                                              fType: "Classic",
                                              // productPrice: "Rs. 2,649",
                                              initialFrameColor: "Black",
                                              initialFrameSize: "4x4",
                                            },
                                          ].map(
                                            ({
                                              id,
                                              imgSrc,
                                              productName,
                                              fType,
                                              productPrice,
                                              initialFrameColor,
                                              initialFrameSize,
                                            }) => {
                                              return (
                                                <li
                                                  className={`md:grid md:grid-flow-rows ${
                                                    frameType === fType
                                                      ? "shadow-dark-input-bottom-right dark:shadow-dark-input rounded-md md:rounded-xl"
                                                      : ""
                                                  } `}
                                                  onClick={() => {
                                                    setFrameType(fType);
                                                    setIntial();
                                                    setFrame(initialFrameColor);
                                                    setsize(initialFrameSize);
                                                  }}
                                                >
                                                  <img
                                                    className={`cursor-pointer w-[100px] md:w-auto h-[100px] md:h-auto ${
                                                      frameType === fType
                                                        ? "p-[10px]"
                                                        : "shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                    }`}
                                                    src={imgSrc}
                                                  />
                                                  <h1 className="my-2 max-w-[100px] md:max-w-none text-xs md:text-base lg:text-xl font-medium pl-1 pr-1 md:pr-0 md:pl-4">
                                                    {productName}
                                                  </h1>
                                                  {/* <p className='text-stone-400'>{productPrice}</p> */}
                                                </li>
                                              );
                                            }
                                          )
                                        )}
                                      </ul>
                                    </>
                                  )}
                                </div>
                                <div className="lg:w-1/2 sm:w-full border-stone-600 lg:border-r">
                                  <div className="p-5 lg:pl-0 flex justify-between relative">
                                    <div className="imageCont" id="viewPage">
                                      <div
                                        style={{ ...stylingObject.outerDiv }}
                                      ></div>
                                      {im != "" && im != undefined ? (
                                        <img
                                          className="frameImage shadow-dark-input-bottom-right dark:shadow-dark-input"
                                          src={
                                            "https://picsniff.s3.ap-south-1.amazonaws.com/frames/" +
                                            imgSize +
                                            "/" +
                                            im
                                          }
                                          alt="background"
                                          style={{ width: "100%" }}
                                        />
                                      ) : null}
                                      <div
                                        className="img-layout-tooltip"
                                        style={{ ...stylingObject.innerImg }}
                                      >
                                        <img
                                          className="cursor-pointer shadow-dark-input-bottom-right dark:shadow-dark-input"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            display: "block",
                                            zIndex: "0",
                                            userSelect: "unset",
                                          }}
                                          src={cropperImage.imageSrc}
                                        />
                                        {/* <div style={{ ...stylingObject.App, background: `url('${cropperImage.imageSrc}')`,
                                            backgroundRepeat: 'no-repeat',backgroundSize: 'cover',backgroundPosition: 'center' }}> */}
                                        <div className="absolute right-2 top-2">
                                          <IconButton
                                            className="mr-3 cursor-pointer !bg-dark-gray"
                                            onClick={(e) => {
                                              setCropImage(!cropImage);
                                            }}
                                          >
                                            <CropIcon />
                                          </IconButton>
                                        </div>
                                        {/* </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex px-6 lg:pl-[0] xl:pr-20 grid">
                                    <div className="text-xl sm:text-[2.625rem] font-bold mt-4">
                                      Rs.&nbsp;
                                      {frameType != "Classic" ? (
                                        <>
                                          {(() => {
                                            switch (size) {
                                              case "8x8":
                                                return eventDetails.photographer
                                                  .framePricing.premium8x8;
                                              case "8x10":
                                                return eventDetails.photographer
                                                  .framePricing.premium8x10;
                                              case "12x15":
                                                return eventDetails.photographer
                                                  .framePricing.premium12x15;
                                              case "12x18":
                                                return eventDetails.photographer
                                                  .framePricing.premium12x18;
                                              case "16x20":
                                                return eventDetails.photographer
                                                  .framePricing.premium16x20;
                                            }
                                          })()}
                                        </>
                                      ) : (
                                        <>
                                          {(() => {
                                            switch (size) {
                                              case "4x4":
                                                return eventDetails.photographer
                                                  .framePricing.classic4x4;
                                              case "4x6":
                                                return eventDetails.photographer
                                                  .framePricing.classic4x6;
                                              case "5.5x5.5":
                                                return eventDetails.photographer
                                                  .framePricing.classic55x55;
                                              case "5x7":
                                                return eventDetails.photographer
                                                  .framePricing.classic5x7;
                                              case "8x10":
                                                return eventDetails.photographer
                                                  .framePricing.classic8x10;
                                            }
                                          })()}
                                        </>
                                      )}
                                    </div>
                                    <div className="text-xs md:text-xl mt-2 font-medium">
                                      {premium.includes(frameType)
                                        ? "Premium Frame " + frameType
                                        : frameType}
                                    </div>
                                    <div className="mt-8">
                                      <div className="text-xs text-base font-medium mb-4">
                                        Frame colour
                                      </div>
                                      <ul className="flex lg:mr-36 md:mr-36">
                                        {React.Children.toArray(
                                          [
                                            {
                                              // black
                                              id: 1,
                                              imgSrc: FrameBlack,
                                              color: "Black",
                                              ftype: [
                                                "Classic Wood Frames",
                                                "Classic thin",
                                                "Modern Wood Frame",
                                                "Classic",
                                              ],
                                            },
                                            {
                                              // brown
                                              id: 2,
                                              imgSrc: FrameYellow,
                                              color: "Brown",
                                              ftype: [
                                                "Distressed",
                                                "Natural Wood",
                                                "Classic",
                                              ],
                                            },
                                            {
                                              // golden
                                              id: 3,
                                              imgSrc: FrameRed,
                                              color: "Golden",
                                              ftype: ["Natural Wood"],
                                            },
                                            {
                                              // white
                                              id: 4,
                                              imgSrc: FramePurple,
                                              color: "White",
                                              ftype: [
                                                "Natural Wood",
                                                "Classic thin",
                                                "Modern Wood Frame",
                                                "Classic",
                                              ],
                                            },
                                            {
                                              // tan
                                              id: 5,
                                              imgSrc: FrameBlue,
                                              color: "Tan",
                                              ftype: ["Classic Wood Frames"],
                                            },
                                            {
                                              // walnut
                                              id: 6,
                                              imgSrc: FrameBlue,
                                              color: "Walnut",
                                              ftype: [
                                                "Classic Wood Frames",
                                                "Classic thin",
                                              ],
                                            },
                                            {
                                              // honey
                                              id: 7,
                                              imgSrc: FrameBlue,
                                              color: "Honey",
                                              ftype: ["Modern Wood Frame"],
                                            },
                                            {
                                              // tortoise
                                              id: 8,
                                              imgSrc: FrameBlue,
                                              color: "Tortoise",
                                              ftype: ["Metallic Brush"],
                                            },
                                            {
                                              // biege
                                              id: 9,
                                              imgSrc: FrameBlue,
                                              color: "Beige",
                                              ftype: ["Classic"],
                                            },
                                            {
                                              // gold
                                              id: 10,
                                              imgSrc: FrameBlue,
                                              color: "Gold",
                                              ftype: ["Classic"],
                                            },
                                            {
                                              id: 11,
                                              // green
                                              imgSrc: FrameYellow,
                                              color: "Green",
                                              ftype: ["Metallic Brush"],
                                            },
                                          ].map(
                                            ({ imgSrc, color, ftype, id }) => {
                                              return ftype.includes(
                                                frameType
                                              ) ? (
                                                <li
                                                  className={`cursor-pointer mr-[30px] w-10 h-10 flex items-center justify-center ${
                                                    frame == color
                                                      ? " shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                      : ""
                                                  } `}
                                                  onClick={() => {
                                                    setFrame(color);
                                                  }}
                                                >
                                                  <span
                                                    className="dot w-5 h-5 shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                    style={{
                                                      backgroundColor:
                                                        Colors[color],
                                                    }}
                                                    title={color}
                                                  ></span>
                                                </li>
                                              ) : (
                                                <></>
                                              );
                                            }
                                          )
                                        )}
                                      </ul>
                                    </div>
                                    <div className="lg:flex justify-between my-4">
                                      <div className="flex-row">
                                        <div className="text-xs md:text-base mb-3 font-medium">
                                          Size
                                        </div>
                                        <ul className="flex grid grid-cols-2 md:grid-cols-2">
                                          {React.Children.toArray(
                                            [
                                              {
                                                id: 1,
                                                fsize: "8x8",
                                                text: "8 x 8 in",
                                                ftype: [
                                                  "Distressed",
                                                  "Natural Wood",
                                                  "Classic Wood Frames",
                                                  "Classic thin",
                                                  "Modern Wood Frame",
                                                  "Metallic Brush",
                                                ],
                                              },
                                              {
                                                id: 2,
                                                fsize: "8x10",
                                                text: "8 x 10 in",
                                                ftype: [
                                                  "Distressed",
                                                  "Natural Wood",
                                                  "Classic Wood Frames",
                                                  "Classic thin",
                                                  "Modern Wood Frame",
                                                  "Metallic Brush",
                                                ],
                                              },
                                              {
                                                id: 3,
                                                fsize: "12x15",
                                                text: "12 x 15 in",
                                                ftype: [
                                                  "Distressed",
                                                  "Natural Wood",
                                                  "Classic Wood Frames",
                                                  "Classic thin",
                                                  "Modern Wood Frame",
                                                  "Metallic Brush",
                                                ],
                                              },
                                              {
                                                id: 4,
                                                fsize: "12x18",
                                                text: "12 x 18 in",
                                                ftype: [
                                                  "Distressed",
                                                  "Natural Wood",
                                                  "Classic Wood Frames",
                                                  "Classic thin",
                                                  "Modern Wood Frame",
                                                  "Metallic Brush",
                                                ],
                                              },
                                              {
                                                id: 5,
                                                fsize: "16x20",
                                                text: "16 x 20 in",
                                                ftype: [
                                                  "Distressed",
                                                  "Natural Wood",
                                                  "Classic Wood Frames",
                                                  "Classic thin",
                                                  "Modern Wood Frame",
                                                  "Metallic Brush",
                                                ],
                                              },
                                              {
                                                id: 6,
                                                fsize: "4x4",
                                                text: "4 x 4 in",
                                                ftype: ["Classic"],
                                              },
                                              {
                                                id: 7,
                                                fsize: "4x6",
                                                text: "4 x 6 in",
                                                ftype: ["Classic"],
                                              },
                                              {
                                                id: 8,
                                                fsize: "5.5x5.5",
                                                text: "5.5 x 5 in",
                                                ftype: ["Classic"],
                                              },
                                              // {
                                              //   fsize: "5x5",
                                              //   text: "5 x 5 in",
                                              //   ftype:["Classic"]
                                              // },
                                              {
                                                id: 9,
                                                fsize: "5x7",
                                                text: "5 x 7 in",
                                                ftype: ["Classic"],
                                              },
                                              // {
                                              //   fsize: "6x4",
                                              //   text: "6 x 4 in",
                                              //   ftype:["Classic"]
                                              // },
                                              // {
                                              //   fsize: "6x8",
                                              //   text: "6 x 8 in",
                                              //   ftype:["Classic"]
                                              // },
                                              // {
                                              //   fsize: "7x5",
                                              //   text: "7 x 5 in",
                                              //   ftype:["Classic"]
                                              // },
                                              // {
                                              //   fsize: "7x9",
                                              //   text: "7 x 9 in",
                                              //   ftype:["Classic"]
                                              // },
                                              {
                                                id: 10,
                                                fsize: "8x10",
                                                text: "8 x 10 in",
                                                ftype: ["Classic"],
                                              },
                                              // {
                                              //   fsize: "10x12",
                                              //   text: "10 x 12 in",
                                              //   ftype:["Classic"]
                                              // },
                                            ].map(
                                              ({ text, fsize, ftype, id }) => {
                                                return ftype.includes(
                                                  frameType
                                                ) ? (
                                                  <li
                                                    onClick={() => {
                                                      setsize(fsize);
                                                      setZoom(1);
                                                      setCrop({ x: 0, y: 0 });
                                                    }}
                                                    className={`flex cursor-pointer items-center sm:mr-5 mr-[10px] dark:shadow-dark-input rounded-[10px] p-3 sm:mb-5 mb-[10px] ${
                                                      size == fsize
                                                        ? "shadow-inner-all dark:shadow-dark-inner-all"
                                                        : "shadow-dark-input-bottom-right "
                                                    } h-[44px] sm:w-[7.25rem] sm:h-[2.75rem]`}
                                                  >
                                                    <input
                                                      type="radio"
                                                      className="w-4 h-4 sm:w-5 sm:h-5 cursor-pointer  accent-cyan-600 downloadRadioButton"
                                                      checked={
                                                        size == fsize
                                                          ? "checked"
                                                          : ""
                                                      }
                                                      onChange={() => {
                                                        setsize(fsize);
                                                        setZoom(1);
                                                        setCrop({ x: 0, y: 0 });
                                                      }}
                                                    />
                                                    <span className="pl-2 text-[11px] sm:text-sm md:text-sm">
                                                      {text}
                                                    </span>
                                                  </li>
                                                ) : (
                                                  <></>
                                                );
                                              }
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="">
                                        <div className="text-xs md:text-base mb-3 lg:mt-0 md:mt-0 mt-6 font-medium">
                                          Quantity
                                        </div>
                                        <div className="flex">
                                          <div className="flex items-center shadow-dark-input-bottom-right dark:shadow-dark-input rounded-[13px] p-2 w-[100px] h-[40px] sm:w-[5.25rem] sm:h-[2.5rem] justify-center">
                                            <span
                                              className="pl-2 text-sm md:text-sm font-bold cursor-pointer"
                                              onClick={() => {
                                                decrementQty();
                                              }}
                                            >
                                              -
                                            </span>
                                            <span className="pl-2 text-[11px] sm:text-sm md:text-sm">
                                              {quantity}
                                            </span>
                                            <span
                                              className="pl-2 text-sm md:text-sm font-bold cursor-pointer"
                                              onClick={() => {
                                                incrementQty();
                                              }}
                                            >
                                              +
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex justify-center sm:py-12 pt-[30px] pb-[40px]">
                                    <Button
                                      onClick={() => {
                                        addthis();
                                        setDisableAdd(true);
                                      }}
                                      text="Add to Cart"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <OtherFrames
                            photo={photo}
                            close={close}
                            cropImage={cropImage}
                            setCropImage={setCropImage}
                            cartId={cartId}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
