export const Colors = {
  Black: '#343331',
  Brown: '#8c685a',
  Golden: '#9b7647',
  White: '#dad2bd',
  Tan: '#5b3e2a',
  Walnut: '#473a33',
  Honey: '#5a3c25',
  Tortoise: '#7f542c',
  Beige: '#f4e0ad',
  Gold: '#af9352',
  Green: '#605948',
  gold: "#af9352",
  black: "#343331",
  brown: "#8c685a",
  white: "#dad2bd",
};

