import Swal from "sweetalert2";

import { fireSingleDownloadGaEvent } from ".";
import {
  HIGH_RES_DOMAIN,
  LOWER_RES_DOMAIN,
} from "../constants";

export const getColumns = (size) => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  let columns = 2;
  if (size === "small") columns = 2;
  if (vw >= 300 && size === "small") columns = 2;
  if (vw > 700 && size === "small") columns = 3;
  if (vw > 1200 && size === "small") columns = 6;
  if (size === "regular") columns = 2;
  if (vw >= 300 && size === "large") columns = 1;
  if (vw >= 300 && size === "regular") columns = 2;
  if (vw > 700 && size === "large") columns = 2;
  if (vw > 700 && size === "regular") columns = 3;
  if (vw > 1200 && size === "large") columns = 3;
  if (vw > 1200 && size === "regular") columns = 4;
  return columns;
};

export const getGalleryStyle = (eventDetails) => {
  return {
    direction:
      eventDetails.data.design.gridLayout === "horizontal" ? "row" : "column",

    targetRowHeight:
      eventDetails.data.design.gridLayout === "horizontal"
        ? eventDetails.data.design.thumbnailSize === "regular"
          ? 350
          : eventDetails.data.design.thumbnailSize === "small"
          ? 200
          : 500
        : undefined,
    columns:
      eventDetails.data.design.gridLayout === "vertical"
        ? getColumns(eventDetails.data.design.thumbnailSize)
        : undefined,
  };
};

export const carouselSlideShow = (iconsColor) => ({
  type: "button",
  class: "fancybox__button--slideshow",
  label: "Start Slideshow",
  html: `<svg height="26" width="26" viewBox="0 0 24 24" tabindex="-1" style="stroke: ${iconsColor}">
                  <g><path color='${iconsColor}' d="M6 4v16"/><path color='${iconsColor}' d="M20 12L6 20"/><path color='${iconsColor}' d="M20 12L6 4"/></g>
                  <g><path color='${iconsColor}' d="M7 4v15M17 4v15"/></g>
              </svg>`,
  click: function (event) {
    event.preventDefault();
    this.Slideshow.toggle();
  },
});

export const carouselZoomButton = (iconsColor) => ({
  type: "button",
  class: "fancybox__button--zoom",
  label: "Zoom",
  html: `<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 24 24" stroke-width="1.5" style="stroke: ${iconsColor}">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" color='${iconsColor}' />
</svg>`,
  click: function (event) {
    event.preventDefault();
    const panzoom = this.fancybox.getSlide().Panzoom;

    if (panzoom) {
      panzoom.toggleZoom();
    }
  },
});

export const carouselDownloadButton = (
  iconsColor,
  isIosDevice,
  downloadOgImage,
  setDownloadAlertShown,
  userId
) => ({
  type: "button",
  label: "Download",
  class: "fancybox__button--download",
  download: "true",
  html: `<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 74 113"
  width="24"
  height="24"
  style="stroke: ${iconsColor}"
>
  <path
    d="M34.559 92.3938C34.713 92.5478 34.8827 92.686 35.0635 92.8065L35.3028 92.9391L35.5819 93.0717L35.9009 93.1784L36.193 93.2711L36.1939 93.2711C36.6326 93.361 37.0842 93.361 37.5228 93.2711L37.8149 93.1784L38.1339 93.0717L38.4659 92.8992L38.7051 92.7666L38.7061 92.7666C38.8878 92.6461 39.0566 92.5079 39.2115 92.354L63.4329 68.1729C64.733 66.8737 64.733 64.7658 63.4329 63.4668C62.1337 62.1676 60.0267 62.1676 58.7268 63.4668L40.2349 81.9725L40.2349 3.3235C40.2349 1.48832 38.7465 -2.61322e-05 36.9113 -2.62927e-05C35.0762 -2.64531e-05 33.5878 1.48832 33.5878 3.3235L33.5878 81.9725L15.0959 63.4799C13.7958 62.1807 11.6888 62.1807 10.3898 63.4799C9.0897 64.78 9.0897 66.887 10.3898 68.186L34.559 92.3938Z"
    color='${iconsColor}' 
    fill='${iconsColor}'
  />
  <path
    d="M3.67645 112.987L70.1471 112.987C71.9776 112.987 73.4632 111.507 73.4706 109.676L73.4706 90.6392C73.4706 88.8031 71.9822 87.3157 70.1471 87.3157C68.3119 87.3157 66.8235 88.8031 66.8235 90.6392L66.8235 106.34L6.99998 106.34L6.99998 90.6392C6.99998 88.8031 5.51163 87.3157 3.67645 87.3157C1.84127 87.3157 0.352922 88.8031 0.352922 90.6392L0.35292 109.676C0.360339 111.507 1.84592 112.987 3.67645 112.987Z"
    color='${iconsColor}' 
    fill='${iconsColor}'
  />
</svg>`,
  click: function (event) {
    event.stopPropagation();
    let url = this.fancybox.getSlide().downloadSrc;
    let filename = this.fancybox.getSlide().originalName;
    if (isIosDevice) {
      const downloadButtonClicked = () => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        fireSingleDownloadGaEvent(filename, userId);
        a.href = downloadOgImage
          ? url.replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN)
          : url;
        console.log(a, downloadOgImage, url);
        a.target = "_blank";
        setTimeout(() => a.click(), 100);
        document.body.removeChild(a);
      };
      if (!this.fancybox.options.downloadAlertShown) {
        this.fancybox.options.downloadAlertShown = true;
        setDownloadAlertShown(true);
        Swal.fire({
          icon: "info",
          title: "",
          text: "A new window with the photo will open. Press and hold the photo for option to save.",
        }).then(function (data) {
          if (data.isConfirmed) {
            downloadButtonClicked();
          }
        });
      } else downloadButtonClicked();
      return;
    }
    url = downloadOgImage
      ? url.replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN)
      : url;
    fetch(url, {
      method: "GET",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
          fireSingleDownloadGaEvent(filename, userId);
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  },
});

export const carouselCloseButton = (iconsColor) => ({
  type: "button",
  label: "Close",
  class: "fancybox__button--close",
  html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1" style="stroke: ${iconsColor}"><path color='${iconsColor}' d="M20 20L4 4m16 0L4 20"></path></svg>`,
  tabindex: 1,
  click: function (event) {
    event.stopPropagation();
    event.preventDefault();
    this.fancybox.close();
  },
});
