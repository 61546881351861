import React from "react";

const VideoPlayIcon = props => {
    const { height = "60", width = "61", color = "#EEE" } = props;
    return (
        <svg width={width} height={height}>
            <path
                d='M30.5 0a29.999 29.999 0 1 0 30 30 30.033 30.033 0 0 0-30-30zM20.327 16.566a.826.826 0 0 1 .42-.726.82.82 0 0 1 .833 0l23.267 13.44a.826.826 0 0 1 0 1.44L21.58 44.16a.84.84 0 0 1-.833 0 .826.826 0 0 1-.42-.727V16.566z'
                fill={color}
                fillRule='nonzero'
            />
        </svg>
    );
};

export default VideoPlayIcon;
