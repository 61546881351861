import axios from "axios"
import Cookies from "js-cookie";

import { LOCALSTORAGE_ACCESS_TOKEN } from "../constants";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const token = Cookies.get(LOCALSTORAGE_ACCESS_TOKEN);

const defaultOptions = {
    baseURL: endpoint,
    headers: {
        'Content-Type': 'application/json',
    },
};
// Create instance
let fetch = axios.create(defaultOptions);

// Set the AUTH token for any request
fetch.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

const sendResponse = (result)=>{
    if(result.status===200 || result.status===204 || result.status===201){
        return {error:false,response:result.data}
    }else{
        return {error:true,message:result.message,e:result.error}
    }

}

export const foldersDownload = async (eventId,selectedFolders,image_type,accessToken,email,type) =>{
    const folders = []
    for(let key in selectedFolders)
        folders.push(selectedFolders[key])

    var data = new FormData();
    data.append('type', type);
    data.append('eventId', eventId);
    folders.forEach((item , index) => data.append(`folderIds[${index}]`, item))
    data.append('quality', !image_type ? "original" : "optimized");
    data.append('email', email);
    data.append('accessToken', accessToken);

    var config = {
    method: 'post',
    url: `${endpoint}downloads`,
    data : data
    };

    try{
        var result = await axios(config)
        // var result = await fetch.post(`${endpoint}user/submitDownload`,data)
        return sendResponse(result)
    }catch(e){
        return sendResponse({status:e.response.status,message:e.response.data.message,error:e.response.data.errors})
    }
}

export const favouriteDownload = async (eventId,email,image_type,listId,accessToken,type) =>{
    var data = new FormData();
    data.append('eventId', eventId);
    data.append('accessToken', accessToken);
    data.append('email', email);
    data.append('quality', !image_type ? "original" : "optimized");
    data.append('favouriteListId', listId);
    data.append('type', type);

    var config = {
    method: 'post',
    url: `${endpoint}downloads`,
    data : data
    };

    try{
        var result = await axios(config)
        // var result = await fetch.post(`${endpoint}user/submitDownload`,data)
        return sendResponse(result)
    }catch(e){
        return sendResponse({status:e.response.status,message:e.response.data.message,error:e.response.data.errors})
    }
}

export const digitalImageDownload = async (eventId,email,cartID,accessToken,cartOrderId) =>{
    const data = new FormData();
    data.append('eventId', eventId);
    data.append('accessToken', accessToken);
    data.append('email', email);
    data.append('quality',  "original");
    cartID.forEach((item , index) => data.append(`cartIds[${index}]`, item))
    data.append('type', "digital");
    data.append("cartOrderId",cartOrderId)

    const config = {
    method: 'post',
    url: `${endpoint}downloads`,
    data : data
    };

    try{
        const result = await axios(config)
        // var result = await fetch.post(`${endpoint}user/submitDownload`,data)
        return sendResponse(result)
    }catch(e){
        return sendResponse({status:e.response.status,message:e.response.data.message,error:e.response.data.errors})
    }
}

export const faceSearchDownload = async (eventId,email,image_type,search_id,accessToken,type) =>{
    var data = new FormData();
    data.append('type', type);
    data.append('eventId', eventId);
    data.append('faceSearchId', search_id);
    data.append('quality', !image_type ? "original" : "optimized");
    data.append('email', email);
    data.append('accessToken', accessToken);

    var config = {
    method: 'post',
    url: `${endpoint}downloads`,
    data : data
    };

    try{
        var result = await axios(config)
        // var result = await fetch.post(`${endpoint}user/submitDownload`,data)
        return sendResponse(result)
    }catch(e){
        return sendResponse({status:e.response.status,message:e.response.data.message,error:e.response.data.errors})
    }
}

export const getDownlaodFolder = async (downId,requestNo)=>{
    try{
       if(requestNo === 1){
            var result = await  axios.get(`${endpoint}downloads/url?id=`+downId)
       }else{
            var result = await  axios.get(`${endpoint}downloads/url?id=`+downId, {
                headers: {
                "X-Requested-With": "XMLHttpRequest",
                },
            })
        }
        return sendResponse(result)
    }catch(e){
        return sendResponse({status:e.response.status,message:e.response.data.message,error:e.response.data.errors})
    }
}