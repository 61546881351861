import { useContext } from "react";
import ErrorIcon from "../../assets/icons/ErrorIcon";
import { ThemeContext } from "../../components/App";

function Error({message,text}) {
    
    const theme = useContext(ThemeContext);
    return (
        <div className={theme}>
            <div className="grid place-items-center h-screen w-full bg-theme-light dark:bg-dark-gray">
                <div className="flex flex-col justify-center items-center text-dark-gray dark:text-light-gray max-w-md">
                <ErrorIcon width={100} height={100} />
                <h3 className="text-2xl mb-5">{message}</h3>
                <p className="text-sm text-center">{text}</p>
                </div>
            </div>
        </div>
    );
}

export default Error;
