import React, { useCallback, useState, useEffect } from "react";

import { useEvent } from "../../../Store/event";
import { useUser } from "../../../Store/user";
import Button from "../Button";
import { InputPhone, InputText } from "../Input";
import { guestLogin, loginFaceReg } from "../../../apis/events";
import {
  LOGIN_BE_MESSAGE_FOR_LIMIT,
  MAX_FACE_REG_REACHED_POPUP,
  MOBILE_REGEX_TO_CHECK_DIGITS,
  SHOW_MOBILE_INSTEAD_OF_EMAIL,
} from "../../../constants";

export default function EmailPopUp({
  close,
  faceRecLogin,
  setCurrentView = () => {},
}) {
  const [email, setEmail] = useState("");
  const { user, setUser } = useUser();
  const [error, setError] = useState(user.error);
  const [isDisabled, setDisable] = useState(true);
  const eventDetails = useEvent().response;

  const fieldIsMobile = SHOW_MOBILE_INSTEAD_OF_EMAIL.includes(
    eventDetails.photographer.id
  );

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = useCallback(async () => {
    if (
      fieldIsMobile
        ? MOBILE_REGEX_TO_CHECK_DIGITS.exec(email) !== null
        : validateEmail(email)
    ) {
      let result = {};
      if (faceRecLogin) {
        result = await loginFaceReg(eventDetails.data.id, {
          email: email,
          pin: eventDetails.data.pin ?? eventDetails.pin ?? user.pin,
        });
        if (result.error === false) {
          let loggedInUserData = {
            ...user,
            email: email,
            accessToken: result.response.data.accessToken,
            isAdmin: result.response.data.isSuperAdmin,
            isValid: true,
            pin:
              eventDetails.pin || user.pin ? eventDetails.pin ?? user.pin : "",
            search_id: result.response.data.searchId,
            phone: fieldIsMobile ? email : result.response.data.phone,
            id: result.response.data.id,
            previousImages: result.response.images,
            isFaceSearch: true,
          };
          if (faceRecLogin) {
            setCurrentView("upload");
          }
          setUser(loggedInUserData);
          close(false);
        } else {
          window.Toast.fire({
            icon: "error",
            title: "Something went wrong",
          });
          close(false);
        }
      } else {
        result = await guestLogin(eventDetails.data.id, email, user.password);
        if (result.error === false) {
          let loggedInUserData = {
            ...user,
            email: email,
            accessToken: result.response.accessToken,
            isAdmin: result.response.isSuperAdmin,
            isValid: true,
            pin:
              eventDetails.pin || user.pin ? eventDetails.pin ?? user.pin : "",
            search_id: result.response.searchId,
            fname: result.response.name?.split(" ")[0],
            lname: result.response.name?.split(" ")[1],
            phone: fieldIsMobile ? email : result.response.phone,
            id: result.response.guestId,
          };
          setUser(loggedInUserData);
          close(false);
        } else {
          if (result.message === LOGIN_BE_MESSAGE_FOR_LIMIT) {
            MAX_FACE_REG_REACHED_POPUP();
            close(false);
            return;
          }
          setUser({ ...user, error: result.message });
          setError(result.message);
        }
      }
    } else {
      setError("Invalid" + fieldIsMobile ? " Mobile number" : " email address");
    }
  }, [email]);

  useEffect(() => {
    setError("");
    setDisable(true);
    if (
      email &&
      (fieldIsMobile
        ? MOBILE_REGEX_TO_CHECK_DIGITS.exec(email) !== null
        : validateEmail(email))
    ) {
      setDisable(false);
    }
  }, [email]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="mt-2 px-1 bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      {SHOW_MOBILE_INSTEAD_OF_EMAIL.includes(eventDetails.photographer.id) ? (
        <InputPhone
          autoFocus
          label="Phone"
          value={email}
          error={error}
          onChange={setEmail}
          onKeyDown={handleKeyDown}
          tabIndex={1}
        />
      ) : (
        <InputText
          autoFocus
          label="Enter your email"
          error={error}
          required={true}
          type="text"
          onChange={setEmail}
          value={email}
          onKeyDown={handleKeyDown}
        />
      )}
      <div className="my-5 flex flex-col justify-center items-center">
        <Button disabled={isDisabled} text="Submit" onClick={handleSubmit} />
      </div>
    </div>
  );
}
