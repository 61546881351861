import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles/input-phone.scss";
import { Input } from ".";
import { getCountryCode } from "../../../apis";

const PhoneInputWrapper = ({
  value = "",
  error = "",
  onChange = () => {},
  tabIndex = -1,
  country,
  onBlur=()=>{}
}) => {


  return (
    <PhoneInput
      containerClass={`rounded-xl
            ${!value ? "" : "filled"}
            ${error ? "border-solid border-2 border-[#e16a6a]" : "border-none"} 
            mt-1 phone-input`}
      inputClass={`rounded-xl bg-transparent px-[16px] py-[10px] 
            ${
              !value && !error
                ? "shadow-light-input dark:shadow-dark-input"
                : "shadow-inner-all dark:shadow-dark-inner-all"
            }
            mt-1 text-dark-gray1 dark:text-light-gray`}
      dropdownClass={"rounded-xl dark:!bg-dark-gray dark:!text-white"}
      country={country}
      enableAreaCodes={true}
      placeholder={""}
      value={value}
      onChange={onChange}
      inputProps={tabIndex}
      onBlur={onBlur}
    />
  );
};

export const InputPhone = (props) => {
  const [defaultCountry, setDefaultCountry] = useState("");

  const getCountry = async () => {
    try {
      const res = await getCountryCode();
      setDefaultCountry(res.data.country.code.toLowerCase());
    } catch (error) {
      console.error(error);
      setDefaultCountry("IN")
    }
  };

  useEffect(() => {
   !props.value &&  getCountry();
  }, []);
  return (
    <>
      <Input {...props} type="tel" />
      <PhoneInputWrapper {...props} country={defaultCountry} />
      {props.error ?  <label className="text-sm text-[#e16a6a]">{props.error}</label> : <></>}
    </>
  );
};
