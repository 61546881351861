import axios from "axios";

import { http } from "./index";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const sendResponse = (result) => {
  if (result.status === 200 || result.status === 204 || result.status === 201) {
    return { error: false, response: result.data };
  } else {
    return { error: true, message: result.message, e: result.error };
  }
};

export const faceSearch = async (
  user,
  event,
  image,
  t,
  sendReplaceParam,
  replaceImage = false
) => {
  const data = new FormData();
  data.append("user_id", user);
  data.append("event_id", event);
  data.append("image", image);
  data.append("type", t);
  !t && sendReplaceParam && data.append("replaceImage", replaceImage);

  const config = {
    method: "post",
    url: `${endpoint}face/search`,
    data: data,
  };

  try {
    let result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const faecResults = async (searchId, eventId, page = 1) => {
  try {
    let result = await axios.get(
      `${endpoint}face/search?searchId=` + searchId + "&page=" + page
    );
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const checkAccess = async (photographerId) => {
  try {
    let result = await axios.get(
      `${endpoint}photographer/check-access?id=` + photographerId
    );
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const imageAfterFaceSearch = (faceSearchId) => {
  return http.get("image-after-face-search", {
    params: {
      faceSearchId,
    },
  });
};

export const getFaceResultWithFolders = async (
  searchId,
  folderId,
  page = 1
) => {
  try {
    let queryParams = `searchId=${searchId}&page=${page}`;
    if (folderId) queryParams += `&folderId=${folderId}`;

    const result = await axios.get(
      `${endpoint}face/search/folder-wise?` + queryParams
    );
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};
