import React from "react";
import { Input } from ".";

export const InputText = props => {
    return (
        <Input
            {...props}
            inputAttr={{
                className:
                    "rounded-xl bg-transparent p-2 shadow-light-input dark:shadow-dark-input",
            }}
        />
    );
};
