import React from "react";

export const Input = (props) => {
  const {
    className = "",
    label = "",
    required = false,
    type = "",
    onChange = () => {},
    onKeyDown = () => {},
    value = "",
    readOnly = false,
    tabIndex = -1,
    error = "",
    autoFocus = false,
    lableClassName = "",
    onBlur = () => {},
    id = null,
    inputClassname,
    emptyInputClassname,
    refElement = null,
  } = props;
  const isPhoneInput = type === "tel";
  const showIsRequired = required ? "*" : "";
  return (
    <div className={`flex flex-col w-full ${className}`}>
      <div className="grid grid-cols-2">
        <label
          htmlFor={id ?? label}
          className={`md:text-sm text-left font-medium text-black-gray dark:text-light-gray ${lableClassName}`}
        >{`${label}${showIsRequired}`}</label>
      </div>
      {!isPhoneInput && (
        <>
          <input
            autoFocus={autoFocus}
            type={type}
            value={value}
            className={`rounded-xl bg-transparent px-[16px] py-[10px] input-shadow-fix 
                    ${
                      !value
                        ? `shadow-light-input dark:shadow-dark-input ${inputClassname}`
                        : `shadow-inner-all dark:shadow-dark-inner-all ${emptyInputClassname}`
                    } 
                     ${
                       error
                         ? "outline-1 border-2 border-[#e16a6a]"
                         : "outline-none"
                     } 
                     mt-1 text-dark-gray1 dark:text-light-gray`}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onKeyDown={(e) => {
              onKeyDown(e);
            }}
            readOnly={readOnly}
            tabIndex={tabIndex}
            name={Math.random()}
            autoComplete="random-thing"
            onFocus={(event) => {
              if (event.target.autocomplete)
                event.target.autocomplete = "random-thing";
            }}
            id={id ?? label}
            onBlur={onBlur}
            ref={refElement}
          />
          <label className="text-sm text-[#e16a6a]">{error}</label>
        </>
      )}
    </div>
  );
};
