import React from "react";
import Text from "../../../../components/common/Text";
import { BMS_PHOTOGRAPHER_ID } from "../../../../constants";
import { useEvent } from "../../../../Store/event";

const BasicInfo = ({ showBrandName = true }) => {
  const eventDetails = useEvent().response;

  return (
    <>
      <Text
        className={`text-base text-dark-gray1 dark:text-light-gray ${
          BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
            ? "block"
            : "hidden lg:block"
        }  font-medium`}
      >
        {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
          <>
            Say CHEESE! Register for face recognition-led captures at
            LOLLAPALOOZA INDIA 2023!
            <div>
              <br className="mt-2" /> Get yours now.
            </div>
          </>
        ) : (
          `Get your images using face recognition at `
        )}
      </Text>
      {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
        <></>
      ) : (
        <Text
          as="p"
          className="text-3xl text-dark-gray1 hidden lg:block lg:pb-8 dark:text-light-gray border-b border-b-retake-light-border dark:border-dark-font dark:border-b-dark-font font-medium"
        >
          {eventDetails.data.name.replace("-", " ")}
        </Text>
      )}
      <Text
        as="div"
        className="text-lg md:text-2xl font-medium text-dark-gray1 lg:pt-6 pb-1 dark:text-light-gray"
      >
        {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
          <div className="mb-2 italic text-black-gray dark:text-light-gray text-xs">
            Jackets, hoops, colourful wigs, or bucket hats - our intelligent,
            cutting edge algorithm can still detect your face with 99.2%
            accuracy! Simply fill the form, and receive your Lollapalooza photos
            on email (privately and securely), by the end of the day. <br /> So,
            what’re you waiting for? Register now, for a personal paparazzi
            experience by professional photographers.
          </div>
        ) : showBrandName ? (
          eventDetails.photographer.brandingAllowed ? (
            eventDetails.photographer.brandName
          ) : (
            "Algomage"
          )
        ) : (
          <div className="mb-2 italic text-black-gray dark:text-light-gray text-xs">
            Welcome to the new age of image discovery. Our cutting edge
            algorithms can detect your face in every photo captured with 99.2%
            accuracy (even if you are wearing sunglasses or masks)! Just fill
            this form, capture a selfie and we'll make all your photos from
            {" " + eventDetails.data.name} reach you automatically and privately! <br />
            PS: Your data is completely secure with us. No spams!
          </div>
        )}
      </Text>
    </>
  );
};

export default BasicInfo;
