import axios from "axios";

import { http } from "./index";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const sendResponse = (result) => {
  if (result.status === 200 || result.status === 204 || result.status === 201) {
    return { error: false, response: result.data };
  } else {
    return { error: true, message: result.message, e: result.error };
  }
};

export const getCartLists = async (eventId, accessToken) => {
  try {
    var result = await axios.get(
      `${endpoint}store/cart/${eventId}?accessToken=${accessToken}`
    );
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const storeToCart = async (
  eventId,
  eventImageId,
  frameType,
  quantity,
  imageSize,
  color,
  frameStyle,
  frameUrl,
  croppedParameters,
  accessToken,
  photographerId,
  passProvider,
  addOn = ""
) => {
  var data = new FormData();
  data.append("photographerId", photographerId);
  data.append("eventId", eventId);
  data.append("eventImageId", eventImageId);
  data.append("frameType", frameType);
  data.append("quantity", quantity);
  data.append("imageSize", imageSize);
  data.append("color", color);
  data.append("frameStyle", frameStyle);
  data.append("frameUrl", frameUrl);
  data.append("croppedParameters", croppedParameters ? croppedParameters : "");
  data.append("accessToken", accessToken);
  passProvider && data.append("provider", "miller");
  addOn && data.append("addOn", addOn);
  var config = {
    method: "post",
    url: `${endpoint}cart/store`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const storeAllToCart = async (
  eventId,
  eventImageId,
  frameType,
  quantity,
  imageSize,
  color,
  frameUrl,
  frameStyle,
  croppedParameters,
  accessToken,
  photographerId,
  id,
  type = "faceSearch"
) => {
  var data = new FormData();
  data.append("photographerId", photographerId);
  data.append("eventId", eventId);
  data.append("eventImageId", eventImageId);
  data.append("frameType", frameType);
  data.append("quantity", quantity);
  data.append("imageSize", imageSize);
  data.append("color", color);
  data.append("frameStyle", frameStyle);
  data.append("frameUrl", frameUrl);
  data.append("croppedParameters", croppedParameters ? croppedParameters : "");
  data.append("accessToken", accessToken);
  data.append("faceSearchId", id);
  data.append("type", type);
  var config = {
    method: "post",
    url: `${endpoint}cart/store`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const cartUpdate = async (id, cartIds, qty, acessToken) => {
  var data = new FormData();
  data.append("id", id);
  data.append("qty", qty);
  data.append("cartIds[]", cartIds);
  data.append("accessToken", acessToken);
  var config = {
    method: "post",
    url: `${endpoint}cart-update`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const cartRemove = async (id, cartIds, qty, acessToken) => {
  var data = new FormData();
  data.append("id", id);
  data.append("qty", qty);
  data.append("cartIds[]", cartIds);
  data.append("accessToken", acessToken);
  var config = {
    method: "post",
    url: `${endpoint}cart-remove`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const getCartCount = async (eventId, accessToken) => {
  try {
    var result = await axios.get(
      `${endpoint}cart/list/${eventId}?accessToken=${accessToken}`
    );
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const payment = async (
  eventId,
  userName,
  email,
  address1,
  address2,
  country,
  city,
  state,
  PostalCode,
  baddress1,
  baddress2,
  bcountry,
  bcity,
  bstate,
  bPostalCode,
  phoneNumber,
  orderIds,
  accessToken,
  currency,
  shippingTotal
) => {
  var data = new FormData();
  data.append("eventId", eventId);
  data.append("email", email);
  data.append("orderIds", JSON.stringify(orderIds));
  data.append("cancel_url", "http://localhost:3000/checkout");
  data.append("shipping_full_name", userName);
  data.append("shipping_city", city);
  data.append("shipping_address_1", address1);
  data.append("shipping_address_2", address2);
  data.append("shipping_zip", PostalCode);
  data.append("shipping_state", state);
  data.append("shipping_country", country);
  data.append("billing_full_name", userName);
  data.append("billing_city", bcity);
  data.append("billing_address_1", baddress1);
  data.append("billing_address_2", baddress2);
  data.append("billing_zip", bPostalCode);
  data.append("billing_state", bstate);
  data.append("billing_country", bcountry);
  data.append("phone", phoneNumber);
  data.append("accessToken", accessToken);
  data.append("currency", currency);
  data.append("shippingTotal", shippingTotal);
  var config = {
    method: "post",
    url: `${endpoint}store/payment`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const completeShopping = async (
  status,
  payment_method_types,
  id,
  created,
  payment_method
) => {
  var data = new FormData();
  data.append("status", status);
  data.append("payment_method_types", payment_method_types);
  data.append("id", id);
  data.append("created", created);
  data.append("payment_method", payment_method);
  var config = {
    method: "post",
    url: `${endpoint}store/complete-shopping`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const completeRazorSingle = async (data) => {
  var config = {
    method: "post",
    url: `${endpoint}store/complete-razor`,
    data: data,
  };
  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const createSingleOrder = async (
  eventId,
  userName,
  email,
  address1,
  address2,
  country,
  city,
  state,
  PostalCode,
  baddress1,
  baddress2,
  bcountry,
  bcity,
  bstate,
  bPostalCode,
  phoneNumber,
  orderIds,
  accessToken,
  appendType = false,
  currency
) => {
  var data = new FormData();
  data.append("eventId", eventId);
  data.append("email", email);
  data.append("orderIds", JSON.stringify(orderIds));
  data.append("shipping_full_name", userName);
  data.append("shipping_city", city);
  data.append("shipping_address_1", address1);
  data.append("shipping_address_2", address2);
  data.append("shipping_zip", PostalCode);
  data.append("shipping_state", state);
  data.append("shipping_country", country);
  data.append("billing_full_name", userName);
  data.append("billing_city", bcity);
  data.append("billing_address_1", baddress1);
  data.append("billing_address_2", baddress2);
  data.append("billing_zip", bPostalCode);
  data.append("billing_state", bstate);
  data.append("billing_country", bcountry);
  data.append("phone", phoneNumber);
  data.append("accessToken", accessToken);
  appendType && data.append("type", "cod");
  data.append("currency", currency);
  var config = {
    method: "post",
    url: `${endpoint}store/create-single-order`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const postDropbox = (file, accessToken) => {
  return http.post(
    `/downloads/dropbox`,
    { fileUrl: file },
    {
      headers: {
        Authorization: `${accessToken}`,
      },
    }
  );
};

export const postStoreOrder = async (
  accessToken,
  id,
  eventId,
  email,
  url,
  key,
  photographerId
) => {
  return http.post(`/store/order`, {
    accessToken: accessToken,
    guestId: id,
    eventId: eventId,
    email: email,
    eventImageUrl: url,
    eventImageId: key,
    photographerId: photographerId,
  });
};

export const couponOnOrder = async (
  eventId,
  userName,
  email,
  address1,
  address2,
  country,
  city,
  state,
  PostalCode,
  baddress1,
  baddress2,
  bcountry,
  bcity,
  bstate,
  bPostalCode,
  phoneNumber,
  orderIds,
  accessToken,
  couponCode
) => {
  const data = new FormData();
  data.append("eventId", eventId);
  data.append("email", email);
  data.append("orderIds", JSON.stringify(orderIds));
  data.append("shipping_full_name", userName);
  data.append("shipping_city", city);
  data.append("shipping_address_1", address1);
  data.append("shipping_address_2", address2);
  data.append("shipping_zip", PostalCode);
  data.append("shipping_state", state);
  data.append("shipping_country", country);
  data.append("billing_full_name", userName);
  data.append("billing_city", bcity);
  data.append("billing_address_1", baddress1);
  data.append("billing_address_2", baddress2);
  data.append("billing_zip", bPostalCode);
  data.append("billing_state", bstate);
  data.append("billing_country", bcountry);
  data.append("phone", phoneNumber);
  data.append("accessToken", accessToken);
  data.append("code", couponCode);
  return http.post(`cart-coupon`, data);
};

export const getMillerProducts = () => http.get("mil/products");

export const checkAddressDetails = (data) => http.post("mil/pre-flight", data);

export const postMailOnSingleDownload = (payload) =>
  http.post("single-download", payload);
