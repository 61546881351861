import axios from "axios";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const sendResponse = (result) => {
  if (result.status === 200 || result.status === 204 || result.status === 201) {
    return { error: false, response: result.data };
  } else {
    return { error: true, message: result.message, e: result.error };
  }
};

export const getFavLists = async (eventId, accessToken, isCorporate) => {
  try {
    const result = await axios.get(
      `${endpoint}event/user/favourite/list?eventId=${eventId}&accessToken=${accessToken}&corporate=${isCorporate}`
    );
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const getFavImages = async (eventId, accessToken, listId, page = 1) => {
  try {
    const result = await axios.get(
      `${endpoint}event/user/favourite/images?eventId=${eventId}&accessToken=${accessToken}&listId=${listId}&page=${page}`
    );
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const addFav = async (eventId, listId, imageId, accessToken) => {
  const data = {
    eventId: eventId,
    listId: listId,
    imageId: imageId,
    accessToken: accessToken,
  };

  const config = {
    method: "post",
    url: `${endpoint}event/images/favourite`,
    data: data,
  };

  try {
    const result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response?.status,
      message: e.response?.data?.message,
      error: e.response?.data?.errors,
    });
  }
};

export const removeFav = async (favList, imageId, accessToken, eventId) => {
  const data = new FormData();
  data.append("listId", favList);
  data.append("imageId", imageId);
  data.append("accessToken", accessToken);
  data.append("eventId", eventId);
  data.append("_method", "DELETE");
  const config = {
    method: "post",
    url: `${endpoint}event/remove/images/favourite`,
    data: data,
  };

  try {
    const result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response?.status,
      message: e.response?.data?.message,
      error: e.response?.data?.errors,
    });
  }
};

export const flag = async (imageId, folderId, visibility, user , eventId) => {
  const data = new FormData();

  data.append("imageId", imageId);
  data.append("folderId", folderId);
  data.append("visibility", visibility);
  data.append("eventId" , eventId)
  if (user.accessToken) data.append("accessToken", user.accessToken);
  const config = {
    method: "post",
    url: `${endpoint}images/flag`,
    data: data,
    headers: {
      Accept: "application/json",
      "X-XSRF-TOKEN": "{{ xsrf-token }}",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${user.jwt}`,
    },
  };

  try {
    const result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const addFavouriteList = async (
  eventId,
  listName,
  accessToken,
  isCorporate
) => {
  const data = new FormData();
  data.append("eventId", eventId);
  data.append("listName", listName);
  data.append("accessToken", accessToken);
  data.append("corporate", isCorporate);
  const config = {
    method: "post",
    url: `${endpoint}event/user/favourite/list`,
    data: data,
  };

  try {
    const result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const duplicateList = async (
  eventId,
  listId,
  accessToken,
  isCorporate
) => {
  const data = new FormData();
  data.append("eventId", eventId);
  data.append("listId", listId);
  data.append("accessToken", accessToken);
  data.append("corporate", isCorporate);
  const config = {
    method: "post",
    url: `${endpoint}event/user/favourite/duplicate-list`,
    data: data,
  };

  try {
    const result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const deleteFavoriteList = async (
  eventId,
  listId,
  acessToken,
  isCorporate
) => {
  const data = new FormData();
  data.append("eventId", eventId);
  data.append("listId", listId);
  data.append("accessToken", acessToken);
  data.append("corporate", isCorporate);
  const config = {
    method: "post",
    url: `${endpoint}event/user/favourite/delete-favourite-list`,
    data: data,
  };

  try {
    const result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const renameFavouriteList = async (
  eventId,
  listId,
  foldeName,
  acessToken
) => {
  const data = new FormData();
  data.append("eventId", eventId);
  data.append("listId", listId);
  data.append("name", foldeName);
  data.append("accessToken", acessToken);
  const config = {
    method: "post",
    url: `${endpoint}event/user/favourite/update-list-name`,
    data: data,
  };

  try {
    const result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};
