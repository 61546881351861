import React from "react";

const BigPlus = props => {
    const { height = "114", width = "114", color = "white" } = props;
    return (
        <svg width={width} height={height} viewBox='0 0 114 114'>
            <g fill='none' fillRule='evenodd'>
                <path d='M57 .431 113.569 57 57 113.569.431 57z' />
                <path
                    d='M56.913 3.277a6.66 6.66 0 0 1 6.659 6.658V50.37h40.434a6.66 6.66 0 1 1 0 13.318H63.572v40.434a6.66 6.66 0 0 1-13.318 0V63.687H9.82a6.66 6.66 0 0 1 0-13.318h40.434V9.935a6.66 6.66 0 0 1 6.659-6.658z'
                    fill={color}
                    fillRule='nonzero'
                />
            </g>
        </svg>
    );
};

export default BigPlus;
