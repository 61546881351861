import ReactDOM from "react-dom";

const BottomModal = (props) => {
    const { children, parentID } = props
    return (
        ReactDOM.createPortal(children, document.getElementById(parentID))
    )
}

export default BottomModal;
