import React from "react";

const LogoutIcon = (props) => {
  const { height = "18", width = "18", color = "white" } = props;
  return (
    // <svg width={width} height={height} fill={color} viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
    //   <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 99.000000 102.000000"
      width={width}
      height={height}
      fill={color}
    >
      <g
        transform="translate(0.000000,102.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M5 1008 c-3 -7 -4 -236 -3 -508 l3 -495 355 0 355 0 3 163 c2 139 0 163 -13 168 -37 14 -45 -13 -45 -147 l0 -129 -300 0 -300 0 0 450 0 450 300 0 300 0 0 -129 c0 -134 8 -161 45 -147 13 5 15 29 13 168 l-3 163 -353 3 c-277 2 -354 0 -357 -10z" />
        <path d="M787 653 c-17 -16 -4 -41 40 -80 l48 -42 -205 -1 c-113 0 -211 -4 -218 -9 -8 -5 -12 -17 -10 -27 3 -18 17 -19 222 -22 l218 -2 -51 -42 c-53 -43 -65 -79 -30 -86 22 -4 184 135 184 157 0 18 -160 161 -180 161 -7 0 -15 -3 -18 -7z" />
      </g>
    </svg>
  );
};

export default LogoutIcon;
