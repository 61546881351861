import React, { useState } from "react";

function Checkbox(props) {
    const {
        checked = false,
        id = "",
        label = "",
        name = "",
        disabled = false,
        onChange = () => { },
        value = "",
        lableClassName= "",
        className="",
    } = props;
    const [isChecked, setChecked] = useState(checked);

    const changeHandler = val => {
        setChecked(!isChecked);
        onChange(val);
    };

    return (
        <label
            className={`text-dark-gray1 select-none cursor-pointer dark:text-light-gray md:text-sm flex items-center ${lableClassName}`}
            htmlFor={id}
        >
            <input
                className={`form-check-input ${isChecked ? '' : 'appearance-none'}
                    h-[16px] w-[16px]
                    border border-theme-light  dark:border-dark-gray
                    rounded bg-theme-light
                    dark:bg-dark-gray dark:shadow-dark-inner-all
                    accent-blue
                    focus:outline-none transition duration-200 align-top
                    bg-no-repeat bg-center bg-contain mr-2 cursor-pointer ${className}`}
                type='checkbox'
                name={name || id}
                id={id}
                checked={isChecked}
                disabled={disabled}
                onChange={changeHandler}
            />
            {label}
        </label>
    );
}

export default Checkbox;
