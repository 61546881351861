import { useEffect, useState } from "react";

import { getPhotogrpaherDetails } from "../../apis/privacyPolicy";
import { BMS_PHOTOGRAPHER_ID } from "../../constants";
import Error from "../Errors";
import Loading from "../Loading";
import Terms from "../Terms";

const PARAGRAPH_CLASSES = "my-4 leading-[18px]";

const H3_CLASSES = "my-[18px] text-[18.75px] font-bold";

function PrivacyPolicy() {
  const [photogrpaherName, setPhotogrpaherName] = useState("");
  const [photographerId , setPhotographerId] = useState()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const photographerDetails = async () => {
    try {
      const domain = encodeURI("https://" + window.location.host.replace("algomage.ai", "algomage.com"));
      const res = await getPhotogrpaherDetails(domain);
      setPhotogrpaherName(res.data.data.brandName ?? "Algomage");
      setPhotographerId(res.data.data.photographerId)
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    photographerDetails();
  },[]);

  return loading ? (
    <Loading />
  ) : error ? (
    <div className="text-center">
    <Error
      message="Something went wrong"
      text="Sorry for the inconvenience. Please try again after some time"
    />
    </div>
  ) : BMS_PHOTOGRAPHER_ID.includes(photographerId) ? <Terms /> : (
    <div
      style={{
        backgroundColor: "#222",
        color: "#EEE",
        fontFamily: "Times new roman",
      }}
      className="dark:text-white min-h-screen"
    >
      <div className="p-2 ">
        <div>
          <h1 className=" text-[32px]  font-bold my-5">Privacy Policy</h1>
          <p className={PARAGRAPH_CLASSES}>
            Thank you for using {photogrpaherName} app for viewing your photos
            of the event. We are committed to protecting the privacy of your
            data and this policy explains what we collect and how we use your
            personal information. This policy may be amended as we release new
            features, or when legal requirements arise. We encourage you to
            review this policy from time to time to stay informed of any changes
            that might affect you.
          </p>
          <h3 className={H3_CLASSES}>1. Definitions</h3>
          <p className={PARAGRAPH_CLASSES}>
            When we say {photogrpaherName}, “we”, or “us”, we are referring to{" "}
            {photogrpaherName}.{photogrpaherName} provides users access to their
            event photos with the help of an application hosted and managed by{" "}
            {photogrpaherName} - a product of Picsniff Media Pvt Ltd.{" "}
            {photogrpaherName} collects all the user information in the
            following manner
          </p>
          <h3 className={H3_CLASSES}>
            2. What information does {photogrpaherName} collect?
          </h3>
          <p className={PARAGRAPH_CLASSES}>
            Account Information: {photogrpaherName} might collect name, email
            addresses, phone number, your face recognition data in case you use
            the face search services. Photo clicked during the face recognition
            is not stored permanently but the face vector data might be stored
            for face search feature.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            Log data: When you access or use our website or services,{" "}
            {photogrpaherName}
            servers will automatically record log data which may include IP
            addresses, device and browser configurations, date and time of
            access, browsing times and loading errors if applicable. Usage data:
            {photogrpaherName} may collect data on how you use the Services,
            such as gallery creation date and time, timestamps of communications
            you sent out via the account.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            Information from third party services: {photogrpaherName} may
            collect information through other services you integrate with (such
            as Google , Facebook accounts when used for login or sign up
            purposes), information collected will be all in accordance with the
            authorization procedures of the services.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            Additional information: {photogrpaherName} may collect additional
            information when you contact us for support, communicate with{" "}
            {photogrpaherName} via social media channels or contact{" "}
            {photogrpaherName} through third-party services.
          </p>
          <h3 className={H3_CLASSES}>
            3. How does {photogrpaherName} collect your information?
          </h3>
          <p className={PARAGRAPH_CLASSES}>
            Information you provided directly: When you visit the gallery or use
            the face search services, you provide account and transaction
            related information through your account. These may include your
            profile information, These may include billing/shipping information
            provided when purchasing from a gallery.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            Information from your use of the site and Services: When you visit
            the website or use the Services, we collect information of your
            activity and usage from your interaction with the site. These may
            include your IP address, browser and device information, timestamps
            of your interactions on the site. This information is mainly
            collected automatically using cookies or other similar technology.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            Information from other sources: {photogrpaherName} may collect
            information from other services such as third party service
            providers, payment processors, or advertising services. These may
            include device and location information, limited information on your
            card used for payment, prior visits to our website, or whether you
            opened an email campaign.
          </p>
          <h3 className={H3_CLASSES}>
            4. How does {photogrpaherName} use the information we collect?
          </h3>
          <p className={PARAGRAPH_CLASSES}>
            {photogrpaherName} uses the information collected primarily to
            provide you with the Services you signed up for and to support our
            legitimate interests in operating our Services and business. To
            provide you with the services you signed up for: {photogrpaherName}{" "}
            will use the information you provided to enable you to create
            galleries, websites, to facilitate payment and order processing and
            any other services requested by you. This also includes sharing this
            information with third-party service providers in order to provide
            the services (such as emailing a Client). When we need to share
            information, we take the steps to ensure that only the necessary
            information is shared, and the information is protected and being
            used in accordance with this policy.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            To maintain and improve our services: {photogrpaherName} uses the
            information collected to analyze our site performance and measure
            the usage of features.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            To personalize your experience and advertise our services:{" "}
            {photogrpaherName}
            may use the information to conduct our advertising and marketing
            campaigns and referral program. You may opt out of certain ad
            targeting and retargeting services with the third-party advertising
            networks directly.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            To meet legal requirements: {photogrpaherName} may need to use the
            information to comply with legal requests such as court orders,
            requests by public authorities, and any other appropriate legal
            mechanisms.
          </p>
          <h3 className={H3_CLASSES}>
            5. How do we share the information we collect?
          </h3>
          <p className={PARAGRAPH_CLASSES}>
            Third-party Service Providers: {photogrpaherName} may share your
            information with third-party Service Providers who provide and
            support our Services. {photogrpaherName} will only share information
            that is necessary for the third-party to complete the service and
            require them to use the information in a manner that is consistent
            with this policy. Examples of Service Providers include hosting and
            content delivery services, customer support management services and
            payment processors.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            User’s instruction: {photogrpaherName} will share and disclose the
            personal information only on your instruction, provided they are
            part of the functionality of the Services and they are in compliance
            with applicable law.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            Change in business: In the case of a merger, acquisition, financing,
            reorganization or sale, information collected may be shared on the
            basis that it is subject to standard confidentiality arrangements.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            To comply with the law: Information may be disclosed if it is deemed
            necessary to comply with the law and court order, to protect the
            rights of individuals and to fulfill law enforcement requirements.
          </p>
          <p className={PARAGRAPH_CLASSES}>
            Marketing: {photogrpaherName} may share information with third-party
            advertising networks (such as Facebook and Instagram) in order to
            deliver relevant advertisements to you and to manage our
            communications with you. {photogrpaherName} does not use your
            content for this purpose.
          </p>
          <h3 className={H3_CLASSES}>
            6. How does {photogrpaherName} protect the information we collect?
          </h3>
          <p className={PARAGRAPH_CLASSES}>
            {photogrpaherName} follows industry standards on the management of
            personal information. {photogrpaherName} employs technical and
            administrative safeguards intended to protect against accidental or
            unlawful destruction, loss, alteration and disclosure of personal
            information. {photogrpaherName} maintain security measures such as
            the use of redundancies and employ firewalls to protect against
            unlawful access and network vulnerabilities. No method of storage
            and transfer of information over the Internet is absolutely secure,
            while we have safeguards in place, we cannot guarantee the absolute
            security of your personal information.
          </p>
          <h3 className={H3_CLASSES}>7. Data Retention</h3>
          <p className={PARAGRAPH_CLASSES}>
            {photogrpaherName} will retain your information for as long as the
            app is active or as long as needed to provide with the Services.{" "}
            {photogrpaherName}
            may also retain and use your information in order to comply with our
            legal obligations, resolve disputes, to enforce our agreements and
            to protect our and others’ interests. We may continue to store your
            information for a reasonable amount of time after you cancel your
            paid subscription or become inactive, in the case that you wish to
            reactivate your account.
          </p>
          <h3 className={H3_CLASSES}>8. International information transfer</h3>
          <p className={PARAGRAPH_CLASSES}>
            As part of providing the Services to you, your personal information
            might be transferred, stored or processed in a country other than
            where you are located. {photogrpaherName} will ensure the transfers
            will be completed in compliance with mechanisms that are recognized
            under the relevant Data Protection Legislation as providing an
            adequate level of protection for data transfers.
          </p>
          <h3 className={H3_CLASSES}>9. Your Rights</h3>
          <p className={PARAGRAPH_CLASSES}>
            You have the rights over your personal information. Subject to any
            exemptions provided by law, {photogrpaherName} takes reasonable
            steps to allow you to access, correct, amend, delete, port, or limit
            the use of your personal information. If you are located in the
            European Economic Area (EEA), we will normally collect personal
            information from you only where, a) we have your consent to do so,
            b) where we need your personal information to fulfill a contract
            with you, or c) where the processing is in our legitimate interest
            in providing the services. In most cases, if you do not provide the
            requested information, we will not be able to provide the service to
            you. If you wish to exercise these rights, please contact the User
            you interacted with directly --
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
