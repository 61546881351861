import React from "react";

import SuccessIcon from "../../../assets/icons/SuccessIcon";
import { ReactComponent as Erroricon } from "../../../assets/svgs/ErrorIcon.svg";
import ProgressBar from "../ProgressBar";
import { useUploader } from "../../../Store/upload";

function ImageQueue({
  isBottom = false,
  ulClass = "max-h-[13rem] overflow-y-auto",
}) {
  const { filesToUpload, completedImages, groupedErrorFiles, retryUpload } =
    useUploader();

  return (
    <div className="flex justify-center text-dark-gray1 dark:text-light-gray  flex-col h-full">
      <p className="text-xs  text-dark-gray dark:text-light-gray">
        Total Uploaded {completedImages.length}/{filesToUpload.length}
      </p>
      <div className="flex justify-around items-center pb-4 border-b border-dark-gray dark:border-light-gray">
        <ProgressBar
          percent={
            filesToUpload.length
              ? Math.round(
                  (completedImages.length / filesToUpload.length) * 100
                )
              : 0
          }
          size="medium"
        />
      </div>
      <div>
        <ul className="mt-[20px]">
          {Object.keys(groupedErrorFiles).map((key) => {
            return (
              <li
                key={key}
                className="flex w-full items-center justify-between shadow-light-input dark:shadow-dark-input h-[30px] mb-3 rounded-xl overflow-hidden pr-4"
              >
                <span className="flex items-center text-xs h-[100%] gap-3">
                  <span className="flex items-center justify-center w-10 h-[100%] bg-rose-400">
                    <Erroricon />
                  </span>
                  {groupedErrorFiles[key].length} Uploads Failed | {key}
                </span>
                {![
                  "Duplicate File",
                  "Duplicate Folder",
                  "Size More Than 30 MB",
                  "File Type Not Allowed",
                ].includes(key) || !key.includes("Size More Than") && (
                  <button
                    onClick={() => {
                      retryUpload({});
                    }}
                    className="text-primary-green text-xs font-semibold underline"
                  >
                    Retry All
                  </button>
                )}
              </li>
            );
          })}
        </ul>
        <ul className={ulClass}>
          <span>
            {filesToUpload.map((val) => {
              return (
                <li
                  key={val.name}
                  className="flex items-center text-dark-gray1 dark:text-light-gray justify-between h-[30px] mb-0.5 rounded-xl px-4"
                >
                  <span
                    className={`text-sm ${
                      isBottom
                        ? "max-w-[40%] truncate"
                        : "max-w-[60%] md:max-w-[70%] truncate"
                    }`}
                  >
                    {val.name}
                  </span>
                  <div>
                    {val.status === "Failed" ? (
                      <>
                        <span
                          className={`text-xs ${
                            !isBottom ? "text-[10px] md:text-xs" : ""
                          }`}
                        >
                          {val.error}
                        </span>
                        {![
                          "Duplicate File",
                          "Duplicate Folder",
                          "Size More Than 30 MB",
                          "File Type Not Allowed",
                        ].includes(val.error)  || !val.error.includes("Size More Than") && (
                          <button
                            onClick={() => {
                              retryUpload(val);
                            }}
                            className="ml-4 text-primary-green text-xs font-semibold underline"
                          >
                            Retry
                          </button>
                        )}
                      </>
                    ) : val.status === "Success" ? (
                      <SuccessIcon />
                    ) : (
                      <span className="text-dark-gray1 dark:text-light-gray text-xs mr-4 animate-pulse">
                        Uploading
                      </span>
                    )}
                  </div>
                </li>
              );
            })}
          </span>
        </ul>
      </div>
    </div>
  );
}

export default ImageQueue;
