import qs from "qs";

import { http } from "./index";

const getPhotogrpaherDetails = (url) =>
  http.get("photographer/showcase", {
    params: {
      url,
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
  });

export { getPhotogrpaherDetails };
