import React from "react";

const UploadIcon = (props) => {
  const { height = "16", width = "16", color = "white" } = props;
  return (
    // <svg width={width} height={height} fill={color} viewBox="0 0 512 512">
    //   <path d="M473.66,210c-14-10.38-31.2-18-49.36-22.11a16.11,16.11,0,0,1-12.19-12.22c-7.8-34.75-24.59-64.55-49.27-87.13C334.15,62.25,296.21,47.79,256,47.79c-35.35,0-68,11.08-94.37,32.05a150.07,150.07,0,0,0-42.06,53,16,16,0,0,1-11.31,8.87c-26.75,5.4-50.9,16.87-69.34,33.12C13.46,197.33,0,227.24,0,261.39c0,34.52,14.49,66,40.79,88.76,25.12,21.69,58.94,33.64,95.21,33.64H240V230.42l-36.69,36.69a16,16,0,0,1-23.16-.56c-5.8-6.37-5.24-16.3.85-22.39l63.69-63.68a16,16,0,0,1,22.62,0L331,244.14c6.28,6.29,6.64,16.6.39,22.91a16,16,0,0,1-22.68.06L272,230.42V383.79H396c31.34,0,59.91-8.8,80.45-24.77,23.26-18.1,35.55-44,35.55-74.83C512,254.25,498.74,228.58,473.66,210Z" />
    //   <path d="M240,448.21a16,16,0,1,0,32,0V383.79H240Z" />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 74 113"
    >
      <path
        d="M39.7939 20.5928C39.6399 20.4389 39.4702 20.3007 39.2894 20.1801L39.0502 20.0475L38.771 19.9149L38.452 19.8083L38.1599 19.7155L38.159 19.7155C37.7203 19.6256 37.2687 19.6256 36.8301 19.7155L36.538 19.8083L36.219 19.9149L35.887 20.0874L35.6478 20.22L35.6468 20.22C35.4651 20.3406 35.2963 20.4787 35.1414 20.6327L10.92 44.8137C9.61991 46.1129 9.61991 48.2208 10.92 49.5198C12.2192 50.819 14.3262 50.819 15.6262 49.5198L34.118 31.0142L34.118 109.663C34.118 111.498 35.6064 112.987 37.4415 112.987C39.2767 112.987 40.7651 111.498 40.7651 109.663L40.7651 31.0142L59.257 49.5068C60.5571 50.806 62.6641 50.806 63.9631 49.5068C65.2632 48.2067 65.2632 46.0997 63.9631 44.8007L39.7939 20.5928Z"
        fill={color}
      />
      <path
        d="M70.6765 1.22032e-05L4.20585 5.81104e-07C2.37533 2.61045e-07 0.889683 1.48002 0.882324 3.31047L0.88232 22.3474C0.88232 24.1835 2.37067 25.6709 4.20585 25.6709C6.04103 25.6709 7.52938 24.1835 7.52938 22.3474L7.52938 6.64682L67.3529 6.64683L67.3529 22.3474C67.3529 24.1835 68.8413 25.671 70.6765 25.671C72.5116 25.671 74 24.1835 74 22.3474L74 3.31049C73.9926 1.47996 72.507 1.25233e-05 70.6765 1.22032e-05Z"
        fill={color}
      />
    </svg>
  );
};

export default UploadIcon;
