import { useEffect, useCallback, useRef, useState } from "react";
import Swal from "sweetalert2";
import * as faceapi from "face-api.js";
import { useNavigate, useParams } from "react-router-dom";

import faceImg from "../../../pages/RegisterFace/faceImg.png";
import faceImg2 from "../../../pages/RegisterFace/faceImg2.png";
import Button from "../Button";
import { useEvent } from "../../../Store/event";
import { useUser } from "../../../Store/user";
import { faceSearch } from "../../../apis/facesearch";
import { useFolder } from "../../../Store/folder";
import {
  FACE_SEARCH_NO_RESULT,
  FACE_SEARCH_NO_RESULT_CUSTOM,
  MY_PHOTOS,
  PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE,
  PHOTOGRAPHER_WITH_FACE_REC_ACCESS,
} from "../../../constants";

let DIMENSIONS = {
  width: 533.33,
  height: 400,
};

const CaptureImage = (props) => {
  const { setLoading, loading, isCamera, setShowPreReg, setIsCamera, close } =
    props;

  const { removeMyPhotosFolder, setMyPhotos } = useFolder();
  const { user, setUser } = useUser();
  const eventDetails = useEvent().response;
  const navigate = useNavigate();
  const { folderName, slug } = useParams();

  const videoRef = useRef(null);
  const imgRef = useRef(null);
  const parentImgRef = useRef();
  const canvasRef = useRef();

  const [gStream, setGStream] = useState();
  const [searchingFace, setSearchingFace] = useState(false);
  const [showCapture, setShowCapture] = useState(false);

  const stopCameraCapture = () => {
    gStream?.getTracks()?.map((track) => {
      track.stop();
    });
  };

  useEffect(() => {
    return () => {
      stopCameraCapture();
    };
  }, [gStream]);

  const captureImage = useCallback(async () => {
    setSearchingFace(true);
    let video = videoRef.current;
    video.pause();
    let canvas = canvasRef.current;
    let context = canvas.getContext("2d");
    context.drawImage(video, 0, 0);
    gStream.getTracks().map((track) => {
      track.stop();
    });
    let imgBase = canvas.toDataURL("image/jpeg");
    try {
      setLoading(true);
      let result = await faceSearch(
        user.id,
        eventDetails.data.id,
        imgBase,
        eventDetails.data.isPublished
      );
      if (result.error) {
        removeMyPhotosFolder();
        close();
        let swalContent = FACE_SEARCH_NO_RESULT;
        if (
          PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
            eventDetails.photographer.id
          ) ||
          PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE.includes(
            eventDetails.photographer.id
          )
        ) {
          swalContent =
            FACE_SEARCH_NO_RESULT_CUSTOM[eventDetails.photographer.id];
        }
        Swal.fire(swalContent).then(function () {
          setUser({ ...user, search_id: null });
          setMyPhotos([]);
        });
      } else if (result.response.status === 1 && result.response.search_id) {
        setUser({ ...user, search_id: result.response.search_id });
        if (folderName !== MY_PHOTOS.slug) {
          if (slug) {
            navigate(`/view/${slug}/${eventDetails.data.slug}/my-photos`, {
              replace: true,
            });
          } else
            navigate(`/view/${eventDetails.data.slug}/my-photos`, {
              replace: true,
            });
        }
        setTimeout(() => {
          close();
          const galleryApp = document.getElementById("photoGalleryContainer");
          galleryApp?.scrollIntoView();
        }, 200);
      } else if (result.response.status === 2) {
        // close();
        // window.Toast.fire({
        //   icon: "success",
        //   title: "Face registration successful",
        // });
        setShowPreReg(true);
        setLoading(false);
      }
    } catch (e) {
      removeMyPhotosFolder();
      close();
      console.log(e);
      let swalContent = FACE_SEARCH_NO_RESULT;
      if (
        PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
          eventDetails.photographer.id
        ) ||
        PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE.includes(
          eventDetails.photographer.id
        )
      ) {
        swalContent =
          FACE_SEARCH_NO_RESULT_CUSTOM[eventDetails.photographer.id];
      }
      Swal.fire(swalContent).then(function () {
        setUser({ ...user, search_id: null });
        setMyPhotos([]);
      });
    }
  }, [user, gStream]);

  const loadModules = () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(
        "https://apps.algomage.com/models/tiny_face_detector_model-weights_manifest.json"
      ),
      faceapi.nets.faceExpressionNet.loadFromUri(
        "https://apps.algomage.com/models/face_expression_model-weights_manifest.json"
      ),
    ])
      .then(() => {
        navigator.mediaDevices
          .getUserMedia({
            video: DIMENSIONS,
            facingMode: { exact: "environment" },
          })
          .then((stream) => {
            setGStream(stream);
            if (videoRef.current) {
              videoRef.current.srcObject = stream;
              videoRef.current.play();
            }
            setLoading(false);
          })
          .catch((err) => {
            console.error("error:", err);
            setLoading(false);
            setIsCamera(false);
          });
      })
      .catch((e) => {
        setLoading(false);
        setIsCamera(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (isCamera) {
      loadModules();
    }
  }, [videoRef, isCamera]);

  useEffect(() => {
    let interval;
    videoRef.current.addEventListener("play", () => {
      interval = setInterval(async () => {
        const detections = await faceapi
          .detectAllFaces(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions()
          )
          .withFaceExpressions();
        let parentRect = parentImgRef.current.getBoundingClientRect();
        const resizedDetections = faceapi.resizeResults(detections, {
          width: parentRect.width,
          height: parentRect.height,
        });
        if (resizedDetections[0]) {
          let width = resizedDetections[0].detection._box._width;
          let x = resizedDetections[0].detection._box._x;
          let rect = imgRef.current?.getBoundingClientRect();
          let imagePosition = { left: rect.left - parentRect.left };
          if (
            x > imagePosition.left - 50 &&
            x < imagePosition.left + 50 &&
            x + width > rect.width + imagePosition.left - 50 &&
            x + width < rect.width + imagePosition.left + 50
          ) {
            imgRef.current.src = faceImg;
            setShowCapture(true);
            return;
          } else {
            imgRef.current.src = faceImg2;
            setShowCapture(false);
          }
        } else {
          imgRef.current.src = faceImg2;
          setShowCapture(false);
        }
      }, 1000);
    });
    videoRef.current.addEventListener("pause", () => {
      clearInterval(interval);
    });
    return () => {
      gStream?.getTracks().map((track) => {
        track.stop();
      });
      clearInterval(interval);
    };
  }, [gStream]);

  return (
    <div className={`grid ${loading ? "hidden" : "h-full"} mt-5`}>
      <div className="pt-[10%] md:pt-0 w-full">
        <div className="relative">
          <video
            className="h-[300px] md:h-[400px] max-h-[480px] mx-auto w-[640px] max-w-full"
            ref={videoRef}
            muted={true}
            playsInline
            autoPlay={false}
            style={{ transform: "scaleX(-1)" }}
          ></video>
          <canvas
            ref={canvasRef}
            width="533px"
            height="400px"
            className="hidden"
          ></canvas>
          <div
            id="hollowRound"
            className="absolute  h-[310px] md:h-[400px] max-h-[480px] mx-auto w-[640px] max-w-full -left-1/2 translate-x-1/2  mt-0 top-0  "
          >
            <div className="w-full h-full  relative overflow-hidden  after:content-[''] after:rounded-full after:shadow-[0_0_0_1000px] after:shadow-theme-light dark:after:shadow-secondary-800 dark:after:shadow-[0_0_0_1000px] after:absolute after:w-[200px] after:h-[200px] md:after:w-[280px] md:after:h-[280px]  after:left-1/2 after:top-[calc(50%-10px)]  after:-translate-x-1/2 after:-translate-y-1/2"></div>
          </div>
          <div
            ref={parentImgRef}
            className={`absolute  ${
              !gStream ? "bg-black" : ""
            } max-w-full h-[300px] md:h-[400px] w-[533px] -left-1/2 translate-x-1/2  mx-auto mt-0 top-0 flex justify-center items-center`}
          >
            <img
              ref={imgRef}
              src={faceImg2}
              className={`max-w-[40%]  ${gStream ? "block" : "hidden"}`}
            />
          </div>
        </div>

        <p className="text-dark dark:text-white text-center mt-5 z-100 md:px-10 uppercase text-sm md:text-xl">
          {gStream
            ? "Align your face and press capture"
            : "Please wait till camera loads"}
        </p>
        <div className={`z-100 mt-6 text-center`}>
          <Button
            disabled={!showCapture}
            text={searchingFace ? "Searching..." : "Capture"}
            onClick={captureImage}
          />
        </div>
      </div>
    </div>
  );
};

export default CaptureImage;
