import React from "react";
import { useUser } from "../../../../Store/user";
import "./navbar.css";

const Navbar = () => {
    const {user} = useUser()
    return (
        <div className='navbar__bg-dark bg-theme-light dark:bg-dark-gray w-full p-[20px]'>
            <div className='navbar__text-dark text-dark-gray1 dark:text-light-gray text-center'>
                {user.isPhotoGrapher ? 'You are viewing this event as admin.':'You are viewing this event as co-admin. You have the rights to mark any image private.'}
            </div>
        </div>
    );
};

export default Navbar;
