import React from "react";
import {
  FRAME_DIGITAL,
  FRAME_TYPE_JIO_PHYSICAL_COPY,
} from "../../../constants";

const PRINT = "print";
const WALLETS = "wallets";
const MINIS = "minis";

const MILLER_NON_FRAME_PRODUCTS = [WALLETS, PRINT, MINIS];

function CartImg({
  frameStyle,
  frameUrl,
  imageUrl,
  imageSize,
  images,
  stylingObject,
  frameType,
}) {
  return (
    <>
      {(() => {
        if (MILLER_NON_FRAME_PRODUCTS.includes(frameStyle)) {
          return <div className="w-full h-full pt-[100%]"></div>;
        }
        switch (imageSize) {
          case "6x4":
            return <div style={{ ...stylingObject.jioEventVertical }}></div>;
          case "8x6":
            return <div style={{ ...stylingObject.jioEventHorizontal }}></div>;
          case "8x8":
            return <div style={{ ...stylingObject.outerDiv1 }}></div>;
          case "8x10":
            if (images.dimensions.width < images.dimensions.height) {
              if (frameStyle == "Classic") {
                return <div style={{ ...stylingObject.outerDiv16 }}></div>;
              } else {
                return <div style={{ ...stylingObject.outerDiv2 }}></div>;
              }
            } else {
              if (frameStyle == "Classic") {
                return <div style={{ ...stylingObject.outerDiv17 }}></div>;
              } else {
                return <div style={{ ...stylingObject.outerDiv3 }}></div>;
              }
            }
          case "12x15":
            if (images.dimensions.width < images.dimensions.height) {
              return <div style={{ ...stylingObject.outerDiv4 }}></div>;
            } else {
              return <div style={{ ...stylingObject.outerDiv5 }}></div>;
            }
          case "12x18":
            if (images.dimensions.width < images.dimensions.height) {
              return <div style={{ ...stylingObject.outerDiv6 }}></div>;
            } else {
              return <div style={{ ...stylingObject.outerDiv7 }}></div>;
            }
          case "16x20":
            if (images.dimensions.width < images.dimensions.height) {
              return <div style={{ ...stylingObject.outerDiv8 }}></div>;
            } else {
              return <div style={{ ...stylingObject.outerDiv9 }}></div>;
            }
          case "4x4":
            return <div style={{ ...stylingObject.outerDiv10 }}></div>;
          case "4x6":
            if (images.dimensions.width < images.dimensions.height) {
              return <div style={{ ...stylingObject.outerDiv11 }}></div>;
            } else {
              return <div style={{ ...stylingObject.outerDiv12 }}></div>;
            }
          case "5.5x5.5":
            return <div style={{ ...stylingObject.outerDiv13 }}></div>;
          case "5x7":
            if (images.dimensions.width < images.dimensions.height) {
              return <div style={{ ...stylingObject.outerDiv14 }}></div>;
            } else {
              return <div style={{ ...stylingObject.outerDiv15 }}></div>;
            }
          default:
            return <div style={{ ...stylingObject.outerDiv1 }}></div>;
        }
      })()}
      {[FRAME_DIGITAL, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(frameType) ? (
        <></>
      ) : (
        <img
          className={`frameImage shadow-dark-input-bottom-right dark:shadow-dark-input ${
            MILLER_NON_FRAME_PRODUCTS.includes(frameStyle) ? "hidden" : ""
          }`}
          src={frameUrl}
          alt="background"
          style={
            imageSize === "6x4" || MILLER_NON_FRAME_PRODUCTS.includes(frameStyle)
              ? { width: "100%", height: "100%" }
              : { width: "100%" }
          }
        />
      )}
      {(() => {
        if (MILLER_NON_FRAME_PRODUCTS.includes(frameStyle)) {
          return (
            <div
              style={{
                width: MILLER_NON_FRAME_PRODUCTS.includes(frameStyle)
                  ? "100%"
                  : "90%",
                height: MILLER_NON_FRAME_PRODUCTS.includes(frameStyle)
                  ? "100%"
                  : "90%",
                position: "absolute",
                top: "50%",
                left: "50%",
                userSelect: "none",
                background: `url('${imageUrl}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                transform: "translate(-50%,-50%)",
              }}
            ></div>
          );
        }
        switch (imageSize) {
          case "6x4":
            return (
              <div
                style={{
                  ...(frameType === FRAME_TYPE_JIO_PHYSICAL_COPY
                    ? {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0%",
                        left: "0%",
                        userSelect: "none",
                      }
                    : { ...stylingObject.jioEventVerticalInnerImg }),
                  background: `url('${imageUrl}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            );
          case "8x6":
            return (
              <div
                style={{
                  ...(frameType === FRAME_TYPE_JIO_PHYSICAL_COPY
                    ? {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0%",
                        left: "0%",
                        userSelect: "none",
                      }
                    : { ...stylingObject.jioEventHorizontalInnerImg }),
                  background: `url('${imageUrl}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            );
          case "8x8":
            return (
              <div
                style={{
                  ...stylingObject.innerImg1,
                  background: `url('${imageUrl}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                {/* <img src={imageUrl}/>     */}
              </div>
            );
          case "8x10":
            if (images.dimensions.width < images.dimensions.height) {
              if (frameStyle == "Classic") {
                return (
                  <div
                    style={{
                      ...stylingObject.innerImg16,
                      background: `url('${imageUrl}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <img src={imageUrl}/>     */}
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      ...stylingObject.innerImg2,
                      background: `url('${imageUrl}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <img src={imageUrl}/>     */}
                  </div>
                );
              }
            } else {
              if (frameStyle == "Classic") {
                return (
                  <div
                    style={{
                      ...stylingObject.innerImg17,
                      background: `url('${imageUrl}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <img src={imageUrl}/>     */}
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      ...stylingObject.innerImg3,
                      background: `url('${imageUrl}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <img src={imageUrl}/>     */}
                  </div>
                );
              }
            }
          case "12x15":
            if (images.dimensions.width < images.dimensions.height) {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg4,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg5,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            }
          case "12x18":
            if (images.dimensions.width < images.dimensions.height) {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg6,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg7,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            }
          case "16x20":
            if (images.dimensions.width < images.dimensions.height) {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg8,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg9,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            }
          case "4x4":
            return (
              <div
                style={{
                  ...stylingObject.innerImg10,
                  background: `url('${imageUrl}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                {/* <img src={imageUrl}/>     */}
              </div>
            );
          case "4x6":
            if (images.dimensions.width < images.dimensions.height) {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg11,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg12,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            }
          case "5.5x5.5":
            return (
              <div
                style={{
                  ...stylingObject.innerImg13,
                  background: `url('${imageUrl}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                {/* <img src={imageUrl}/>     */}
              </div>
            );
          case "5x7":
            if (images.dimensions.width < images.dimensions.height) {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg14,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    ...stylingObject.innerImg15,
                    background: `url('${imageUrl}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {/* <img src={imageUrl}/>     */}
                </div>
              );
            }
          default:
            return (
              <div
                style={{
                  ...stylingObject.innerImgDigital,
                  background: `url('${imageUrl}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                {/* <img src={imageUrl}/>     */}
              </div>
            );
        }
      })()}
    </>
  );
}

export default CartImg;
