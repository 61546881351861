import React, { useContext, useState } from "react";

import BottomUploadModal from "../BottomUploadModal";
import CloseIcon from "../../../assets/icons/CloseIcon";
import MinimizeIcon from "../../../assets/icons/MinimizeIcon";
import DialogWrapper from "../Dialog";
import { useUploader } from "../../../Store/upload";
import ImageQueue from "../ImageQueue";
import { ThemeContext } from "../../App";

const UploadModal = ({ open = true, close }) => {
  const [isMinimziedModal, showMinimziedModal] = useState(false);
  const { closeUploader, isUploading } = useUploader();
  const theme = useContext(ThemeContext);
  const iconsColor = theme === "dark" ? "#EEE" : "black";

  const openMinimizedModal = () => {
    showMinimziedModal(true);
  };

  return (
    <>
      {!isMinimziedModal && (
        <DialogWrapper
          open={open}
          close={close}
          hideTitle={true}
          hideClose={true}
          containerClassname="w-full"
        >
          <div className="flex justify-center flex-col mb-[21px] md:mb-5">
            <div className="flex items-center justify-between h-full">
              <p className="text-md text-dark-gray1 dark:text-light-gray">
                Upload Queue
              </p>
              <div className="flex gap-4">
                <span className="cursor-pointer" onClick={openMinimizedModal}>
                  <MinimizeIcon fill={iconsColor} />
                </span>
                <span
                  className={`${
                    isUploading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={closeUploader}
                >
                  <CloseIcon
                    h="16"
                    fill={
                      isUploading
                        ? theme === "dark"
                          ? "rgba(0,0,0,0.5)"
                          : "#aaa"
                        : theme === "dark"
                        ? "#eee"
                        : "black"
                    }
                    w="16"
                  />
                </span>
              </div>
            </div>
            <ImageQueue ulClass="max-h-[30rem] overflow-y-auto" />
          </div>
        </DialogWrapper>
      )}
      {isMinimziedModal && <BottomUploadModal />}
    </>
  );
};

export default UploadModal;
