/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

const useOutsideClick = (reference, fn) => {
  useEffect(() => {
    const onClick = (e) => {
      if (reference.current && !reference.current.contains(e.target)) {
        fn(e);
      }
    };
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [reference]);
};

export default useOutsideClick;
