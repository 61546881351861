import { http } from "./index";

const getSingleImage = (folderId, imageId) => {
  return http.get("image/show", {
    params: {
      folderId,
      imageId,
    },
  });
};

export { getSingleImage };
