import { HIGH_RES_DOMAIN, SPONSOR_POSITION } from "../../../constants";
import { useEvent } from "../../../Store/event";

const SponsorBar = () => {
  const event = useEvent().response;
  const { sponsorDetails } = event.data;

  return SPONSOR_POSITION.map((el) => (
    <div key={el}>
      {sponsorDetails[el] ? (
        <div className="flex flex-col items-center justify-center gap-2 pb-2 mt-4 text-dark-gray1 dark:text-light-gray font-bold text-xl ">
          <h1>{sponsorDetails[el].value}</h1>
          <div className="flex justify-center items-center gap-8 flex-wrap">
            {sponsorDetails[el].image.map((e) =>
              e.link ? (
                <a href={e.link ? e.link : ""} target="_blank">
                  <img
                    src={"https://" + HIGH_RES_DOMAIN + "/" + e.logo}
                    alt="Logo"
                    height={100}
                    width={100}
                    className="object-contain"
                  />
                </a>
              ) : (
                <img
                  src={"https://" + HIGH_RES_DOMAIN + "/" + e.logo}
                  alt="Logo"
                  height={100}
                  width={100}
                  className="object-contain"
                />
              )
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  ));
};

export default SponsorBar;
