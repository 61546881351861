import React, { useContext, useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import Info from "../../assets/images/info.png";
import InfoBlack from "../../assets/images/infoBlack.png";
import { ThemeContext } from "../../components/App";
import BackButton from "../../components/common/Backbutton";
import Button from "../../components/common/Button";
import CartItem from "../../components/common/Cart/CartItem";
import DigitalCart from "../../components/common/Cart/DigitalCart";
import GoBackSection from "../../components/common/GoBackSection";
import StaticLogo from "../../components/common/StaticLogo";
import {
  CHECKOUT_ADDRESS_DISABLED,
  DISABLE_ADDRESS_PAGE,
  ENVIRONMENT,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  INDIAN_CURRENCY,
  PRICING_IN_DOLLAR,
  STAGING_ENVIRONMENT_KEY,
} from "../../constants";
import { useCart } from "../../Store/cart";
import { useEvent } from "../../Store/event";
import { useUser } from "../../Store/user";

const AddToCart = () => {
  let { user } = useUser();
  const theme = useContext(ThemeContext);

  let {
    digitalCartItem,
    cartAmount,
    cartList,
    removeFromCart,
    updateCart,
    cartCnt,
  } = useCart();
  const eventDetails = useEvent().response;
  const navigate = useNavigate();

  const { slug } = useParams();

  useEffect(() => {
    if (!user.email || (!cartList.length && !digitalCartItem.length)) {
      if (
        eventDetails.photographer.domain &&
        user.accessLevel &&
        ENVIRONMENT !== STAGING_ENVIRONMENT_KEY
      )
        window.location.assign(
          `//${eventDetails.photographer.domain}/${
            user.accessLevel ?? "facerec"
          }${user.accessLevel === "view" ? `/${slug}` : ""}/${
            eventDetails.data.slug
          }${user.accessLevel === "view" ? "" : `/results/${user.search_id}`}`
        );
      else {
        navigate(
          user.accessLevel === "view"
            ? slug
              ? `/view/${slug}/${eventDetails.data.slug}`
              : `/view/${eventDetails.data.slug}`
            : `/facerec/${eventDetails.data.slug}/results/${user.search_id}`
        );
      }
    }
  }, []);

  const increment = (e) => {
    updateCart(e.id, e.cartIds, e.q);
  };

  const decrement = (e) => {
    updateCart(e.id, e.cartIds, e.q);
  };

  const remove = (e) => {
    var cartIds = [];
    {
      cartList.map(({ id }) => {
        cartIds.push(id);
      });
    }
    digitalCartItem.forEach((el) =>
      el.items.forEach((e) => cartIds.push(e.id))
    );
    removeFromCart(e.id, cartIds, e.qty);
  };

  return (
    <div className="min-h-screen">
      <StaticLogo />
      <div className="px-[18px] md:px-10 py-4 items-center text-neutral-800 dark:text-slate-50">
        <div className="flex justify-between items-center lg:mt-5 self-center">
          <div className="lg:text-3xl text-2xl font-medium mb-1">
            {eventDetails.data.name.replace("-", " ")}
          </div>
        </div>
        {/* <GalleryHeading onFolderClickHandler={onFolderClickHandler} isMobileView changeGallery={changeGallery} isAddToCartVisible={isAddToCartVisible} /> */}
        <GoBackSection
          changeGallery={() => {}}
          onFolderClickHandler={() => {}}
          backLink={-1}
          addAnchorTag={eventDetails.photographer.domain}
        />
        <div className="flex flex-col lg:flex-row justify-between mt-10">
          {cartCnt > 0 ? (
            <>
              <ul className="lg:w-4/6 w-full lg:order-1 order-2 hidden lg:block">
                {cartList.map(
                  ({
                    id,
                    frameUrl,
                    frameStyle,
                    quantity,
                    imageSize,
                    color,
                    framePrice,
                    images,
                    croppedParameters,
                    frameType,
                    addOn,
                  }) => {
                    return (
                      <CartItem
                        id={id}
                        frameUrl={frameUrl}
                        frameStyle={frameStyle}
                        frameType={frameType}
                        quantity={quantity}
                        imageSize={imageSize}
                        color={color}
                        framePrice={framePrice}
                        images={images}
                        remove={(e) => {
                          remove(e);
                        }}
                        increment={(e) => {
                          increment(e);
                        }}
                        decrement={(e) => {
                          decrement(e);
                        }}
                        croppedParameters={croppedParameters}
                        isMobile={false}
                        key={id}
                        addOn={addOn}
                      />
                    );
                  }
                )}
                {digitalCartItem.map((el, id) => {
                  return (
                    <DigitalCart
                      digitalImageArray={el}
                      remove={(e) => {
                        remove(e);
                      }}
                      index={id}
                      isMobile={false}
                      key={id}
                    />
                  );
                })}
              </ul>
              <ul className="w-full flex flex-col lg:hidden block lg:order-1 order-2">
                {cartList.map(
                  ({
                    id,
                    frameUrl,
                    frameStyle,
                    quantity,
                    imageSize,
                    color,
                    framePrice,
                    images,
                    croppedParameters,
                    frameType,
                    addOn,
                  }) => {
                    return (
                      <CartItem
                        id={id}
                        frameUrl={frameUrl}
                        frameStyle={frameStyle}
                        quantity={quantity}
                        frameType={frameType}
                        imageSize={imageSize}
                        color={color}
                        framePrice={framePrice}
                        images={images}
                        addOn={addOn}
                        remove={(e) => {
                          remove(e);
                        }}
                        increment={(e) => {
                          increment(e);
                        }}
                        decrement={(e) => {
                          decrement(e);
                        }}
                        croppedParameters={croppedParameters}
                        isMobile={true}
                        key={id}
                      />
                    );
                  }
                )}
                {digitalCartItem.map((el, id) => {
                  return (
                    <DigitalCart
                      digitalImageArray={el}
                      remove={(e) => {
                        remove(e);
                      }}
                      index={id}
                      isMobile={true}
                      key={id}
                    />
                  );
                })}
              </ul>
              {cartCnt > 3 ? (
                <div className="lg:order-1 order-2 md:hidden">
                  <Link
                    to={`/view/${slug ? `${slug}/` : ""}${
                      eventDetails.data.slug
                    }/checkout`}
                  >
                    <Button
                      className="w-56 flex mx-auto mt-2 mb-2 justify-center"
                      text="Proceed to Checkout"
                      //TODO : Remove this disabled
                      // disabled={cartAmount > 0 ? false : true}
                    />
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="dark:text-light-gray">
              No item found in the cart
            </div>
          )}
          <div className="lg:w-2/6 w-full shadow-dark-input-bottom-right dark:shadow-dark-input rounded-xl p-8 order-1 lg:order-2 mb-8 lg:mb-0">
            <div className="text-base md:text-2xl font-medium dark:text-light-gray text-dark-gray1">
              Order Summary
            </div>
            <div className="text-xs md:text-xl text-black-gray dark:text-stone-400 mt-8 font-medium">
              {DISABLE_ADDRESS_PAGE.includes(eventDetails.data.id) ||
              CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id)
                ? "Total"
                : "Sub Total"}
            </div>
            <div className="text-xl md:text-3xl font-semibold mt-2 dark:text-light-gray">
              {eventDetails.data?.downloadPricing?.currency ===
                PRICING_IN_DOLLAR ||
              eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
                ? "$"
                : "Rs."}{" "}
              {cartAmount.toFixed(2)}
            </div>
            {DISABLE_ADDRESS_PAGE.includes(eventDetails.data.id) ||
            CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id) ||
            eventDetails.data?.downloadPricing?.currency ===
              PRICING_IN_DOLLAR ? (
              <></>
            ) : (
              <div className="text-xs md:text-base my-8 text-dark-gray1 dark:text-stone-400">
                <img
                  src={theme === "dark" ? Info : InfoBlack}
                  className="w-4 inline-block mr-2 cursor-pointer"
                />
                Shipping and taxes will be calculated at checkout
              </div>
            )}
            <Link
              to={`/view/${slug ? `${slug}/` : ""}${
                eventDetails.data.slug
              }/checkout`}
            >
              <Button
                className="w-56 flex mx-auto mt-20 justify-center"
                text="Proceed to Checkout"
                // disabled={cartAmount > 0 ? false : true}
              />
            </Link>
            {eventDetails.photographer.domain && user.accessLevel ? (
              <a
                href={`//${eventDetails.photographer.domain}/${
                  user.accessLevel ?? "facerec"
                }/${eventDetails.data.slug}${
                  user.accessLevel === "view"
                    ? ""
                    : `/results/${user.search_id}`
                }`}
              >
                {" "}
                <BackButton
                  className="w-56 flex mx-auto mt-6 justify-center"
                  text="Continue Shopping"
                />
              </a>
            ) : (
              <Link
                to={
                  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                    eventDetails.data.id
                  )
                    ? `/facerec/${eventDetails.data.slug}/results/${user.search_id}`
                    : `/view/${slug ? `${slug}/` : ""}${eventDetails.data.slug}`
                }
              >
                <BackButton
                  className="w-56 flex mx-auto mt-6 justify-center"
                  text="Continue Shopping"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
