import React, { useRef, useState, useEffect, useCallback } from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as faceapi from "face-api.js";

import Button from "../../components/common/Button";
import Loading from "../Loading";
import { faceSearch } from "../../apis/facesearch";
import { useUser } from "../../Store/user";
import { useEvent } from "../../Store/event";
import faceImg from "./faceImg.png";
import faceImg2 from "./faceImg2.png";
import RetakePhoto from "../RetakePhoto";
import RegisterStatus from "../FaceRec/RegisterStatus";
import StaticLogo from "../../components/common/StaticLogo";
import BackButton from "../../components/common/Backbutton";
import {
  PHOTOGRAPHER_WITH_DIRECT_FACE_REC,
  FACE_SEARCH_NO_RESULT,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  REFRESH_LOCAL_STORAGE,
  BMS_PHOTOGRAPHER_ID,
  EVENT_WITH_DIRECT_FACE_REC,
  PHOTOGRAPHER_WITH_FACE_REC_ACCESS,
  FACE_SEARCH_NO_RESULT_CUSTOM,
  PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE,
} from "../../constants";
import { checkIsDeviceIOS } from "../../helper";

const CameraWithoutLiveliness = () => {
  const videoRef = useRef(null);
  const divRef = useRef(null);
  const imgRef = useRef(null);
  const parentImgRef = useRef();
  const { user, setUser } = useUser();
  const eventDetails = useEvent().response;
  const canvasRef = useRef();
  const [isCamera, setIsCamera] = useState(true);
  var gStream;
  const [loading, setLoading] = useState(true);
  const [searchingFace, setSearchingFace] = useState(false);
  const [showCapture, setShowCapture] = useState(false);
  const [showPreRegSuccess, setShowPreRegSuccess] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [searchId, setSearchId] = useState("");

  let dimensions = {
    width: 640,
    height: 480,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      gStream.getTracks().map((track) => {
        track.stop();
      });
    };
  }, [gStream]);

  const loadCamera = () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(
        "https://apps.algomage.com/models/tiny_face_detector_model-weights_manifest.json"
      ),
      //faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
      //faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      faceapi.nets.faceExpressionNet.loadFromUri(
        "https://apps.algomage.com/models/face_expression_model-weights_manifest.json"
      ),
    ])
      .then(() => {
        navigator.mediaDevices
          .getUserMedia({
            video: dimensions,
            facingMode: { exact: "environment" },
          })
          .then((stream) => {
            gStream = stream;
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            setLoading(false);
          })
          .catch((err) => {
            console.error("error:", err);
            setLoading(false);
            setIsCamera(false);
          });
      })
      .catch((e) => {
        setLoading(false);
        setIsCamera(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (isCamera) {
      loadCamera();
    }
  }, [videoRef, isCamera]);

  useEffect(async () => {
    const status = await navigator.permissions.query({ name: "camera" });
    status.addEventListener(
      "change",
      (evt) => {
        if (evt.currentTarget.state === "denied") setIsCamera(false);
        else setIsCamera(true);
      },
      { once: true }
    );
  });

  const isDeviceIOS = checkIsDeviceIOS();

  useEffect(() => {
    const img = new Image();
    img.src = faceImg;
    let interval;
    videoRef.current.addEventListener("play", () => {
      interval = setInterval(async () => {
        const detections = await faceapi
          .detectAllFaces(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions()
          )
          .withFaceExpressions();
        let parentRect = parentImgRef.current.getBoundingClientRect();
        const resizedDetections = faceapi.resizeResults(detections, {
          width: parentRect.width,
          height: parentRect.height,
        });
        if (resizedDetections[0]) {
          let width = resizedDetections[0].detection._box._width;
          let x = resizedDetections[0].detection._box._x;
          let rect = imgRef.current.getBoundingClientRect();
          let parentRect = parentImgRef.current.getBoundingClientRect();
          let imagePosition = { left: rect.left - parentRect.left };

          imgRef.current.src = faceImg;
          setShowCapture(true);
          if (
            x > imagePosition.left - 50 &&
            x < imagePosition.left + 50 &&
            x + width > rect.width + imagePosition.left - 50 &&
            x + width < rect.width + imagePosition.left + 50
          ) {
            imgRef.current.src = faceImg;
            setShowCapture(true);
            return;
          } else {
            imgRef.current.src = faceImg2;
            setShowCapture(false);
          }
        } else {
          imgRef.current.src = faceImg2;
          setShowCapture(false);
        }
      }, 1000);
    });
    videoRef.current.addEventListener("pause", () => {
      clearInterval(interval);
    });
  }, [gStream]);

  const captureImage = useCallback(async () => {
    setSearchingFace(true);
    let video = videoRef.current;
    let canvas = canvasRef.current;
    let context = canvas.getContext("2d");
    context.drawImage(video, 0, 0);
    video.pause();
    gStream?.getTracks().map((track) => {
      track.stop();
    });
    setLoading(true);
    let imgBase = canvas.toDataURL("image/jpeg");
    try {
      let result = await faceSearch(
        user.id,
        eventDetails.data.id,
        imgBase,
        eventDetails.data.isPublished,
        user?.previousImages
      );
      if (result.error) {
        let swalContent = FACE_SEARCH_NO_RESULT;
        if (
          PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
            eventDetails.photographer.id
          ) ||
          PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE.includes(
            eventDetails.photographer.id
          )
        ) {
          swalContent =
            FACE_SEARCH_NO_RESULT_CUSTOM[eventDetails.photographer.id];
        }
        Swal.fire(swalContent).then(function (response) {
          if (
            PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
              eventDetails.photographer.id
            ) ||
            PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
              eventDetails.photographer.id
            ) ||
            EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id)
          ) {
            setLoading(false);
            loadCamera();
            setSearchingFace(false);
          } else {
            setUser({ ...user, id: 0 });
          }
        });
      } else if (result.response.status === 1 && result.response.search_id) {
        setSearchId(result.response.search_id);
        EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id) &&
          localStorage.removeItem(REFRESH_LOCAL_STORAGE);
        if (
          EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id) &&
          isDeviceIOS
        )
          setUser({ ...user, search_id: result.response.search_id });
      } else if (result.response.status === 2) {
        setShowPreRegSuccess(true);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      let swalContent = FACE_SEARCH_NO_RESULT;
      if (
        PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
          eventDetails.photographer.id
        ) ||
        PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE.includes(
          eventDetails.photographer.id
        )
      ) {
        swalContent =
          FACE_SEARCH_NO_RESULT_CUSTOM[eventDetails.photographer.id];
      }
      Swal.fire(swalContent).then(function (response) {
        if (
          PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
            eventDetails.photographer.id
          ) ||
          PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
            eventDetails.photographer.id
          ) ||
          EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id)
        ) {
          setLoading(false);
          loadCamera();
          setSearchingFace(false);
        } else {
          setUser({ ...user, id: 0 });
        }
      });
    }
  }, [user, gStream]);

  useEffect(() => {
    const letsChatElement = document.getElementById("haptik-xdk-wrapper");
    if (isMobile && isCamera) {
      if (letsChatElement) letsChatElement.style = "display:none";
    }
    return () => {
      if (letsChatElement) letsChatElement.style = "";
    };
  }, [showPreRegSuccess, loading, isCamera]);

  const stopCamera = useCallback(() => {
    gStream.getTracks().map((track) => {
      track.stop();
    });
    setIsCamera(false);
  }, [gStream]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <div className="bg-light-gray dark:bg-dark-gray">
      {loading ? (
        <Loading />
      ) : (
        !showPreRegSuccess &&
        !isMobile && (
          <header className="">
            <StaticLogo />
          </header>
        )
      )}
      {searchId ? (
        <Navigate
          to={`/facerec/${eventDetails.data.slug}/results/${searchId}`}
        />
      ) : showPreRegSuccess ? (
        <RegisterStatus />
      ) : isCamera ? (
        <div className={`grid ${loading ? "hidden" : "h-full"} md:mt-5`}>
          <div className="h-screen pt-0 relative w-full" ref={divRef}>
            <video
              className="h-[480px] mx-auto w-[640px] max-w-full"
              ref={videoRef}
              muted={true}
              playsInline
              autoPlay={false}
              style={{ transform: "scaleX(-1)" }}
            ></video>
            <canvas
              ref={canvasRef}
              width={dimensions.width}
              height={dimensions.height}
              className="hidden"
            ></canvas>
            <div
              id="hollowRound"
              className="absolute h-[490px] max-h-[490px] mx-auto w-[640px] max-w-full left-1/2 -translate-x-1/2  top-0"
            >
              <div className="w-full h-full  relative overflow-hidden  after:content-[''] after:rounded-full after:shadow-[0_0_0_1000px_#eeeeee] dark:after:shadow-[0_0_0_1000px_#1A1A1A] after:absolute md:after:w-[350px] md:after:h-[350px] after:w-[220px] after:h-[220px]  after:left-1/2 after:top-[calc(50%-10px)] after:-translate-x-1/2 after:-translate-y-1/2"></div>
            </div>

            <p className="text-dark absolute top-[380px] left-1/2 -translate-x-1/2 w-[70%] md:w-full md:translate-x-0 md:static dark:text-white text-center px-10 mt-5 z-100 text-lg md:text-2xl">
              {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
                ? "Position your face, and press ‘Capture’."
                : "Align your face and press capture"}
            </p>
            <div className={`z-100 mt-5 text-center`}>
              {PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                eventDetails.photographer.id
              ) ||
              PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
                eventDetails.photographer.id
              ) ||
              EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id) ? (
                <></>
              ) : (
                <BackButton
                  onClick={() => {
                    stopCamera();
                    setUser({ ...user, id: 0 });
                  }}
                  text="Cancel"
                  className="mx-3 cursor-pointer"
                  bg="dark"
                />
              )}
              <Button
                disabled={!showCapture}
                text={searchingFace ? "Searching..." : "Capture"}
                onClick={captureImage}
              />
            </div>

            <div
              ref={parentImgRef}
              className="absolute max-w-full h-[480px] w-[640px] left-0 right-0 mx-auto  top-0 flex justify-center items-center"
            >
              <img ref={imgRef} src={faceImg2} className="max-w-[40%]" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`${loading ? "hidden" : "h-full"}`}>
          <RetakePhoto
            setIsCamera={setIsCamera}
            setSearchId={setSearchId}
            setLoading={setLoading}
            setShowPreRegSuccess={setShowPreRegSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default CameraWithoutLiveliness;
