import React from "react";
import CloseIcon from "../../../../assets/icons/CloseIcon";

const FavoriteFolderDrawer = ({ close }) => {
    const isDarkMode = true;

    return (
        <div className='container mx-auto p-[24px] bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray'>
            <div className='flex justify-between mt-[30px] mb-[30px]'>
                <div className='text-2xl leading-6 font-medium text-dark-gray1 dark:text-light-gray'>
                    Favourite folder options
                </div>
                <span className='text-3xl' onClick={close}>
                    <CloseIcon fill={`${isDarkMode ? "#eee" : "#222"}`} />
                </span>
            </div>

            <ul>
                <li className='leading-loose mt-[10px] mb-[10px]'>
                    Download images in folder
                </li>
                <li className='leading-loose mt-[10px] mb-[10px]'>
                    Delete folder
                </li>
                <li className='leading-loose mt-[10px] mb-[10px]'>
                    Duplicate folder
                </li>
                <li className='leading-loose mt-[10px] mb-[10px]'>
                    Mark as default
                </li>
            </ul>
        </div>
    );
};

export default FavoriteFolderDrawer;
