/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import DownArrow from "../../../assets/icons/DownArrow";
import { ThemeContext } from "../../App";

export default function Dropdown({ current, setCurrent, className="", options , onItemSelected , objectKey = null , buttonClassname="" }) {
    const theme = useContext(ThemeContext);

    const iconsColor = theme === "dark" ? "white" : "#222";
    return (
        <Menu as='div' className={`relative inline-block text-left ${className} `}>
            <div>
                <Menu.Button className={`flex items-center justify-between text-xs md:text-sm dark:text-light-gray shadow-button  rounded-lg p-2 px-3 w-full shadow-light-input dark:shadow-dark-input ${buttonClassname}`}>
                    {current}
                    <DownArrow color={iconsColor}/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
            >
                <Menu.Items className={`flex items-center justify-between text-sm dark:text-light-gray dark:shadow-dark-input rounded-lg p-2 px-3 mr-30px shadow-dark-input-bottom-right ${buttonClassname}`}>
                    <div className='py-1'>
                        {options.map((op, id) => {
                            return (
                                <Menu.Item key={id}>
                                    {({ active }) => (
                                        <p
                                            className={` mb-2 cursor-pointer ${objectKey ? current === op[objectKey] ? "text-primary-green" : "" : current === op ? "text-primary-green" : ""  } `}
                                            onClick={() => {
                                                setCurrent(op);
                                                onItemSelected(op)
                                            }}
                                        >
                                            {objectKey ? op[objectKey] : op}
                                        </p>
                                    )}
                                </Menu.Item>
                            );
                        })}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
