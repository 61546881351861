import React, { useEffect, useState, useRef } from "react";
import moment from "moment";

import "./header.css";
import Logo from "../../../../components/common/Logo";
import { useEvent } from "../../../../Store/event";
import { useUser } from "../../../../Store/user";
import FaceSearchPopUp from "../FaceSearchPopUp";
import DialogWrapper from "../../../../components/common/Dialog";
import LoginPopUp from "../Login";
import {
  BMS_PHOTOGRAPHER_ID,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
} from "../../../../constants";
import { useUploader } from "../../../../Store/upload";
import AddImages from "../../../../components/common/AddImages";
import ImageQueue from "../../../../components/common/ImageQueue";
import Button from "../../../../components/common/Button";

const Header = ({
  h = false,
  showAuthButtons = false,
  faceRecPopUp = false,
  setFaceRecPopUp = () => {},
  uploadFromCoverImage = false,
  setUploadFromCoverImage = () => {},
}) => {
  const eventDetails = useEvent().response;
  const { user } = useUser();

  const [showLoginModal, setLoginModal] = useState(false);
  const [currentView, setCurrentView] = useState("login");
  const { isUploading, filesToUpload } = useUploader();

  const descRef = useRef();

  const isMobile = window.innerWidth <= 768;

  /** 
   * const descriptionStyle = {
    overflow: !showMoreDesc && "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: showMoreDesc ? "20" : "2",
    WebkitBoxOrient: "vertical",
  };
  */
  /** JSX to show the see more button */

  // const seeMoreJSX =
  //   /** Line height is 16px so scrollHeight is > 32 it means there's more content so we need to show the button */
  //   descRef.current?.scrollHeight > 32 ? (
  //     <button
  //      // onClick={() => setShowMoreDesc((prev) => !prev)}
  //       className={`text-light-gray text-left  md:text-base text-xs mt-1 ${
  //         !isMobile ? "hidden" : "block"
  //       }`}
  //     >
  //       See {showMoreDesc ? "less" : "more"}
  //     </button>
  //   ) : (
  //     <></>
  //   );

  const AUTH_BUTTON_CLASSES =
    " w-[92px] md:w-[125px] border py-1 px-2  md:px-5 rounded-lg border-light-gray";

  const hasAccess = eventDetails.data.requiresEmail
    ? user.accessToken || user.jwt
    : eventDetails.data.requiresPassword
    ? user.isValid
    : eventDetails.data.isPublished
    ? true
    : user.accessToken || user.jwt;

  const authButtons = (wrapperClassname = "mt-1 md:mt-2") => (
    <div
      className={`flex gap-2 flex-col md:flex-row md:gap-4 mt-4 md:mt-0 md:items-center text-sm md:text-base whitespace-nowrap ${wrapperClassname} text-light-gray`}
    >
      <button
        onClick={() => setFaceRecPopUp(true)}
        className={AUTH_BUTTON_CLASSES}
      >
        View mine
      </button>
      <span className="w-1 h-full bg-white"></span>
      <button
        onClick={() =>
          hasAccess
            ? window.scrollBy(0, window.innerHeight)
            : setLoginModal(true)
        }
        className={AUTH_BUTTON_CLASSES}
      >
        View all
      </button>
      {eventDetails.data.isGuestUploadEnabled &&
      eventDetails.photographer.isGuestUploadEnabled ? (
        <>
          <span className="font-bold pl-4 pr-4 ml-5 md:ml-[0px] text-left">
            OR
          </span>
          <button
            onClick={() => {
              if (user.accessToken) {
                setCurrentView("upload");
              } else setLoginModal(true);
              setUploadFromCoverImage(true);
            }}
            className={AUTH_BUTTON_CLASSES}
          >
            Upload
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );

  const scrollDown = () => {
    setTimeout(() => {
      window.scrollBy(0, window.innerHeight);
    }, 50);
  };

  useEffect(() => {
    if (user.accessToken && !faceRecPopUp && !uploadFromCoverImage) {
      scrollDown();
    }
  }, [user.accessToken]);

  useEffect(() => {
    if (user.accessToken && !faceRecPopUp && !uploadFromCoverImage) {
      scrollDown();
    }
  }, [user.isValid]);

  useEffect(() => {
    if (!isUploading) setUploadFromCoverImage(false);
  }, [isUploading]);

  useEffect(() => {
    if (currentView === "onGoingUpload" && filesToUpload.length < 1)
      setCurrentView("upload");
  }, [filesToUpload, currentView]);

  const NAME_FOR_JIO_EVENT = "Image delivery partner : Algomage";

  const ADD_SPACING_FOR_JIO_EVENT = () =>
    EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id)
      ? "mt-2 mb-3"
      : "";

  const DESKTOP_PARAMS = eventDetails.data.desktopCoverImageUrl.includes("?cr")
    ? "&w=1600"
    : "?w=1600";

  const MOBILE_PARAMS = eventDetails.data.mobileCoverImageUrl.includes("?cr")
    ? "&w=1600"
    : "?w=1600";

  const DESKTOP_URL = eventDetails.data.desktopCoverImageUrl + DESKTOP_PARAMS;

  const MOBILE_URL = eventDetails.data.mobileCoverImageUrl + MOBILE_PARAMS;
  return (
    <>
      {currentView === "login" ? (
        <div
          className={`pics__header overflow-hidden relative ${
            h ? "md:h-[100vh]" : "md:h-[90vh]"
          }`}
          style={{
            background: `url("${isMobile ? MOBILE_URL : DESKTOP_URL}")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            ...(isMobile && {
              height:
                window.innerHeight -
                (user.isAdmin ? (window.innerWidth >= 400 ? 88 : 112) : 0),
            }),
            ...(!isMobile &&
              !showAuthButtons &&
              BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) && {
                height: "100%",
              }),
          }}
        >
          <div
            style={{
              ...(isMobile && {
                height:
                  window.innerHeight -
                  (user.isAdmin ? (window.innerWidth >= 400 ? 88 : 112) : 0),
              }),
            }}
            className={`mx-auto  ${
              h
                ? BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
                  ? "h-full"
                  : "md:h-[100vh]"
                : "md:h-[90vh]"
            }`}
          >
            <header className="md:px-10 px-[24px] absolute">
              <Logo logoStyle="md:left-[0px]" />
            </header>
            {eventDetails.data.design.coverImageTemplate === "label" && (
              <div className="flex flex-col justify-center items-center w-full h-full full__container_gradient">
                {/* <div className="absolute inset-0 bg-black bg-opacity-20 rounded-xl" ></div> */}
                <div className="w-3/4 flex flex-col justify-center items-center text-center ">
                  <div className="pics__header-title uppercase  text-xl md:text-[30px]">
                    {eventDetails.data.name.replace("-", " ")}
                  </div>
                  <div
                    className={`pics__header-date ${ADD_SPACING_FOR_JIO_EVENT()} ${
                      eventDetails.data.eventDate ? "mb-1" : "mb-0"
                    } text-sm md:text-[20px]"`}
                  >
                    {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                      eventDetails.data.id
                    )
                      ? NAME_FOR_JIO_EVENT
                      : eventDetails.data?.eventDate &&
                        moment(eventDetails.data.eventDate).format(
                          "Do MMM, YYYY"
                        )}
                  </div>
                  {showAuthButtons && authButtons()}
                  {/* <p
                ref={descRef}
                style={isMobile ? descriptionStyle : {}}
                className={` ${
                  eventDetails.data.description ? "mt-4" : ""
                } text-light-gray text-center  md:text-base text-xs`}
              >
                {eventDetails.data.description}
              </p>
              {seeMoreJSX} */}
                </div>
              </div>
            )}
            {/* <div className="absolute w-full bottom-0 pt-5 pics__header_container">
                    <div className='pics__header-content  z-50 md:max-w-full flex md:ml-[0px] md:px-10 px-[24px]'> */}
            {/* <div className='pics__header-title text-xl md:text-[30px]'>
                            {eventDetails.data.name.replace("-", " ")}
                        </div>
                        <div className={`pics__header-date ${eventDetails.data.eventDate ? "mb-1" : "mb-0"} text-sm md:text-[20px]'`}>
                            {moment(eventDetails.data.date).format('Do MMM, YYYY')}
                        </div>
                        <p className='mt-[20px] text-light-gray text-md w-[80%] md:w-[60%]'>
                            {eventDetails.data.description}
                        </p>
                    </div> */}
            {/* </div> */}
            {eventDetails.data.design.coverImageTemplate === "split" && (
              <div className="md:px-10 px-[24px] w-full sm:w-2/3 h-full flex flex-col justify-center right__container_gradient">
                {/* <div className="absolute inset-0 bg-black bg-opacity-10 rounded-r-xl" ></div> */}
                <div className="w-1/2 sm:w-5/6 ">
                  <div className="pics__header-title uppercase text-xl md:text-[30px]">
                    {eventDetails.data.name.replace("-", " ")}
                  </div>
                  <div
                    className={`pics__header-date ${ADD_SPACING_FOR_JIO_EVENT()} ${
                      eventDetails.data.eventDate ? "mb-1" : "mb-0"
                    } text-sm md:text-[20px]"`}
                  >
                    {
                      EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                        eventDetails.data.id
                      )
                        ? NAME_FOR_JIO_EVENT
                        : eventDetails?.data?.eventDate &&
                          moment(eventDetails.data.eventDate).format(
                            "Do MMM, YYYY"
                          )
                      // moment(event.date).format('Do MMM, YYYY')}
                    }
                  </div>
                  {showAuthButtons && authButtons()}
                  {/* <p
                ref={descRef}
                style={isMobile ? descriptionStyle : {}}
                className={`${
                  eventDetails.data.description ? "mt-4" : ""
                } text-light-gray text-left md:text-base text-xs`}
              >
                {eventDetails.data.description}
              </p>
              {seeMoreJSX} */}
                </div>
              </div>
            )}

            {eventDetails.data.design.coverImageTemplate === "border" && (
              <div className="flex flex-col h-full justify-end">
                <div
                  className={`flex justify-items-center w-full py-8 md:py-10 h-auto bottom-0 bottom__container_gradient`}
                >
                  <div className="md:px-10 px-[24px] md:flex-row flex-col justify-center items-center flex w-full">
                    <div className="w-2/3 md:w-1/2 flex flex-col items-center md:items-start justify-center">
                      <div className="pics__header-title uppercase text-xl md:text-[30px]">
                        {eventDetails.data.name.replace("-", " ")}
                      </div>
                      <div
                        className={`pics__header-date ${ADD_SPACING_FOR_JIO_EVENT()} ${
                          eventDetails.data.eventDate ? "mb-1" : "mb-0"
                        } text-sm md:text-[20px]"`}
                      >
                        {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                          eventDetails.data.id
                        )
                          ? NAME_FOR_JIO_EVENT
                          : eventDetails?.data?.eventDate &&
                            moment(eventDetails.data.eventDate).format(
                              "Do MMM, YYYY"
                            )}
                      </div>
                    </div>
                    <div className="w-1/2  flex flex-col justify-center items-center md:items-end">
                      {/* <p
                    ref={descRef}
                    style={isMobile ? descriptionStyle : {}}
                    className="text-light-gray text-left pb-4 md:pb-0  md:text-base text-xs"
                  >
                    {eventDetails.data.description}
                  </p>
                  {seeMoreJSX} */}
                      {showAuthButtons && (
                        <div className="mb-[21px]">
                          {authButtons("mt-1 md:mt-0")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {eventDetails.data.design.coverImageTemplate === "corner" && (
              <div className="flex flex-col h-full justify-end">
                <div className="absolute opacity-75 blur-[10px] w-1/2  h-1/2  -left-3 bottom__container_gradient"></div>
                <div className="flex flex-col justify-end pb-8  md:pb-10 w-[55%] md:w-1/2 h-1/2 z-0">
                  <div className="md:px-10 px-[24px] flex flex-col sm:w-full">
                    <div className="flex flex-col items-start">
                      <div className="pics__header-title text-xl uppercase md:text-[30px]">
                        {eventDetails.data.name.replace("-", " ")}
                      </div>
                      <div
                        className={`pics__header-date ${ADD_SPACING_FOR_JIO_EVENT()} ${
                          eventDetails.data.eventDate ? "mb-1" : "mb-0"
                        } text-sm md:text-[20px]"`}
                      >
                        {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                          eventDetails.data.id
                        )
                          ? NAME_FOR_JIO_EVENT
                          : eventDetails?.data?.eventDate &&
                            moment(eventDetails.data.eventDate).format(
                              "Do MMM, YYYY"
                            )}
                      </div>
                    </div>
                    {/* {eventDetails.data.description && (
                  <div className="flex flex-col justify-center pt-[0.258rem] md:pt-4">
                    <p
                      ref={descRef}
                      style={isMobile ? descriptionStyle : {}}
                      className="text-light-gray text-left  md:text-base text-xs"
                    >
                      {eventDetails.data.description}
                    </p>
                    {seeMoreJSX}
                  </div>
                )} */}
                    {showAuthButtons && authButtons()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : currentView === "upload" ? (
        <div
          className={`relative text-dark-gray1 dark:text-light-gray  ${
            h ? "h-[100vh]" : "h-[90vh]"
          }`}
        >
          <header className="md:px-10 px-[24px] absolute">
            <Logo logoStyle="md:left-[0px]" />
          </header>
          <div className="flex flex-col gap-20 justify-center items-center">
            <h1 className="text-xl md:text-[28px] pt-28 font-medium relative">
              <span
                onClick={() => {
                  setCurrentView("login");
                  setUploadFromCoverImage(false);
                }}
                className="cursor-pointer absolute -left-[20px] md:-left-[40px] "
              >
                &lt;
              </span>{" "}
              <span> Upload images to {eventDetails.data.name} </span>{" "}
            </h1>
            <AddImages setCurrentView={setCurrentView} coverImageUpload />
          </div>
        </div>
      ) : currentView === "onGoingUpload" ? (
        <div
          className={`relative text-dark-gray1 dark:text-light-gray  ${
            h ? "h-[100vh]" : "h-[90vh]"
          }`}
        >
          <header className="md:px-10 px-[24px] absolute">
            <Logo logoStyle="md:left-[0px]" />
          </header>
          <div className="flex flex-col gap-20 justify-center items-center">
            <h1 className="text-xl md:text-[28px] pt-28 font-medium relative">
              <span
                onClick={() => {
                  if (isUploading) {
                    window.Toast.fire({
                      icon: "error",
                      title: "Upload is going on",
                    });
                    return;
                  }
                  setCurrentView("upload");
                  setUploadFromCoverImage(false);
                }}
                className="absolute cursor-pointer -left-[20px] md:-left-[40px]"
              >
                &lt;
              </span>{" "}
              <span> Upload images to {eventDetails.data.name} </span>{" "}
            </h1>
            <div className="flex justify-center flex-col max-w-[90%] md:max-w-full md:min-w-[30%] ">
              <div className="flex items-center justify-between mt-2">
                <p className="text-base text-dark-gray1 dark:text-light-gray">
                  Upload Queue
                </p>
              </div>
              <ImageQueue
                ulClass="max-h-[16rem] overflow-y-auto"
                isBottom={true}
              />
              {isUploading ? (
                <></>
              ) : (
                <>
                  <div className="mt-12 flex justify-center">
                    <Button
                      onClick={() => setFaceRecPopUp(true)}
                      text="View your images"
                      className="text-sm md:text-base"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {showAuthButtons && (
        <>
          <FaceSearchPopUp
            getter={faceRecPopUp}
            setter={setFaceRecPopUp}
            user={user}
          />
          <DialogWrapper
            close={() => setLoginModal(false)}
            open={showLoginModal}
            title="Sign In"
          >
            <LoginPopUp
              setCurrentView={setCurrentView}
              close={() => setLoginModal(false)}
              guestUpload={uploadFromCoverImage}
            />
          </DialogWrapper>
        </>
      )}
    </>
  );
};

export default Header;
