import React from "react";

const DotsOptions = props => {
    const { height = "16", width = "16", color = "#EEE" } = props;
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            className='bi bi-three-dots-vertical'
            viewBox='0 0 16 16'
        >
            <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z' />
        </svg>
    );
};

export default DotsOptions;
