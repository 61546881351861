import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Gallery from "react-photo-gallery";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  getFacePatch,
  getFacePatchResult,
  postFacePatchName,
  postFacePatchSearchId,
} from "../../../../apis/events";
import { flag } from "../../../../apis/favourites";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import { ThemeContext } from "../../../../components/App";
import Button from "../../../../components/common/Button";
import DialogWrapper from "../../../../components/common/Dialog";
import GoBackSection from "../../../../components/common/GoBackSection";
import InputWithSuffix from "../../../../components/common/InputWithSuffix";
import Share from "../../../../components/common/Share";
import {
  DOWNLOAD_ORIGINAL_IMAGE,
  HIGH_RES_DOMAIN,
  LOWER_RES_DOMAIN,
} from "../../../../constants";
import { checkIsDeviceIOS } from "../../../../helper";
import {
  carouselCloseButton,
  carouselDownloadButton,
  carouselSlideShow,
  carouselZoomButton,
  getGalleryStyle,
} from "../../../../helper/galleryHelper";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import { useEvent } from "../../../../Store/event";
import { useFavourites } from "../../../../Store/favourites";
import { useFolder } from "../../../../Store/folder";
import { useUser } from "../../../../Store/user";
import Loading from "../../../Loading";
import { galleryCaraouselIcons } from "../GalleryWrapper/helpers/util";
import SelectedImage from "../SelectedImage";

const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9\s_]*$/;

const FacePatch = ({ images }) => {
  const eventDetails = useEvent().response;
  const { folders, setPrivacy } = useFolder();
  const { user } = useUser();
  const theme = useContext(ThemeContext);

  const {slug} = useParams()

  const { currentFav, fancyboxFavClicked } = useFavourites();

  const isIosDevice = useMemo(checkIsDeviceIOS, []);

  const [width, setWidth] = useState(window.innerWidth);
  // const [facePatchesName, setFacePatchesName] = useState({});
  // const [nameFacePatchLoader, setNameFacePatchLoader] = useState(false);
  const [facePatchNameValue, setFacePatchNameValue] = useState("");
  const [updateNameLoader, setUpdateNameLoader] = useState(false);
  const [showEditField, setShowEditField] = useState();
  const [photos, setPhotos] = useState([]);
  const [selectedFacePatchId, setSelectedFacePatchId] = useState("");
  const [shareUrl, setShareUrl] = useState();
  const [downloadAlertShown, setDownloadAlertShown] = useState(false);
  const [sharePopUp, setSharePopUp] = useState(false);
  const [facePatches, setFacePatches] = useState(images);
  const [displayBatchSize, setDisplayBatchSize] = useState(0);
  const [editName, setEditName] = useState(false);
  const [facePatchLoader, setFacePatchLoader] = useState(false);
  const [shareResultUrl, setShareResultUrl] = useState("");
  const [showResultModal, setShowResultModal] = useState(false);
  const [paginationLoader, setPaginationLoader] = useState(false);

  const requestOnGoing = useRef(false);
  const inputRef = useRef();
  const ref = useRef([]);
  const currentBatchSize = useRef(0);
  const photosRef = useRef();
  const imagesMetadata = useRef({ currentPage: 1 });
  const inputNearAvatar = useRef();
  const loaderRef = useRef();

  useOutsideClick(inputRef, () => {
    if (showEditField) {
      setShowEditField(false);
      setFacePatchNameValue("");
    }
  });

  useOutsideClick(inputNearAvatar, () => {
    if (editName) {
      setEditName(false);
    }
  });

  const galleryViewPath = `/view/${slug ? `${slug}/` : ""}${eventDetails.data.slug}/${
    folders[0]?.slug ?? ""
  }`;

  const isMobile = width < 768;
  const iconsColor = theme === "dark" ? "white" : "#222";

  const galleryCarouselItems = {
    slideshow: carouselSlideShow(iconsColor),
    zoom: carouselZoomButton(iconsColor),
    ...(eventDetails.data.downloadSingleImageSize !== "" &&
      eventDetails.data.downloadSingleImageSize !== null &&
      !!!eventDetails.data.isDownloadChargeable && {
        download: carouselDownloadButton(
          iconsColor,
          isIosDevice,
          eventDetails.data.downloadSingleImageSize === DOWNLOAD_ORIGINAL_IMAGE,
          setDownloadAlertShown,
          user.id
        ),
      }),

    privacy: {
      type: "button",
      label: "Privacy",
      class: "fancybox__button--download fancybox__button--privacy",
      html: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  style="stroke: ${iconsColor}" stroke-width="1.5" stroke="currentColor"  width="18" height="18">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
        </svg>`,
      click: async function (event) {
        event.stopPropagation();
        try {
          const imageObj = this.fancybox.getSlide().originalImageObj;
          const privacyList = this.fancybox.options.privacyList;
          privacyHandler(
            imageObj.id,
            imageObj.folderId,
            +privacyList[imageObj.id]
          );
          privacyList[imageObj.id] = !privacyList[imageObj.id];
          if (privacyList[imageObj.id])
            event.target.firstElementChild.firstElementChild.style.stroke =
              "#00b7b8";
          else
            event.target.firstElementChild.firstElementChild.style.stroke =
              iconsColor;
        } catch (error) {
          console.error("Error", error);
        }
      },
    },

    ...(!!eventDetails.data.showImageShare && {
      share: {
        type: "button",
        label: "Share",
        class: "fancybox__button--share",
        html: `<svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden='true'
          focusable='false'
          data-prefix='fas'
          data-icon='share-alt'
          className='svg-inline--fa fa-share-alt fa-w-14'
          role='img'
          viewBox="0 0 96 110"
       
          style='height:18px; width:18px;stroke: ${iconsColor}'
        >
          <path
            d="M81.0937 79.918L81.0923 79.9111H80.6822C76.5061 79.9111 72.7421 81.6535 70.0474 84.4304L29.4984 58.8416C29.9137 57.4851 30.1451 56.0467 30.1451 54.5537C30.1451 53.057 29.9121 51.6175 29.4959 50.2589L69.5002 24.9925C72.2189 28.1092 76.223 30.0851 80.6827 30.0851C88.8536 30.0851 95.5 23.4499 95.5 15.29C95.5 7.13225 88.8517 0.5 80.6827 0.5C72.5073 0.5 65.8554 7.1339 65.8554 15.29C65.8554 16.505 66.0157 17.6798 66.2951 18.8035L25.9555 44.2803C23.2613 41.5017 19.4942 39.7588 15.3173 39.7588C7.14643 39.7588 0.5 46.394 0.5 54.5531C0.5 62.7084 7.14842 69.3399 15.3173 69.3399C19.4949 69.3399 23.262 67.596 25.9562 64.8191L66.505 90.4078C66.088 91.7682 65.8543 93.2109 65.8543 94.71C65.8543 102.868 72.5063 109.5 80.6816 109.5C88.8524 109.5 95.4989 102.866 95.4989 94.71C95.4998 86.6876 89.0753 80.1362 81.0937 79.918ZM80.6822 7.44025C85.0187 7.44025 88.5453 10.963 88.5453 15.2898C88.5453 19.621 85.0183 23.1444 80.6822 23.1444C76.3404 23.1444 72.8091 19.6196 72.8091 15.2898C72.8091 10.9636 76.3401 7.44025 80.6822 7.44025ZM15.3177 62.3994C10.981 62.3994 7.45461 58.8788 7.45461 54.5531C7.45461 50.2235 10.9816 46.6993 15.3177 46.6993C19.6595 46.6993 23.1908 50.2241 23.1908 54.5531C23.1908 58.8782 19.66 62.3994 15.3177 62.3994ZM80.6822 102.559C76.3402 102.559 72.8091 99.0358 72.8091 94.7096C72.8091 90.3778 76.3406 86.8516 80.6822 86.8516C85.0183 86.8516 88.5453 90.3772 88.5453 94.7096C88.5453 99.0363 85.0185 102.559 80.6822 102.559Z"
            fill='${iconsColor}'
          />
        </svg>`,
        click: function (event) {
          event.stopPropagation();
          const image = this.fancybox.getSlide().originalImageObj;
          setShareUrl(
            `${window.location.protocol}//${window.location.host}/view/${slug ? `${slug}/` : ""}${
              eventDetails.data.name
            }/${image.folderId}/image/${this.fancybox.getSlide().key}`
          );
          setSharePopUp(true);
        },
      },
    }),
    close: carouselCloseButton(iconsColor),
    like: {
      type: "link",
      label: "Favourite",
      class: "fancybox__button--share fancybox__button--like",
      html: ` <svg
            aria-hidden='true'
            focusable='false'
            data-prefix='fas'
            data-icon='heart'
            className='svg-inline--fa fa-heart fa-w-20'
            role='img'
            viewBox='0 0 512 512'
            height='18'
            width='18'
            style="stroke: ${iconsColor};stroke-width: 40px;width: 30px;"
        >
            <path
                fill='${iconsColor}'
                d='M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z'
            ></path>
        </svg>`,
      tabindex: 1,
      click: async function (e) {
        try {
          e.stopPropagation();

          const originalImageObj = this.fancybox.getSlide().originalImageObj;

          const isFav = originalImageObj?.favouriteList?.includes(
            currentFav.id
          );
          /**Remove image from fav */
          if (isFav) {
            const res = await fancyboxFavClicked(originalImageObj, true);
            if (!res?.isError) {
              e.target.firstElementChild.firstElementChild.style.fill =
                "transparent";
              const key = this.fancybox.getSlide()?.key;
              const photos = this.fancybox.options.folderImages;
              const currentPhotoIndex = photos.findIndex(
                (el) => el.key === key
              );
              const favList =
                photos[currentPhotoIndex].originalImageObj.favouriteList;
              const favListIndex = favList.findIndex(
                (el) => el === currentFav.id
              );
              favList.splice(favListIndex, 1);
              photos[currentPhotoIndex].originalImageObj.favouriteList =
                favList;
              const fancyboxFavIndex = originalImageObj.favouriteList.findIndex(
                (el) => el === currentFav.id
              );
              originalImageObj.favouriteList.splice(fancyboxFavClicked, 1);
              setPhotos(photos);
            }
          } else {
            const res = await fancyboxFavClicked(originalImageObj, false);
            if (res?.favId) {
              e.target.firstElementChild.firstElementChild.style.fill =
                iconsColor;
              const key = this.fancybox.getSlide()?.key;
              const photos = this.fancybox.options.folderImages;
              const currentPhotoIndex = photos.findIndex(
                (el) => el.key === key
              );

              if (
                Array.isArray(
                  photos[currentPhotoIndex].originalImageObj.favouriteList
                )
              )
                photos[currentPhotoIndex].originalImageObj.favouriteList.push(
                  currentFav.id
                );
              else
                photos[currentPhotoIndex].originalImageObj.favouriteList = [
                  currentFav.id,
                ];
              if (Array.isArray(originalImageObj?.favouriteList))
                originalImageObj?.favouriteList?.push(currentFav.id);
              else {
                originalImageObj.favouriteList = [currentFav.id];
              }
              setPhotos(photos);
            }
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  };

  const FancyboxOptions = {
    infinite: false,
    autoFocus: true,
    preload: 2,
    trapFocus: true,
    Toolbar: {
      display: galleryCaraouselIcons,
      items: galleryCarouselItems,
    },
    Thumbs: {
      autoStart: false,
    },
  };

  const showFancyBox = (index) => {
    Fancybox.defaults.Hash = false;
    let newAllPhotos = ref.current.map((p) => {
      return { ...p, height: null, width: null };
    });

    const addColorToLikeButton = function () {
      let id;
      let isFav;
      if (![null, undefined].includes(this.pageIndex)) {
        id = this.pages[this.pageIndex].slides[0].key;
        isFav = this.pages[
          this.pageIndex
        ].slides[0]?.originalImageObj?.favouriteList?.includes(currentFav.id);
      } else {
        id = this.items[this.options.startIndex].key;
        isFav = this.items[
          this.options.startIndex
        ]?.originalImageObj.favouriteList?.includes(currentFav.id);
      }

      const toolbar = document.getElementsByClassName(
        "fancybox__toolbar__items"
      );
      if (toolbar.length) {
        const likeButon = toolbar[0].getElementsByClassName(
          "fancybox__button--like"
        );
        likeButon[0].firstElementChild.firstElementChild.style.fill = isFav
          ? iconsColor
          : "transparent";

        const privacyButton = toolbar[0].getElementsByClassName(
          "fancybox__button--privacy"
        );
        if (privacyButton[0]) {
          privacyButton[0].firstElementChild.firstElementChild.style.stroke =
            Fancybox.getInstance().options.privacyList[id]
              ? "#00b7b8"
              : iconsColor;
          if (!user.isAdmin)
            privacyButton[0].style.display = !Fancybox.getInstance().options
              .privacyList[id]
              ? "none"
              : "inherit";
        }
      }
    };
    const privacyList = {};

    newAllPhotos.forEach((el) => (privacyList[el.key] = !!el.isPrivate));

    Fancybox.show(newAllPhotos, {
      ...FancyboxOptions,
      startIndex: index,
      folderImages: photos,
      downloadAlertShown: downloadAlertShown,
      privacyList: privacyList,
      once: {
        done: addColorToLikeButton,
      },
      Carousel: {
        on: {
          change: addColorToLikeButton,
        },
      },
    });
  };

  const privacyHandler = async (photoId, folderId, visibility) => {
    let res = await flag(
      photoId,
      folderId,
      visibility,
      user,
      eventDetails.data.id
    );

    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: visibility == 0 ? "Photo is now private" : "Photo is now public",
      });
      const photosClone = structuredClone(photos);
      const index = photosClone.findIndex((f) => f.key == photoId);
      photosClone[index].isPrivate = visibility = +!visibility;
      setPhotos(photosClone);
      ref.current = photosClone;
    } else {
      window.Toast.fire({
        icon: "error",
        title: res.message,
      });
    }
  };

  const imageRenderer = ({
    index,
    left,
    top,
    key,
    photo,
    direction,
    margin,
  }) => (
    <SelectedImage
      key={key}
      margin={margin}
      index={index}
      photo={photo}
      left={left}
      top={top}
      onClick={showFancyBox}
      direction={direction}
      onFavClickHandler={() => {}}
      privateMode={true}
      folderId={photo.originalImageObj.folderId}
      privacyHandler={privacyHandler}
      ownPrivacyHandler
    />
  );

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  // const callFacePatchName = async () => {
  //   setNameFacePatchLoader(true);
  //   try {
  //     const { data } = await getFacePatchName(eventDetails.data.awsEventName);
  //     setFacePatchesName(data);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setNameFacePatchLoader(false);
  //   }
  // };

  const updateFacePatchName = async (id) => {
    setUpdateNameLoader(true);
    const index = Object.keys(facePatches).findIndex((el) => {
      if (el == id) return false;
      return (
        facePatches[el].name.toLocaleLowerCase() ===
        facePatchNameValue.trim().toLocaleLowerCase()
      );
    });
    if (index > -1) {
      window.Toast.fire({
        icon: "error",
        title: "This name already belongs to other face.",
      });
      setUpdateNameLoader(false);
      return;
    }
    try {
      const payload = { id, name: facePatchNameValue };
      await postFacePatchName(eventDetails.data.awsEventName, payload);
      setFacePatches((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          name: facePatchNameValue,
        },
      }));
      setShowEditField(undefined);
    } catch (error) {
      console.error(error);
    } finally {
      setUpdateNameLoader(false);
      setEditName(false);
      setFacePatchNameValue("");
    }
  };

  const fetchImages = async (id, firstReq = true) => {
    try {
      if (firstReq) {
        imagesMetadata.current = { currentPage: 1 };
      }
      setFacePatchLoader(firstReq);
      const { data } = await getFacePatchResult(
        eventDetails.data.awsEventName,
        id,
        imagesMetadata.current.currentPage
      );
      if (data.message) {
        Swal.fire({
          title: "Oops!",
          text: data.message,
          icon: "warning",
        });
        return;
      }
      const feData = data.images.map((i) => {
        let appendString,
          downloadSrc = "";
        if (
          !eventDetails.data.watermark ||
          !eventDetails.photographer.showWatermarkOnGallery
        ) {
          if (eventDetails.data.downloadSingleImageSize == "optimized")
            downloadSrc = i.url + "?v=" + i.version + "&w=1600";
          else
            downloadSrc =
              i.url
                .replace(LOWER_RES_DOMAIN, HIGH_RES_DOMAIN)
                .replace("+", "%2B") +
              "?v=" +
              i.version;
        } else {
          let wp = eventDetails.data.watermark.position
            ? eventDetails.data.watermark.position
            : "bottom-left";
          const isFullImageWatermark =
            eventDetails.data.watermark.fullWatermark;
          if (eventDetails.data?.watermark?.logoText) {
            appendString =
              "&wt=" +
              eventDetails.data.watermark.logoText +
              "&wtc=" +
              eventDetails.data.watermark.fontColor +
              "&wts=" +
              eventDetails.data.watermark.fontSize +
              "&wp=" +
              wp +
              "&wo=" +
              Number(eventDetails.data.watermark.opacity) * 0.1 +
              "&wof=" +
              Number(eventDetails.data.watermark.xOffset) +
              "," +
              Number(eventDetails.data.watermark.xOffset) +
              "&wtf=" +
              eventDetails.data.watermark.font;
          }
          if (eventDetails.data?.watermark?.logoUrl) {
            let watermark = eventDetails.data.watermark.logoUrl.replace(
              "watermark/",
              ""
            );
            appendString =
              "&wm=" +
              watermark +
              "&ws=" +
              eventDetails.data.watermark.logoScale +
              "&wp=" +
              wp +
              "&wof=" +
              Number(eventDetails.data.watermark.xOffset) +
              "," +
              Number(eventDetails.data.watermark.yOffset) +
              "&wo=" +
              Number(eventDetails.data.watermark.opacity) * 0.1 +
              "&wfi=" +
              isFullImageWatermark;
          }
          if (eventDetails.data.downloadSingleImageSize == "optimized")
            downloadSrc = i.url + "?v=" + i.version + "&w=1600" + appendString;
          else downloadSrc = i.url + "?v=" + i.version + appendString;
        }
        return {
          src: appendString
            ? i.url.replace(
                "images.picsniff.com/assets-picsniff",
                LOWER_RES_DOMAIN
              ) +
              "?w=1600&v=" +
              i.version +
              appendString
            : i.url.replace(
                "images.picsniff.com/assets-picsniff",
                LOWER_RES_DOMAIN
              ) +
              "?w=1600&v=" +
              i.version,
          originalSrc: appendString
            ? i.url.replace(
                "images.picsniff.com/assets-picsniff",
                LOWER_RES_DOMAIN
              ) +
              "?v=" +
              i.version +
              appendString
            : i.url.replace(
                "images.picsniff.com/assets-picsniff",
                LOWER_RES_DOMAIN
              ) +
              "?v=" +
              i.version,
          downloadSrc: downloadSrc,
          width: Number(i.width),
          height: Number(i.height),
          key: i.id.toString(),
          originalName: i.originalImageName,
          originalImageObj: i,
          printStoreSrc: i.url + "?v=" + i.version,
          isPrivate: i.isPrivate,
        };
      });
      if (imagesMetadata.current.currentPage == 1) {
        setPhotos(feData);
        ref.current = feData;
        imagesMetadata.current.last_page = data.meta.last_page;
        setSelectedFacePatchId(id);
        photosRef.current.scrollIntoView();
      } else {
        setPhotos((prev) => [...prev, ...feData]);
        ref.current = [...ref.current, ...feData];
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFacePatchLoader(false);
    }
  };

  const getFacePatches = async (id = 0) => {
    try {
      const { data } = await getFacePatch(eventDetails.data.awsEventName, id);

      setFacePatches((prev) => ({ ...prev, ...data }));
    } catch (error) {
      console.error(error);
    }
  };

  const onShareResultClicked = async () => {
    try {
      setShowResultModal(true);
      const payload = {
        faceId: selectedFacePatchId,
        collectionId: eventDetails.data.awsEventName,
        eventId: eventDetails.data.id,
        userId: eventDetails.photographer.userId,
        guestId: user.id ?? eventDetails.photographer.userId,
      };
      const { data } = await postFacePatchSearchId(payload);
      const url =
        window.location.origin +
        "/facerec/" +
        eventDetails.data.slug +
        "/results/" +
        data.searchId;
      setShareResultUrl(url);
    } catch (error) {
      console.error(error);
      setShowResultModal(false);
      setShareResultUrl("");
    }
  };

  const handleObserver = async (entries) => {
    const target = entries[0];

    if (target.isIntersecting) {
      if (photos.length && !requestOnGoing.current && !facePatchLoader) {
        imagesMetadata.current.currentPage =
          imagesMetadata.current.currentPage + 1;
        requestOnGoing.current = true;
        if (
          imagesMetadata.current.last_page < imagesMetadata.current.currentPage
        )
          return;
        setPaginationLoader(true);
        try {
          await fetchImages(selectedFacePatchId, false);
        } catch (error) {
          console.error(error);
        } finally {
          setPaginationLoader(false);
          requestOnGoing.current = false;
        }
      }
    }
  };

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "500px",
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loaderRef.current) observer.observe(loaderRef.current);
    return () => {
      observer.disconnect();
    };
  }, [photos, selectedFacePatchId, facePatchLoader]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (user.isAdmin) {
      if (!images.length && !Object.keys(facePatches).length) {
        getFacePatches(0);
        // callFacePatchName();
      } else {
        // callFacePatchName();
      }
    }
  }, [user.isAdmin]);

  useEffect(() => {
    const maxPage = Math.ceil(eventDetails.data.facePatches / 16);
    if (
      Object.keys(facePatches).length &&
      currentBatchSize.current !== displayBatchSize + 1 &&
      currentBatchSize.current <= maxPage
    ) {
      getFacePatches(currentBatchSize.current + 1);
      currentBatchSize.current += 1;
    }
  }, [facePatches, displayBatchSize]);

  return (
    <div className="mx-auto px-[18px] md:px-10 bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray pb-[4.063rem]">
      <div className=" flex justify-between items-center sm:pt-[1.875rem] pt-8 ">
        <div>
          <div
            className={`text-sm md:text-xl lg:text-3xl ${
              isMobile ? "overflow-hidden  whitespace-nowrap text-ellipsis" : ""
            } font-medium uppercase leading-6`}
          >
            {eventDetails.data.name.replace("-", " ")}
          </div>
          <div
            className={`mt-[2px] text-xs md:text-sm ${
              isMobile ? "overflow-hidden  whitespace-nowrap text-ellipsis" : ""
            }`}
          >
            {eventDetails.photographer.brandName}
          </div>
        </div>
        {!eventDetails.data.isPublished && (
          <>
            <div className="flex ml-3 p-1 text-indigo-50 text-sm bg-slate rounded-lg items-center h-[30px] sm:h-full">
              <div className="p-1 text-indigo-50 bg-slate rounded">Draft</div>
            </div>
            <div className="flex-1"></div>
          </>
        )}
      </div>
      <GoBackSection
        changeGallery={() => {}}
        onFolderClickHandler={() => {}}
        backLink={galleryViewPath}
      />
      <div className="flex  items-center  flex-wrap gap-8">
        {Object.keys(facePatches)
          .filter((x, index) => index < (displayBatchSize + 1) * 16)
          .map((el, id) => (
            <div
              key={el}
              className={`md:mr-4 flex flex-col justify-center items-center`}
            >
              <img
                width={isMobile ? 60 : 100}
                height={isMobile ? 60 : 100}
                className={`rounded-[100px] cursor-pointer ${
                  isMobile ? "w-[60px] h-[60px]" : "w-[100px] h-[100px]"
                }`}
                src={`data:image/png;base64,${facePatches[el].face_patch}`}
                onClick={() => fetchImages(el)}
              />
              <div className="flex md:flex-row w-[150px] md:w-[122px] h-[30px]  items-center justify-center mt-2 relative">
                {showEditField !== el ? (
                  <>
                    {" "}
                    <div className="text-center max-w-[122px] text-ellipsis overflow-hidden  text-sm ">
                      {facePatches[el].name ?? "Unknown face"}
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowEditField(el);
                        setFacePatchNameValue(
                          facePatches[el].name.includes("Unknown")
                            ? ""
                            : facePatches[el].name
                        );
                      }}
                    >
                      <PencilIcon />
                    </button>
                  </>
                ) : (
                  <div ref={inputRef}>
                    <InputWithSuffix
                      showCancelButton
                      value={facePatchNameValue}
                      onChange={(e) => {
                        if (ALPHANUMERIC_REGEX.test(e.target.value)) {
                          setFacePatchNameValue(e.target.value);
                        } else
                          window.Toast.fire({
                            icon: "error",
                            title: "Special characters are not allowed",
                          });
                      }}
                      placeholder={facePatchNameValue ?? "Unnamed Person"}
                      setCancel={() => {
                        setShowEditField(undefined);
                        setFacePatchNameValue("");
                      }}
                      className="max-w-[200px]"
                      onUpdate={() => updateFacePatchName(el)}
                      isProcessing={updateNameLoader}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-end w-full">
        {eventDetails.data.facePatches < 16 ? (
          <></>
        ) : eventDetails.data.facePatches / ((displayBatchSize + 1) * 16) >
          1 ? (
          <button
            onClick={() => setDisplayBatchSize((prev) => prev + 1)}
            className="text-primary-green  mr-4 mt-1 underline text-sm"
          >
            View all
          </button>
        ) : (
          <button
            onClick={() => setDisplayBatchSize((prev) => prev - 1)}
            className="text-primary-green  mr-4 mt-1 underline text-sm"
          >
            View less
          </button>
        )}
      </div>
      <div ref={photosRef}>
        {facePatchLoader ? (
          <Loading height="h-fit min-h-[500px]" />
        ) : photos.length ? (
          <div className="min-h-[500px]">
            <div className="mt-8 flex gap-4 md:gap-8 items-center">
              <img
                width={isMobile ? 40 : 60}
                height={isMobile ? 40 : 60}
                className={`rounded-[100px] cursor-pointer ${
                  isMobile ? "w-[60px] h-[60px]" : "w-[100px] h-[100px]"
                }`}
                src={`data:image/png;base64,${facePatches[selectedFacePatchId].face_patch}`}
              />

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (!editName) {
                    setEditName(true);
                    setFacePatchNameValue(
                      facePatches[selectedFacePatchId].name ?? ""
                    );
                  }
                }}
                className="flex gap-2 group"
              >
                <div className="text-center  text-sm cursor-pointer text-ellipsis ">
                  {editName
                    ? ""
                    : facePatches[selectedFacePatchId].name ?? "Unknown face"}
                </div>
                {!editName ? (
                  <button className=" md:group-hover:visible md:invisible">
                    <PencilIcon />
                  </button>
                ) : (
                  <></>
                )}
                {editName ? (
                  <div ref={inputNearAvatar}>
                    <InputWithSuffix
                      showCancelButton
                      value={facePatchNameValue}
                      onChange={(e) => {
                        if (ALPHANUMERIC_REGEX.test(e.target.value)) {
                          setFacePatchNameValue(e.target.value);
                        } else
                          window.Toast.fire({
                            icon: "error",
                            title: "Special characters are not allowed",
                          });
                      }}
                      placeholder={facePatchNameValue ?? "Unnamed Person"}
                      setCancel={() => {
                        setEditName(false);
                        setFacePatchNameValue("");
                      }}
                      className="max-w-[200px]"
                      onUpdate={() => updateFacePatchName(selectedFacePatchId)}
                      isProcessing={updateNameLoader}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <Button className="my-4" onClick={onShareResultClicked}>
              {" "}
              Share Result
            </Button>
            <div>
              <Gallery
                {...getGalleryStyle(eventDetails)}
                photos={photos}
                margin={
                  eventDetails.data.design.gridSpacing === "small"
                    ? 3
                    : eventDetails.data.design.gridSpacing === "regular"
                    ? 6
                    : 9
                }
                renderImage={imageRenderer}
              />
            </div>
          </div>
        ) : (
          <div className="min-h-[500px] invisible"></div>
        )}
        {paginationLoader ? (
          <div>
            <Loading height="h-fit" />{" "}
          </div>
        ) : (
          <></>
        )}
        <div id="loaderRef" ref={loaderRef} />
      </div>
      <DialogWrapper
        title="Share Face search result"
        close={() => {
          setShowResultModal(false);
          setShareResultUrl("");
        }}
        open={showResultModal}
      >
        {shareResultUrl ? (
          <Share
            shareUrl={shareResultUrl}
            waDescription="Check%20Out%20Your%20Images"
            shareSearchId
            searchId={shareResultUrl.split("/").reverse()[0]}
            eventId={eventDetails.data.id}
          />
        ) : (
          <Loading height="!h-fit" />
        )}
      </DialogWrapper>
      <DialogWrapper
        title="Share Image"
        close={() => {
          setSharePopUp(false);
        }}
        open={sharePopUp}
      >
        <Share shareUrl={shareUrl} />
      </DialogWrapper>
    </div>
  );
};

export default FacePatch;
