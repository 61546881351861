import React, { useState, useEffect } from "react";

import {
  addFavouriteList,
  renameFavouriteList,
} from "../../../../apis/favourites";
import Button from "../../../../components/common/Button";
import DialogWrapper from "../../../../components/common/Dialog";
import { InputText } from "../../../../components/common/Input";
import { useEvent } from "../../../../Store/event";
import { useUser } from "../../../../Store/user";
import { useFavourites } from "../../../../Store/favourites";
import Loading from "../../../Loading";

const FavoritiesDialog = (props) => {
  const { open, close, rename, oldListName = "", listId = "" } = props;
  let eventDetails = useEvent().response;
  let { user } = useUser();
  const { favList, setFavList } = useFavourites();
  const [folderName, setFolderName] = useState(oldListName);
  const [error, setError] = useState("");
  const [isDisabled, setDisable] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [apiLoader, setApiLoader] = useState(false);

  const handleSubmit = async () => {
    if (!folderName) {
      setError("List name is required");
    } else {
      try {
        setApiLoader(true);
        if (rename) {
          let res = await renameFavouriteList(
            eventDetails.data.id,
            listId,
            folderName,
            user.accessToken
          );
          /** It's a 204 req so it does not return anything */
          if (!res.error) {
            window.Toast.fire({
              icon: "success",
              title: "List renamed successfully.",
            });
            const index = favList.findIndex((f) => f.id === listId);
            const newFav = [...favList];
            newFav[index]["name"] = folderName;
            close();
          } else {
            window.Toast.fire({
              icon: "error",
              title: "Something went wrong",
            });
          }
        } else {
          const corporate =
            eventDetails.data.tags.length > 0
              ? eventDetails.data.tags.some((el) => el.title === "Corporate")
              : false;
          let res = await addFavouriteList(
            eventDetails.data.id,
            folderName,
            user.accessToken,
            corporate
          );
          /* Add fav response
          {
            "id": 162387,
            "name": "dss",
            "isDefault": false,
            "maxFavouritesAllowed": null,
            "count": 0,
            "coverImage": "https://picsniff.s3.ap-south-1.amazonaws.com/assets/images/banners/dummy.jpg"
          }
        */
          if (!res.error) {
            // console.log(res)
            window.Toast.fire({
              icon: "success",
              title: folderName + " created successfully.",
            });
            setFolderName("");
            setFavList([...res.response.data]);
            close();
          } else {
            window.Toast.fire({
              icon: "error",
              title: res.message
            });
            setError(res.message)
            setApiLoader(false)
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setApiLoader(false);
      }
    }
  };

  useEffect(() => {
    setError("");
    setDisable(true);
    if (folderName) {
      setDisable(false);
    }
  }, [folderName]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <DialogWrapper
      title={rename ? "Rename List" : "New folder"}
      description={"Create new folder in your favourites"}
      closeClassname="self-baseline"
      open={open}
      close={apiLoader ? () => {} : close}
    >
      <div>
        {/* <div className="text-red-500">{error}</div> */}
        <InputText
          autoFocus
          className=""
          label={rename ? "Enter folder name" : "Enter folder name"}
          required
          error={error}
          onChange={setFolderName}
          value={folderName}
          lableClassName={"pl-4 text-xs"}
          onKeyDown={handleKeyDown}
        />
        {/* <div className="flex pt-6">
                    <Text
                        as='label'
                        className='text-base text-dark-gray1 dark:text-light-gray mr-4'
                    >
                        Set as default favourites folder
                    </Text>
                    <Switch/>
                </div> */}
        <div
          className={`flex justify-center pt-6 ${
            isMobile ? "mb-[47px] mt-[33px]" : ""
          }`}
        >
          {apiLoader ? (
            <Loading height="h-auto" hideText />
          ) : (
            <Button
              disabled={isDisabled}
              className="w-44"
              onClick={handleSubmit}
              text="Save"
            />
          )}
        </div>
      </div>
    </DialogWrapper>
  );
};

export default FavoritiesDialog;
