import React from "react";
import "./existingFolderMobile.css";

export const CreateNewFolder = () => {
    return (
        <div className='existing-folder-mobile cursor-pointer text-sm dark:text-light-gray'>
            <div className='folder-thumbnail-mobile existing-folder-box'></div>
            <div className='mt-[10px] text-center'>My Favourites</div>
        </div>
    );
};

export default CreateNewFolder;
