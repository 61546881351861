import React, { useEffect, useState } from "react";
import { useContext } from "react";
import ConversationIcon from "../../../assets/icons/ConversationIcon";

import { REDIRECT_ON_CART_USER } from "../../../constants";
import Loading from "../../../pages/Loading";
import { useCart } from "../../../Store/cart";
import { useEvent } from "../../../Store/event";
import { useUploader } from "../../../Store/upload";
import { useUser } from "../../../Store/user";
import { ThemeContext } from "../../App";
import BottomUploadModal from "../BottomUploadModal";
import Button from "../Button";
import Router from "../Router";

function Main() {
  const { isUploading, showUploader } = useUploader();
  const { user, requestOnGoing } = useUser();
  const themeMode = useContext(ThemeContext);
  const { cartApiCalled } = useCart();
  const eventDetails = useEvent().response;

  const [showLoader, setShowLoader] = useState(
    REDIRECT_ON_CART_USER.includes(eventDetails.photographer.id) ||
      (window.location.pathname.includes("add-to-cart") && !cartApiCalled)
  );

  const sponsorWindow = eventDetails.data.sponsorWindow;

  const [showSponsorWindow, setShowSponsorWindow] = useState(false);

  const alertUser = (e) => {
    if (isUploading) {
      e.preventDefault();
      e.returnValue = "";
    } else {
      return true;
    }
  };

  const onScroll = () => {
    if (
      window.scrollY > window.innerHeight &&
      eventDetails.data.sponsorWindow &&
      Object.keys(eventDetails.data.sponsorWindow).length
    ) {
      setShowSponsorWindow(true);
      window.removeEventListener("scroll", onScroll);
    }
    if (
      !(
        eventDetails.data.sponsorWindow &&
        Object.keys(eventDetails.data.sponsorWindow).length
      )
    ) {
      window.removeEventListener("scroll", onScroll);
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [isUploading]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (
      REDIRECT_ON_CART_USER.includes(eventDetails.photographer.id) ||
      (window.location.pathname.includes("add-to-cart") && !cartApiCalled)
    ) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const accessToken = params.get("at");
      if (accessToken) setShowLoader(true);
      else setShowLoader(false);
    } else setShowLoader(false);
  }, []);

  useEffect(() => {
    if (user.email && cartApiCalled) setShowLoader(false);
  }, [user, cartApiCalled]);

  return showLoader ? (
    <Loading className={themeMode} />
  ) : (
    <div>
      {requestOnGoing ? <Loading className={themeMode} /> : <Router />}
      {showUploader ? <BottomUploadModal /> : <></>}

      {eventDetails.data &&
      eventDetails.data.sponsorWindow &&
      Object.keys(eventDetails.data.sponsorWindow).length &&
      (eventDetails.data.sponsorWindow.title ||
        eventDetails.data.sponsorWindow.url ||
        eventDetails.data.sponsorWindow.header) ? (
        <div className="fixed bottom-4 right-4 z-[10000]">
          <span
            className="cursor-pointer relative"
            onClick={() => setShowSponsorWindow((prev) => !prev)}
          >
            <ConversationIcon
              color={themeMode === "dark" ? "white" : "black"}
              className="w-8 h-8 md:w-12 md:h-12"
            />
            {showSponsorWindow ? (
              <div className="bg-white right-full bottom-full absolute dark:bg-dark-gray dark:text-white w-[250px] max-h-[250px] md:w-[300px] md:max-h-[300px] rounded-xl text-black ">
                {sponsorWindow ? (
                  <h2 className="text-xl truncate md:text-2xl   pl-4 pt-4 pb-2 mb-4 border-b border-b-[1px] shadow-xl">
                    {sponsorWindow.header}
                  </h2>
                ) : (
                  <></>
                )}
                {sponsorWindow.url ? (
                  <img
                    className="max-h-[150px] md:max-h-[200px] mb-4 object-contain mx-auto"
                    src={"https://i2.pixnif.com/" + sponsorWindow.url}
                    alt="sponsorWindowUrl"
                  />
                ) : (
                  <>
                    {sponsorWindow.title ? (
                      <h3 className="text-lg truncate md:text-xl max-h-[44px]  text-ellipsis w-full overflow-hidden pl-4 pb-4  ">
                        {sponsorWindow.title}
                      </h3>
                    ) : (
                      <></>
                    )}
                    {sponsorWindow.description ? (
                      <p className="pl-4 pr-4 overflow-y-auto scrollbar-sponsor  h-[100px] md:h-[150px] break-words mb-4">
                        {sponsorWindow.description}
                      </p>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {sponsorWindow.cta ? (
                  <a href={sponsorWindow.link} target="_blank">
                    <Button
                      className="!w-full !h-[3rem] truncate !rounded-none !rounded-b-xl overflow-hidden text-ellipsis "
                      text={sponsorWindow.cta}
                    />
                  </a>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Main;
