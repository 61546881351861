import React from "react";

const MaximizeIcon = props => {
    const { height = "16", width = "16", fill = "#EEE" } = props;
    return (
    <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1.143C0 .512.512 0 1.143 0h4.571a1.143 1.143 0 1 1 0 2.286H3.902l2.62 2.62a1.143 1.143 0 0 1-1.616 1.616l-2.62-2.62v1.812a1.143 1.143 0 0 1-2.286 0V1.143zm10.286 1.143a1.143 1.143 0 1 1 0-2.286h4.571C15.488 0 16 .512 16 1.143v4.571a1.143 1.143 0 0 1-2.286 0V3.902l-2.62 2.62a1.143 1.143 0 1 1-1.616-1.616l2.62-2.62h-1.812zM0 10.286a1.143 1.143 0 1 1 2.286 0v1.812l2.62-2.62a1.143 1.143 0 0 1 1.616 1.616l-2.62 2.62h1.812a1.143 1.143 0 0 1 0 2.286H1.143A1.143 1.143 0 0 1 0 14.857v-4.571zm14.857-1.143c.631 0 1.143.512 1.143 1.143v4.571c0 .631-.512 1.143-1.143 1.143h-4.571a1.143 1.143 0 1 1 0-2.286h1.812l-2.62-2.62a1.143 1.143 0 0 1 1.616-1.616l2.62 2.62v-1.812c0-.631.512-1.143 1.143-1.143z" fill={fill} fillRule="nonzero"/>
    </svg>

    );
};

export default MaximizeIcon;
