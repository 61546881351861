const PARAGRAPH_CLASSES = "my-4 leading-[18px]";

const Terms = () => (
  <div
    style={{
      backgroundColor: "#222",
      color: "#EEE",
      fontFamily: "Times new roman",
    }}
    className="dark:text-white min-h-screen"
  >
    <div className="p-2 ">
      <div>
        <h1 className=" text-[32px]  font-bold my-5">Terms and conditions</h1>
        <p className={PARAGRAPH_CLASSES}>
          Picsniff Media Pvt. Ltd. (“Algomage”) is not affiliated with
          BookMyShow Live Private Limited (“BookMyShow”) and/or any of its group
          companies, and by proceeding ahead, you understand and acknowledge
          that you shall leave BookMyShow website and enter an independent third
          party operated   website/mobile application operating under the brand
          name “Algomage” (“Algomage Website”). You understand further that
          Algomage may collect certain personally identifiable information
          (“personal information”) about you when you visit and/or interact with
          Algomage Website.  Examples of personal information Algomage may
          collect from you includes your name, contact number, photograph/image,
          biometric data and/or e-mail address.
        </p>
        <p className={PARAGRAPH_CLASSES}>
          BookMyShow does not not in any manner control/interfere or have any
          authority over the availability, content and services of Algomage
          including the use of your personal information by Algomage. Any
          concerns regarding Algomage’s service/resource and/or use of your
          personal information, or any queries thereto, should be directed to
          Algomage at hello@algomage.com
        </p>
        <p className={PARAGRAPH_CLASSES}>
          BookMyShow is not liable in any manner for the Algomage’s services,
          the collection, storage, processing and use by Algomage of your
          personal information by it and/or any other third party platform that
          it may link or re-direct to. To this extent, BookMyShow neither
          warrants nor makes any representations regarding quality, accuracy or
          completeness of any data or information contained therein. BookMyShow
          makes no warranty, express or implied, concerning the Algomage Website
          and/or its contents and disclaims all warranties of fitness for a
          particular purpose and warranties of merchantability in respect of
          Algomage’s services, including any liability, responsibility or any
          other claim, whatsoever, in respect of any loss, whether direct or
          consequential, to any user or any other person, arising out of or from
          the use of the Algomage Website.
        </p>
      </div>
    </div>
  </div>
);

export default Terms;
