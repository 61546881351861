import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Accept: "application/json",
  },
});

const sendResponse = (result) => {
  if (result.status === 200 || result.status === 204 || result.status === 201) {
    return { error: false, response: result.data };
  } else {
    return {
      error: true,
      message: result.message
        ? result.message
        : "Couldnot Complete Your Request",
      e: result.error ? result.error : "Something Went Wrong",
    };
  }
};


/** Set auth token for all requets */
// http.interceptors.request.use(function (config) {
//   const token = localStorage.getItem("access_token");
//   config.headers.Authorization = `Bearer ${token}`;
//   return config;
// });

const getCountryCode = () => axios.get("https://country.pixnif.com/");
/** Response of the API */
// {
//     "country": {
//       "name":"India",
//       "code":"IN"
//     }
//   }

export { http, sendResponse , getCountryCode };
