import React from "react";
import ReloadIcon from "../../assets/icons/ReloadIcon";

import Error from "../Errors";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex justify-center flex-col relative">
          <Error
            message={"Something has gone wrong"}
            text={
              "Sorry for the inconvenience. Please try again after some time"
            }
          />
          <div onClick={()=>window.location.reload(true)} className="top-2/3 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute cursor-pointer">
          <ReloadIcon />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
