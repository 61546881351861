import React from "react";
import "./staticLogo.css";
import Logo from "../Logo";

const StaticLogo = () => {
    return (
        <div className='bg-theme-light dark:bg-dark-gray rectangle-copy  px-[18px] md:px-10'>
            <Logo staticLogo cartDownArrow/>
        </div>
    );
};

export default StaticLogo;
