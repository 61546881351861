const InputWithSuffix = (props) => {
  const {
    name = (Math.random() + 1).toString(36).substring(7),
    className = "",
    label = "",
    required = false,
    type = "",
    onChange,
    value = "",
    error = "",
    placeholder = "",
    onUpdate,
    readOnly = false,
    actionText,
    id,
    showCancelButton = false,
    setCancel,
    labelClassname = "",
    isProcessing,
  } = props;

  const showIsRequired = required ? "*" : "";

  return (
    <div className={`flex flex-col max-w-[28rem] ${className}`}>
      {label && (
        <div className="grid grid-cols-2">
          <label
            htmlFor={id ?? label}
            className={`md:text-sm text-left font-medium text-black-gray dark:text-light-gray ${labelClassname}`}
          >{`${label}${showIsRequired}`}</label>
        </div>
      )}

      <div className="flex justify-center items-center  shadow-inner-all dark:shadow-dark-inner-all rounded-sm ">
        <input
          value={value}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          name={name}
          readOnly={readOnly}
          className={`w-[130px] md:w-[130px] text-sm bg-transparent pl-4 pr-2 py-2 input-shadow-fix 
           ${error ? "outline-1 border-2 border-[#e16a6a]" : "outline-none"} 
            text-dark-gray1 dark:text-light-gray`}
          onChange={(event) => onChange(event)}
          onKeyDown={(event) => {
            if (event.key === "Enter" && value) {
              onUpdate(value);
            }
          }}
        />

        {isProcessing && !error ? (
          <div className="clipPathLoader mx-auto"></div>
        ) : null}

        {showCancelButton && !isProcessing ? (
          <svg
            onClick={(event) => {
              event.stopPropagation()
              if (value) {
                onUpdate(value);
              } else setCancel(true);
            }}
            className="h-4 w-4 ml-1 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m4.5 12.75 6 6 9-13.5"
            />
          </svg>
        ) : null}
      </div>
    </div>
  );
};

export default InputWithSuffix;
