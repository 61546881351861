import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './slider.scss';

function SliderComponent(props) {
    const   {defaultValue, marks, onChange, min, max,step} = props
    return (
        <Slider 
            onChange={(e) => onChange(e)}
            value={defaultValue}
            // marks={marks}
            min={min}
            max={max}
            step={step}
        />
    );
}

export default SliderComponent;
