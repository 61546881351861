import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import FrameBlack from "../../../assets/images/Frame_Black.png";
import FrameBlue from "../../../assets/images/Frame_Blue.png";
import FramePurple from "../../../assets/images/Frame_Purple.png";
import FrameRed from "../../../assets/images/Frame_Red.png";
import FrameYellow from "../../../assets/images/Frame_Yellow.png";
import {
    EVENT_WHERE_DROPBOX_NEEDED,
    EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
    FRAME_DIGITAL,
    FRAME_TYPE_JIO_EVENT,
    FRAME_TYPE_JIO_PHYSICAL_COPY,
    INDIAN_CURRENCY,
    JIO_PRINT_ONLY,
    JIO_PRINT_WITH_FRAME
} from "../../../constants";
import { Colors } from "../../../constants/Color";
import { useCart } from "../../../Store/cart";
import { useEvent } from "../../../Store/event";
import { ThemeContext } from "../../App";
import BackButton from "../Backbutton";
import ProductDetailsModal from "../ProductDetailsModal";
import CartImg from "./CartImg";

var stylingObject = {
  outerDivDigital: { width: "100%", paddingTop: "100%" },
  innerImgDigital: {
    position: "absolute",
    userSelect: "none",
    opacity: 1,
    width: "100%",
    height: "100%",
    top: "0%",
    left: "0%",
    overflow: "hidden",
  },
};

const MILLER_SOHO_FRAME = "SoHo Black Frame";

const SOHO_FRAME = "Soho Frame";

const FRAME_TYPE_FOR_BE = {
  soho: SOHO_FRAME,
};

const MILLER_FRAMES = ["minis", "wallets", "print"];

const MILLER_NON_FRAME_PRODUCTS = ["minis", "wallets", "print"];

const CartItem = ({
  id,
  frameUrl,
  frameStyle,
  quantity,
  imageSize,
  color,
  framePrice,
  images,
  remove,
  increment,
  decrement,
  croppedParameters,
  isMobile,
  frameType,
  addOn,
}) => {
  const theme = useContext(ThemeContext);
  const iconsColor = theme === "dark" ? "white" : "#222";
  const [qty, setQty] = useState(quantity);
  const [colorImg, setColorImg] = useState();
  const [cartPopUp, setCartPopUp] = useState(false);

  const { cartList } = useCart();

  const eventDetails = useEvent().response;

  let imageUrl = croppedParameters
    ? images.src + "?w=1600&cr=" + croppedParameters
    : images.src + "?w=1600";

  const incrementQty = () => {
    var cartIds = [];
    {
      cartList.map(({ id }) => {
        cartIds.push(id);
      });
    }
    setQty(qty + 1);
    increment({ id, cartIds, q: qty + 1 });
  };

  const decrementQty = () => {
    if (qty > 1) {
      setQty(qty - 1);
    } else {
      setQty(1);
      return;
    }
    var cartIds = [];
    {
      cartList.map(({ id }) => {
        cartIds.push(id);
      });
    }
    decrement({ id, cartIds, q: qty - 1 });
  };

  useEffect(() => {
    // console.log(cartList)
    switch (color) {
      case "Brown":
        setColorImg(FrameBlack);
        break;
      case "Green":
        setColorImg(FrameYellow);
        break;
      case "Golden":
        setColorImg(FrameRed);
        break;
      case "White":
        setColorImg(FramePurple);
        break;
      case "Black":
        setColorImg(FrameBlue);
        break;
      case "Tan":
        setColorImg(FrameBlack);
        break;
      case "Walnut":
        setColorImg(FrameBlack);
        break;
      case "Honey":
        setColorImg(FrameBlack);
        break;
      case "Tortoise":
        setColorImg(FrameBlack);
        break;
      case "Beige":
        setColorImg(FrameBlack);
        break;
      case "Gold":
        setColorImg(FrameBlack);
        break;
    }
  }, [cartList]);

  // console.log(frameStyle)
  useEffect(() => {
    let paddingTop;
    let varwidth;
    let varheight;
    let vartop;
    let varleft;
    let width = images.dimensions.width;
    let height = images.dimensions.height;
    switch (imageSize) {
      case "6x4":
        stylingObject.jioEventVertical = { width: "100%", paddingTop: "100%" };
        stylingObject.jioEventVerticalInnerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: "70%",
          height: "61%",
          top: "49%",
          left: "50%",
          overflow: "hidden",
          transform: "translate(-50%, -50%)",
        };
      case "8x6":
        stylingObject.jioEventHorizontal = {
          width: "100%",
          paddingTop: "100%",
        };
        stylingObject.jioEventHorizontalInnerImg = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: "70%",
          height: "50%",
          top: "42%",
          left: "50%",
          overflow: "hidden",
          transform: "translate(-50%, -50%)",
        };
        break;
      case "8x8":
        stylingObject.outerDiv1 = { width: "100%", paddingTop: "100%" };
        stylingObject.innerImg1 = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: frameType === FRAME_DIGITAL ? "100%" : "61.4812%",
          height: frameType === FRAME_DIGITAL ? "100%" : "61.4812%",
          top: frameType === FRAME_DIGITAL ? "0%" : "19.2425%",
          left: frameType === FRAME_DIGITAL ? "0%" : "19.2425%",
          overflow: "hidden",
        };
        break;
      case "8x10":
        if (frameStyle == "Classic") {
          paddingTop = "120%";
          varwidth = "80%";
          varheight = "83.3333%";
          vartop = "8.33333%";
          varleft = "10%";
          stylingObject.outerDiv16 = { width: "100%", paddingTop: paddingTop };
          stylingObject.innerImg16 = {
            position: "absolute",
            userSelect: "none",
            opacity: 1,
            width: varwidth,
            height: varheight,
            top: vartop,
            left: varleft,
            overflow: "hidden",
          };
          if (width > height) {
            paddingTop = "83.3333%";
            varwidth = "83.3333%";
            varheight = "80%";
            vartop = "10%";
            varleft = "8.33333%";
            stylingObject.outerDiv17 = {
              width: "100%",
              paddingTop: paddingTop,
            };
            stylingObject.innerImg17 = {
              position: "absolute",
              userSelect: "none",
              opacity: 1,
              width: varwidth,
              height: varheight,
              top: vartop,
              left: varleft,
              overflow: "hidden",
            };
          }
        } else {
          paddingTop = "115.049%";
          varwidth = "61.6505%";
          varheight = "66.9831%";
          vartop = "16.5084%";
          varleft = "19.1748%";
          stylingObject.outerDiv2 = { width: "100%", paddingTop: paddingTop };
          stylingObject.innerImg2 = {
            position: "absolute",
            userSelect: "none",
            opacity: 1,
            width: varwidth,
            height: varheight,
            top: vartop,
            left: varleft,
            overflow: "hidden",
          };
          if (width > height) {
            paddingTop = "86.9198%";
            varwidth = "66.9831%";
            varheight = "61.6505%";
            vartop = "19.1748%";
            varleft = "16.5084%";
            stylingObject.outerDiv3 = { width: "100%", paddingTop: paddingTop };
            stylingObject.innerImg3 = {
              position: "absolute",
              userSelect: "none",
              opacity: 1,
              width: varwidth,
              height: varheight,
              top: vartop,
              left: varleft,
              overflow: "hidden",
            };
          }
        }
        break;
      case "12x15":
        paddingTop = "118.435%";
        varwidth = "69.7514%";
        varheight = "74.7298%";
        vartop = "12.6143%";
        varleft = "15.112%";
        stylingObject.outerDiv4 = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg4 = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
          overflow: "hidden",
        };
        if (width > height) {
          paddingTop = "83.435%";
          varwidth = "74.7298%";
          varheight = "69.7514%";
          vartop = "15.112%";
          varleft = "12.6143%";
          stylingObject.outerDiv5 = { width: "100%", paddingTop: paddingTop };
          stylingObject.innerImg5 = {
            position: "absolute",
            userSelect: "none",
            opacity: 1,
            width: varwidth,
            height: varheight,
            top: vartop,
            left: varleft,
            overflow: "hidden",
          };
        }

        break;
      case "12x18":
        paddingTop = "138.675%";
        varwidth = "71.4034%";
        varheight = "79.1788%";
        vartop = "10.4106%";
        varleft = "14.2857%";
        stylingObject.outerDiv6 = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg6 = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
          overflow: "hidden",
        };
        if (width > height) {
          paddingTop = "72.1112%";
          varwidth = "79.1788%";
          varheight = "71.4034%";
          vartop = "14.2857%";
          varleft = "10.4106%";
          stylingObject.outerDiv7 = { width: "100%", paddingTop: paddingTop };
          stylingObject.innerImg7 = {
            position: "absolute",
            userSelect: "none",
            opacity: 1,
            width: varwidth,
            height: varheight,
            top: vartop,
            left: varleft,
            overflow: "hidden",
          };
        }

        break;
      case "16x20":
        paddingTop = "120.55%";
        varwidth = "77.2627%";
        varheight = "81.0055%";
        vartop = "9.48893%";
        varleft = "11.3586%";
        stylingObject.outerDiv8 = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg8 = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
          overflow: "hidden",
        };
        if (width > height) {
          paddingTop = "82.9532%";
          varwidth = "81.0055%";
          varheight = "77.2627%";
          vartop = "11.3586%";
          varleft = "9.48893%";
          stylingObject.outerDiv9 = { width: "100%", paddingTop: paddingTop };
          stylingObject.innerImg9 = {
            position: "absolute",
            userSelect: "none",
            opacity: 1,
            width: varwidth,
            height: varheight,
            top: vartop,
            left: varleft,
            overflow: "hidden",
          };
        }
        break;
      case "4x4":
        stylingObject.outerDiv10 = { width: "100%", paddingTop: "100%" };
        stylingObject.innerImg10 = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: "66.6667%",
          height: "66.6667%",
          top: "16.6667%",
          left: "16.6667%",
        };
        break;
      case "4x6":
        paddingTop = "133.333%";
        varwidth = "66.6667%";
        varheight = "75%";
        vartop = "12.5%";
        varleft = "16.6667%";
        stylingObject.outerDiv11 = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg11 = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
        };
        if (width > height) {
          paddingTop = "75%";
          varwidth = "75%";
          varheight = "66.6667%";
          vartop = "16.6667%";
          varleft = "12.5%";
          stylingObject.outerDiv12 = { width: "100%", paddingTop: paddingTop };
          stylingObject.innerImg12 = {
            position: "absolute",
            userSelect: "none",
            opacity: 1,
            width: varwidth,
            height: varheight,
            top: vartop,
            left: varleft,
          };
        }
        break;
      case "5.5x5.5":
        stylingObject.outerDiv13 = { width: "100%", paddingTop: "100%" };
        stylingObject.innerImg13 = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: "73.3333%",
          height: "73.3333%",
          top: "13.3333%",
          left: "13.3333%",
        };
        break;
      case "5x7":
        paddingTop = "128.571%";
        varwidth = "71.4286%";
        varheight = "77.7778%";
        vartop = "11.1111%";
        varleft = "14.2857%";
        stylingObject.outerDiv14 = { width: "100%", paddingTop: paddingTop };
        stylingObject.innerImg14 = {
          position: "absolute",
          userSelect: "none",
          opacity: 1,
          width: varwidth,
          height: varheight,
          top: vartop,
          left: varleft,
        };
        if (width > height) {
          paddingTop = "77.7778%";
          varwidth = "77.7778%";
          varheight = "71.4286%";
          vartop = "14.2857%";
          varleft = "11.1111%";
          stylingObject.outerDiv15 = { width: "100%", paddingTop: paddingTop };
          stylingObject.innerImg15 = {
            position: "absolute",
            userSelect: "none",
            opacity: 1,
            width: varwidth,
            height: varheight,
            top: vartop,
            left: varleft,
          };
        }
        break;
      default:
    }
  });

  const getBorderImage = () =>
    imageSize === "6x8"
      ? `url(${frameUrl})  6% round`
      : `url(${frameUrl}) 8% round`;
  return (
    <div className={`${isMobile ? "bg-[#eee] p-4 my-2 rounded-sm" : ""}`}>
      {!isMobile ? (
        <>
          <li className="flex justify-between border-b border-stone-300 dark:border-stone-700 mr-16 mb-8">
            <div className="grid grid-rows-4 grid-flow-col mb-8 md:grid-cols-[200px]">
              {/* <div className='row-span-4 my-auto mr-8 cursor-pointer'><img src={frameUrl} width="180" /></div> */}
              {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                eventDetails.data.id
              ) &&
              [FRAME_TYPE_JIO_EVENT, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(
                frameType
              ) ? (
                <div
                  className="row-span-4 my-auto mr-8 max-h-[220px] md:mt-4  md:w-fit"
                  style={
                    frameType === FRAME_TYPE_JIO_EVENT
                      ? {
                          borderWidth: "20px",
                          borderImage: getBorderImage(),
                        }
                      : {}
                  }
                >
                  <img className="max-h-[180px]" src={`${imageUrl}`} />
                </div>
              ) : (
                <div
                  className={`${
                    MILLER_NON_FRAME_PRODUCTS.includes(frameStyle)
                      ? "md:w-[180px]"
                      : ""
                  } row-span-4 my-auto mr-8 relative w-full table bg-[length:100%_100%] bg-no-repeat`}
                >
                  {frameStyle === "soho" ? (
                    <div
                      className={` lg:pl-0 flex justify-between relative pr-5 ${
                        !(images.dimensions.width > images.dimensions.height) &&
                        ["5x30", "10x30"].includes(imageSize)
                          ? "w-[150px]"
                          : ""
                      }`}
                      style={{
                        aspectRatio:
                          images.dimensions.height < images.dimensions.width
                            ? imageSize.split("x")[1] * 2 +
                              " / " +
                              imageSize.split("x")[0] * 2
                            : imageSize.split("x")[0] * 2 +
                              " / " +
                              imageSize.split("x")[1] * 2,
                      }}
                    >
                      <div
                        className={
                          frameStyle === "soho"
                            ? "imageCont w-full relative"
                            : ""
                        }
                        id="viewPage"
                      >
                        <img
                          className="absolute top-0 w-[40px]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/corner-tl.png`}
                          alt="topLeftCorner"
                        />
                        <img
                          className="absolute top-0  left-[25px] w-[calc(100%-50px)] h-[20px]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/verticalStrip.jpg`}
                          alt="horizontalStripTop"
                        />
                        <img
                          className="absolute top-0 right-0 w-[40px]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/corner-tl.png`}
                          style={{ transform: "rotate(90deg)" }}
                          alt="topRightCorner"
                        />
                        <img
                          className="absolute left-0 top-[24px] w-[20px] h-[calc(100%-48px)]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/verticalStrip.jpg`}
                          alt="verticalStripLeft"
                        />
                        <img
                          className="absolute right-0 top-[24px] w-[20px] h-[calc(100%-48px)]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/verticalStrip.jpg`}
                          alt="verticalStripRight"
                        />
                        <img
                          className="absolute w-[40px] bottom-0"
                          style={{ transform: "rotate(270deg)" }}
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/corner-tl.png`}
                          alt="bottomLeftCorner"
                        />
                        <img
                          className="absolute left-[25px] bottom-0 w-[calc(100%-50px)] h-[20px]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/verticalStrip.jpg`}
                          alt="horizontalStripBottom"
                        />
                        <img
                          className="absolute w-[40px] bottom-0 right-0"
                          style={{ transform: "rotate(180deg)" }}
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/corner-tl.png`}
                          alt="bottomRightCorner"
                        />

                        <div
                          className={`img-layout-tooltip absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  ${
                            addOn.includes("mat")
                              ? "h-[calc(100%-39px)] w-[calc(100%-39px)] flex items-center justify-center"
                              : "h-[calc(100%-39px)] w-[calc(100%-39px)]"
                          } ${
                            addOn?.includes("White mat")
                              ? "bg-white"
                              : "bg-black"
                          }  `}
                        >
                          <img
                            className={`cursor-pointer  object-cover block z-[0] ${
                              addOn.includes("mat")
                                ? "w-[calc(100%-40px)] h-[calc(100%-40px)]"
                                : "w-full h-full"
                            } `}
                            src={imageUrl}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <CartImg
                      frameStyle={frameStyle}
                      frameUrl={frameUrl}
                      imageUrl={imageUrl}
                      imageSize={imageSize}
                      images={images}
                      stylingObject={stylingObject}
                      frameType={frameType}
                    />
                  )}
                </div>
              )}
              <p className="col-span-2 capitalize text-sm md:text-2xl mt-4 font-medium ml-4">
                {FRAME_TYPE_FOR_BE[frameStyle] ?? frameStyle}
              </p>

              <div className="col-span-2 text-base dark:text-stone-400 mt-2 font-medium ml-4">
                Size{" "}
                {imageSize === "minis"
                  ? "1.75 x 2.5"
                  : imageSize === "wallets"
                  ? "2.5 x 3.5"
                  : imageSize}{" "}
                in
              </div>

              {(EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                eventDetails.data.id
              ) &&
                [FRAME_TYPE_JIO_EVENT, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(
                  frameType
                )) ||
              MILLER_NON_FRAME_PRODUCTS.includes(frameStyle) ? (
                <></>
              ) : (
                <>
                  <div className={`${FRAME_TYPE_FOR_BE[frameType] === SOHO_FRAME ? "col-span-1" : "col-span-2"} -mt-4 ml-4`}>
                    <div className="flex items-center">
                      <div className="text-base dark:text-stone-400 leading-8 font-medium">
                        Color
                      </div>
                      <span className="mt-2 ml-[10px]">
                        <span
                          className="dot shadow-dark-input-bottom-right dark:shadow-dark-input"
                          style={{
                            backgroundColor: Colors[color],
                            height: "20px",
                            width: "20px",
                          }}
                          title={color}
                        ></span>
                      </span>
                    </div>
                    {addOn && FRAME_TYPE_FOR_BE[frameStyle] === SOHO_FRAME ? (
                      <div className="flex items-center gap-4">
                        <div className="text-base dark:text-stone-400 leading-8 font-medium">
                          Addons
                        </div>
                        <div className="mt-1 text-xs font-bold  capitalize">
                          {addOn.split(",").map((el, id) => (
                            <span key={el}>{id !== 0 ? ", " + el : el}</span>
                          ))}
                          <button
                            onClick={() => setCartPopUp(true)}
                            className="text-primary-green text-xs underline ml-4"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
              {/* <div className='col-span-2 -mt-4'>
                                <div><Button className='w-36 mr-6' text='Edit' /><span><BackButton onClick={e=>{remove({id,qty})}} className='w-36' text='Remove' /></span></div>
                            </div> */}
              <div
                className={`col-span-2 ml-4 ${
                  [
                    FRAME_DIGITAL,
                    FRAME_TYPE_JIO_EVENT,
                    FRAME_TYPE_JIO_PHYSICAL_COPY,
                  ].includes(frameType) || MILLER_FRAMES.includes(frameStyle)
                    ? "row-start-3"
                    : SOHO_FRAME === FRAME_TYPE_FOR_BE[frameStyle]  ? "mt-4" :"-mt-4"
                }`}
              >
                <div>
                  <BackButton
                    onClick={(e) => {
                      remove({ id, qty });
                    }}
                    className="w-36"
                    text="Remove"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-rows-4 grid-flow-col text-right">
              <div className="text-2xl mt-3 font-medium">
                {eventDetails.photographer.printStoreCurrency !==
                INDIAN_CURRENCY
                  ? "$"
                  : "Rs."}
                {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                  eventDetails.data.id
                ) &&
                [FRAME_TYPE_JIO_EVENT, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(
                  frameType
                )
                  ? frameType === FRAME_TYPE_JIO_PHYSICAL_COPY
                    ? JIO_PRINT_ONLY
                    : JIO_PRINT_WITH_FRAME
                  : framePrice}
              </div>
              {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                eventDetails.data.id
              ) &&
              [FRAME_TYPE_JIO_EVENT, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(
                frameType
              ) ? (
                <></>
              ) : (
                <>
                  <div className="text-base dark:text-stone-400 font-medium">
                    Quantity
                  </div>
                  <div className="flex  shadow-dark-input-bottom-right dark:shadow-dark-input rounded-[13px] justify-center items-center h-8 -mt-4">
                    <div
                      className={`${qty > 1 ? "cursor-pointer" : ""}`}
                      onClick={() => {
                        decrementQty();
                      }}
                    >
                      -
                    </div>
                    <div className="mx-2 text-xs">{qty}</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        incrementQty();
                      }}
                    >
                      +
                    </div>
                  </div>
                </>
              )}
            </div>
          </li>
        </>
      ) : (
        <>
          <li className={`flex`}>
            <div className="w-2/5">
              {/* <div><img src={frameUrl} /></div> */}
              {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                eventDetails.data.id
              ) &&
              [FRAME_TYPE_JIO_EVENT, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(
                frameType
              ) ? (
                <div
                  style={
                    frameType === FRAME_TYPE_JIO_EVENT
                      ? {
                          borderWidth: "10px",
                          borderImage: getBorderImage(),
                        }
                      : {}
                  }
                >
                  <img src={imageUrl} />
                </div>
              ) : (
                <div className="relative table bg-[length:100%_100%] w-[100%] bg-no-repeat">
                  {frameStyle === "soho" ? (
                    <div
                      className={` lg:pl-0 flex justify-between relative ${
                        !(images.dimensions.width > images.dimensions.height) &&
                        ["5x30", "10x30"].includes(imageSize)
                          ? "w-[150px]"
                          : ""
                      }`}
                      style={{
                        aspectRatio:
                          images.dimensions.height < images.dimensions.width
                            ? imageSize.split("x")[1] * 2 +
                              " / " +
                              imageSize.split("x")[0] * 2
                            : imageSize.split("x")[0] * 2 +
                              " / " +
                              imageSize.split("x")[1] * 2,
                      }}
                    >
                      <div
                        className={
                          frameStyle === "soho"
                            ? "imageCont w-full relative"
                            : ""
                        }
                        id="viewPage"
                      >
                        <img
                          className="absolute top-0 w-[40px]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/corner-tl.png`}
                          alt="topLeftCorner"
                        />
                        <img
                          className="absolute top-0  left-[25px] w-[calc(100%-50px)] h-[20px]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/verticalStrip.jpg`}
                          alt="horizontalStripTop"
                        />
                        <img
                          className="absolute top-0 right-0 w-[40px]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/corner-tl.png`}
                          style={{ transform: "rotate(90deg)" }}
                          alt="topRightCorner"
                        />
                        <img
                          className="absolute left-0 top-[24px] w-[20px] h-[calc(100%-48px)]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/verticalStrip.jpg`}
                          alt="verticalStripLeft"
                        />
                        <img
                          className="absolute right-0 top-[24px] w-[20px] h-[calc(100%-48px)]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/verticalStrip.jpg`}
                          alt="verticalStripRight"
                        />
                        <img
                          className="absolute w-[40px] bottom-0"
                          style={{ transform: "rotate(270deg)" }}
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/corner-tl.png`}
                          alt="bottomLeftCorner"
                        />
                        <img
                          className="absolute left-[25px] bottom-0 w-[calc(100%-50px)] h-[20px]"
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/verticalStrip.jpg`}
                          alt="horizontalStripBottom"
                        />
                        <img
                          className="absolute w-[40px] bottom-0 right-0"
                          style={{ transform: "rotate(180deg)" }}
                          src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${color}/corner-tl.png`}
                          alt="bottomRightCorner"
                        />

                        <div
                          className={`img-layout-tooltip absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  ${
                            addOn.includes("mat")
                              ? "h-[calc(100%-39px)] w-[calc(100%-39px)] flex items-center justify-center"
                              : "h-[calc(100%-39px)] w-[calc(100%-39px)]"
                          } ${
                            addOn?.includes("White mat")
                              ? "bg-white"
                              : "bg-black"
                          }  `}
                        >
                          <img
                            className={`cursor-pointer  object-cover block z-[0] ${
                              addOn.includes("mat")
                                ? "w-[calc(100%-25px)] h-[calc(100%-25px)] md:w-[calc(100%-75px)] md:h-[calc(100%-75px)]"
                                : "w-full h-full"
                            }  `}
                            src={imageUrl}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <CartImg
                      frameStyle={frameStyle}
                      frameUrl={frameUrl}
                      imageUrl={imageUrl}
                      imageSize={imageSize}
                      images={images}
                      stylingObject={stylingObject}
                      frameType={frameType}
                    />
                  )}
                </div>
              )}
            </div>
            <div className={`w-3/5 ml-6`}>
              <div className="text-sm md:text-2xl capitalize font-medium text-dark-gray1 dark:text-light-gray">
                {FRAME_TYPE_FOR_BE[frameStyle] ?? frameStyle}
              </div>
              <div
                className={
                  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                    eventDetails.data.id
                  ) &&
                  [FRAME_TYPE_JIO_EVENT, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(
                    frameType
                  )
                    ? "flex justify-between"
                    : ""
                }
              >
                <div>
                  <div
                    className={`text-xs md:text-base ${
                      EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                        eventDetails.data.id
                      ) &&
                      [
                        FRAME_TYPE_JIO_EVENT,
                        FRAME_TYPE_JIO_PHYSICAL_COPY,
                      ].includes(frameType)
                        ? "mt-1"
                        : "mt-2"
                    } dark:text-stone-400 text-black-gray`}
                  >
                    Size{" "}
                    {imageSize === "minis"
                      ? "1.75 x 2.5"
                      : imageSize === "wallets"
                      ? "2.5 x 3.5"
                      : imageSize}{" "}
                    in
                  </div>
                  {(EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                    eventDetails.data.id
                  ) &&
                    [
                      FRAME_TYPE_JIO_EVENT,
                      FRAME_TYPE_JIO_PHYSICAL_COPY,
                    ].includes(frameType)) ||
                  MILLER_NON_FRAME_PRODUCTS.includes(frameStyle) ? (
                    <></>
                  ) : (
                    <>
                      <div className="flex items-center gap-4">
                        <div className="text-xs md:text-base dark:text-stone-400 leading-8 text-black-gray">
                          Color
                        </div>
                        <span className="inline-block ml-[10px]">
                          <span
                            className="dot w-3.5 h-3.5 shadow-dark-input-bottom-right dark:shadow-dark-input"
                            style={{ backgroundColor: Colors[color] }}
                            title={color}
                          ></span>
                        </span>
                      </div>
                      {addOn && FRAME_TYPE_FOR_BE[frameStyle] === SOHO_FRAME ? (
                        <div className="flex items-center gap-4">
                          <div className="text-xs md:text-base dark:text-stone-400 leading-8 text-black-gray">
                            Addons
                          </div>
                          <div className="inline-block text-xs font-bold  capitalize">
                            {addOn.split(",").map((el, id) => (
                              <span key={el}>{id !== 0 ? ", " + el : el}</span>
                            ))}
                            <button
                              onClick={() => setCartPopUp(true)}
                              className="text-primary-green text-xs underline ml-4"
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  <div
                    className={`text-sm md:text-base font-medium ${
                      EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                        eventDetails.data.id
                      )
                        ? "mt-1"
                        : "mt-2"
                    } text-dark-gray1 dark:text-light-gray`}
                  >
                    {eventDetails.photographer.printStoreCurrency !==
                    INDIAN_CURRENCY
                      ? "$"
                      : "Rs."}
                    {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                      eventDetails.data.id
                    ) &&
                    [
                      FRAME_TYPE_JIO_EVENT,
                      FRAME_TYPE_JIO_PHYSICAL_COPY,
                    ].includes(frameType)
                      ? frameType === FRAME_TYPE_JIO_PHYSICAL_COPY
                        ? JIO_PRINT_ONLY
                        : JIO_PRINT_WITH_FRAME
                      : framePrice}
                  </div>
                </div>
                {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
                  eventDetails.data.id
                ) &&
                [FRAME_TYPE_JIO_EVENT, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(
                  frameType
                ) ? (
                  <div
                    className="flex h-fit mt-2 shadow-dark-input-bottom-right dark:shadow-dark-input w-fit px-4  py-2 items-center justify-center rounded-[10px]  "
                    onClick={(e) => {
                      remove({ id, qty });
                    }}
                    style={{
                      boxShadow:
                        "0px 0px 10px 0 rgb(0 0 0 / 15%), 0px 0px 10px 0 rgb(255 255 255 / 80%)",
                    }}
                  >
                    <DeleteIcon color={iconsColor} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* <div className='flex mt-6 mb-8'> */}
              {/* <div><Button text="Edit" className='w-24' /></div> */}
              {/* <div className='flex shadow-dark-input-bottom-right dark:shadow-dark-input rounded-lg w-16 ml-3 items-center justify-center rounded-[10px] h-10' onClick={e=>{remove({id,qty})}}>
                                    <DeleteIcon color={iconsColor} />
                                </div> */}
              {/* </div> */}
            </div>
          </li>
          <div className="flex">
            {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
              eventDetails.data.id
            ) &&
            [FRAME_TYPE_JIO_EVENT, FRAME_TYPE_JIO_PHYSICAL_COPY].includes(
              frameType
            ) ? (
              <></>
            ) : (
              <>
                <div className="flex items-center justify-center text-center shadow-dark-input-bottom-right dark:shadow-dark-input p-2 mt-4 text-sm rounded-[13px] w-2/5">
                  <span
                    className={`${
                      qty > 1 ? "cursor-pointer" : ""
                    } text-lg font-bold`}
                    onClick={() => {
                      decrementQty();
                    }}
                  >
                    -
                  </span>
                  <span className="px-4 text-dark-gray1 dark:text-light-gray">
                    {qty}
                  </span>
                  <span
                    className="text-xl font-bold"
                    onClick={() => {
                      incrementQty();
                    }}
                  >
                    +
                  </span>
                </div>
                <div
                  className="flex shadow-dark-input-bottom-right dark:shadow-dark-input rounded-lg w-16 ml-3 items-center justify-center rounded-[10px] h-10 mt-4 "
                  onClick={(e) => {
                    remove({ id, qty });
                  }}
                >
                  <DeleteIcon color={iconsColor} />
                </div>
              </>
            )}
          </div>
        </>
      )}
      {!EVENT_WHERE_DROPBOX_NEEDED.includes(eventDetails.data.id) &&
        cartPopUp && (
          <ProductDetailsModal
            open={cartPopUp}
            close={() => {
              setCartPopUp(false);
            }}
            cartId={id}
            photo={{
              printStoreSrc: images.src,
              originalImageObj: {
                width: images.dimensions.width,
                height: images.dimensions.height,
              },
              key: images.id,
            }}
          />
        )}
    </div>
  );
};

export default CartItem;
