import React from "react";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import CreateNewFolderMobile from "../../../../components/common/CreateNewFolderMobile";
import ExistingFolderMobile from "../../../../components/common/ExistingFolderMobile";

const FavoritesDrawer = ({ close }) => {
    const isDarkMode = true;

    return (
        <div className='container mx-auto p-[24px] bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray'>
            <div className='flex justify-between'>
                <div className='text-2xl leading-6 font-medium text-dark-gray1 dark:text-light-gray'>
                    Favourite image
                </div>
                <span className='text-3xl' onClick={close}>
                    <CloseIcon fill={`${isDarkMode ? "#eee" : "#222"}`} />
                </span>
            </div>
            <div className='leading-loose'>
                You can add favourites to folders
            </div>

            <div className='mt-[30px] grid gap-3 grid-cols-3'>
                <CreateNewFolderMobile />
                <ExistingFolderMobile />
                <ExistingFolderMobile />
            </div>
        </div>
    );
};

export default FavoritesDrawer;
