import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import { useEvent } from "../../../Store/event";

export default function CookiePopUp() {
  const event = useEvent().response;
  const cookieStatus = localStorage.getItem("cookieAcceptance");
  const [cookieState, setCookieState] = useState(cookieStatus);

  const acceptCookies = () => {
    localStorage.setItem("cookieAcceptance", "true");
    setCookieState("true");
    //initialiseGA()
  };
  let declineCookies = () => {
    localStorage.setItem("cookieAcceptance", "false");
    setCookieState("false");
  };

  useEffect(() => {
    if (cookieState === "true") {
      const TRACKING_ID = event.photographer.googleAnalyticsCode
        ? event.photographer.googleAnalyticsCode
        : "";
      if (TRACKING_ID)
        ReactGA.initialize([
          { trackingId: TRACKING_ID },
          { trackingId: "G-R7VZ0BBRF9" },
        ]);
      else ReactGA.initialize([{ trackingId: "G-R7VZ0BBRF9" }]);
    }
  }, [cookieState]);

  return (
    <>
      {cookieState === null ? (
        <div className="fixed w-full cookie-popup left-0 bottom-4 z-[999]">
          <div className="w-[60%] ml-4 md:mx-auto rounded-md text-sm p-2 bg-white">
            <div className="text-center mb-2 items-center">
              We use cookies to give you a better experience & use all
              functionalities. We do not use cookies to track users. By
              accepting, you agree to use such cookies. Please check
              <a
                target="_blank"
                className="text-sm underline ml-1"
                href="/privacy-policy"
              >
                Privacy Policy
              </a>
            </div>
            <div className="text-center">
              <button
                className="bg-white text-black border-black border p-1 mr-2 rounded-sm"
                onClick={declineCookies}
              >
                <abbr title="Decline Non Functional Cookies">Decline</abbr>
              </button>
              <button
                className="bg-black text-white border border-black p-1 rounded-sm"
                onClick={acceptCookies}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
