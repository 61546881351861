import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

import { guestLogin } from "../../../../apis/events";
import Button from "../../../../components/common/Button";
import {
  InputEmail,
  InputPhone,
  InputText,
} from "../../../../components/common/Input";
import {
  EMIAL_REGEX,
  LOGIN_BE_MESSAGE_FOR_LIMIT,
  MAX_FACE_REG_REACHED_POPUP,
  MOBILE_REGEX_TO_CHECK_DIGITS,
  SHOW_MOBILE_INSTEAD_OF_EMAIL,
} from "../../../../constants";
import { useEvent } from "../../../../Store/event";
import { useUser } from "../../../../Store/user";
import Loading from "../../../Loading";

const LoginPopUp = ({
  close,
  setCurrentView = () => {},
  guestUpload = false,
}) => {
  const { user, setUser } = useUser();
  const eventDetails = useEvent().response;

  const params = useParams();

  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState(user.password);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const emailFieldRef = useRef(null);
  const passwordFieldRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      loginUser();
    }
  };

  const hasAccess = eventDetails.data.requiresEmail
    ? user.accessToken || user.jwt
    : eventDetails.data.requiresPassword
    ? user.isValid
    : eventDetails.data.isPublished
    ? true
    : user.accessToken || user.jwt;

  const loginUser = async () => {
    const fieldIsMobile = SHOW_MOBILE_INSTEAD_OF_EMAIL.includes(
      eventDetails.photographer.id
    );
    if (hasAccess && !guestUpload) {
      window.scrollBy(0, window.innerHeight);
      close();
    } else if (
      (eventDetails.data.requiresEmail ||
        !eventDetails.data.isPublished ||
        guestUpload) &&
      (fieldIsMobile
        ? !email || MOBILE_REGEX_TO_CHECK_DIGITS.exec(email) === null
        : !email || EMIAL_REGEX.exec(email.toLocaleLowerCase()) === null)
    ) {
      setError({
        ...error,
        email: "Invalid" + fieldIsMobile ? " Mobile number" : " email address",
      });
    } else if (eventDetails.data.requiresPassword && !password) {
      setError({ ...error, password: "Please Enter Password" });
    } else if (
      eventDetails.data.requiresEmail ||
      params?.listId ||
      !eventDetails.data.isPublished ||
      guestUpload
    ) {
      setLoading(true);
      setError({ ...error, email: "", password: "" });
      let result = await guestLogin(eventDetails.data.id, email, password);
      if (result.error === false) {
        let loggedInUserData = {
          ...user,
          email,
          password,
          accessToken: result.response.accessToken,
          isAdmin: result.response.isSuperAdmin,
          isValid: true,
          search_id: result.response.searchId,
          fname: result.response.name?.split(" ")[0],
          lname: result.response.name?.split(" ")[1],
          phone: fieldIsMobile ? email : result.response.phone,
          pin: eventDetails.pin ? eventDetails.pin : "",
          id: result.response.guestId,
        };
        if (guestUpload) {
          setCurrentView("upload");
        }
        setUser(loggedInUserData);
        setEmail("");
        setPassword("");
        setError("");
        close();
      } else {
        if (result.message === LOGIN_BE_MESSAGE_FOR_LIMIT) {
          MAX_FACE_REG_REACHED_POPUP();
          close();
          return;
        }
        console.log("result Error", result.message);
        if (result.message.includes("password"))
          setError({ ...error, password: result.message });
        else setError({ ...error, email: result.message });

        setLoading(false);
      }
    } else if (eventDetails.data.requiresPassword) {
      setLoading(true);
      setUser({ ...user, password: password });
      setError("");
    }
  };

  useEffect(() => {
    if (!user.password && password) {
      setPassword("");
      setLoading(false);
      setError({ ...error, password: "Invalid Credentials" });
    }
  }, [user.password]);

  useEffect(() => {
    if (eventDetails.data.requiresPassword && user.isValid && !guestUpload) {
      close();
      window.scrollBy(0, window.innerHeight);
      setLoading(false);
    }
  }, [user.isValid, guestUpload]);

  return (
    <div className="flex flex-col gap-4 items-center">
      {loading ? (
        <Loading height="!h-auto" />
      ) : (
        <>
          {(eventDetails.data.requiresEmail && !hasAccess) ||
          guestUpload ||
          params?.listId ||
          !eventDetails.data.isPublished ? (
            <div className="flex flex-col w-full ">
              {SHOW_MOBILE_INSTEAD_OF_EMAIL.includes(
                eventDetails.photographer.id
              ) ? (
                <InputPhone
                  autoFocus
                  label="Phone"
                  value={email}
                  error={error.email}
                  onChange={setEmail}
                  onKeyDown={handleKeyDown}
                  tabIndex={1}
                  refElement={emailFieldRef}
                />
              ) : (
                <InputEmail
                  autoFocus
                  label="Email"
                  value={email}
                  error={error.email}
                  onChange={setEmail}
                  onKeyDown={handleKeyDown}
                  tabIndex={1}
                  refElement={emailFieldRef}
                />
              )}
            </div>
          ) : (
            <></>
          )}
          {eventDetails.data.requiresPassword && !user.isValid ? (
            <div className="flex flex-col  w-full">
              <InputText
                type="password"
                label="Password"
                error={error.password}
                value={password}
                onChange={setPassword}
                onKeyDown={handleKeyDown}
                tabIndex={2}
                refElement={passwordFieldRef}
              />
            </div>
          ) : (
            <></>
          )}
          <Button
            className="self-center mt-2 uppercase  w-[100px]"
            onClick={loginUser}
            text="Sign in"
          ></Button>
        </>
      )}
    </div>
  );
};

export default LoginPopUp;
