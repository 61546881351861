import React, { useEffect, useState } from "react";

import {
  BMS_PHOTOGRAPHER_ID,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  INDIAN_CURRENCY,
  PRICING_IN_DOLLAR
} from "../../../constants";
import { useCart } from "../../../Store/cart";
import { useEvent } from "../../../Store/event";
import Button from "../Button";
import { Input } from "../Input";
import Text from "../Text";

function OrderSummery({
  hideCouponCode = false,
  couponCode = "",
  setCouponCode = () => {},
  shippingAmount = 0,
  taxAmount = 0,
}) {
  const eventDetails = useEvent().response;
  const { cartList, cartAmount, digitalCartItem } = useCart();
  const [total, setTotal] = useState(cartAmount);
  const [tax, setTax] = useState();

  useEffect(() => {
    if (cartList.length || digitalCartItem.length) {
      setTotal(cartAmount);
      const calculatedTax = +((+cartAmount / 100) * 18).toFixed(2);
      setTax(calculatedTax);
    }
  }, [cartList, digitalCartItem]);

  const data = [
    {
      text: "Sub Total",
      price: total,
    },
  ];

  const taxJsx = (amount = tax, name = "Tax") => (
    <div className="flex justify-between">
      <Text className="text-black-gray dark:text-gray text-xs md:text-xl font-medium">
        {name}
      </Text>
      <Text className="text-dark-gray1 dark:text-light-gray text-xs md:text-xl font-medium">
        {eventDetails.data?.downloadPricing?.currency === PRICING_IN_DOLLAR ||
        eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
          ? "$"
          : "Rs."}
        {" " + amount}
      </Text>
    </div>
  );

  return (
    <div>
      <div className="shadow-dark-input-bottom-right dark:shadow-dark-input p-5 mx-5 lg:mr-5 lg:ml-0 rounded-lg lg:mt-5">
        <Text className="font-semibold text-base md:text-lg my-10">
          Order summary
        </Text>

        <div className="space-y-5 mt-7">
          <div className="flex justify-between">
            <Text className="text-black-gray dark:text-gray text-xs md:text-xl font-medium">
              Sub Total
            </Text>
            <Text className="text-dark-gray1 dark:text-light-gray text-xs md:text-xl font-medium">
              {eventDetails.data?.downloadPricing?.currency ===
                PRICING_IN_DOLLAR ||
              eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
                ? "$"
                : "Rs."}
              {" " + total}
            </Text>
          </div>
          {eventDetails.data?.downloadPricing?.currency ===
          PRICING_IN_DOLLAR ? (
            <></>
          ) : eventDetails.photographer.printStoreCurrency !==
            INDIAN_CURRENCY ? (
            shippingAmount ? (
              taxJsx(taxAmount)
            ) : (
              <></>
            )
          ) : (
            taxJsx()
          )}
          {eventDetails.photographer.printStoreCurrency !==
          INDIAN_CURRENCY ? (
            shippingAmount ? (
              taxJsx(shippingAmount, "Shipping")
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>

        <hr className="text-black-gray mt-5" />
        <div className="space-y-5 mt-5 ">
          <div className="flex justify-between">
            <Text className="text-dark-gray1 dark:text-gray font-medium text-xs md:text-xl">
              Total
            </Text>
            <Text className="font-semibold text-base md:text-3xl text-dark-gray1 dark:text-light-gray">
              {eventDetails.data?.downloadPricing?.currency ===
                PRICING_IN_DOLLAR ||
              eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
                ? "$"
                : "Rs."}{" "}
              {eventDetails.data?.downloadPricing?.currency ===
                PRICING_IN_DOLLAR && !!!cartList.length
                ? +total.toFixed(2)
                : eventDetails.photographer.printStoreCurrency ===
                  INDIAN_CURRENCY
                ? +(+total + +tax).toFixed(2)
                : +(+total + +taxAmount + +shippingAmount).toFixed(2)}
            </Text>
          </div>
        </div>
      </div>
      {EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id) ? (
        <></>
      ) : BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) &&
        hideCouponCode ? (
        <></>
      ) : (
        <div className="flex flex-col lg:flex-row justify-between mt-5 mx-5 lg:ml-0 lg:mr-5 items-end">
          <Input
            label="Gift card or discount code"
            lableClassName={"mb-4 text-xs text-base"}
            className="lg:mr-3"
            value={couponCode}
            onChange={setCouponCode}
          />
          {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
            <></>
          ) : (
            <Button
              className="mx-auto w-2/4 mt-5 lg:w-auto px-7"
              disabled={true}
              text="Apply"
            />
          )}
        </div>
      )}
    </div>
  );
}

export default OrderSummery;
