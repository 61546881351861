/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from "../../../assets/icons/CloseIcon";
import { ThemeContext } from "../../../components/App";
import AddAddress from '../AddAddress';

export default function AddAddressModal({
  userName,
  setuserName,
  email,
  setemail,
  phoneNumber,
  setphoneNumber,
  address1,
  setaddress1,
  address2,
  setaddress2,
  country,
  setcountry,
  city,
  setcity,
  PostalCode,
  setPostalCode,
  addaddress
}) {
  const [open, setOpen] = useState(true)

  const theme = useContext(ThemeContext);
  const isDarkMode = theme === "dark";
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className='fixed z-10 inset-0 overflow-y-auto' initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className='flex lg:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className='lg:w-2/3 inline-block bg-theme-light bg-dark-gray text-light-gray text-dark-gray1 align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full'>
              <div className='px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                <div className='mt-3 sm:mt-0 sm:ml-4 sm:text-left'>
                  <Dialog.Title as="h3" className='text-xl lg:text-2xl leading-6 font-medium text-gray-900'>
                    New Address<span className='absolute right-6 cursor-pointer'><CloseIcon fill={`${isDarkMode ? '#eee' : '#222'}`} /></span>
                  </Dialog.Title>
                  <div className='mt-7'>
                    <AddAddress isModal={true}
                      userName={userName}
                      setuserName={setuserName}
                      email={email}
                      setemail={setemail}
                      phoneNumber={phoneNumber}
                      setphoneNumber={setphoneNumber}
                      address1={address1}
                      setaddress1={setaddress1}
                      address2={address2}
                      setaddress2={setaddress2}
                      country={country}
                      setcountry={setcountry}
                      city={city}
                      setcity={setcity}
                      PostalCode={PostalCode}
                      setPostalCode={setPostalCode}
                      addaddress={addaddress}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}