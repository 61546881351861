import React from "react";

import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { INDIAN_CURRENCY, PRICING_IN_DOLLAR } from "../../../constants";
import { watermarkParams } from "../../../helper";
import { useCart } from "../../../Store/cart";
import { useEvent } from "../../../Store/event";
import BackButton from "../Backbutton";

const DigitalCart = ({ digitalImageArray, remove, isMobile, index }) => {
  const iconsColor = "#222";

  const { digitalCartItem } = useCart();
  const eventDetails = useEvent().response;

  const watermark = watermarkParams(eventDetails.data.watermark);

  return (
    <div
      className={`${
        index !== digitalCartItem.length - 1
          ? "border-b border-stone-300 dark:border-stone-700"
          : ""
      } ${index == 0 ? "mt-2 " : "mt-2 md:mt-0"} ${
        index == 0 && isMobile
          ? "border-t border-stone-300 dark:border-stone-700  pt-8"
          : ""
      } mb-2 ${
        isMobile ? "bg-[#eee] rounded-lg p-4" : ""
      } flex lg:justify-between lg:mr-16`}
    >
      <div>
        {digitalImageArray?.items.map((el, id) => (
          <div
            className={`lg:grid grid-rows-4 grid-flow-col ${
              id === 0 ? "mb-14" : "mb-8"
            } w-fit relative`}
          >
            <div className=" row-span-4 my-auto mr-8 relative w-[100px] md:mt-1  md:w-[180px] table bg-[length:100%_100%] bg-no-repeat">
              <img
                src={
                  el.croppedParameters
                    ? el.images.src +
                      "?w=1600&cr=" +
                      el.croppedParameters +
                      watermark
                    : el.images.src + "?w=1600" + watermark
                }
              />
            </div>
            {isMobile ? (
              <div
                className={`flex  absolute left-[175%] ${
                  id === 0
                    ? el.images.dimensions.height >= el.images.dimensions.width
                      ? "bottom-0 "
                      : "-bottom-[30%]"
                    : "bottom-[10%]"
                } shadow-dark-input-bottom-right dark:shadow-dark-input mt-2 items-center justify-center rounded-[10px] w-fit px-4 py-2 `}
                onClick={() => {
                  const args = {
                    id: el.id,
                    qty: 1,
                  };
                  remove(args);
                }}
                style={{
                  boxShadow:
                    "0px 0px 10px 0 rgb(0 0 0 / 15%), 0px 0px 10px 0 rgb(255 255 255 / 80%)",
                }}
              >
                <DeleteIcon color={iconsColor} />
              </div>
            ) : (
              <>
                <div className="text-sm md:text-2xl font-medium text-dark-gray1 dark:text-light-gray">
                  Digital Download
                </div>
                <div className={`col-span-2 row-start-3`}>
                  <div>
                    <BackButton
                      onClick={() => {
                        const args = {
                          id: el.id,
                          qty: 1,
                        };
                        remove(args);
                      }}
                      className="w-36"
                      text="Remove"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      {isMobile ? (
        <div>
          <div className="text-sm md:text-2xl font-medium text-dark-gray1">
            Digital Download
          </div>
          <div className="text-xs md:text-base mt-2 dark:text-stone-400 text-black-gray mb-2">
            Special combo of {digitalImageArray.quantity} price applied
          </div>
          <div className="text-sm md:text-base font-medium text-dark-gray1 dark:text-light-gray">
            {eventDetails.data?.downloadPricing?.currency ===
              PRICING_IN_DOLLAR ||
            eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
              ? "$"
              : "Rs."}
            {digitalImageArray.amount}
          </div>
        </div>
      ) : (
        <div className="text-right">
          <div className="text-2xl my-3 font-medium">
            {eventDetails.data?.downloadPricing?.currency ===
              PRICING_IN_DOLLAR ||
            eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY
              ? "$"
              : "Rs."}
            {digitalImageArray.amount}
          </div>
          <div className="text-base dark:text-stone-400 font-medium">
            Special combo of {digitalImageArray.quantity} price applied
          </div>
        </div>
      )}
    </div>
  );
};

export default DigitalCart;
