import LoadingGif from "../../assets/images/loading.gif";

function Loading({ className, height = "" , hideText = false }) {
  return (
    <div className={className}>
      <div
        className={`grid place-items-center h-screen w-full bg-theme-light dark:bg-dark-gray ${height}`}
      >
        <div className="flex flex-col justify-center items-center text-dark-gray dark:text-light-gray max-w-sm">
          <img src={LoadingGif} width={150} height={150} />
          {hideText ? <></> : <h3 className="text-2xl mb-5">Loading...</h3>}
        </div>
      </div>
    </div>
  );
}

export default Loading;
