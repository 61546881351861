import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DownArrow from "../../../assets/icons/DownArrow";
import { ThemeContext } from "../../App";
import { useEvent } from "../../../Store/event";
import { useUser } from "../../../Store/user";
import { ENVIRONMENT, STAGING_ENVIRONMENT_KEY } from "../../../constants";

const GoBackSection = ({
  changeGallery,
  onFolderClickHandler,
  title = "Go Back",
  folderDetails = "",
  backLink = "/",
  addAnchorTag,
}) => {
  const navigate = useNavigate();
  const eventDetails = useEvent().response;
  const { user } = useUser();
  const theme = useContext(ThemeContext);
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <div className="items-center dark:text-light-gray text-sm flex justify-start mb-[30px] mt-4">
      <span
        onClick={(e) => {
          changeGallery(false);
          onFolderClickHandler(e);
          if (addAnchorTag && user.accessLevel) {
            if (ENVIRONMENT === STAGING_ENVIRONMENT_KEY) {
              navigate(
                `/${user.accessLevel ?? "facerec"}/${eventDetails.data.slug}${
                  user.accessLevel === "view"
                    ? ""
                    : `/results/${user.search_id}`
                }`
              );
              return;
            }
            window.location.assign(
              `//${eventDetails.photographer.domain}/${
                user.accessLevel ?? "facerec"
              }/${eventDetails.data.slug}${
                user.accessLevel === "view" ? "" : `/results/${user.search_id}`
              }`
            );
          } else navigate(backLink);
        }}
      >
        <DownArrow
          className={"cursor-pointer rotate-90"}
          color={theme === "dark" ? "white" : "black"}
          height={isMobile ? "12" : "16"}
          width={isMobile ? "12" : "16"}
        />
      </span>
      <div
        className="cursor-pointer text-sm md:text-lg sm:whitespace-nowrap pl-2"
        onClick={(e) => {
          changeGallery(false);
          onFolderClickHandler(e);
          if (addAnchorTag && user.accessLevel) {
            if (ENVIRONMENT === STAGING_ENVIRONMENT_KEY) {
              navigate(
                `/${user.accessLevel ?? "facerec"}/${eventDetails.data.slug}${
                  user.accessLevel === "view"
                    ? ""
                    : `/results/${user.search_id}`
                }`
              );
              return;
            }
            window.location.assign(
              `//${eventDetails.photographer.domain}/${
                user.accessLevel ?? "facerec"
              }/${eventDetails.data.slug}${
                user.accessLevel === "view" ? "" : `/results/${user.search_id}`
              }`
            );
          } else navigate(backLink);
        }}
      >
        {title}
      </div>
      {!!Object.keys(folderDetails).length ? (
        <span
          className={`cursor-pointer text-xs ml-2.5 rounded-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[2px] ${
            theme === "dark" ? "bg-[#555555]" : "bg-[#cccccc]"
          }`}
        >
          {" "}
          {folderDetails.count}{" "}
          {folderDetails.maxFavouritesAllowed
            ? "/" + folderDetails.maxFavouritesAllowed
            : "photos"}{" "}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default GoBackSection;
