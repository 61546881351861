export const galleryCaraouselIcons = [
    "zoom",
    //"menu",
    "slideshow",
    "privacy",
    "cart",
    "share",
    "download",
    "like",
    "close",

];

export const galleryCaraouselItems = {
    slideshow: {
        type: "button",
        class: "fancybox__button--slideshow",
        label: "Start Slideshow",
        html: `<svg height="26" width="26" viewBox="0 0 24 24" tabindex="-1">
                  <g><path d="M6 4v16"/><path d="M20 12L6 20"/><path d="M20 12L6 4"/></g>
                  <g><path d="M7 4v15M17 4v15"/></g>
              </svg>`,
        click: function (event) {
            event.preventDefault();
            this.Slideshow.toggle();
        },
    },
    zoom: {
        type: "button",
        class: "fancybox__button--zoom",
        label: "Zoom",
        html: '<svg height="24" width="24" viewBox="0 0 24 24" tabindex="-1"><circle cx="10" cy="10" r="7"></circle><path d="M16 16 L21 21"></svg>',
        click: function (event) {
            event.preventDefault();
            const panzoom = this.fancybox.getSlide().Panzoom;

            if (panzoom) {
                panzoom.toggleZoom();
            }
        },
    },
    download: {
        type: "button",
        label: "Download",
        class: "fancybox__button--download",
        download: "true",
        html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M12 15V3m0 12l-4-4m4 4l4-4M2 17l.62 2.48A2 2 0 004.56 21h14.88a2 2 0 001.94-1.51L22 17"/></svg>',
        click: function (event) {
            event.stopPropagation();
            console.log(this.fancybox.getSlide())
            let url = this.fancybox.getSlide().src;
            let filename = url.split('/').pop().split('#')[0].split('?')[0];
            
            fetch(url, {
                method: "GET"
                })
                .then(response => {
                    response.arrayBuffer().then(function(buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
    cart: {
        type: "button",
        label: "Add To Cart",
        class: "fancybox__button--cart",
        html: ` <svg viewBox='0 0 26 26' style='height:18px; width:18px'>
        <g transform='translate(2 2)' fill='none' fillRule='evenodd'>
            <path
                d='M3.312 3.115h19.244a1 1 0 0 1 .936 1.352l-3.93 10.462a1 1 0 0 1-.937.648H5.52L3.312 3.115z'
                fill='#EEE'
            />
            <ellipse
                stroke='#EEE'
                fill='#EEE'
                cx='17.938'
                cy='21'
                rx='2.157'
                ry='2.5'
            />
            <ellipse
                stroke='#EEE'
                fill='#EEE'
                cx='8.969'
                cy='21'
                rx='2.157'
                ry='2.5'
            />
            <path
                stroke='#EEE'
                strokeWidth='3'
                stroke-linecap='round'
                stroke-linejoin='round'
                d='M0 0h2.76l2.76 15.577-2.312 3.388H18.11'
            />
        </g>
    </svg>
`,
        click: function (event) {
            event.stopPropagation();
        },
    },
    menu: {
        type: "button",
        label: "TOGGLE_THUMBS",
        class: "fancybox__button--thumbs",
        html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><circle cx="4" cy="4" r="1" /><circle cx="12" cy="4" r="1" transform="rotate(90 12 4)"/><circle cx="20" cy="4" r="1" transform="rotate(90 20 4)"/><circle cx="4" cy="12" r="1" transform="rotate(90 4 12)"/><circle cx="12" cy="12" r="1" transform="rotate(90 12 12)"/><circle cx="20" cy="12" r="1" transform="rotate(90 20 12)"/><circle cx="4" cy="20" r="1" transform="rotate(90 4 20)"/><circle cx="12" cy="20" r="1" transform="rotate(90 12 20)"/><circle cx="20" cy="20" r="1" transform="rotate(90 20 20)"/></svg>',
        click: function (event) {
          event.stopPropagation();
    
          const thumbs = this.fancybox.plugins.Thumbs;
    
          if (thumbs) {
            thumbs.toggle();
          }
        },
      },
    share: {
        type: "button",
        label: "Share",
        class: "fancybox__button--share",
        html: `<svg
        aria-hidden='true'
        focusable='false'
        data-prefix='fas'
        data-icon='share-alt'
        className='svg-inline--fa fa-share-alt fa-w-14'
        role='img'
        viewBox='0 0 448 512'
        style='height:18px; width:18px'
    >
        <path
            fill="white"
            d='M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z'
        ></path>
    </svg>`,
        click: function (event) {
            event.stopPropagation();
        },
    },
    close: {
        type: "button",
        label: "Close",
        class: "fancybox__button--close",
        html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M20 20L4 4m16 0L4 20"></path></svg>',
        tabindex: 1,
        click: function (event) {
            event.stopPropagation();
            event.preventDefault();
            this.fancybox.close();
        },
    },
    like: {
        type: "link",
        label: "Favourite",
        class: "fancybox__button--share",
        html: ` <svg
        aria-hidden='true'
        focusable='false'
        data-prefix='fas'
        data-icon='heart'
        className='svg-inline--fa fa-heart fa-w-20'
        role='img'
        viewBox='0 0 512 512'
        height='18'
        width='18'
    >
        <path
            fill='white'
            d='M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z'
        ></path>
    </svg>`,
        tabindex: 1,
        click: function (event) {
            event.stopPropagation();
        },
    },
};
