import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from 'react-ga4';
import { useEvent } from '../../Store/event';




export const Gatag = () =>{
   const  location  = useLocation()
   
   const event = useEvent().response
   const TRACKING_ID = event.photographer.googleAnalyticsCode?event.photographer.googleAnalyticsCode:"";
    useEffect(()=>{     
            ReactGA.send({ hitType: "pageview", page: location.pathname + window.location.search })
           
        
    },[location])
    return null
}