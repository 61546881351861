import React from "react";

/* You'll need to install @reach/portal which simplify creating portal*/
import Portal from "@reach/portal";

export const DrawerPage = ({ component, isOpen, setIsOpen }) => {
    const toggle = e => {
        setIsOpen(e);
    };

    return (
        <div className='m-8'>
            <Drawer isOpen={isOpen} toggle={toggle} position='left'>
                {component}
            </Drawer>
        </div>
    );
};

const style = {
    animation: {
        bottom: "animate-drawer-bottom",
    },
    orientation: {
        bottom: `flex w-full h-auto absolute bottom-0 focus:outline-none`,
    },
    body: `flex-shrink flex-grow p-4`,
    headerTitle: `text-2xl md:text-3xl font-light`,
    content: `relative flex flex-col pointer-events-auto w-full`,
    header: `items-start justify-between p-4 border-b border-gray-300`,
    container: `fixed top-0 left-0 z-40 w-full h-full m-0 overflow-hidden dark w-full`,
    overlay: `fixed top-0 left-0 z-30 w-screen h-screen bg-black opacity-50`,
    footer: `flex flex-wrap items-center justify-end p-3 border-t border-gray-300`,
};

function Drawer({ children, isOpen, toggle }) {
    const ref = React.useRef(null);

    // close drawer on click outside
    React.useEffect(() => {
        const handleOutsideClick = event => {
            if (!ref.current?.contains(event.target)) {
                if (!isOpen) return;
                toggle(event);
            }
        };
        window.addEventListener("click", handleOutsideClick);
        return () => window.removeEventListener("click", handleOutsideClick);
    }, [isOpen, ref, toggle]);

    // close drawer when you click on "ESC" key
    React.useEffect(() => {
        const handleEscape = event => {
            if (!isOpen) return;

            if (event.key === "Escape") {
                toggle(event);
            }
        };
        document.addEventListener("keyup", handleEscape);
        return () => document.removeEventListener("keyup", handleEscape);
    }, [isOpen, toggle]);

    // hide scrollbar and prevent body from moving when drawer is open
    //put focus on drawer dialogue
    React.useEffect(() => {
        if (!isOpen) return;

        ref.current?.focus();

        const html = document.documentElement;
        const scrollbarWidth = window.innerWidth - html.clientWidth;

        html.style.overflow = "hidden";
        html.style.paddingRight = `${scrollbarWidth}px`;

        return () => {
            html.style.overflow = "";
            html.style.paddingRight = "";
        };
    }, [isOpen]);

    return (
        <Portal>
            {isOpen && (
                <>
                    <div className={style.overlay} />
                    <div className={style.container}>
                        <div
                            ref={ref}
                            tabIndex={-1}
                            role='dialogue'
                            aria-modal={true}
                            className={style.orientation.bottom}
                        >
                            <div
                                className={`${style.animation.bottom} ${style.content}`}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Portal>
    );
}
