import React, { useEffect, useState } from "react";


import {
  cartRemove, cartUpdate, getCartCount, getCartLists, payment,
  storeAllToCart, storeToCart
} from "../apis/cart";
import { INDIAN_CURRENCY } from "../constants";
import { useEvent } from "./event";
import { useUser } from "./user";

const Cart = React.createContext();
Cart.displayName = "Cart";

export const useCart = () => React.useContext(Cart);

export const CartProvider = ({ children }) => {
  let { user } = useUser();
  let eventDetails = useEvent().response;
  const [cartCnt, setCartCnt] = useState("");
  const [cartList, setCartList] = useState([]);
  const [digitalCartItem, setDigitalCartItem] = useState([]);
  const [cartAmount, setCartAmount] = useState(0);
  const [cartApiCalled, setCartApiCalled] = useState(false);

  useEffect(async () => {
    if (user.accessToken) {
      let cartL = await getCartLists(eventDetails.data.id, user.accessToken);
      if (cartL.error === false) {
        setCartList(cartL.response.data);
        setDigitalCartItem(cartL.response?.digitalImages);
        setCartAmount(cartL.response.totalCartAmount);
        setCartApiCalled(true);
      }
      let cartC = await getCartCount(eventDetails.data.id, user.accessToken);
      if (cartC.error === false) {
        setCartCnt(cartC.response.data.cartCount);
      }
    }
  }, [user.accessToken]);

  const addToCart = async (
    imageId,
    size,
    frame,
    quantity,
    frame_style,
    frame_url,
    croppedParameters,
    frame_type = "classic",
    addOn = null
  ) => {
    const premiumFrames = [
      "Distressed",
      "Natural Wood",
      "Classic Wood Frames",
      "Classic thin",
      "Modern Wood Frame",
      "Metallic Brush",
    ];
    if (premiumFrames.includes(frame_style)) {
      frame_type = "premium";
    }
    if (frame_type === "digital") {
      let sameImage = false;
      digitalCartItem?.forEach((el) => {
        if (Array.isArray(el.items)) {
          const sameImageFound = el.items.filter(
            (el) => el.eventImageId === imageId
          );
          if (sameImageFound.length) sameImage = true;
        }
      });
      if (sameImage) {
        window.Toast.fire({
          icon: "error",
          title: "Photo already added to cart",
        });
        return;
      }
    }
    if (frame_type === "digital") {
      const maxImage = eventDetails.data?.downloadPricing.price.at(-1).quantity;
      let digitalCartItemLength = 0;
      digitalCartItem?.forEach((el) => {
        if (Array.isArray(el.items)) digitalCartItemLength += el.items.length;
      });
      if (+maxImage === digitalCartItemLength) {
        window.Toast.fire({
          icon: "error",
          title: "Maximum photos added",
        });
        return;
      }
    }
    let res = await storeToCart(
      eventDetails.data.id,
      imageId,
      frame_type,
      quantity,
      size,
      frame,
      frame_style,
      frame_url,
      croppedParameters,
      user.accessToken,
      eventDetails.photographer.id,
      eventDetails.photographer.printStoreCurrency !== INDIAN_CURRENCY &&
        frame_type !== "digital",
      addOn
    );
    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: "Photo added to cart",
      });
      let cartL = await getCartLists(eventDetails.data.id, user.accessToken);
      // console.log(cartL)
      if (!cartL.error) {
        setCartList(cartL.response.data);
        setDigitalCartItem(cartL.response?.digitalImages);
        setCartAmount(cartL.response.totalCartAmount);
      }
      let cartC = await getCartCount(eventDetails.data.id, user.accessToken);
      // console.log(cartC)
      if (!cartC.error) {
        setCartCnt(cartC.response.data.cartCount);
      }

      return true;
    }
  };

  const addAllProductsToCart = async (imageId) => {
    let res = await storeAllToCart(
      eventDetails.data.id,
      imageId,
      "digital",
      1,
      null,
      null,
      "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style1_8x8/8x8-2photo-grid-brown.png",
      null,
      null,
      user.accessToken,
      eventDetails.photographer.id,
      user.search_id
    );
    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: "All photos added to cart",
      });
      let cartL = await getCartLists(eventDetails.data.id, user.accessToken);
      // console.log(cartL)
      if (!cartL.error) {
        setCartList(cartL.response.data);
        setDigitalCartItem(cartL.response?.digitalImages);
        setCartAmount(cartL.response.totalCartAmount);
      }
      let cartC = await getCartCount(eventDetails.data.id, user.accessToken);
      // console.log(cartC)
      if (!cartC.error) {
        setCartCnt(cartC.response.data.cartCount);
      }

      return true;
    }
  };

  const removeFromCart = async (id, cartIds, qty, showToast = true) => {
    let res = await cartRemove(id, cartIds, qty, user.accessToken);
    if (!res.error && showToast) {
      window.Toast.fire({
        icon: "success",
        title: "Item removed from cart",
      });
      if (cartCnt == 1) {
        setCartList([]);
        setDigitalCartItem([]);
        setCartAmount(0);
      } else {
        let cartL = await getCartLists(eventDetails.data.id, user.accessToken);
        // console.log(cartL)
        if (!cartL.error) {
          setCartList(cartL.response.data);
          setDigitalCartItem(cartL.response?.digitalImages);
          setCartAmount(cartL.response.totalCartAmount);
        }
      }
      let cartC = await getCartCount(eventDetails.data.id, user.accessToken);
      if (!cartC.error) {
        setCartCnt(cartC.response.data.cartCount);
      }
    }
  };

  const updateCart = async (id, cartIds, qty) => {
    let res = await cartUpdate(id, cartIds, qty, user.accessToken);
    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: "Cart updated",
      });
      let cartL = await getCartLists(eventDetails.data.id, user.accessToken);
      if (!cartL.error) {
        setTimeout(() => {
          setCartList(cartL.response.data);
          setDigitalCartItem(cartL.response?.digitalImages);
          setCartAmount(cartL.response.totalCartAmount);
        }, 100);
      }
      let cartC = await getCartCount(eventDetails.data.id, user.accessToken);
      if (!cartC.error) {
        setCartCnt(cartC.response.data.cartCount);
      }
    }
  };

  const cartPayment = async (
    userName,
    email,
    address1,
    address2,
    country,
    city,
    state,
    PostalCode,
    baddress1,
    baddress2,
    bcountry,
    bcity,
    bstate,
    bPostalCode,
    phoneNumber,
    currency,
    shippingTotal
  ) => {
    var orderIds = [];
    {
      cartList.map(({ id }) => {
        orderIds.push(id);
      });
    }
    digitalCartItem?.forEach((el) =>
      el.items.forEach((e) => orderIds.push(e.id))
    );

    let res = await payment(
      eventDetails.data.id,
      userName,
      email,
      address1,
      address2,
      country,
      city,
      state,
      PostalCode,
      baddress1,
      baddress2,
      bcountry,
      bcity,
      bstate,
      bPostalCode,
      phoneNumber,
      orderIds,
      user.accessToken,
      currency,
      shippingTotal
    );
    if (!res.error) {
      // console.log(res.response.resp.url)
      return res;
      // window.location.replace(res.response.resp.url)
    } else {
      window.Toast.fire({
        icon: "error",
        title: res.message ?? "Something went wrong",
      });
      return;
    }
  };

  return (
    <Cart.Provider
      value={{
        cartCnt,
        setCartCnt,
        cartList,
        setCartList,
        addToCart,
        removeFromCart,
        updateCart,
        cartPayment,
        digitalCartItem,
        cartAmount,
        setDigitalCartItem,
        cartApiCalled,
        addAllProductsToCart,
      }}
    >
      {children}
    </Cart.Provider>
  );
};
