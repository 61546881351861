import React from "react";

const ShareIcon = (props) => {
  const { height = "24", width = "24", color = "#eee" } = props;
  return (
    // <svg
    //     aria-hidden='true'
    //     focusable='false'
    //     data-prefix='fas'
    //     data-icon='share-alt'
    //     className='svg-inline--fa fa-share-alt fa-w-14'
    //     role='img'
    //     viewBox='0 0 448 512'
    //     height={height}
    //     width={width}
    // >
    //     <path
    //         fill={color}
    //         d='M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z'
    //     ></path>
    // </svg>
    //     <svg width={width} height={height} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    // <path d="M20 9.5a4.5 4.5 0 1 0-4.466-3.945L8.124 9.26a4.5 4.5 0 1 0 0 6.478l7.41 3.705a4.5 4.5 0 1 0 1.342-2.683l-7.41-3.705a4.54 4.54 0 0 0 0-1.11l7.41-3.705A4.478 4.478 0 0 0 20 9.5z" fill={color} fillRule="nonzero"/>
    //     </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 96 110"
      fill="none"
    >
      <path
        d="M81.0937 79.918L81.0923 79.9111H80.6822C76.5061 79.9111 72.7421 81.6535 70.0474 84.4304L29.4984 58.8416C29.9137 57.4851 30.1451 56.0467 30.1451 54.5537C30.1451 53.057 29.9121 51.6175 29.4959 50.2589L69.5002 24.9925C72.2189 28.1092 76.223 30.0851 80.6827 30.0851C88.8536 30.0851 95.5 23.4499 95.5 15.29C95.5 7.13225 88.8517 0.5 80.6827 0.5C72.5073 0.5 65.8554 7.1339 65.8554 15.29C65.8554 16.505 66.0157 17.6798 66.2951 18.8035L25.9555 44.2803C23.2613 41.5017 19.4942 39.7588 15.3173 39.7588C7.14643 39.7588 0.5 46.394 0.5 54.5531C0.5 62.7084 7.14842 69.3399 15.3173 69.3399C19.4949 69.3399 23.262 67.596 25.9562 64.8191L66.505 90.4078C66.088 91.7682 65.8543 93.2109 65.8543 94.71C65.8543 102.868 72.5063 109.5 80.6816 109.5C88.8524 109.5 95.4989 102.866 95.4989 94.71C95.4998 86.6876 89.0753 80.1362 81.0937 79.918ZM80.6822 7.44025C85.0187 7.44025 88.5453 10.963 88.5453 15.2898C88.5453 19.621 85.0183 23.1444 80.6822 23.1444C76.3404 23.1444 72.8091 19.6196 72.8091 15.2898C72.8091 10.9636 76.3401 7.44025 80.6822 7.44025ZM15.3177 62.3994C10.981 62.3994 7.45461 58.8788 7.45461 54.5531C7.45461 50.2235 10.9816 46.6993 15.3177 46.6993C19.6595 46.6993 23.1908 50.2241 23.1908 54.5531C23.1908 58.8782 19.66 62.3994 15.3177 62.3994ZM80.6822 102.559C76.3402 102.559 72.8091 99.0358 72.8091 94.7096C72.8091 90.3778 76.3406 86.8516 80.6822 86.8516C85.0183 86.8516 88.5453 90.3772 88.5453 94.7096C88.5453 99.0363 85.0185 102.559 80.6822 102.559Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

export default ShareIcon;
