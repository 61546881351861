import React, { useContext, useState } from 'react';

import { ThemeContext } from "../../components/App";
import StaticLogo from '../../components/common/StaticLogo';
import LeftArrow from '../../assets/icons/LeftArrow';
import Text from '../../components/common/Text';
import ColouredBulletIcon from '../../assets/icons/ColouredBulletIcon';
import AddAddress from '../../components/common/AddAddress';
import OrderSummery from '../../components/common/OrderSummery';
import { BMS_PHOTOGRAPHER_ID } from '../../constants';

function AddressAndPayment() {
    const theme = useContext(ThemeContext);
    const iconsColor = theme === "dark" ? "white" : "#222";

    return (
        <div className='dark:text-white'>
            <StaticLogo />
            <div className='flex space-x-5 items-center my-10 px-5'>
                <LeftArrow className="rotate-90" color={iconsColor} />
                <Text className="font-extrabold text-xl">Checkout</Text>
            </div>

            <div className='flex justify-between'>

                <div style={{ width: "70%" }}>


                    {/* ---------------- Address ----------------*/}
                    <div className='px-5'>
                        <div className='flex space-x-5 items-start'>
                            <ColouredBulletIcon color="#22b7b9" className="mt-2" />
                            <div className='w-full pr-14'>
                                <Text className="font-semibold text-lg">Address</Text>
                                <hr className='w-full text-black-gray my-4' />
                                <AddAddress />
                            </div>
                        </div>
                    </div>


                    {/* ---------------- Payment ----------------*/}
                    <div className='mt-20 px-5'>
                        <div className='flex space-x-5 items-start'>
                            <ColouredBulletIcon color="#222222" className="mt-2" />
                            <div className='w-full pr-14'>
                                <Text className="font-semibold text-lg">Payment</Text>
                                <hr className='w-full text-black-gray my-4' />
                            </div>
                        </div>
                    </div>

                </div>

                <div style={{ width: "30%" }}>
                    <OrderSummery hideCouponCode  />
                </div>
            </div>
        </div>
    )
}

export default AddressAndPayment;
