import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getDownlaodFolder } from "../../../apis/download";
import { useEvent } from "../../../Store/event";
import StaticLogo from "../StaticLogo";
import LoadingGif from "../../../assets/images/loading.gif";
import { useUser } from "../../../Store/user";
import { useRef } from "react";
import { postBandwidth } from "../../../apis/bandwidth";

function DownloadNow() {
  const eventDetails = useEvent().response;
  const params = useParams();
  const { user } = useUser();

  const [isDownload, setDownload] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [downloadLink, setDownloadLink] = useState([]);
  const [loading, setLoading] = useState(true);

  const guestId = useRef();

  useEffect(() => {
    let intitialRequest = 0;
    if (!downloadLink.length) {
      const intervalId = setInterval(async () => {
        intitialRequest = intitialRequest + 1;
        let res = await getDownlaodFolder(params.downloadId, intitialRequest);
        setLoading(false);
        if (!res.error) {
          if (res.response) {
            guestId.current = res.response.data.guestId;
            setResponseText("Request Processing!!");
            if (res.response.data.status === "zip_deleted") {
              clearInterval(intervalId);
              setDownload("zip_deleted");
            } else if (res.response.data.urls.length) {
              setDownload(true);
              setDownloadLink(res.response.data.urls);
            }
          }
        }
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [downloadLink]);

  const downloadingZip = async (link) => {
    const postData = {
      eventId: eventDetails.data.id,
      downloadRequestId: params.downloadId,
      guestId: user.id || guestId.current,
      url: link,
    };
    // await postBandwidth(postData);
  };

  return (
    <div className="dark:text-white min-h-screen">
      <StaticLogo />
      <div className="container mt-14 mx-auto max-w-screen-lg xl:max-w-screen-xl xl:px-10 px-10 ">
        <div className="col-md-10 mx-auto mt-5">
          <h1 className="text-center my-6 sm:text-4xl text-2xl">
            <a
              href={eventDetails.photographer.website}
              className="login-logodownload pt-3"
            >
              {eventDetails.photographer.brandName
                ? eventDetails.photographer.brandName
                : "Algomage"}
            </a>
          </h1>
          <div className="text-center">
            {loading ? (
              <div className="flex justify-center">
                <img src={LoadingGif} width={150} height={150} />
              </div>
            ) : !isDownload ? (
              <>
                <span id="text1">
                  You will be notified via email once your download is ready.
                  <br />
                  You can also wait on this page
                </span>
                <h3 className="text-center mt-3 text-dark">{responseText}</h3>
              </>
            ) : isDownload === "zip_deleted" ? (
              <>
                <span id="text1">
                  This download link has expired.
                  <br />
                  Please create a new download request.
                </span>
                <br />
              </>
            ) : (
              downloadLink.length > 0 &&
              downloadLink.map((link, i) => (
                <a onClick={() => downloadingZip(link)} href={link} key={i}>
                  <button
                    className={`rounded-[10px] shadow-button shadow-dark-input-bottom-right dark:shadow-dark-input py-[9px] px-[16px] dark:text-slate-100 font-semibold font-semibold text-base w-56 flex mx-auto mt-6 justify-center`}
                  >
                    Download Link {i + 1}
                  </button>
                </a>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadNow;
