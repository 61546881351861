import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSingleImage } from "../../apis/singleImage";
import { IMAGE_OG_SUBDOMAIN, IMAGE_RENDERER_SUBDOMAIN } from "../../constants";
import { watermarkParams } from "../../helper";

import { useEvent } from "../../Store/event";
import Error from "../Errors";
import Loading from "../Loading";

const SinglePage = () => {
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {
    response: { data, photographer },
  } = useEvent();
  const { folderId, imageId } = useParams();

  const getImage = async () => {
    try {
      const res = await getSingleImage(folderId, imageId);
      const paramsToBeAppended = watermarkParams(data.watermark);
      setImageData(() => ({
        name: res.data.name,
        url: paramsToBeAppended
          ? res.data.url.replace(IMAGE_OG_SUBDOMAIN, IMAGE_RENDERER_SUBDOMAIN) + "?w=1600" + paramsToBeAppended
          : res.data.url,
      }));
    } catch (error) {
      console.log(error);
      setError({ isError: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  return loading ? (
    <Loading />
  ) : error?.isError ? (
    <div className="text-center">
      <Error
        message={"Please contact your photographer"}
        text={"This link seems to be broken"}
      />
    </div>
  ) : (
    <div className="min-h-screen dark:text-light-gray flex items-center md:justify-center flex-col px-10 py-10">
      <div className="text-bold mt-5 md:mt-0">{data?.name.toUpperCase()}</div>
      {photographer?.brandName && (
        <div className="text-gray mt-1 text-xs">{`BY ${photographer?.brandName.toUpperCase()}`}</div>
      )}
      <img
        className="max-h-[550px]  object-contain mt-8 mb-2"
        src={imageData?.url}
        alt={imageData?.name}
      />
      <caption className="text-gray text-xs">{imageData?.name}</caption>
    </div>
  );
};

export default SinglePage;
