import React from "react";

const HorizontalDotsOptions = props => {
    const { height = "16", width = "16", color = "#EEE" } = props;
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            className='bi bi-three-dots'
            viewBox='0 0 16 16'
        >
            <path d='M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z' />
        </svg>
    );
};

export default HorizontalDotsOptions;
