import React, { useContext, useEffect, useState } from "react";
import face from "../../assets/images/face.png";
import Button from "../../components/common/Button";
import "./registerFace.css";
import BackButton from "../../components/common/Backbutton";
import StaticLogo from "../../components/common/StaticLogo";
import GoBackSection from "../../components/common/GoBackSection";
import CloseIcon from "../../assets/icons/CloseIcon";
import { ThemeContext } from "../../components/App";
import { Link, Navigate } from "react-router-dom";
import { useEvent } from "../../Store/event";
import { useUser } from "../../Store/user";
import Cameramodule from "./Cameramodule";
import { checkAccess } from "../../apis/facesearch";
import Loading from "../Loading";
import CameraWithoutLiveliness from "./CameraWithoutLiveliness";

const RegisterFace = () => {
  const theme = useContext(ThemeContext);
  const isDarkMode = theme === "dark";
  const eventDetails = useEvent().response;
  const { user, setUser } = useUser();
  const [cameraMode, setCameraMode] = useState(true);
  const [allowed, setAllowed] = useState();
  const [loading, setLoading] = useState(true);
  const handleBack = () => {
    setUser({ ...user, id: 0, fname: "", lname: "", phone: "" });
  };

  useEffect(async () => {
    var res = await checkAccess(eventDetails.photographer.id);
    if (!res.error) {
      setAllowed(res.response.faceSearchAccess);
    }
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : allowed ? (
        user.id ? (
          cameraMode ? (
            !eventDetails.data.isLivenessEnabled ? (
              <CameraWithoutLiveliness />
            ) : (
              <Cameramodule />
            )
          ) : (
            <div className="h-screen">
              <StaticLogo />
              <div className="p-4 items-center">
                <div className="hidden lg:block">
                  <button onClick={handleBack}>
                    <GoBackSection />
                  </button>
                </div>

                <div className="mx-auto lg:border lg:border-zinc-400/20  flex justify-center items-center  lg:p-20 lg:px-10 self-center">
                  <div className="w-full">
                    <div
                      className="flex justify-end text-neutral-800 dark:text-slate-50 p-5 md:hidden lg:hidden block mb-5"
                      onClick={handleBack}
                    >
                      <CloseIcon fill={`${isDarkMode ? "#eee" : "#222"}`} />
                    </div>
                    <div className="p-2 mb-6">
                      <h1 className="text-center text-xl font-normal text-neutral-800 dark:text-slate-50 pt-5">
                        Register Face
                      </h1>
                      <h2 className="text-center text-sm font-normal text-neutral-800 dark:text-slate-50 pt-5">
                        Register your face to view your photos
                      </h2>
                      <div className="flex justify-center pt-10">
                        <img
                          src={face}
                          width="100"
                          height="101"
                          text-align="center"
                          alt="register your face"
                        />
                      </div>
                      <h2 className="text-center text-sm font-normal text-neutral-800 dark:text-slate-50 pt-5">
                        Allow camera to capture your face
                      </h2>
                    </div>
                    <div className="flex justify-center pt-6 lg:pt-0">
                      <div className="flex text-sm pr-2 lg:block hidden">
                        <BackButton text="Back" onClick={handleBack} />
                      </div>
                      <div className="flex text-sm justify-center pl-2">
                        <Button text="Get Started" onClick={setCameraMode} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <Navigate to={`/facerec/${eventDetails.data.slug}`} />
        )
      ) : (
        <div className="flex lg:pl-24 lg:pt-[100px] lg:pr-16 md:p-8 pt-[26px] pb-[36px] px-6 relative justify-center items-center">
          <h1 className="text-base md:text text-dark-gray1 pb-4 dark:text-light-gray">
            This is disabled
          </h1>
        </div>
      )}
    </>
  );
};

export default RegisterFace;
