import React from "react";

const BigTick = ({className}) => {
    return (
    <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g fillRule="nonzero" fill="none">
            <circle fill="#842D73" cx="50" cy="50" r="50"/>
            <path d="M50 100c27.617 0 50-22.383 50-50S77.617 0 50 0v100z" fill="#702065"/>
            <g transform="translate(10 10)">
                <circle fill="#BC66AC" cx="40" cy="40" r="40"/>
                <path d="M40 80c22.083 0 40-17.917 40-40S62.083 0 40 0v80z" fill="#A64492"/>
            </g>
            <path fill="#842D73" d="M33.217 43.233 46.083 56.1l20.484-20.483L73.25 42.3 46.217 69.217 26.75 49.75z"/>
            <path fill="#702065" d="m50.717 51.483 15.85-15.866L73.25 42.3 50.717 64.733z"/>
        </g>
    </svg>

    );
};

export default BigTick;