import React from "react";
import BigPlus from "../../../assets/icons/BigPlus";
import "./createNewFolderMobile.css";

export const CreateNewFolderMobile = () => {
    return (
        <React.Fragment>
            <div className='new-folder-mobile text-sm dark:text-light-gray cursor-pointer'>
                <div className='new-folder-box flex justify-center items-center'>
                    <div className='flex justify-center'>
                        <BigPlus height='36' width='36'></BigPlus>
                    </div>
                </div>
                <div className='mt-[10px] text-center'>New Folder</div>
            </div>
        </React.Fragment>
    );
};

export default CreateNewFolderMobile;
