import React, { useState, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";

import CropIcon from "../../../assets/icons/CropIcon";
import { INTERNAL_PRODUCT_KEY, MILLER_FRAME_SIZE_INCHES } from "../../../constants";
import { useCart } from "../../../Store/cart";
import { useEvent } from "../../../Store/event";
import Button from "../Button";
import IconButton from "../IconButton";
import SliderComponent from "../Slider";
import Text from "../Text";
import "./ProductDetailsModal.css";
import Dropdown from "../Dropdown";
import SohoFrameImage from "../../../assets/images/sohoFrame.jpeg";
import PrintsImage from "../../../assets/images/prints.jpeg";
import MinisImage from "../../../assets/images/minis.jpeg";
import WalletsImage from "../../../assets/images/wallets.jpeg";

const COLOR = {
  gold: "#af9352",
  black: "#343331",
  brown: "#8c685a",
  white: "#dad2bd",
};

const DROPDOWN_BUTTON_CLASSES = "flex gap-4 px-5 py-2";

const SOHO_FRAME = "Soho Frames";
const PRINT = "Prints";
const WALLETS = "Wallets";
const MINIS = "Minis";

const FRAME_TYPE_FOR_BE = {
  [SOHO_FRAME]: "soho",
  [PRINT]: "print",
  [WALLETS]: "wallets",
  [MINIS]: "minis",
};

const IMAGES = [SohoFrameImage, PrintsImage, WalletsImage, MinisImage];

const OtherFrames = ({ photo, close, cropImage, setCropImage , cartId=null }) => {
  const { addToCart , cartList , digitalCartItem , removeFromCart } = useCart();
  const eventDetails = useEvent().response;

  const [frameSize, setFrameSize] = useState("3.5x5");
  const [frameType, setFrameType] = useState(SOHO_FRAME);
  const [framePrice, setFramePrice] = useState({
    frame: eventDetails.photographer.framePricingUsd["soho"]["3.5x5"],
  });

  const [allFrameSizes, setAllFrameSizes] = useState(
    Object.keys(eventDetails.photographer.framePricingUsd["soho"]).map((e) => ({
      size: e,
      price: eventDetails.photographer.framePricingUsd["soho"][e],
    }))
  );
  const [quantity, setQuantity] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedParameters, setCroppedParameters] = useState();
  const [imageStatus, setImageStatus] = useState("loading");
  const [cropperImage, setCropperImage] = useState({
    imageSrc: photo.printStoreSrc,
  });
  const [frameColor, setFrameColor] = useState("black");
  const [aspectRatio, setAspectRatio] = useState({ style: {}, ratio: "" });
  const [lustreCoating, setLustreCoating] = useState(false);
  const [mount, setMount] = useState("");
  const [glass, setGlass] = useState("None");
  const [glassOptions, setGlassOptions] = useState([]);
  const [matAndMount, setMatAndMount] = useState([]);

  const framePricing = useRef(eventDetails.photographer.framePricingUsd);

  const isPhotoLandscape =
    photo.originalImageObj.height < photo.originalImageObj.width;

  const MAX = 10;

  const onCropChange = (c) => {
    setCrop({ ...crop, x: c.x, y: c.y });
    setCropperImage({
      ...cropperImage,
      imageSrc: photo.printStoreSrc + "&cr=" + croppedParameters,
    });
  };

  const incrementQty = () => {
    setQuantity(quantity + 1);
  };

  const decrementQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    } else {
      setQuantity(1);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedParameters(
      Math.round(croppedAreaPixels.x) +
        "," +
        Math.round(croppedAreaPixels.y) +
        "," +
        Math.round(croppedAreaPixels.width) +
        "," +
        Math.round(croppedAreaPixels.height)
    );
  };

  const handleImageLoaded = () => {
    setImageStatus("loaded");
  };

  function cancelCropping() {
    setCropImage(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCropperImage({ ...cropperImage, imageSrc: photo.printStoreSrc });
  }

  function updateCroppedCoverImage() {
    setCropImage(false);
    setCropperImage({
      ...cropperImage,
      imageSrc: photo.printStoreSrc + "&cr=" + croppedParameters,
    });
    window.Toast.fire({
      icon: "success",
      title: "Image cropped successfully.",
    });
  }

  const incrementZoom = () => {
    setZoom(zoom + 1);
  };

  const decrementZoom = () => {
    if (zoom > 1) {
      setZoom(zoom - 1);
    } else {
      setZoom(1);
    }
  };

  const onCartButtonClicked = async() => {
    if(cartId){
      const cartItem = cartList.find(el=>el.id === cartId)
      const cartIds = [];
      {
        cartList.map(({ id }) => {
          cartIds.push(id);
        });
      }
      digitalCartItem.forEach((el) =>
        el.items.forEach((e) => cartIds.push(e.id))
      );
      await removeFromCart(cartId, cartIds, cartItem.quantity , false);
    }
    let addOns = "";
    if (lustreCoating) addOns += "luster";
    if (mount) {
      if (lustreCoating) addOns += ",";
      addOns += mount;
    }
    if (glass !== "None") {
      if (lustreCoating && !mount) addOns += ",";
      else if (mount) addOns += ",";
      addOns += glass;
    }

    let res = addToCart(
      photo.key,
      frameSize,
      frameColor,
      quantity,
      FRAME_TYPE_FOR_BE[frameType],
      frameType === SOHO_FRAME
        ? `https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}`
        : "https://picsniff.s3.ap-south-1.amazonaws.com/frames",
      croppedParameters,
      null,
      addOns
    );
    if (res) {
      setImageStatus("loading");
      setCroppedParameters();
      close();
    }
  };

  useEffect(() => {
    if ([SOHO_FRAME, PRINT].includes(frameType) && !cartId) {
      const isPhotoNotPortrait =
        photo.originalImageObj.height < photo.originalImageObj.width;
      const xAxis = isPhotoNotPortrait
        ? frameSize.split("x")[1] * 2
        : frameSize.split("x")[0] * 2;
      const yAxis = isPhotoNotPortrait
        ? frameSize.split("x")[0] * 2
        : frameSize.split("x")[1] * 2;
      setAspectRatio({
        ratio: xAxis / yAxis,
        style: { aspectRatio: (xAxis / yAxis).toString() },
      });
      if (frameType === SOHO_FRAME) {
        if (framePricing.current["Double Weight Matboard"][frameSize]) {
          setMount("Double Weight Matboard");
          setFramePrice((prev) => ({
            ...prev,
            mount: framePricing.current["Double Weight Matboard"][frameSize],
          }));
        } else if (framePricing.current["Single Weight Matboard"][frameSize]) {
          setMount("Single Weight Matboard");
          setFramePrice((prev) => ({
            ...prev,
            mount: framePricing.current["Single Weight Matboard"][frameSize],
          }));
        } else if (framePricing.current["Mat"][frameSize]) {
          setMount("White mat");
          setGlass("Non-Glare Glass");
          setFramePrice((prev) => ({
            ...prev,
            mount: framePricing.current["Mat"][frameSize],
            glass: framePricing.current["Non-Glare Glass"][frameSize],
          }));
        }
      }
    } else
      setAspectRatio({
        ratio: 1 / 1,
        style: { aspectRatio: (1 / 1).toString() },
      });
  }, [frameSize, frameType]);

  useEffect(() => {
    setCropperImage({ ...cropperImage, imageSrc: photo.printStoreSrc });
  }, [photo]);

  useEffect(() => {
    if (frameType === SOHO_FRAME) {
      const matAndMountArray = [];
      if (framePricing.current["Double Weight Matboard"][frameSize])
        matAndMountArray.push("Double Weight Matboard");
      if (framePricing.current["Single Weight Matboard"][frameSize])
        matAndMountArray.push("Single Weight Matboard");
      if (framePricing.current["Mat"][frameSize])
        matAndMountArray.push("Black mat", "White mat");
      setMatAndMount(matAndMountArray);
    } else setMatAndMount([]);
  }, [frameSize, frameType]);

  useEffect(() => {
    if (frameType === SOHO_FRAME) {
      const glassOptionsArray = [];
      if (framePricing.current["Non-Glare Glass"][frameSize])
        glassOptionsArray.push("Non-Glare Glass");
      if (framePricing.current["Clear Glass"][frameSize])
        glassOptionsArray.push("Clear Glass");
      if (!mount.includes("mat")) glassOptionsArray.push("None");
      setGlassOptions(glassOptionsArray);
    } else setGlassOptions([]);
  }, [frameSize, frameType, mount]);

  useEffect(()=>{
    if(cartId){
      const cartItem = cartList.find(el=>el.id === cartId)
      setFrameColor(cartItem.color)
      setQuantity(cartItem.quantity)
      setFrameSize(cartItem.imageSize)
      setCroppedParameters(cartItem.croppedParameters)
      setFramePrice(prev=>({
        ...prev,
        frame: eventDetails.photographer.framePricingUsd["soho"][cartItem.imageSize],
      }))
      if(cartItem.addOn.includes("luster")){
        setLustreCoating(true)
        setFramePrice(prev=>({
          ...prev,
          lustre: eventDetails.photographer.framePricingUsd["luster"][cartItem.imageSize],
        }))
      }
      if(cartItem.addOn.includes("Double Weight Matboard")){
        setMount("Double Weight Matboard")
        setFramePrice(prev=>({
          ...prev,
          mount: eventDetails.photographer.framePricingUsd["Double Weight Matboard"][cartItem.imageSize],
        }))
      }
      if(cartItem.addOn.includes("Single Weight Matboard")){
        setMount("Single Weight Matboard")
        setFramePrice(prev=>({
          ...prev,
          mount: eventDetails.photographer.framePricingUsd["Single Weight Matboard"][cartItem.imageSize],
        }))
      }
      if(cartItem.addOn.includes("Black mat") ||  cartItem.addOn.includes("White mat")){
        setMount(cartItem.addOn.includes("Black") ?  "Black mat" : "White mat")
        setFramePrice(prev=>({
          ...prev,
          mount: eventDetails.photographer.framePricingUsd["Mat"][cartItem.imageSize],
        }))
      }
      if(cartItem.addOn.includes("Clear Glass")){
        setGlass("Clear Glass")
        setFramePrice(prev=>({
          ...prev,
          glass: eventDetails.photographer.framePricingUsd["Clear Glass"][cartItem.imageSize],
        }))
      }
      if(cartItem.addOn.includes("Non-Glare Glass")){
        setGlass("Non-Glare Glass")
        setFramePrice(prev=>({
          ...prev,
          glass: eventDetails.photographer.framePricingUsd["Non-Glare Glass"][cartItem.imageSize],
        }))
      }
    }
  },[])


  return (
    <div className="w-full pt-3">
      {cropImage ? (
        <div className={`w-full`}>
          <div className="sm:flex justify-between mb-2.5">
            <Text as="p" className="text-base mb-4 font-medium">
              Crop the image to your liking
            </Text>
            <div className="flex justify-center">
              <Button
                disabled={imageStatus == "loaded" ? false : true}
                className="mr-3"
                onClick={updateCroppedCoverImage}
                text="Crop Image"
              />
              <Button className="mr-3" onClick={cancelCropping} text="Cancel" />
            </div>
          </div>
          <div className="sm:flex sm:justify-start sm:gap-12">
            <div
              className={`relative sm:w-3/4  ${
                !isPhotoLandscape && ["5x30", "10x30"].includes(frameSize)
                  ? "!w-[100px]"
                  : ""
              }`}
              style={aspectRatio.style}
            >
              <Cropper
                image={photo.printStoreSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio.ratio}
                showGrid={true}
                viewMode={3}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                objectFit={"horizontal-cover"}
                onMediaLoaded={handleImageLoaded}
              />
            </div>
            <div className="controls mt-2 sm:w-1/4 text-center">
              <Text className="text-base mb-4 font-medium">Zoom</Text>
              <div className="flex justify-center items-center mx-auto py-4">
                <span
                  className="text-base md:text-lg pt-2 mr-2 cursor-pointer font-bold"
                  onClick={() => {
                    decrementZoom();
                  }}
                >
                  -
                </span>
                <SliderComponent
                  defaultValue={zoom}
                  min={1}
                  max={MAX}
                  step={1}
                  onChange={(e) => {
                    setZoom(e);
                  }}
                />
                <span
                  className="text-base md:text -ml-3 pt-1 cursor-pointer font-bold"
                  onClick={() => {
                    incrementZoom();
                  }}
                >
                  +
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="lg:flex lg:gap-4">
          <div className="lg:w-3/5 sm:w-full lg:mx-10 md:mx-10 order-2">
            <div className="flex justify-between items-center mb-4 md:mb-8">
              <div className="flex items-center">
                <div className="text-base md:text-2xl font-bold">
                  All products
                  {/* <span className="text-xs md:text-base text-stone-400 block lg:inline-block lg:ml-4">
                    Showing 4 out of 4
                  </span> */}
                </div>
              </div>
            </div>
            <ul className="flex flex-wrap items-center justify-between gap-x-4 gap-y-4 md:grid md:grid-cols-2 md:gap-6">
              {[SOHO_FRAME, PRINT, WALLETS, MINIS].map((el, id) => (
                <li
                  key={el}
                  className={`md:grid md:grid-flow-rows ${
                    frameType === el
                      ? "shadow-dark-input-bottom-right dark:shadow-dark-input rounded-md md:rounded-xl"
                      : ""
                  } `}
                  onClick={() => {
                    if (el !== frameType) {
                      if ([SOHO_FRAME, PRINT].includes(el)) {
                        const allFrames = [];
                        const elCopy = INTERNAL_PRODUCT_KEY[PRINT] ?? el;
                        Object.keys(
                          framePricing.current[elCopy.split(" ")[0].toLowerCase()]
                        ).forEach((e) => {
                          allFrames.push({
                            size: e,
                            price:
                              framePricing.current[elCopy.split(" ")[0].toLowerCase()
                              ][e],
                          });
                        });

                        setAllFrameSizes(allFrames);
                        setFrameSize(allFrames[0].size);
                        setFramePrice({
                          frame: allFrames[0].price,
                        });
                      } else {
                        setFramePrice({
                          frame: framePricing.current[el.toLowerCase()],
                        });
                        setFrameSize(el.toLowerCase());
                        setAllFrameSizes([]);
                      }
                      setLustreCoating(false);
                      setFrameType(el);
                      setMount("");
                      setGlass("None");
                    }
                  }}
                >
                  <img
                    className={`cursor-pointer w-[75px] md:w-auto h-[75px] md:h-auto rounded-xl ${
                      frameType === el
                        ? "p-[10px] "
                        : "shadow-dark-input-bottom-right dark:shadow-dark-input"
                    }`}
                    style={{
                      borderRadius: "10px",
                    }}
                    src={IMAGES[id]}
                  />
                  <h1 className="my-2 text-xs md:text-base lg:text-xl font-medium pl-1 pr-1 md:pr-0 md:pl-4">
                    {el}
                  </h1>
                </li>
              ))}
            </ul>
          </div>
          <div className="lg:w-1/2 sm:w-full border-stone-600 lg:border-r">
            <div
              className={`p-5 ${
                [WALLETS, MINIS, PRINT].includes(frameType)
                  ? "pb-5 pt-0"
                  : "pb-0 mb-10 pt-0"
              } mt-5 md:mt-0 lg:px-0 flex justify-between relative  lg:w-[550px] lg:pr-10`}
            >
              <div
                className={`${
                  frameType === SOHO_FRAME
                    ? "relative min-w-[150px]"
                    : "min-w-[200px]"
                }  w-full  ${
                  !isPhotoLandscape
                    ? ["5x30", "10x30"].includes(frameSize)
                      ? "w-[150px]"
                      : ["5x30", "10x30"].includes(frameSize)
                      ? ""
                      : ""
                    : ""
                }`}
                id="viewPage"
                style={
                  [WALLETS, MINIS].includes(frameType) ? {} : aspectRatio.style
                }
              >
                {frameType === SOHO_FRAME ? (
                  <>
                    <img
                      className="absolute top-0 w-[25px] md:w-[50px]"
                      src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}/corner-tl.png`}
                      alt="topLeftCorner"
                    />
                    <img
                      className="absolute top-0 left-[15px]  md:left-[28px] w-[calc(100%-30px)]  md:w-[calc(100%-56px)] h-[12px] md:h-[25px]"
                      src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}/verticalStrip.jpg`}
                      alt="horizontalStripTop"
                    />
                    <img
                      className="absolute top-0 right-0 w-[25px] md:w-[50px]"
                      src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}/corner-tl.png`}
                      style={{ transform: "rotate(90deg)" }}
                      alt="topRightCorner"
                    />
                    <img
                      className="absolute left-0 top-[15px] w-[12px] h-[calc(100%-30px)] md:top-[28px] md:w-[25px] md:h-[calc(100%-56px)]"
                      src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}/verticalStrip.jpg`}
                      alt="verticalStripLeft"
                    />
                    <img
                      className="absolute right-0 top-[15px] w-[12px] h-[calc(100%-30px)] md:top-[28px] md:w-[25px] md:h-[calc(100%-56px)]"
                      src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}/verticalStrip.jpg`}
                      alt="verticalStripRight"
                    />
                    <img
                      className="absolute w-[25px] md:w-[50px] bottom-0"
                      style={{ transform: "rotate(270deg)" }}
                      src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}/corner-tl.png`}
                      alt="bottomLeftCorner"
                    />
                    <img
                      className="absolute bottom-0  left-[15px]  md:left-[28px] w-[calc(100%-30px)]  md:w-[calc(100%-56px)] h-[12px] md:h-[25px]"
                      src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}/verticalStrip.jpg`}
                      alt="horizontalStripBottom"
                    />
                    <img
                      className="absolute w-[25px] md:w-[50px] bottom-0 right-0"
                      style={{ transform: "rotate(180deg)" }}
                      src={`https://picsniff.s3.ap-south-1.amazonaws.com/frames/miller/soho/${frameColor}/corner-tl.png`}
                      alt="bottomRightCorner"
                    />
                  </>
                ) : null}
                <div
                  className={
                    [WALLETS, MINIS, PRINT].includes(frameType)
                      ? "h-full"
                      : `img-layout-tooltip absolute top-1/2  -translate-y-1/2  ${
                          mount?.includes("mat")
                            ? "h-[calc(100%-24px)] w-[calc(100%-25px)] md:h-[calc(100%-50px)] md:w-[calc(100%-50px)] flex items-center justify-center"
                            : "h-[calc(100%-24px)] w-[calc(100%-25px)] md:h-[calc(100%-50px)] md:w-[calc(100%-50px)]"
                        } ${
                          mount?.includes("White mat") ? "bg-white" : "bg-black"
                        } ${
                          frameType === SOHO_FRAME
                            ? "left-1/2 -translate-x-1/2"
                            : "left-0 -translate-x-0 !h-full !w-[calc(100%-40px)]"
                        } ${
                          [SOHO_FRAME, PRINT].includes(frameType)
                            ? ""
                            : "!bg-transparent "
                        }`
                  }
                >
                  <img
                    className={`cursor-pointer ${
                      mount?.includes("mat")
                        ? "w-[90%] h-[90%]"
                        : "w-full h-full"
                    } block z-[0] ${
                      [SOHO_FRAME, PRINT].includes(frameType)
                        ? "object-cover"
                        : "object-contain"
                    } `}
                    src={cropperImage.imageSrc}
                  />
                  <div
                    className={
                      [WALLETS, MINIS, PRINT].includes(frameType)
                        ? "absolute right-4 top-8 md:right-12 md:top-8"
                        : "absolute right-2 top-2"
                    }
                  >
                    <IconButton
                      className="mr-3 cursor-pointer !bg-dark-gray"
                      onClick={(e) => {
                        setCropImage(!cropImage);
                      }}
                    >
                      <CropIcon />
                    </IconButton>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              {frameType === SOHO_FRAME ? (
                <>
                  <div
                    className="absolute flex items-center text-xs top-1/2 -translate-y-1/2 right-[94%] md:right-[101%]"
                    style={{
                      textOrientation: "mixed",
                      writingMode: "vertical-lr",
                    }}
                  >
                    <span>{"<"}</span>
                    <div className="h-[30px] w-[1px] bg-black" />
                    <span className="my-4">
                      {MILLER_FRAME_SIZE_INCHES[frameSize].split("X")[
                        isPhotoLandscape ? 0 : 1
                      ] + " inches"}
                    </span>
                    <div className="h-[30px] w-[1px] bg-black" />
                    <span>{">"}</span>
                  </div>
                  <div className="absolute text-xs top-[104%] md:top-[102%] left-1/2 -translate-x-1/2 flex items-center">
                    <span>{"<"}</span>
                    <div className="w-[30px] h-[1px] bg-black" />
                    <span className="mx-4 w-max">
                      {MILLER_FRAME_SIZE_INCHES[frameSize].split("X")[
                        isPhotoLandscape ? 1 : 0
                      ] + " inches"}
                    </span>
                    <div className="w-[30px] h-[1px] bg-black" />
                    <span>{">"}</span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="px-6 lg:px-[0] xl:pr-20 grid">
              <div className={`text-xl sm:text-[2.625rem] font-bold mt-4`}>
                $&nbsp;
                {Object.keys(framePrice)
                  .reduce((acc, el) => {
                    acc += +framePrice[el];
                    return acc;
                  }, 0)
                  .toFixed(2)}
              </div>
              <div className="text-xs md:text-xl mt-2 font-medium">
                {frameType}
                {frameType === WALLETS ? (
                  <div className="text-xs">Pack of 4</div>
                ) : (
                  ""
                )}
                {frameType === MINIS ? (
                  <div className="text-xs">Pack of 8</div>
                ) : (
                  ""
                )}
              </div>
              {frameType === SOHO_FRAME ? (
                <div className="mt-4">
                  <div className="text-xs font-medium mb-4">Frame colour</div>
                  <ul className="flex lg:mr-36 md:mr-36">
                    {["gold", "black", "white", "brown"].map((el) => (
                      <li
                        key={el}
                        className={`cursor-pointer mr-[30px] w-10 h-10 flex items-center justify-center ${
                          frameColor === el
                            ? " shadow-dark-input-bottom-right dark:shadow-dark-input"
                            : ""
                        } `}
                        onClick={() => {
                          setFrameColor(el);
                        }}
                      >
                        <span
                          className="dot w-5 h-5 shadow-dark-input-bottom-right dark:shadow-dark-input"
                          style={{
                            backgroundColor: COLOR[el],
                          }}
                          title={el}
                        ></span>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <></>
              )}
              <div className="lg:flex justify-between my-4">
                {allFrameSizes.length ? (
                  <div className="flex-row">
                    <div className="text-xs md:text-base mb-3 font-medium">
                      Size
                    </div>

                    <Dropdown
                      options={allFrameSizes}
                      objectKey={"size"}
                      current={frameSize}
                      setCurrent={() => {}}
                      onItemSelected={(el) => {
                        setFrameSize(el.size);
                        setFramePrice((prev) => ({
                          ...prev,
                          frame: el.price,
                        }));
                        setZoom(1);
                        setCrop({ x: 0, y: 0 });
                      }}
                      buttonClassname={DROPDOWN_BUTTON_CLASSES}
                    />
                    {/* {allFrameSizes.map((el, id) => (
                        <li
                          key={id}
                          onClick={() => {
                            setFrameSize(el.size);
                            setFramePrice((prev) => ({
                              ...prev,
                              frame: el.price,
                            }));
                            setZoom(1);
                            setCrop({ x: 0, y: 0 });
                          }}
                          className={`flex cursor-pointer items-center sm:mr-5 mr-[10px] dark:shadow-dark-input rounded-[10px] p-3 sm:mb-5 mb-[10px] ${
                            frameSize == el.size
                              ? "shadow-inner-all dark:shadow-dark-inner-all"
                              : "shadow-dark-input-bottom-right "
                          } h-[44px] sm:w-[7.25rem] sm:h-[2.75rem]`}
                        >
                          <input
                            type="radio"
                            className="w-4 h-4 sm:w-5 sm:h-5 cursor-pointer  accent-cyan-600 downloadRadioButton"
                            checked={frameSize == el.size ? "checked" : ""}
                            onChange={() => {
                              setFrameSize(el.size);
                              setFramePrice((prev) => ({
                                ...prev,
                                frame: el.price,
                              }));
                              setZoom(1);
                              setCrop({ x: 0, y: 0 });
                            }}
                          />
                          <span className="pl-2 text-[11px] sm:text-sm md:text-sm">
                            {el.size}
                          </span>
                        </li>
                      ))} */}
                  </div>
                ) : (
                  <></>
                )}
                <div className="mt-4 md:mt-0">
                  <div className="text-xs md:text-base mb-3 font-medium md:text-end">
                    Quantity
                  </div>
                  <div className="flex">
                    <div className="flex items-center shadow-dark-input-bottom-right dark:shadow-dark-input rounded-lg p-2 px-3  h-[32px] justify-center">
                      <span
                        className="pl-2 text-sm md:text-sm font-bold cursor-pointer"
                        onClick={() => {
                          decrementQty();
                        }}
                      >
                        -
                      </span>
                      <span className="pl-2 text-[11px] sm:text-sm md:text-sm">
                        {quantity}
                      </span>
                      <span
                        className="pl-2 text-sm md:text-sm font-bold cursor-pointer"
                        onClick={() => {
                          incrementQty();
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex justify-between">
                {framePricing.current.luster[frameSize] ? (
                  <div className="mb-4">
                    <div className="text-xs md:text-base mb-3 md:mt-0 mt-4 font-medium">
                      Lustre Coating
                    </div>
                    <Dropdown
                      options={["Yes", "No"]}
                      current={lustreCoating ? "Yes" : "No"}
                      setCurrent={() => {}}
                      onItemSelected={() => {
                        setLustreCoating((prev) => !prev);
                        if (lustreCoating) {
                          setFramePrice((prev) => ({
                            ...prev,
                            lustre: 0,
                          }));
                        } else {
                          setFramePrice((prev) => ({
                            ...prev,
                            lustre: framePricing.current.luster[frameSize],
                          }));
                        }
                      }}
                      buttonClassname={DROPDOWN_BUTTON_CLASSES}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {(framePricing.current["Double Weight Matboard"][frameSize] ||
                  framePricing.current["Single Weight Matboard"][frameSize] ||
                  framePricing.current["Mat"][frameSize]) &&
                frameType === SOHO_FRAME ? (
                  <div className="mb-4">
                    <div
                      className={`text-xs ${
                        framePricing.current.luster[frameSize]
                          ? "md:text-end"
                          : ""
                      } md:text-base mb-3  font-medium`}
                    >
                      Mount & Mat
                    </div>
                    <Dropdown
                      options={matAndMount}
                      buttonClassname={DROPDOWN_BUTTON_CLASSES}
                      setCurrent={() => {}}
                      current={mount}
                      onItemSelected={(el) => {
                        if (el.includes("mat")) {
                          setMount(el);
                          setGlass("Non-Glare Glass");
                          setFramePrice((prev) => ({
                            ...prev,
                            mount: framePricing.current["Mat"][frameSize],
                            glass:
                              framePricing.current["Non-Glare Glass"][
                                frameSize
                              ],
                          }));
                        } else {
                          setMount(el);
                          setFramePrice((prev) => ({
                            ...prev,
                            mount: framePricing.current[el][frameSize],
                          }));
                        }
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {(framePricing.current["Non-Glare Glass"][frameSize] ||
                framePricing.current["Clear Glass"][frameSize]) &&
              frameType === SOHO_FRAME ? (
                <div>
                  <div className="text-xs md:text-base mb-3  font-medium">
                    Glass
                  </div>
                  <Dropdown
                    options={glassOptions}
                    current={glass}
                    setCurrent={() => {}}
                    buttonClassname={DROPDOWN_BUTTON_CLASSES}
                    onItemSelected={(el) => {
                      if (el === "None") {
                        setGlass("Non-Glare Glass");
                        setFramePrice((prev) => ({
                          ...prev,
                          glass: framePricing.current.luster[frameSize],
                        }));
                      } else {
                        setGlass(el);
                        setFramePrice((prev) => ({
                          ...prev,
                          glass: framePricing.current[el][frameSize],
                        }));
                      }
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex justify-center sm:py-12 pt-[30px] pb-[40px]">
              <Button onClick={onCartButtonClicked} text="Add to Cart" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OtherFrames;
