import { useEffect, useState, useRef } from "react";

import { getPhotogrpaherDetails } from "../../apis/privacyPolicy";
import { postSupportRequest } from "../../apis/support";
import Button from "../../components/common/Button";
import {
  InputEmail,
  InputPhone,
  InputText,
} from "../../components/common/Input";
import { EMIAL_REGEX } from "../../constants";
import Error from "../Errors";
import Loading from "../Loading";

const LABEL_CLASSNAME = "text-xs md:text-sm";

const NAME_ERROR = "Please Enter Valid Name";

const EMAIL_ERROR = "Please Enter Valid Email";

const FIELD_ERROR = "This field is a required field.";

const INPUT_CLASSNAME = "mb-4";

const PARAGRAPH_CLASSES = "my-4 leading-[18px]";

const Support = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [fname, setFname] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [eventName, setEventName] = useState();
  const [message, setMessage] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [photographerData, setPhotographerData] = useState({});
  const [inputError, setInputError] = useState({
    fnameError: "",
    emailError: "",
    mobileError: "",
    eventNameError: "",
    messageError: "",
  });
  const requestSubmitted = useRef();

  const checkFname = () => {
    if (fname === "" || fname?.trim().length < 2) {
      setInputError((prev) => ({ ...prev, fnameError: NAME_ERROR }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, fnameError: "" }));
      return true;
    }
  };

  const checkField = (id) => {
    if (id === "mobile") {
      if (mobile === "") {
        setInputError((prev) => ({ ...prev, mobileError: FIELD_ERROR }));
        return false;
      } else {
        setInputError((prev) => ({ ...prev, mobileError: "" }));
        return true;
      }
    } else if (id === "eventName") {
      if (eventName === "") {
        setInputError((prev) => ({ ...prev, eventNameError: FIELD_ERROR }));
        return false;
      } else {
        setInputError((prev) => ({ ...prev, eventNameError: "" }));
        return true;
      }
    } else {
      if (message === "") {
        setInputError((prev) => ({ ...prev, messageError: FIELD_ERROR }));
        return false;
      } else {
        setInputError((prev) => ({ ...prev, messageError: "" }));
        return true;
      }
    }
  };

  const checkEmail = () => {
    if (email === "" || EMIAL_REGEX.exec(email) === null) {
      setInputError((prev) => ({ ...prev, emailError: EMAIL_ERROR }));
      return false;
    } else {
      setInputError((prev) => ({ ...prev, emailError: "" }));
      return true;
    }
  };

  const handleSubmit = async () => {
    if (
      checkEmail() ||
      checkField("mobile") ||
      checkField("message") ||
      checkField("eventName") ||
      checkFname()
    ) {
      setLoading(true);
      requestSubmitted.current = true;
      try {
        const postData = {
          name: fname,
          email,
          phone: mobile,
          eventName,
          message,
        };
        const data = await postSupportRequest(postData);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else setIsDisabled(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && fname.length === 0) {
      e.preventDefault();
    }
  };

  const photographerDetails = async () => {
    try {
      const domain = encodeURI(
        "https://" + window.location.host.replace("algomage.ai", "algomage.com")
      );
      const res = await getPhotogrpaherDetails(domain);
      setPhotographerData(res.data.data);
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    photographerDetails();
  }, []);

  const headerJSX = (
    <div className="bg-theme-light dark:bg-dark-gray rectangle-copy shadow-2xl px-[18px] md:px-10 !min-h-0">
      <div className="w-full text-dark-gray dark:text-light-gray">
        <div className="w-[calc(100vw-48px)] md:w-full md:cursor-pointer flex relative gap-4 md:gap-8  md:justify-start items-center z-1  pics__header-logo-static z-10">
          {photographerData?.logo ? 
          <img
              className={`w-10 h-10 object-contain`}
              width={100}
              src={photographerData.logo}
              alt="logo"
            />:
          <h3
            className="leading-[1] text-2xl
               mr-[8px] md:mr-4"
          >
            {photographerData?.name ?? "Algomage"}
          </h3>}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (!fname || !email || !mobile || !eventName || !message)
      setIsDisabled(true);
    else setIsDisabled(false);
  }, [message, fname, mobile, email, eventName]);

  const onBlur = (e) => {
    if (e.target.id === "fname") checkFname();
    else if (e.target.id === "email") checkEmail();
    else if (["mobile", "eventName", "message"].includes(e.target.id))
      checkField(e.target.id);
  };

  return loading ? (
    <Loading />
  ) : error ? (
    <div className="text-center">
      <Error
        message="Something went wrong"
        text="Sorry for the inconvenience. Please try again after some time"
      />
    </div>
  ) : requestSubmitted.current ? (
    <>
    {headerJSX}
    <div className="p-2 md:p-6 flex items-center flex-col">
      <h1 className=" text-2xl md:text-[32px]  font-bold my-5">
        Support Request
      </h1>
      <p className={PARAGRAPH_CLASSES}>
        Thanks for contacting support. We shall get back to you with the
        resolution at the earliest.
      </p>
    </div>
    </>
  ) : (
    <>
      {headerJSX}
      <div className="p-2 md:p-6 w-full md:w-1/2 mx-auto">
        <h1 className=" text-2xl md:text-[32px]  font-bold my-5">
          Support Request
        </h1>
        <InputText
          tabIndex={1}
          className={INPUT_CLASSNAME}
          label="Name"
          required
          value={fname}
          onChange={setFname}
          error={inputError.fnameError}
          onKeyDown={handleKeyDown}
          lableClassName={LABEL_CLASSNAME}
          autoFocus
          onBlur={onBlur}
          id="fname"
        />
        <InputEmail
          tabIndex={2}
          className={INPUT_CLASSNAME}
          label="Email"
          required
          value={email}
          onChange={setEmail}
          error={inputError.emailError}
          onKeyDown={handleKeyDown}
          lableClassName={LABEL_CLASSNAME}
          onBlur={onBlur}
          id="email"
        />
        <InputPhone
          tabIndex={3}
          label="Phone"
          className={INPUT_CLASSNAME}
          value={mobile}
          onChange={setMobile}
          onKeyDown={handleKeyDown}
          lableClassName={LABEL_CLASSNAME}
          error={inputError.mobileError}
          required
          id="mobile"
        />
        <InputText
          tabIndex={4}
          className={`${INPUT_CLASSNAME} mt-4`}
          label="Event name"
          required
          value={eventName}
          onChange={setEventName}
          error={inputError.eventNameError}
          onKeyDown={handleKeyDown}
          lableClassName={LABEL_CLASSNAME}
          onBlur={onBlur}
          id="eventName"
        />
        <div className="flex flex-col">
          <label
            htmlFor="message"
            className={`md:text-sm text-left font-medium text-black-gray dark:text-light-gray`}
          >
            Message*
          </label>
          <textarea
            tabIndex={6}
            style={{ resize: "none" }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={7}
            id="message"
            className={`rounded-xl bg-transparent px-[16px] py-[10px] input-shadow-fix 
                    ${
                      !message
                        ? `shadow-light-input dark:shadow-dark-input `
                        : `shadow-inner-all dark:shadow-dark-inner-all`
                    } 
                     ${
                       inputError.messageError
                         ? "outline-1 border-2 border-[#e16a6a]"
                         : "outline-none"
                     } 
                     mt-1 text-dark-gray1 dark:text-light-gray`}
          />
          {inputError.messageError && (
            <label className="text-sm text-[#e16a6a]">{error}</label>
          )}
        </div>

        <div className="flex justify-center mt-4">
          <Button disabled={isDisabled} onClick={handleSubmit} text="Submit" />
        </div>
      </div>
    </>
  );
};

export default Support;
