import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import "./existingFolder.css";
import HorizontalDotsOptions from "../../../assets/icons/HorizontalDotsOptions";
import { useUser } from "../../../Store/user";
import { useEvent } from "../../../Store/event";
import { duplicateList, deleteFavoriteList } from "../../../apis/favourites";
import { useFavourites } from "../../../Store/favourites";
import FavoritiesDialog from "../../../pages/GalleryView/components/FavoritiesDialog";
import { ThemeContext } from "../../App";
import { favouriteDownload } from "../../../apis/download";
import DialogWrapper from "../Dialog";
import DownloadForms from "../DownloadFolders";

export const ExistingFolder = ({ folder, onFavoriteFolderClickHandler }) => {
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  let { user } = useUser();
  const eventDetails = useEvent().response;
  const { setFavList, setCurrentFav, favList } = useFavourites();
  const route = useLocation();
  let { slug } = useParams();

  const [actionOptionsVisible, setActionOptionsVisible] = useState(false);
  const [coverImg, setCoverImg] = useState();
  const [renameListPopUp, setRenameListPopUp] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [downloadPopUp, setDownloadPopUp] = useState(false);

  if (eventDetails.data.stub) slug = eventDetails.data.stub;

  const showMoreActions = (e) => {
    setActionOptionsVisible(!actionOptionsVisible);
    e.stopPropagation();
  };

  function handleWindowSizeChange() {
    if (window.innerWidth <= 768) setIsMobile(true);
    else setIsMobile(false);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const corporate =
      eventDetails.data.tags.length > 0
        ? eventDetails.data.tags.some((el) => el.title === "Corporate")
        : false;
    if (folder.count > 0 || corporate) {
      setCoverImg(folder.coverImage);
    } else {
      setCoverImg(eventDetails.data.coverImageUrl);
    }
  }, []);

  const myStyle = {
    backgroundImage: `url('${coverImg}')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    // height: isMobile ?'60.19%':'67.0299728%'
  };

  const duplicate = async () => {
    const corporate =
      eventDetails.data.tags.length > 0
        ? eventDetails.data.tags.some((el) => el.title === "Corporate")
        : false;
    let res = await duplicateList(
      eventDetails.data.id,
      folder.id,
      user.accessToken,
      corporate
    );
    // console.log(res)
    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: "Duplicate List created ",
      });
      setFavList(res.response.data);
    }
  };

  const deleteFavorite = async () => {
    const corporate =
      eventDetails.data.tags.length > 0
        ? eventDetails.data.tags.some((el) => el.title === "Corporate")
        : false;
    let res = await deleteFavoriteList(
      eventDetails.data.id,
      folder.id,
      user.accessToken,
      corporate
    );
    /** In response get the whole list as an array the way we get for get list 
      [
        {
          "id": 162381,
          "name": "My Favorite",
          "maxFavouritesAllowed": null,
          "isDefault": true
        }
      ]  
    */
    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: folder.name + " deleted",
      });
      setFavList(res.response.data);
      if (!res.response?.data?.length) {
        setCurrentFav({
          name: null,
          id: null,
          count: 0,
        });
      }
    }
  };

  const downloadFavorite = async (e) => {
    if (eventDetails.data.downloadBulkImageSize != "both") {
      let downloadSize;
      if (eventDetails.data.downloadBulkImageSize == "original") {
        downloadSize = 0;
      }
      if (eventDetails.data.downloadBulkImageSize == "optimized") {
        downloadSize = 1;
      }

      const res = await favouriteDownload(
        eventDetails.data.id,
        user.email,
        downloadSize,
        folder.id,
        user.accessToken,
        "favourites"
      );

      if (!res.error) {
        // console.log(res)
        window.Toast.fire({
          icon: "success",
          title: res.response.message
            ? res.response.message
            : "Download in process, please wait for it to complete you wil receive an email shortly.",
        });
      } else {
        window.Toast.fire({
          icon: "error",
          title: res.e.message[0],
        });
      }
      if (res.response.dl) {
        // let url = window.location.protocol + '//' +window.location.host+'/downloadNow/'+eventDetails.data.slug+'/'+Base64.encode(res.response.dl.toString())
        let url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/downloadNow/" +
          eventDetails.data.slug +
          "/" +
          res.response.dl;
        // console.log(url);
        window.open(url, "_blank").focus();
      }
    } else {
      setDownloadPopUp(e);
    }
  };

  const renameList = () => {
    setRenameListPopUp(true);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setActionOptionsVisible(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const showDeleteFavWarning = () => {
    let textClass = "";

    if (theme === "dark") {
      textClass = "light-text-dark-gray-1-swal";
    } else textClass = "dark-text-light-gray-1-swal";

    Swal.fire({
      icon: "warning",
      title: "Warning!",
      text: `Are you sure you want to permanently delete favourite list ${folder.name}?`,
      showCancelButton: true,
      confirmButtonText: `Delete`,
      customClass: {
        cancelButton: textClass,
      },
      showLoaderOnConfirm: true,
      preConfirm: deleteFavorite,
    });
  };

  return (
    <>
      <div
        className="shadow-dark-input-bottom-right dark:shadow-dark-input rounded-[4px] md:rounded-[10px] cursor-pointer h-auto text-sm dark:text-light-gray flex justify-center relative"
        onClick={(e) => {
          if (folder.count > 0) {
            onFavoriteFolderClickHandler(e);
            navigate(
              `/favourites/${slug ? `${slug}/` : "esre12s3de/"}${eventDetails.data.slug}/${
                folder.id
              }${route.search ? route.search : ""}`
            );
            setCurrentFav(folder);
          } else {
            window.Toast.fire({
              icon: "error",
              title: "No images in favoutite list",
            });
          }
        }}
      >
        <div className="w-full">
          <div
            className="folder-thumbnail rounded-[4px] md:rounded-t-xl relative w-full aspect-video"
            style={myStyle}
          >
            {/* {!isMobileView && ( */}
            {/* {folderNumber == '0' && (
                    <div className='flex justify-between items-center absolute container-padding-small md:container-padding'>
                        <div className='w-[40px] h-[20px] rounded-[5px] bg-[#a64492] text-[8px] text-center decoration-[#eee] pl-[5px] pr-[5px] sm:w-[70px] sm:h-[30px] sm:rounded-[8px] sm:text-[14px]  sm:pl-[5px] sm:pr-[7px] sm:pt-[5px] sm:pb-[7px]'>Default</div>
                    </div>
                )} */}
          </div>
          <div className="text-sm dark:text-light-gray md:px-[30px] md:py-6 px-[6px] pt-[6px] pb-[9px] md:container-padding">
            <div className="flex justify-between md:mb[0px] ">
              <span
                className="folder-name text-xs md:text-2xl font-medium overflow-hidden md:h-[33%]"
                title={folder.name}
              >
                {folder.name.length > 20
                  ? folder.name.substring(0, 20) + "..."
                  : folder.name}
              </span>
              {/* {!isMobileView && ( */}
              <span className="relative" onClick={showMoreActions}>
                <HorizontalDotsOptions
                  color={theme === "dark" ? "white" : "black"}
                  width={isMobile ? "16" : "30"}
                  height={isMobile ? "16" : "30"}
                />
                {actionOptionsVisible && (
                  <div
                    className={`absolute p-5 bg-theme-light dark:bg-dark-gray shadow-dark-input-bottom-right dark:shadow-dark-input w-40 md:w-44 right-[0] ${
                      !eventDetails.data.downloadBulkImageSize
                        ? "lg:top-[-400%]"
                        : "lg:top-[-480%]"
                    } z-[3] rounded-[10px]`}
                    ref={wrapperRef}
                  >
                    {eventDetails.data.downloadBulkImageSize ? (
                      <div
                        className={` ${
                          folder.count === 0
                            ? "cursor-not-allowed text-slate-400 dark:text-light-gray"
                            : "cursor-pointer text-dark-gray1 dark:text-light-gray"
                        } flex flex-row mb-3 text-xs md:text-sm`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (folder.count > 0) downloadFavorite(true);
                          else {
                            window.Toast.fire({
                              icon: "error",
                              title: "No images in favourite list",
                            });
                          }
                        }}
                      >
                        Download
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className={`${
                        favList.length > 1
                          ? "cursor-pointer text-dark-gray1 dark:text-light-gray"
                          : "cursor-not-allowed text-slate-400 dark:text-light-gray"
                      } flex flex-row mb-3 text-xs md:text-sm`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (favList.length > 1) {
                          showDeleteFavWarning();
                        }
                      }}
                    >
                      Delete
                    </div>
                    <div
                      className="flex flex-row cursor-pointer text-dark-gray1 dark:text-light-gray mb-3 text-xs md:text-sm"
                      onClick={() => {
                        duplicate();
                      }}
                    >
                      Duplicate Folder
                    </div>
                    <div
                      className="flex flex-row cursor-pointer text-dark-gray1 dark:text-light-gray text-xs md:text-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        renameList();
                      }}
                    >
                      Rename Folder
                    </div>
                  </div>
                )}
              </span>

              {/* )} */}
            </div>
            <div className="photos-count text-xs md:text-lg md:mt-3 w-full aspect-bottom-card">
              {/* 10/239 photos */}
              {folder.count}
              {folder.maxFavouritesAllowed ? (
                "/" + folder.maxFavouritesAllowed
              ) : (
                <></>
              )}
              {` photos`}
            </div>
          </div>
        </div>
      </div>
      <FavoritiesDialog
        open={renameListPopUp}
        close={() => {
          setRenameListPopUp(false);
        }}
        rename={renameListPopUp}
        oldListName={folder.name}
        listId={folder.id}
      />
      <DialogWrapper
        modal="downloadModal"
        open={downloadPopUp}
        close={() => {
          setDownloadPopUp(false);
        }}
        description={""}
        className="mt-[0.375rem] text-base"
        title={"Download Photos"}
        closeClassname="self-baseline"
      >
        <DownloadForms
          showFavouriteImages={true}
          listId={folder.id}
          close={(e) => {
            setDownloadPopUp(e);
          }}
        />
      </DialogWrapper>
    </>
  );
};

export default ExistingFolder;
