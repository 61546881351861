import React, { useEffect } from "react";

import BigTick from "../../../assets/icons/BigTick.jsx";
import Text from "../../../components/common/Text";
import InstagramIcon from "../../../assets/images/Instagram-icon.png";
import WebIcon from "../../../assets/images/web_icon.png";
import FacebookIcon from "../../../assets/images/Facebook-icon.png";
import YoutubeIcon from "../../../assets/images/Youtube-icon.png";
import TwitterIcon from "../../../assets/images/Twitter-icon.png";
import { useEvent } from "../../../Store/event.js";
import Header from "../../GalleryView/components/Header/index.jsx";
import { useUser } from "../../../Store/user.js";
import {
  BMS_PHOTOGRAPHER_ID,
  PRE_REGISTRATION_MESSAGE,
} from "../../../constants/index.js";

function RegisterStatus({ isFullAccess }) {
  const eventDetails = useEvent().response;

  const { resetUser } = useUser();

  const socialMediaIcons = [
    {
      name: "website",
      icon: WebIcon,
    },
    {
      name: "instagram",
      icon: InstagramIcon,
    },
    {
      name: "facebook",
      icon: FacebookIcon,
    },
    {
      name: "youtube",
      icon: YoutubeIcon,
    },
  ];

  BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) &&
    socialMediaIcons.push({
      name: "twitter",
      icon: TwitterIcon,
    });

  useEffect(() => {
    return () => resetUser();
  }, []);

  return (
    <div className="relative">
      <div
        className={` ${
          isFullAccess ? "" : "h-screen grid lg:grid-cols-coverImg"
        }  bg-theme-light dark:bg-dark-gray relative`}
      >
        {!isFullAccess && <Header isLogin={true} h={true} />}
        {/* <SideCoverImage /> */}
        <div
          className={`bg-theme-light dark:bg-dark-gray  ${
            isFullAccess
              ? "px-8"
              : "lg:pb-[60px] p-8 lg:pl-24 lg:pt-[85px lg:pr-16 "
          } relative flex flex-col items-center justify-start lg:items-start`}
        >
          <div
            className={`w-full flex flex-col items-center ${
              isFullAccess ? "" : "lg:items-start"
            }`}
          >
            <BigTick className="w-[60px] h-[60px] md:w-[100px] md:h-[100px]" />
            <Text
              as="p"
              className="text-dark-gray1 dark:text-light-gray font-medium md:hidden pt-8"
            >
              Done
            </Text>
            <Text
              as="p"
              className="text-xs md:text-3xl text-dark-gray1 dark:text-light-gray md:font-medium pt-2 md:pt-8 md:pb-1 text-center lg:text-left lg:w-3/4"
            >
              {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
                ? "Woohoo! You did great!"
                : ""}
            </Text>
            <Text
              as="p"
              className="text-xs md:text-base text-dark-gray1 dark:text-light-gray md:font-medium md:pb-10 text-center lg:text-left  lg:w-3/4"
            >
              {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id) ? (
                <div className="md:mt-2">
                  Now all you’ve got to do is head to Lollapalooza, and flash
                  the brightest smiles you can! <br /> We’ll send you all your
                  pictures over email.{" "}
                  <div className="mt-2">
                    {" "}
                    So go on, share this with your fellow concert-goers and
                    spread the excitement!{" "}
                    <span
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        window.Toast.fire({
                          title: "Link Copied",
                        });
                      }}
                      className="text-primary-green underline cursor-pointer"
                    >
                      Click Here
                    </span>{" "}
                  </div>
                </div>
              ) : (
                PRE_REGISTRATION_MESSAGE
              )}
            </Text>
          </div>
          <div
            className={`text-center ${
              isFullAccess ? "items-center" : "lg:text-left"
            }  w-full md:border-t border-retake-light-border  dark:border-retake-dark-border  pt-10`}
          >
            {eventDetails.photographer.logo ? (
              <img
                className="w-36 hidden lg:block"
                src={eventDetails.photographer.logo}
                alt="logo"
              />
            ) : (
              <h3
                className={`leading-[1] text-2xl hidden md:block text-dark-gray dark:text-light-gray mr-[8px] md:mr-4`}
              >
                {eventDetails.photographer.brandName}
              </h3>
            )}

            <Text
              as="p"
              className="text-xs md:text-base text-dark-gray1 dark:text-light-gray pt-3"
            >
              {BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
                ? "Stay updated on all things BookMyShow at:"
                : `Check out ${
                    eventDetails.photographer.brandName ?? "photographer"
                  } here.`}
            </Text>
            <div
              className={`${
                isFullAccess ? "flex justify-center md:mb-5 mb-[21px]" : ""
              }`}
            >
              <ul className="flex justify-center pt-3 lg:justify-start">
                {socialMediaIcons.map(
                  (item) =>
                    eventDetails.photographer[item.name] && (
                      <li className="mr-4" key={item.name}>
                        <a
                          href={
                            eventDetails.photographer[item.name].indexOf(
                              "https://"
                            ) === 0
                              ? eventDetails.photographer[item.name]
                              : "https://" +
                                eventDetails.photographer[item.name]
                          }
                          target="_blank"
                        >
                          <img
                            className="drop-shadow-dark-image h-[24px] md:h-[30px] max-w-fit"
                            src={item.icon}
                            alt="side cover"
                          />
                        </a>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterStatus;
