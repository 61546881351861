/* This example requires Tailwind CSS v2.0+ */
import { useContext, useState, useEffect } from "react";

import InstagramIcon from "../../../assets/images/Instagram-icon.png";
import FacebookIcon from "../../../assets/images/Facebook-icon.png";
import TwitterIcon from "../../../assets/images/Twitter-icon.png";
import YoutubeIcon from "../../../assets/images/Youtube-icon.png";
import WhatsappIcon from "../../../assets/images/Whatsapp-icon.png";
import LinkedinIcon from "../../../assets/images/Linkedin-icon.png";
import MailIcon from "../../../assets/images/mail.jpg";
import "./Share.css";
import { InputEmail } from "../Input";
import LeftArrow from "../../../assets/icons/LeftArrow";
import { useParams } from "react-router-dom";
import { postSendEmail, getShareTemplateLists } from "../../../apis/events";
import { useEvent } from "../../../Store/event";


export default function Share({
  shareUrl,
  waDescription = "Check%20Out%20my%20Image",
  shareSearchId,
  searchId,
  eventId,
}) {
  const [show, setShow] = useState(false);
  const [showMailInput, setShowMailInput] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [shareTexts, setShareTexts] = useState([]);
  const [selectedTextId, setSelectedTextId] = useState('');

  const eventDetails = useEvent().response
  
  useEffect(() => {
    const fetchShareTexts = async () => {
      try {
        const response = await getShareTemplateLists(eventDetails.data.id);
        setShareTexts(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedTextId(response.data.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching share texts:', error);
      }
    };

    fetchShareTexts();
  }, [eventDetails.data.id]);

  const getSharingUrl = (platform, url) => {
    const selectedText = shareTexts.find(text => text.id == selectedTextId)?.share_message || waDescription;
    
    switch (platform) {
      case 'Facebook':
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(selectedText)}`;
      case 'Twitter':
        return `https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(selectedText)}`;
      case 'WhatsappIcon':
        return `https://wa.me/?text=${encodeURIComponent(selectedText)} ${url}`;
      case 'Linkedin':
        return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;
      default:
        return url;
    }
  };

  const socialMediaIcons = [
    {
      name: "Facebook",
      icon: FacebookIcon,
      href: getSharingUrl("Facebook", shareUrl),
    },
    {
      name: "Twitter",
      icon: TwitterIcon,
      href: getSharingUrl("Twitter", shareUrl),
    },
    {
      name: "WhatsappIcon",
      icon: WhatsappIcon,
      href: getSharingUrl("WhatsappIcon", shareUrl),
    },
    {
      name: "Linkedin",
      icon: LinkedinIcon,
      href: getSharingUrl("Linkedin", shareUrl),
    },
  ];

  const sendEmail = async (e) => {
    if (!emailValue || !eventId || !searchId) return;
    const payload = {
      eventId,
      searchId,
      email: emailValue,
    };
    try {
      setLoader(true);
      await postSendEmail(payload);
      window.Toast.fire({
        title: "Mail sent successfully",
        icon: "success",
      });
      setShowMailInput(false);
      setEmailValue("");
    } catch (error) {
      console.error(error);
      window.Toast.fire({
        title: error.response?.data?.message ?? "Something went wrong",
        icon: "error",
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="inline-block bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:mt-3 sm:align-middle sm:max-w-lg w-full ">
      <div className="sm:flex sm:items-start md:flex md:items-start lg:flex lg:items-start mb-12">
        <div className="mt-3 sm:mt-0 sm:text-left md:text-left lg:text-left w-full">
          <div className="mt-2">
            <div className="shareDiv shadow-light-share dark:shadow-dark-share px-2 py-7 rounded-xl flex">
              <p className="overflow-hidden whitespace-nowrap w-[70%]	sm:w-[80%] text-dark-gray1 text-sm dark:text-light-gray">
                {shareUrl}
              </p>
              <a
                onClick={() => {
                  setShow(true);
                  navigator.clipboard.writeText(shareUrl);
                }}
                className="cursor-pointer text-[#00b7b8] overflow-hidden whitespace-nowrap text-right w-[30%]	sm:w-[20%] text-sm font-semibold"
              >
                Copy Link
              </a>
            </div>
            {show ? (
              <span
                className="px-1"
                style={{ fontSize: 11, fontWeight: "bold", color: "#708757" }}
              >
                Link Copied to Clipboard
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="mb-6 px-2">
        <p className="text-sm font-medium mb-3 text-dark-gray1 dark:text-light-gray">Select sharing text:</p>
        <div className="space-y-3">
          {shareTexts.map((text) => (
            <div key={text.id} className="flex items-start space-x-2">
              <input
                type="radio"
                id={text.id}
                name="shareText"
                value={text.id}
                checked={selectedTextId == text.id}
                onChange={(e) => setSelectedTextId(e.target.value)}
                className="mt-3"
              />
              <label htmlFor={text.id} className="flex-1">
                <div className="shareDiv shadow-light-share dark:shadow-dark-share px-2 py-3 rounded-xl flex">
                  <input
                    type="text"
                    value={text.share_message}
                    readOnly
                    className="w-full bg-transparent text-dark-gray1 dark:text-light-gray text-sm outline-none"
                  />
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-5 px-1 sm:flex sm:flex-row">
        {socialMediaIcons.map((item, index) => (
          <div className="mr-6" key={index}>
            <a href={item.href} target="_blank">
              <img
                className="shadow-xl rounded-md"
                src={item.icon}
                alt="Share"
                width="30"
              />
            </a>
          </div>
        ))}

        {shareSearchId ? (
          <>
            <div className="mr-6">
              <button onClick={() => setShowMailInput((prev) => !prev)}>
                <img
                  className="shadow-xl rounded-md"
                  src={MailIcon}
                  alt="Share"
                  width="30"
                />
              </button>
            </div>
            {showMailInput ? (
              <div className="relative">
                <input
                  className="px-2 bg-theme-light dark:bg-dark-gray border-b border-b-black dark:border-b-theme-light outline-none"
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !loader) {
                      sendEmail(e);
                    }
                  }}
                />
                <div
                  onClick={() => (loader ? () => {} : sendEmail())}
                  className="absolute -right-4 bottom-[16px] ml-4 cursor-pointer"
                >
                  {loader ? (
                    <div className="clipPathLoader mx-auto"></div>
                  ) : (
                    <LeftArrow
                      height="12"
                      width="12"
                      color={
                        eventDetails.data.design.backgroundColor ==
                        "black"
                          ? "black"
                          : "#eee"
                      }
                      className="-rotate-90"
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}{" "}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
