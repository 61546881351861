import React from "react";

const ProfileIcon = (props) => {
  const { height = "18", width = "18", color = "white" } = props;
  return (
    //         <svg width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    //     <g transform="translate(1 1)" fill="none" fillRule="evenodd">
    //         <rect fill="#EEE" x="2.73" y="2.786" width="10.554" height="10.667" rx="5.277"/>
    //         <ellipse fill="#1A1A1A" cx="5.997" cy="5.928" rx="1.005" ry="1.016"/>
    //         <path d="M8.007 11.515a2.02 2.02 0 0 0 2.009-2.03c-.93-.005-3.04.003-4.017 0 0 1.121.899 2.03 2.008 2.03z" fill="#1A1A1A"/>
    //         <ellipse fill="#1A1A1A" cx="10.018" cy="5.928" rx="1.005" ry="1.016"/>
    //         <path stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" d="M3.42 16H0v-3.286M3.42 0H0v3.286M12.58 16H16v-3.286M12.58 0H16v3.286"/>
    //     </g>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 108 108"
    >
      <path
        d="M30.7688 99.3736H11.4674C10.4539 99.3736 9.48128 98.9709 8.76466 98.2543C8.04803 97.5377 7.64534 96.5651 7.64534 95.5516V76.0591C7.64534 74.6932 6.91677 73.4315 5.73431 72.7489C4.55185 72.0664 3.09469 72.0664 1.91225 72.7489C0.729814 73.4315 0.0012207 74.6932 0.0012207 76.0591V95.5516C0.0012207 98.5931 1.20925 101.509 3.35917 103.66C5.50994 105.81 8.42592 107.018 11.4674 107.018H30.7688C32.1347 107.018 33.3964 106.289 34.0789 105.107C34.7614 103.924 34.7614 102.467 34.0789 101.285C33.3964 100.102 32.1346 99.3736 30.7688 99.3736Z"
        fill={color}
      />
      <path
        d="M103.196 72.2369C102.182 72.2369 101.209 72.6396 100.493 73.3562C99.7761 74.0728 99.3734 75.0454 99.3734 76.0589V95.5514C99.3734 96.565 98.9708 97.5376 98.2541 98.2542C97.5375 98.9708 96.5649 99.3735 95.5514 99.3735H76.25C74.8841 99.3735 73.6224 100.102 72.9399 101.285C72.2574 102.467 72.2574 103.924 72.9399 105.107C73.6224 106.289 74.8842 107.018 76.25 107.018H95.5514C98.5929 107.018 101.509 105.81 103.66 103.66C105.81 101.509 107.018 98.5929 107.018 95.5514V76.0589C107.018 75.0454 106.615 74.0728 105.898 73.3562C105.182 72.6396 104.209 72.2369 103.196 72.2369Z"
        fill={color}
      />
      <path
        d="M3.82206 34.3985C4.83558 34.3985 5.80818 33.9958 6.5248 33.2792C7.24143 32.5626 7.64412 31.59 7.64412 30.5765V11.4662C7.64412 10.4527 8.04681 9.48006 8.76344 8.76343C9.48006 8.04681 10.4527 7.64412 11.4662 7.64412H30.7676C32.1335 7.64412 33.3952 6.91555 34.0777 5.73309C34.7602 4.55063 34.7602 3.09347 34.0777 1.91103C33.3952 0.728594 32.1334 0 30.7676 0H11.4662C8.42469 0 5.50879 1.20803 3.35795 3.35795C1.20803 5.50872 0 8.42469 0 11.4662V30.5765C0 31.59 0.402692 32.5626 1.11932 33.2792C1.83594 33.9958 2.80854 34.3985 3.82206 34.3985Z"
        fill={color}
      />
      <path
        d="M95.5514 0H76.25C74.8841 0 73.6224 0.728572 72.9399 1.91103C72.2574 3.09349 72.2574 4.55065 72.9399 5.73309C73.6224 6.91552 74.8842 7.64412 76.25 7.64412H95.5514C96.5649 7.64412 97.5375 8.04681 98.2541 8.76343C98.9708 9.48006 99.3734 10.4527 99.3734 11.4662V30.5765C99.3734 31.9423 100.102 33.2041 101.284 33.8866C102.467 34.5691 103.924 34.5691 105.107 33.8866C106.289 33.2041 107.018 31.9423 107.018 30.5765V11.4662C107.018 8.42469 105.81 5.50879 103.66 3.35795C101.509 1.20803 98.5929 0 95.5514 0Z"
        fill={color}
      />
      <path
        d="M45.8646 40.1315C45.8646 43.2975 43.2975 45.8646 40.1315 45.8646C36.9656 45.8646 34.3984 43.2975 34.3984 40.1315C34.3984 36.9656 36.9656 34.3984 40.1315 34.3984C43.2975 34.3984 45.8646 36.9656 45.8646 40.1315Z"
        fill={color}
      />
      <path
        d="M72.6192 40.1315C72.6192 43.2975 70.0521 45.8646 66.8861 45.8646C63.7201 45.8646 61.153 43.2975 61.153 40.1315C61.153 36.9656 63.7201 34.3984 66.8861 34.3984C70.0521 34.3984 72.6192 36.9656 72.6192 40.1315Z"
        fill={color}
      />
      <path
        stroke={color}
        d="M75.3863 62.3411C74.6875 61.6082 73.726 61.1816 72.7134 61.1569C71.7008 61.1313 70.7197 61.5092 69.9851 62.2071C65.5428 66.4387 59.6433 68.7985 53.5086 68.7985C47.3738 68.7985 41.4741 66.4387 37.032 62.2071C36.0415 61.2669 34.6252 60.9257 33.3157 61.3122C32.0052 61.6995 31.0019 62.7557 30.6819 64.0833C30.362 65.4116 30.7749 66.8089 31.7646 67.7492C37.6274 73.3329 45.413 76.4469 53.5086 76.4469C61.6041 76.4469 69.3898 73.3329 75.2526 67.7492C75.988 67.0504 76.4154 66.0881 76.441 65.0728C76.4658 64.0585 76.0861 63.0757 75.3865 62.3411H75.3863Z"
        fill={color}
      />
    </svg>
  );
};

export default ProfileIcon;
