import React from "react";

const CropIcon = props => {
    const {height = "16", width = "16", color = "white"} = props;
    return (
        <svg version="1.1" width={width} height={height} fill={color} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 512 512">
            <g>
                <path d="M358.4,409.6H153.6c-28.3,0-51.2-22.9-51.2-51.2V153.6H0v-51.2h102.4V0h51.2v358.4H512v51.2H409.6V512h-51.2V409.6z
		 M358.4,332.8V153.6H179.2v-51.2h179.2c28.3,0,51.2,22.9,51.2,51.2v179.2H358.4z"/>
            </g>
        </svg>
    );
};

export default CropIcon;
