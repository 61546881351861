import React, { useState } from "react";

import DialogWrapper from "../../../../components/common/Dialog";
import FaceSearchModal from "../../../../components/common/FaceSearchModal";
import { useEvent } from "../../../../Store/event";

const FaceSearchPopUp = (props) => {
  const { getter, setter , user } = props;
  const [loading, setLoading] = useState(
    !user.fname && !user.lname && !user.email
  );
  const [isCamera, setIsCamera] = useState(true);

  const eventDetails = useEvent().response;

  return (
    <DialogWrapper
      open={getter}
      close={() => {
        !loading && setter(false);
      }}
      title={!!user.fname && !!user.lname && !!user.email ? "" : "SCAN IN"}
      containerClassname={
        !loading &&
        !!user.fname &&
        !!user.lname &&
        !!user.email &&
        isCamera &&
        eventDetails.data.isLivenessEnabled
          ? `!max-w-[720px]`
          : ""
      }
      hideClose={eventDetails.data.isLivenessEnabled || loading}
      blockClassname={window.innerWidth < 500 ? "h-full min-h-full" : ""}
    >
      <FaceSearchModal
        close={() => {
          setter(false);
        }}
        isCamera={isCamera}
        setIsCamera={setIsCamera}
        loading={loading}
        setLoading={setLoading}
      />
    </DialogWrapper>
  );
};

export default FaceSearchPopUp;
