import React, { useEffect, useState } from "react";

import {
  BMS_PHOTOGRAPHER_ID,
  CHECKOUT_ADDRESS_DISABLED,
  DISABLE_ADDRESS_PAGE,
  EMIAL_REGEX,
  INDIAN_CURRENCY,
  MOBILE_REGEX_TO_CHECK_DIGITS,
  SHOW_MOBILE_INSTEAD_OF_EMAIL,
} from "../../../constants";
import { useEvent } from "../../../Store/event";
import Checkbox from "../Checkbox";
import Dropdown from "../Dropdown";
import { InputEmail, InputPhone, InputText } from "../Input";
import Text from "../Text";

function AddAddress({
  isModal = false,
  userName,
  setuserName,
  email,
  setemail,
  phoneNumber,
  setphoneNumber,
  address1,
  setaddress1,
  address2,
  setaddress2,
  country,
  setcountry,
  city,
  setcity,
  state,
  setstate,
  PostalCode,
  setPostalCode,
  baddress1,
  setbaddress1,
  baddress2,
  setbaddress2,
  bcountry,
  setbcountry,
  bcity,
  setbcity,
  bstate,
  setbstate,
  bPostalCode,
  setbPostalCode,
  setSameAddress,
  addaddress,
}) {
  const eventDetails = useEvent().response;

  const emailFieldIsMobile = SHOW_MOBILE_INSTEAD_OF_EMAIL.includes(
    eventDetails.photographer.id
  );

  const checkIfAddressIsNeeded = () =>
    DISABLE_ADDRESS_PAGE.includes(eventDetails.data.id)
      ? "algomage"
      : CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id)
      ? BMS_PHOTOGRAPHER_ID.includes(eventDetails.photographer.id)
        ? "lollapalooza"
        : "jio"
      : "";

  const [error, setError] = useState({});
  const [em, setEm] = useState(emailFieldIsMobile ? "" : email);
  const [localPostalCode, setLocalPostalCode] = useState();
  const [blocalPostalCode, setbLocalPostalCode] = useState();

  const [billingAddress, showBillingAddress] = useState(true);

  function localEmail(e) {
    setEm(e);
  }

  useEffect(() => {
    setstate(checkIfAddressIsNeeded());
    setcity(checkIfAddressIsNeeded());
    setPostalCode(checkIfAddressIsNeeded());
    setLocalPostalCode(checkIfAddressIsNeeded());
    setError({});
  }, [country]);

  const handleKeyDown = (e, p) => {
    var field;
    switch (p) {
      case "userName":
        field = userName;
        break;
      case "email":
        field = email;
        break;
      case "phoneNumber":
        field = phoneNumber;
        break;
      case "address1":
        field = address1;
        break;
      case "address2":
        field = address2;
        break;
      case "state":
        field = state;
        break;
      case "city":
        field = city;
        break;
      case "postalCode":
        field = PostalCode;
        if (
          e.keyCode == 8 ||
          e.keyCode == 46 ||
          e.keyCode == 37 ||
          e.keyCode == 39
        ) {
          return true;
        } else if (e.keyCode < 48 || e.keyCode > 57) {
          e.preventDefault();
        }
        break;
      case "baddress1":
        field = baddress1;
        break;
      case "baddress2":
        field = baddress2;
        break;
      case "bstate":
        field = bstate;
        break;
      case "bcity":
        field = bcity;
        break;
      case "bpostalCode":
        field = bPostalCode;
        if (
          e.keyCode == 8 ||
          e.keyCode == 46 ||
          e.keyCode == 37 ||
          e.keyCode == 39
        ) {
          return true;
        } else if (e.keyCode < 48 || e.keyCode > 57) {
          e.preventDefault();
        }
        break;
    }
    if (e.key === " " && field.length === 0) {
      e.preventDefault();
    }
  };

  const handleCheckbox = (e) => {
    setSameAddress(e.target.checked);
    showBillingAddress(e.target.checked ? false : true);
  };

  const checkEmail = (e) => {
    if (e.target.value === "" || EMIAL_REGEX.exec(e.target.value) === null) {
      setError((prev) => ({ ...prev, email: "Please enter email" }));
    } else setError((prev) => ({ ...prev, email: "" }));
  };

  const checkRequiredField = (e, field) => {
    if (e.target.value === "") {
      setError((prev) => ({
        ...prev,
        [field]: "Please fill the required field",
      }));
    } else setError((prev) => ({ ...prev, [field]: "" }));
    if (field === "phone") {
      if (e.target.value.length < 5)
        setError((prev) => ({
          ...prev,
          [field]: "Please enter the correct number",
        }));
      else setError((prev) => ({ ...prev, [field]: "" }));
    }
  };

  return (
    <div className="">
      {DISABLE_ADDRESS_PAGE.includes(eventDetails.data.id) ||
      CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id) ? (
        <></>
      ) : (
        <Text className="font-medium text-xl">Contact Information</Text>
      )}
      <div className="lg:px-1">
        <div className="mt-5 space-y-10">
          <div className="flex flex-wrap justify-between">
            <InputText
              autoFocus
              value={userName}
              label="Name"
              lableClassName={"mb-4 text-xs"}
              required
              onBlur={(e) => checkRequiredField(e, "userName")}
              error={error.userName}
              className="w-full lg:w-[350px]"
              onChange={setuserName}
              onKeyDown={(e) => handleKeyDown(e, "userName")}
            />

            <InputEmail
              value={em}
              label="Email"
              lableClassName={"mb-4 text-xs"}
              required
              onBlur={checkEmail}
              className="w-full lg:w-[350px] mt-6 lg:mt-0"
              onChange={(e) => {
                localEmail(e);
                setError({ ...error, email: "" });
                setemail(em);
              }}
              error={error.email}
              onKeyDown={(e) => handleKeyDown(e, "email")}
            />
          </div>

          <div className="my-3 w-full lg:w-[350px]">
            <InputPhone
              required
              onBlur={(e) => checkRequiredField(e, "phone")}
              error={error.phone}
              label="Phone number"
              lableClassName={"mb-4 text-xs"}
              value={phoneNumber}
              onChange={setphoneNumber}
              onKeyDown={(e) => handleKeyDown(e, "phoneNumber")}
            />
          </div>

          {/* <div className='flex space-x-2 my-10'>
                        <Checkbox />
                        <Text className='text-sm'>Keep me up to date with new products and offers</Text>
                    </div> */}
        </div>
      </div>
      {DISABLE_ADDRESS_PAGE.includes(eventDetails.data.id) ||
      CHECKOUT_ADDRESS_DISABLED.includes(eventDetails.data.id) ? (
        <></>
      ) : (
        <>
          <div className="mt-10">
            <Text className="text-sm text-xl font-medium">
              Shipping Address
            </Text>
            <div className="space-y-10 pt-5">
              <InputText
                label="Address line 1"
                lableClassName={"mb-4 text-xs"}
                required
                onBlur={(e) => checkRequiredField(e, "shippingAddressL1")}
                error={error.shippingAddressL1}
                value={address1}
                onChange={setaddress1}
                onKeyDown={(e) => handleKeyDown(e, "address1")}
              />
              <InputText
                label="Address line 2 (optional)"
                lableClassName={"mb-4 text-xs"}
                value={address2}
                onChange={setaddress2}
                onKeyDown={(e) => handleKeyDown(e, "address2")}
              />
              <div className="flex flex-wrap mt-5 justify-between items-start">
                <div className="flex flex-col w-full lg:w-[200px]">
                  <p className="mb-5 text-xs md:text-sm font-medium">
                    Country *
                  </p>
                  <Dropdown
                    current={country}
                    setCurrent={setcountry}
                    options={
                      eventDetails.photographer.printStoreCurrency ===
                      INDIAN_CURRENCY
                        ? ["India"]
                        : ["USA", "Canada"]
                    }
                    className=""
                  />
                </div>
                <InputText
                  lableClassName={"mb-5 text-xs"}
                  label="State"
                  required
                  onBlur={(e) => checkRequiredField(e, "shippingState")}
                  error={error.shippingState}
                  className="w-full lg:w-[200px] mt-6 lg:mt-0"
                  value={state}
                  onChange={setstate}
                  onKeyDown={(e) => handleKeyDown(e, "state")}
                />
                <InputText
                  lableClassName={"mb-5 text-xs"}
                  label="City"
                  required
                  onBlur={(e) => checkRequiredField(e, "shippingCity")}
                  error={error.shippingCity}
                  className="w-full lg:w-[200px] mt-6 lg:mt-0"
                  value={city}
                  onChange={setcity}
                  onKeyDown={(e) => handleKeyDown(e, "city")}
                />
                <InputText
                  lableClassName={"mb-5 text-xs"}
                  label="Postal code"
                  required
                  onBlur={(e) => checkRequiredField(e, "postalCode")}
                  className="w-full lg:w-[200px] mt-6 lg:mt-0"
                  value={localPostalCode}
                  onChange={(e) => {
                    setLocalPostalCode(e);
                    setError({ ...error, postalCode: "" });
                    setPostalCode(localPostalCode);
                  }}
                  onKeyDown={(e) => handleKeyDown(e, "postalCode")}
                  error={error.postalCode}
                />
              </div>
              {isModal && (
                <div className="flex space-x-2 my-10">
                  <Checkbox />
                  <Text>Make this my default address</Text>
                </div>
              )}

              {/* <div className='flex flex-col lg:flex-row justify-center lg:justify-start'>
                        {isModal && <button className='rounded-lg shadow-button py-[9px] px-11 font-semibold lg:mr-4 mt-4 lg:mt-0 order-2 lg:order-1'>Cancel</button>}
                        <Button className='order-1 lg:order-2' disabled={!areAllFieldsFilled} text="Save Address" onClick={addaddress} />
                        {!loading ?
                        <Button className='order-1 lg:order-2' disabled={!areAllFieldsFilled} text="Proceed to pay" onClick={()=>{addaddress(); showLoading(!loading) }} />
                        : <Button className='order-1 lg:order-2 cursor-none' text="Payment Processsing please Wait"/>} 
                    </div> */}
            </div>
            {isModal && <div className="mt-5"></div>}
          </div>

          <div className="mt-10">
            <Text className="text-sm text-xl font-medium">Billing Address</Text>
            <span>
              <Checkbox
                onChange={(e) => {
                  handleCheckbox(e);
                }}
                id="billingAddress"
                label="Same as Shipping address"
                lableClassName="text-xs"
                className={"shadow-light-checkbox"}
              />
            </span>
            {billingAddress && (
              <div className="space-y-10 pt-5">
                <InputText
                  label="Address line 1"
                  lableClassName={"mb-4 text-xs"}
                  required
                  onBlur={(e) => checkRequiredField(e, "billingAddressL1")}
                  error={error.billingAddressL1}
                  value={baddress1}
                  onChange={setbaddress1}
                  onKeyDown={(e) => handleKeyDown(e, "baddress1")}
                />
                <InputText
                  label="Address line 2 (optional)"
                  lableClassName={"mb-4 text-xs"}
                  value={baddress2}
                  onChange={setbaddress2}
                  onKeyDown={(e) => handleKeyDown(e, "baddress2")}
                />
                <div className="flex flex-wrap mt-5 justify-between items-start">
                  <div className="flex flex-col w-full lg:w-[200px]">
                    <p className="mb-5 text-xs md:text-sm font-medium">
                      Country *
                    </p>
                    <Dropdown
                      current={bcountry}
                      setCurrent={setbcountry}
                      options={
                        eventDetails.photographer.printStoreCurrency ===
                        INDIAN_CURRENCY
                          ? ["India"]
                          : ["USA", "Canada"]
                      }
                      className=""
                    />
                  </div>
                  <InputText
                    lableClassName={"mb-5 text-xs"}
                    label="State"
                    onBlur={(e) => checkRequiredField(e, "billingState")}
                    error={error.billingState}
                    required
                    className="w-full lg:w-[200px] mt-6 lg:mt-0"
                    value={bstate}
                    onChange={setbstate}
                    onKeyDown={(e) => handleKeyDown(e, "bstate")}
                  />
                  <InputText
                    lableClassName={"mb-5 text-xs"}
                    label="City"
                    onBlur={(e) => checkRequiredField(e, "billingCity")}
                    error={error.billingCity}
                    required
                    className="w-full lg:w-[200px] mt-6 lg:mt-0"
                    value={bcity}
                    onChange={setbcity}
                    onKeyDown={(e) => handleKeyDown(e, "bcity")}
                  />
                  <InputText
                    lableClassName={"mb-5 text-xs"}
                    label="Postal code"
                    onBlur={(e) => checkRequiredField(e, "bpostalCode")}
                    required
                    className="w-full lg:w-[200px] mt-6 lg:mt-0"
                    value={blocalPostalCode}
                    onChange={(e) => {
                      setbLocalPostalCode(e);
                      setError({ ...error, bpostalCode: "" });
                      setbPostalCode(blocalPostalCode);
                    }}
                    onKeyDown={(e) => handleKeyDown(e, "bpostalCode")}
                    error={error.bpostalCode}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default AddAddress;
