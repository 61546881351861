import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  InputText,
  InputEmail,
  InputPhone,
} from "../../../components/common/Input";
import Text from "../../../components/common/Text";
import BasicInfo from "../common/BasicInfo";
import Button from "../../../components/common/Button";
import Checkbox from "../../../components/common/Checkbox";
import Header from "../../GalleryView/components/Header";
import { ThemeContext } from "../../../components/App";
import { useUser } from "../../../Store/user";
import { useEvent } from "../../../Store/event";
import { loginFaceReg, postUpdateGuest } from "../../../apis/events";
import RegisterFace from "../../RegisterFace";
import { checkAccess } from "../../../apis/facesearch";
import {
  BMS_PHOTOGRAPHER_ID,
  EMIAL_REGEX,
  EVENT_WHERE_PHONE_IS_REQUIRED,
  EVENT_WITH_DIRECT_FACE_REC,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  MAX_FACE_REG_REACHED_POPUP,
  MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
  MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
  MOBILE_REGEX_TO_CHECK_DIGITS,
  PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED,
  PHOTOGRAPHER_WITH_DIRECT_FACE_REC,
  PIN_WHERE_PIN_IS_HARD_CODED,
  SAVE_FACE_REC_INFO,
  SHOW_MOBILE_INSTEAD_OF_EMAIL,
} from "../../../constants";
import DialogWrapper from "../../../components/common/Dialog";
import EmailPopUp from "../../../components/common/EmailPopUp";
import AddImages from "../../../components/common/AddImages";
import { FolderProvider } from "../../../Store/folder";
import ImageQueue from "../../../components/common/ImageQueue";
import { useUploader } from "../../../Store/upload";

function Signup() {
  const savedUserInfo = JSON.parse(localStorage.getItem(SAVE_FACE_REC_INFO));

  const { isUploading, filesToUpload } = useUploader();

  const className = useContext(ThemeContext);
  const { user, setUser } = useUser();
  const eventDetails = useEvent().response;

  const emailFieldIsMobile = SHOW_MOBILE_INSTEAD_OF_EMAIL.includes(
    eventDetails.photographer.id
  );

  const [email, setEmail] = useState(
    savedUserInfo?.checkbox ? user.email || savedUserInfo?.email : ""
  );
  const [fname, setFname] = useState(
    savedUserInfo?.checkbox ? user.fname || savedUserInfo?.fname : ""
  );
  const [lname, setLname] = useState(
    savedUserInfo?.checkbox ? user.lname || savedUserInfo?.lname : ""
  );
  const [phone, setPhone] = useState(
    savedUserInfo?.checkbox
      ? !!eventDetails.data.showMobileField
        ? user.phone || savedUserInfo?.phone
        : null
      : ""
  );
  const [emailError, setEmailError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [isDisabled, setDisable] = useState(true);
  const [allowed, setAllowed] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [checkbox, setCheckbox] = useState(savedUserInfo?.checkbox || false);
  const [emailRegisteredModal, setEmailRegisteredModal] = useState(false);
  const [currentView, setCurrentView] = useState("login");
  const [uploadFromCoverImage, setUploadFromCoverImage] = useState(false);
  const [showLoginModal, setLoginModal] = useState(false);
  const [registerButtonClicked, setRegisterButtonClicked] = useState(false);

  const onCheckboxClicked = (e) => setCheckbox(e.target.checked);

  const checkFName = (e) => {
    if (e.target.value === "" || e.target.value.trim().length < 2) {
      setFnameError("Please Enter Valid Name");
    } else setFnameError("");
  };

  const checkLName = (e) => {
    if (e.target.value === "" || e.target.value.trim().length < 2) {
      setLnameError("Please Enter Valid Name");
    } else setLnameError("");
  };

  const checkEmail = (e) => {
    if (
      emailFieldIsMobile
        ? e.target.value === "" ||
          MOBILE_REGEX_TO_CHECK_DIGITS.exec(e.target.value) === null
        : e.target.value === "" || EMIAL_REGEX.exec(e.target.value) === null
    ) {
      setEmailError(
        `Please enter valid ${
          emailFieldIsMobile ? "Mobile number" : "email address"
        }`
      );
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async () => {
    let error = false;
    setEmailError("");
    setFnameError("");
    setLnameError("");
    setUploadFromCoverImage(false);
    if (fname === "" || fname.trim().length < 2) {
      setFnameError("Please Enter Valid Name");
      error = true;
    }
    if (lname === "" || lname.trim().length < 2) {
      setLnameError("Please Enter Valid Name");
      error = true;
    }
    if (
      emailFieldIsMobile
        ? email === "" || MOBILE_REGEX_TO_CHECK_DIGITS.exec(email) === null
        : email === "" || EMIAL_REGEX.exec(email) === null
    ) {
      setEmailError(
        `Please enter valid ${
          emailFieldIsMobile ? "Mobile number" : "email address"
        }`
      );
      error = true;
    }
    if (!error) {
      if (checkbox) {
        localStorage.setItem(
          SAVE_FACE_REC_INFO,
          JSON.stringify({ email, fname, lname, phone, checkbox })
        );
      } else localStorage.removeItem(SAVE_FACE_REC_INFO);
      let newUser = {
        ...user,
        email,
        fname: fname.trim(),
        lname: lname.trim(),
        phone: emailFieldIsMobile ? email : phone,
        pin: user.pin || eventDetails.data.pin,
      };
      /**JIO EVENT */
      if (EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id))
        newUser.pin = "6868";
      /** BMS EVENT */ else if (eventDetails.data.id === 28578)
        newUser.pin = "9725";
      /** TEST EVENT */ else if (eventDetails.data.id === 28778)
        newUser.pin = "6436";
      /** Custom event */ else if (eventDetails.data.id === 8693)
        newUser.pin = 9417;
      /**BMS New event */ else if (eventDetails.data.id === 30128)
        newUser.pin = 9045;
      else if (
        Object.keys(PIN_WHERE_PIN_IS_HARD_CODED).includes(
          eventDetails.data.id?.toString()
        )
      ) {
        newUser.pin = PIN_WHERE_PIN_IS_HARD_CODED[eventDetails.data.id];
      }
      if (user.accessToken && user.email === newUser.email) {
        try {
          const { data } = await postUpdateGuest(
            eventDetails.data.id,
            newUser,
            user
          );
          setRegisterButtonClicked(true);
          let loggedInUserData = {
            ...newUser,
            isValid: true,
            id: data.data.id,
            accessToken: data.data.accessToken,
            previousImages: data.images,
            isFaceSearch: true,
          };
          if (Array.isArray(data.images) && !eventDetails.data.isPublished) {
            setEmailRegisteredModal(true);
          }
          setUser(loggedInUserData);
        } catch (error) {
          console.error(error);
          if (
            [
              MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
              MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
            ].includes(error?.response?.data?.message)
          ) {
            MAX_FACE_REG_REACHED_POPUP(!!eventDetails.data.isPublished);
          } else {
            setUser({ ...newUser, error: error?.response?.data?.message });
            setFnameError("Something Went Wrong Please Try Again");
          }
        }
      } else {
        let result = await loginFaceReg(eventDetails.data.id, newUser);
        if (result.error === false) {
          let loggedInUserData = {
            ...newUser,
            isValid: true,
            id: result.response.data.id,
            accessToken: result.response.data.accessToken,
            previousImages: result.response.images,
            isFaceSearch: true,
          };
          if (
            Array.isArray(result.response.images) &&
            !eventDetails.data.isPublished
          ) {
            setEmailRegisteredModal(true);
          }
          setRegisterButtonClicked(true);
          setUser(loggedInUserData);
        } else {
          if (
            [
              MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
              MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
            ].includes(result.message)
          ) {
            MAX_FACE_REG_REACHED_POPUP(!!eventDetails.data.isPublished);
          } else {
            setUser({ ...newUser, error: result.message });
            setFnameError("Something Went Wrong Please Try Again");
          }
        }
      }
    }
  };

  useEffect(() => {
    setDisable(true);
    if (email && fname && lname) {
      if (
        EVENT_WHERE_PHONE_IS_REQUIRED.includes(eventDetails.data.id) ||
        PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED.includes(
          eventDetails.photographer.id
        )
      ) {
        if (phone) setDisable(false);
      } else setDisable(false);
    }
  }, [email, fname, lname, phone]);

  const handleKeyDown = (e) => {
    if (
      (e.key === " " && fname.length === 0) ||
      (e.key === " " && lname.length === 0) ||
      (e.key === " " && phone.length === 0)
    ) {
      e.preventDefault();
    }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(async () => {
    var res = await checkAccess(eventDetails.photographer.id);
    if (!res.error) {
      setAllowed(res.response.faceSearchAccess);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const onWarningClosed = () => {
    setEmailRegisteredModal(false);
    setUser((prev) => ({
      ...prev,
      id: "",
      isValid: false,
      previousImages: null,
    }));
  };

  useEffect(() => {
    if (!isUploading) setUploadFromCoverImage(false);
  }, [isUploading]);

  useEffect(() => {
    const letsChatElement = document.getElementById("haptik-xdk-wrapper");
    if (isMobile) {
      if (letsChatElement) letsChatElement.style = "display:none";
    }
    if (user.id === 0) setRegisterButtonClicked(false);
    return () => {
      if (letsChatElement) letsChatElement.style = "";
    };
  }, [user.id]);

  useEffect(() => {
    if (currentView === "onGoingUpload" && filesToUpload.length < 1)
      setCurrentView("upload");
  }, [filesToUpload, currentView]);

  useEffect(() => {
    if (user.email) setEmail(user.email);
  }, [user.email]);

  const isMobile = width <= 768;
  return (
    <>
      {!emailRegisteredModal &&
      user.id &&
      user.fname &&
      user.lname &&
      !uploadFromCoverImage &&
      (registerButtonClicked ||
        PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
          eventDetails.photographer.id
        ) ||
        PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
          eventDetails.photographer.id
        ) ||
        EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id)) ? (
        <RegisterFace />
      ) : (
        <div className="relative text-dark-gray1 dark:text-light-gray">
          <div className="grid min-h-screen lg:grid-cols-coverImg bg-theme-light dark:bg-dark-gray relative">
            <Header isLogin={true} h={true} />
            {allowed ? (
              <div
                className={`lg:pl-20 ${
                  eventDetails.data.isGuestUploadEnabled &&
                  eventDetails.photographer.isGuestUploadEnabled
                    ? ""
                    : "lg:pt-[100px]"
                } lg:pr-16 md:p-8 pt-[26px] pb-[36px] px-6 relative overflow-hidden`}
              >
                {currentView === "login" ? (
                  <div>
                    <BasicInfo showBrandName={false} />
                    <Text
                      as="p"
                      className="text-xs md:text-base text-dark-gray1 pb-4 dark:text-light-gray"
                    >
                      Provide a few of your details
                    </Text>
                    <div className="lg:grid lg:grid-cols-2 lg:gap-5 pb-4">
                      <InputText
                        tabIndex={1}
                        className="mb-4 lg:mb-0"
                        label="First Name"
                        required
                        value={fname}
                        onChange={setFname}
                        error={fnameError}
                        onKeyDown={handleKeyDown}
                        lableClassName="text-xs md:text-sm"
                        name="fname"
                        onBlur={checkFName}
                      />
                      <InputText
                        tabIndex={2}
                        label="Last Name"
                        required
                        lableClassName="text-xs md:text-sm"
                        value={lname}
                        onChange={setLname}
                        error={lnameError}
                        onKeyDown={handleKeyDown}
                        onBlur={checkLName}
                      />
                    </div>
                    {emailFieldIsMobile ? (
                      <div className="flex flex-col pb-4">
                        <InputPhone
                          tabIndex={3}
                          autoFocus
                          label="Phone"
                          required
                          lableClassName="text-xs md:text-sm"
                          value={email}
                          onChange={setEmail}
                          error={emailError}
                          onBlur={checkEmail}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col pb-4">
                        <InputEmail
                          tabIndex={3}
                          label="Email"
                          required
                          lableClassName="text-xs md:text-sm"
                          value={email}
                          onChange={setEmail}
                          error={emailError}
                          onBlur={checkEmail}
                        />
                      </div>
                    )}
                    {(!!eventDetails.data.showMobileField ||
                      PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED.includes(
                        eventDetails.photographer.id
                      )) &&
                      !emailFieldIsMobile && (
                        <div className="flex flex-col pb-4">
                          <InputPhone
                            tabIndex={4}
                            label="Phone"
                            className={className}
                            value={phone}
                            onChange={setPhone}
                            onKeyDown={handleKeyDown}
                            lableClassName="text-xs md:text-sm"
                            required={
                              EVENT_WHERE_PHONE_IS_REQUIRED.includes(
                                eventDetails.data.id
                              ) ||
                              PHOTOGRAPHER_WHERE_PHONE_IS_REQUIRED.includes(
                                eventDetails.photographer.id
                              )
                            }
                          />
                        </div>
                      )}
                    <Checkbox
                      checked={savedUserInfo?.checkbox}
                      onChange={onCheckboxClicked}
                      id="rememberMe"
                      name="rememberMe"
                      label="Remember me"
                      lableClassName="text-xs"
                      className={
                        isMobile
                          ? "shadow-light-face-register-checkbox"
                          : "shadow-light-checkbox"
                      }
                    />

                    <div className="flex justify-center mt-[33px] md:mt-4">
                      <Button
                        disabled={isDisabled}
                        onClick={handleSubmit}
                        text="Find My Image"
                      />
                    </div>
                    {BMS_PHOTOGRAPHER_ID.includes(
                      eventDetails.photographer.id
                    ) ? (
                      <div className="mt-4 flex justify-center mx-auto text-xs text-dark-gray1 dark:text-light-gray">
                        By proceeding ahead, you understand and acknowledge that
                        you shall leave BookMyShow website and enter an
                        independent third party operated website/mobile
                        application operating under the brand name “Algomage”.
                        Refer to the{" "}
                        <Link
                          to="/privacy-policy"
                          target="_blank"
                          className="text-primary-green contents cursor-pointer underline"
                        >
                          terms and conditions
                        </Link>{" "}
                        page for further details.
                      </div>
                    ) : eventDetails.data.isGuestUploadEnabled &&
                      eventDetails.photographer.isGuestUploadEnabled ? (
                      <div className="relative ">
                        <div className="absolute w-[calc(100%+144px)] h-[1px] dark:bg-light-gray bg-black-gray -left-[80px] "></div>
                        <h2 className="mt-4 pt-4 text-xl font-medium">
                          Want to upload your own images to the lot?
                        </h2>
                        <div className="mt-4">
                          Click on the below button to add in images from your
                          device’s gallery on to the Event album.
                        </div>
                        <div className="mt-4 flex justify-center">
                          <Button
                            text="Upload Images"
                            onClick={() => {
                              setUploadFromCoverImage(true);
                              if (user.accessToken && user.id)
                                setCurrentView("upload");
                              else {
                                setLoginModal(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : currentView === "upload" ? (
                  <div>
                    <FolderProvider>
                      <h1 className="text-[28px] font-medium">
                        <span
                          onClick={() => {
                            setCurrentView("login");
                            setUploadFromCoverImage(false);
                          }}
                          className="absolute cursor-pointer left-[4px] md:left-[40px]"
                        >
                          &lt;
                        </span>{" "}
                        <span> Upload images to {eventDetails.data.name} </span>{" "}
                      </h1>
                      <AddImages
                        setCurrentView={setCurrentView}
                        coverImageUpload
                      />
                    </FolderProvider>
                  </div>
                ) : currentView === "onGoingUpload" ? (
                  <>
                    <h1 className="text-[28px] font-medium">
                      <span
                        onClick={() => {
                          if (isUploading) {
                            window.Toast.fire({
                              icon: "error",
                              title: "Upload is going on",
                            });
                            return;
                          }
                          setCurrentView("upload");
                          setUploadFromCoverImage(false);
                        }}
                        className="absolute cursor-pointer left-[4px] md:left-[40px]"
                      >
                        &lt;
                      </span>{" "}
                      <span> Upload images to {eventDetails.data.name} </span>{" "}
                    </h1>
                    <div className="flex justify-center flex-col">
                      <div className="flex items-center justify-between mt-2">
                        <p className="text-base text-dark-gray1 dark:text-light-gray">
                          Upload Queue
                        </p>
                      </div>
                      <ImageQueue
                        isBottom={true}
                        ulClass="max-h-[13rem] overflow-y-auto mb-[80px] md:mb-0"
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="flex lg:pl-24 lg:pt-[100px] lg:pr-16 md:p-8 pt-[26px] pb-[36px] px-6 relative justify-center items-center ">
                <h1 className="text-base md:text text-dark-gray1 pb-4 dark:text-light-gray">
                  This is disabled
                </h1>
              </div>
            )}
          </div>
        </div>
      )}
      {emailRegisteredModal && (
        <DialogWrapper
          title="Register Again?"
          open={emailRegisteredModal}
          close={onWarningClosed}
        >
          <div className="flex flex-col gap-8">
            {" "}
            <span className="text-xs text-dark-gray1 dark:text-light-gray">
              {`This email id is already registered with us for ${eventDetails.data.name}. Do you want add one more selfie to the same email id?`}
            </span>
            <div className="flex gap-8 mx-auto">
              <Button
                className="w-20"
                text="Yes"
                onClick={() => setEmailRegisteredModal(false)}
              />
              <Button text="No" className="w-20" onClick={onWarningClosed} />
            </div>
          </div>
        </DialogWrapper>
      )}
      {showLoginModal && (
        <DialogWrapper
          title="Login"
          open={showLoginModal}
          close={() => setLoginModal(false)}
        >
          <EmailPopUp
            setCurrentView={setCurrentView}
            faceRecLogin
            close={setLoginModal}
          />
        </DialogWrapper>
      )}
    </>
  );
}

export default Signup;
