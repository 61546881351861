import React from "react";

const HeartIcon = (props) => {
  const { height = "24", width = "24", color = "white" } = props;
  return (
    // <svg
    //     aria-hidden='true'
    //     focusable='false'
    //     data-prefix='fas'
    //     data-icon='heart'
    //     className='svg-inline--fa fa-heart fa-w-20'
    //     role='img'
    //     viewBox='0 0 512 512'
    //     height={height}
    //     width={width}
    // >
    //     <path
    //         fill={color}
    //         d='M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z'
    //     ></path>
    // </svg>
    //         <svg width={width} height={height} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    //     <g fill="none" fillRule="evenodd">
    //         <path d="M.5.5h24v24H.5z"/>
    //         <path d="M2.25 4.078a5.982 5.982 0 0 1 8.46 0l1.752 1.75 1.753-1.75a5.982 5.982 0 1 1 8.458 8.458L12.462 22.75 2.251 12.536a5.982 5.982 0 0 1 0-8.458z" fill={color} fillRule="nonzero"/>
    //     </g>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      width={width}
      height={height}
    >
      <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
    </svg>
  );
};

export default HeartIcon;
