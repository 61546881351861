/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from "react";
import { Fragment, useRef, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../../../assets/icons/CloseIcon";
import { ThemeContext } from "../../../components/App";

export default function DialogWrapper(props) {
  const {
    title,
    open,
    close,
    description,
    className,
    modal,
    hideTitle = false,
    hideClose = false,
    containerClassname,
    closeClassname = "",
    blockClassname = "",
  } = props;
  const theme = useContext(ThemeContext);
  const isDarkMode = theme === "dark";
  const cancelButtonRef = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleClose = (e) => {
    close(true);
  };

  const isMobile = width <= 768;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-[9999] inset-0 overflow-y-auto ${
          isDarkMode && "dark"
        } px-[18px]`}
        initialFocus={cancelButtonRef}
        onClose={(e) => {
          handleClose(e);
        }}
      >
        <div
          className={`flex items-end justify-center min-h-screen text-center sm:block sm:p-0 ${blockClassname}`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`fixed inset-0  ${
                isDarkMode
                  ? "bg-black bg-opacity-60"
                  : "bg-[#d6d6d6] bg-opacity-75"
              }  transition-opacity`}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${
                modal == "downloadModal" ? "max-w-4xl" : "sm:max-w-xl"
              } sm:w-full self-center ${containerClassname}`}
            >
              <div className="bg-theme-light dark:bg-dark-gray md:px-[1.875rem] px-[18px] pt-4 pb-4 sm:p-6 sm:pb-6">
                <div className="flex justify-between items-center md:mb-5 mb-[21px]">
                  {!hideTitle && (
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-dark-gray1 uppercase dark:text-light-gray font-medium text-xl md:text-3xl"
                      >
                        {title}
                      </Dialog.Title>
                      <p
                        className={`text-dark-gray1 dark:text-light-gray md:text-base text-xs ${className}`}
                      >
                        {description}
                      </p>
                    </div>
                  )}
                  {!hideClose && (
                    <span
                      className={`text-3xl cursor-pointer ${closeClassname}`}
                      onClick={close}
                    >
                      <CloseIcon
                        fill={`${isDarkMode ? "#eee" : "#222"}`}
                        w={isMobile ? "14" : "20"}
                        h={isMobile ? "14" : "20"}
                      />
                    </span>
                  )}
                </div>
                {props.children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
