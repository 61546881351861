import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import { getPhotographerDetails, guestDetails } from "../apis/events";
import { useEvent } from "./event";
import {
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  LOCALSTORAGE_ACCESS_TOKEN,
} from "../constants";
import { checkIsDeviceIOS } from "../helper";

const User = React.createContext();
User.displayName = "User";

export const useUser = () => React.useContext(User);
export const UserProvider = ({ children }) => {
  const eventDetails = useEvent().response;

  const isDeviceIOS = checkIsDeviceIOS();

  const INITIAL_USER = {
    email: "",
    password: eventDetails.data.passwordText || "",
    accessToken: "",
    jwt: "",
    isValid: false,
    isAdmin: false,
    error: "",
    pin: eventDetails.pin || eventDetails.data.pin || "",
    fname: "",
    lname: "",
    phone: "",
    id: 0,
    search_id: "",
    images: [],
    isPhotoGrapher: false,
  };

  const [requestOnGoing, setRequestOnGoing] = useState(true);

  let initialUser = { ...INITIAL_USER };
  let guestUser = JSON.parse(
    localStorage.getItem("userFor" + eventDetails.data.id)
  );

  if (
    guestUser &&
    Object.keys(guestUser).length &&
    guestUser.isFaceSearch &&
    window.location.pathname.includes("/view/") &&
    (eventDetails.data.requiresPassword || !eventDetails.data.isPublished)
  ) {
    guestUser = {};
    localStorage.removeItem("userFor" + eventDetails.data.id);
  }

  if (
    EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id) &&
    isDeviceIOS
  )
    delete guestUser?.search_id;

  let jwt = Cookies.get(LOCALSTORAGE_ACCESS_TOKEN);
  if (!jwt && guestUser && Object.keys(guestUser).length && guestUser.jwt) {
    initialUser.jwt = "";
    initialUser.isPhotoGrapher = false;
    initialUser.isAdmin = false;
    initialUser.isValid = false;
  } else if (
    guestUser &&
    Object.keys(guestUser).length &&
    eventDetails.data.isPublished
  ) {
    initialUser = guestUser;
  }

  const [user, setUser] = useState(initialUser);
  localStorage.setItem(
    "userFor" + eventDetails.data.id,
    JSON.stringify(initialUser)
  );

  const getUserDetails = async (accessToken) => {
    const { data } = await guestDetails(accessToken);
    //As user context is above router context can't use searchParams hook
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const view = params.get("view");
    const accessLevel = view == 1 ? "facerec" : "view";
    if (data.data) {
      setUser((prev) => ({
        ...prev,
        email: data.data.email,
        id: data.data.id,
        fname: data.data.name?.split(" ")[0],
        lname: data.data?.name?.split(" ")[1],
        search_id: data.data.searchId,
        accessToken: accessToken,
        phone: data.data?.phone,
        pin: eventDetails.pin || eventDetails.data.pin || "",
        accessLevel,
      }));
    }
  };

  const callPhotographerDetails = async () => {
    setRequestOnGoing(true);
    try {
      const { data } = await getPhotographerDetails();
      if (data.photographer.id === eventDetails.photographer.id) {
        setUser((prev) => ({
          ...prev,
          email: "",
          id: 0,
          accessToken: "",
          jwt: jwt,
          isPhotoGrapher: true,
          isAdmin: true,
          isValid: true,
          password: eventDetails.data.passwordText,
          pin: eventDetails.data.pin || eventDetails.pin,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRequestOnGoing(false);
    }
  };

  /** Searching if access token is present or not. If present fetching user details */
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const accessToken = params.get("at");
    if (accessToken) getUserDetails(accessToken);
  }, []);

  /** If jwt call photographer details API and get data */
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const accessToken = params.get("at");
    if (jwt && !accessToken) {
      callPhotographerDetails();
    } else setRequestOnGoing(false);
  }, []);

  useEffect(() => {
    if (eventDetails.data.isPublished || user.isFaceSearch) {
      localStorage.setItem(
        "userFor" + eventDetails.data.id,
        JSON.stringify(user)
      );
    }
  }, [user]);

  const resetUser = () => {
    setUser({ ...INITIAL_USER });
  };

  return (
    <User.Provider value={{ user, setUser, resetUser, requestOnGoing }}>
      {children}
    </User.Provider>
  );
};
